<div class="cursor-pointer" [ngClass]="cssClasses">
  <div class="form-check">
    <input
      type="checkbox"
      class="form-check-input"
      [id]="'custom-checkbox-' + name"
      [ngModel]="value"
      (ngModelChange)="onChange($event)"
      [disabled]="disabled"
    />
    <label [ngClass]="[text?.length? 'd-block':'', labelClasses]" class="form-check-label color-black" [for]="'custom-checkbox-' + name">{{ text }}</label>
  </div>
</div>
