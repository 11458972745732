import {Directive, ElementRef, Input, OnChanges, OnInit, Renderer2} from '@angular/core';
import {SearchService} from '../../../core/tree/search/search.service';

@Directive({
  selector: '[appHideOnSearchData]'
})
export class HideOnSearchDataDirective implements OnChanges {
  @Input() appHideOnSearchData: any;

  constructor(
    private elem: ElementRef, private renderer: Renderer2, private searchService: SearchService) {
  }

  ngOnChanges() {
    const hidden = this.appHideOnSearchData === 0 && this.searchService.isSearchActive();
    if (hidden) {
      this.renderer.setStyle(this.elem.nativeElement, 'display', 'none');
    } else {
      this.renderer.removeStyle(this.elem.nativeElement, 'display');
    }
  }
}
