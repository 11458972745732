import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { PropertyClassChangeEventEmitterComponent } from '../../../property-class-change.component';
import { ArticlePropertyClassInterface, ArticlePropertyClassPropertyInterface } from '../../../../core/models/configurator.model';

@Component({
  selector: 'app-default-properties',
  templateUrl: './default-properties.component.html',
  styleUrls: ['./default-properties.component.scss']
})
export class DefaultPropertiesComponent extends PropertyClassChangeEventEmitterComponent implements OnInit, OnChanges {

  @Input() propertyClass!: ArticlePropertyClassInterface;

  properties: ArticlePropertyClassPropertyInterface[] = [];

  constructor() {
    super();
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.propertyClass && changes.propertyClass.currentValue) {
      this.properties = this.propertyClass.properties;
    }
  }
}
