<app-generic-modal [closable]="false">
  <ng-template appModalHeader>
    <h4 class="modal-header__title">
      {{'DETECTED_AD_BLOCKER_MODAL.TITLE' | translate}}
    </h4>
  </ng-template>
  <ng-template appModalBody>
    <p>{{'DETECTED_AD_BLOCKER_MODAL.TEXT' | translate}}</p>
  </ng-template>
  <ng-template appModalFooter>
    <button class="btn btn-green" (click)="onRefresh()">{{'DETECTED_AD_BLOCKER_MODAL.BUTTON' | translate}}</button>
  </ng-template>
</app-generic-modal>
