import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { Router } from '@angular/router';
import { AuthConstants } from './auth.constants';
import { RoleInterface, UserInterface } from '../core/models/user.model';
import { ImpersonateService } from '../core/impersonate/impersonate.service';
import { map, tap } from 'rxjs/operators';
import { UserRole } from '../core/enums/user-role.enum';
import { RedirectConstants } from '../core/constants/redirect.constants';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private token: string;

  private authenticated$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private api: ApiService, private router: Router, private impersonateService: ImpersonateService) {
    this.initUserFromStorage();

    window.addEventListener('storage', (event) => {
      switch (event.key) {
        case AuthConstants.LOCAL_STORAGE_READY_KEY:
          this.initUserFromStorage();
          this.router.navigate(['/']);
          break;
        case null:
          this.logout();
          break;
        default:
          break;
      }
    });
  }

  initUserFromStorage() {
    const currentUser: UserInterface = this.getUser();
    this.authenticated$.next(this.isAuthenticated());
    this.token = currentUser ? currentUser.token : null;
  }

  getRealUser(): UserInterface {
    return JSON.parse(localStorage.getItem(AuthConstants.LOCAL_STORAGE_KEY)) || null;
  }

  getToken(): string {
    const user: UserInterface = this.getRealUser();
    return user && user.token ? user.token : null;
  }

  isAuthenticated(): boolean {
    const user = this.getUser();
    return !!user;
  }

  authenticated(authenticated: boolean) {
    this.authenticated$.next(authenticated);
  }

  authenticatedObservable(): Observable<boolean> {
    return this.authenticated$.asObservable();
  }

  login(username, password) {
    const body = { _username: username, _password: password };
    return this.api.post(AuthConstants.LOGIN_PATH, body).pipe(
      map(({ data, token }) => {
        if (!token) {
          throw new Error('Valid token not provided');
        }
        const role: RoleInterface = { name: data.role };
        const postLoginRedirect = JSON.parse(sessionStorage.getItem(RedirectConstants.SESSION_STORAGE_KEY));
        const externalRedirect = data.redirect;
        let internalRedirect = null;

        if (postLoginRedirect) {
          internalRedirect = postLoginRedirect.url;
          sessionStorage.removeItem(RedirectConstants.SESSION_STORAGE_KEY);
        } else if (role.name !== UserRole.ROLE_ADMIN) {
          internalRedirect = null;
        }
        return { externalRedirect, internalRedirect, token, role };
      }),
      tap(({ externalRedirect, internalRedirect, token, role }) => {
        this.token = token;
        localStorage.setItem(
          AuthConstants.LOCAL_STORAGE_KEY,
          JSON.stringify({ token, role, redirect: internalRedirect || externalRedirect })
        );
      })
    );
  }

  getUser(): UserInterface {
    return this.impersonateService.getUser() || this.getRealUser();
  }

  updateUser(user: UserInterface) {
    if (this.impersonateService.impersonated()) {
      return this.impersonateService.updateUser(user);
    }

    const currentUser: UserInterface = this.getUser();
    const updatedUser: UserInterface = { ...currentUser, ...user };

    localStorage.setItem(AuthConstants.LOCAL_STORAGE_KEY, JSON.stringify(updatedUser));
  }

  logout(expiredToken = false) {
    const clearData = () => {
      localStorage.clear();
      this.impersonateService.unImpersonate();
      this.api.clearCache();
      this.authenticated$.next(false);
      this.router.navigate(['/sign-in']);
    };
    // Clear user lastUpdatedOrder and lastUpdatedPageBreak information on logout
    const user = this.getUser();
    if (user && (user.lastUpdatedOrder || user.lastUpdatedPageBreak) && !expiredToken) {
      this.api.patch(`users/${user.id}`, { lastUpdatedOrder: null, lastUpdatedPageBreak: null }).subscribe(() => {
        this.updateUser({ ...user, lastUpdatedOrder: null, lastUpdatedPageBreak: null });
        clearData();
      });
    } else {
      clearData();
    }
  }
}
