import { Pipe, PipeTransform } from '@angular/core';
import { VisibilityDecisionMakerService } from '../visibility-decision-maker.service';

@Pipe({
  name: 'visibilityByVariables',
})
export class VisibilityByVariablesPipe implements PipeTransform {
  constructor(private visibilityDecisionMaker: VisibilityDecisionMakerService) {}

  transform(value: any, args?: { propertyKeys: string | string[]; replacement: string }): string {
    if (this.visibilityDecisionMaker.shouldHide(Array.isArray(args.propertyKeys) ? args.propertyKeys : [args.propertyKeys])) {
      return args.replacement;
    }
    return value;
  }
}
