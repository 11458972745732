import { RedirectInterface } from './role-redirector/model';
import { UserRole } from './core/enums/user-role.enum';
import { Observable } from 'rxjs';
import { OrderRoutePath } from './core/enums/route-types.enum';
import { TreeService } from './core/tree/tree.service';
import { ROLE_REDIRECTOR_REDIRECTS } from './role-redirector/injection.tokens';

export const redirects: RedirectInterface[] = [
  {
    roles: [UserRole.ROLE_DEALER, UserRole.ROLE_PM_NARBUTAS, UserRole.ROLE_ADMIN],
    redirect: function(treeService: TreeService) {
      return new Observable(observer => {
        observer.next(['news']);
        observer.complete();
      });
    },
    deps: [TreeService],
  },
  {
    roles: [UserRole.ROLE_PM, UserRole.ROLE_PM_RU],
    redirect: function() {
      return new Observable(observer => {
        observer.next([OrderRoutePath.ROOT, OrderRoutePath.WAITING]);
        observer.complete();
      });
    },
  },
];

export const REDIRECTS_FACTORY = () => {
  return redirects;
};

export const REDIRECTS_FACTORY_PROVIDER = {
  provide: ROLE_REDIRECTOR_REDIRECTS,
  useFactory: REDIRECTS_FACTORY,
};
