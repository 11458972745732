<div class="container">
  <div class="row">
    <div class="col col-lg-8 mx-auto pt-3">
      <h1 class="my-3">{{ 'SETTINGS.TITLE' | translate }}</h1>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-lg-8 mx-auto pb-3">
      <div class="card rounded-0 border-0 shadow-sm mb-3">
        <div class="card-header bg-white p-0">
          <ul class="nav nav-pills">
            <li class="nav-item">
              <a routerLink="basics" routerLinkActive="active" class="nav-link">{{ 'SETTINGS.BASICS_TITLE' | translate }}</a>
            </li>
            <li class="nav-item">
              <a routerLink="profile" routerLinkActive="active" class="nav-link">{{ 'SETTINGS.PROFILE_TITLE' | translate }}</a>
            </li>
            <li class="nav-item">
              <a routerLink="notifications" routerLinkActive="active" class="nav-link">{{ 'SETTINGS.NOTIFICATIONS_TITLE' | translate }}</a>
            </li>
            <li class="nav-item">
              <a routerLink="defaults" routerLinkActive="active" class="nav-link">{{ 'SETTINGS.DEFAULTS_TITLE' | translate }}</a>
            </li>
            <li class="nav-item">
              <a
                routerLink="shipping-addresses"
                routerLinkActive="active"
                class="nav-link"
                *permissionsElementVisibilityDecisionMaker="[permissionActions.CAN_EDIT_SHIPPING_ADDRESSES]"
              >
                {{ 'SETTINGS.SHIPPING_ADDRESSES_TITLE' | translate }}
              </a>
            </li>
            <li class="nav-item">
              <a
                routerLink="clients"
                routerLinkActive="active"
                class="nav-link"
                *permissionsElementVisibilityDecisionMaker="[permissionActions.CAN_EDIT_CUSTOMERS]"
              >
                {{ 'SETTINGS.CUSTOMERS_TITLE' | translate }}
              </a>
            </li>
          </ul>
        </div>
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
