import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../../api.service';
import { OrderInterface } from '../../../core/models/order.model';
import { CloseButtonTheme } from '../../../ui-elements/close-button/close-button-theme.enum';
import { LoaderComponent } from '../../../ui-elements/loader/loader.component';
import { TextFieldThemeTypes } from '../../../ui-elements/text-field/text-field/theme-types.enum';
import { ImportableItem, SeparatorOption } from './importable-item.model';
import { OptionValueModel } from '../../create-document-modal/document-template/option-value.model';

@Component({
  selector: 'app-import-csv-modal',
  templateUrl: './import-csv-modal.component.html',
  styleUrls: ['./import-csv-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ImportCsvModalComponent implements OnInit {
  @Input() order: OrderInterface;
  @ViewChild('loader', { static: true }) loader: LoaderComponent;

  @Output() confirm: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() cancel: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() formChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  preview = false;
  parsed: ImportableItem[] = [];
  incorrectFileType = false;
  textFieldThemeTypes = TextFieldThemeTypes;
  loading = false;
  filename = '';
  quantityWarning = false;
  unitPriceWarning = false;

  closeButtonTheme = CloseButtonTheme;

  selectedSeparator: SeparatorOption = {
    id: '\t',
    title: 'tab',
  };

  separators: SeparatorOption[] = [
    {
      id: '\t',
      title: 'tab',
    },
    {
      id: ',',
      title: ',',
    },
    {
      id: ';',
      title: ';',
    },
  ];

  form: UntypedFormGroup = new UntypedFormGroup({
    content: new UntypedFormControl(null, [Validators.required, Validators.maxLength(10000)]),
  });

  constructor(public activeModal: NgbActiveModal, protected api: ApiService) {}

  ngOnInit() {
    this.form.valueChanges.subscribe((event) => {
      if (event.content) {
        this.parsed = this.parseCsv(this.form.get('content').value);
      }

      this.formChange.emit(this.form.dirty);
    });
  }

  onCancel() {
    this.cancel.emit(true);
    this.activeModal.close();
  }

  onConfirm() {
    this.loader.show();
    this.api.post(`orders/${this.order.id}/import-csv`, { csv: this.convertToCSV(this.parsed) }).subscribe(
      (result) => {
        this.loader.hide();
        this.activeModal.close();

        // @ts-ignore: global window.dataLayer
        window.dataLayer.push({
          event: 'importCSV',
          url: window.location.href,
          order: this.order.id,
          item_count: this.parsed.length,
        });

        // this will be caught by controls component and will trigger order reload
        this.confirm.emit(true);
      },
      (error) => {
        // @ts-ignore: global window.dataLayer
        window.dataLayer.push({
          event: 'importFailed',
          url: window.location.href,
          order: this.order.id,
          item_count: 0,
          error:
            error.error && error.error.data && error.error.data[0] && error.error.data[0].error
              ? error.error.data[0].error.code
              : error.status || 'unknown',
        });
        this.loader.hide();
      }
    );
  }

  onSeparatorChange({ id, title }: OptionValueModel) {
    this.selectedSeparator = {
      id: `${id}`,
      title: title,
    };
    this.parsed = this.parseCsv(this.form.get('content').value);
  }

  onActivateFileInput(event: MouseEvent) {
    event.preventDefault();
    document.getElementById('csvFileUploadInput').click();
  }

  onFileChangeListener(event: any) {
    this.incorrectFileType = false;

    if (!event.target.files[0]) {
      this.filename = '';
      this.parsed = [];
      this.form.get('content').setValue('');

      return;
    }

    const file: File = event.target.files[0];
    const myReader: FileReader = new FileReader();
    this.filename = file.name;


    myReader.onloadend = (e) => {
      this.form.get('content').setValue(myReader.result.toString());
    };

    myReader.readAsText(file);
  }

  togglePreview() {
    this.preview = !this.preview;
  }

  parseCsv(content: string) {
    this.quantityWarning = false;
    this.unitPriceWarning = false;

    if (!content || !content.length) {
      return [];
    }

    const rows = content.split('\n');
    const result: ImportableItem[] = [];

    rows.forEach((val) => {
      const o = val.split(this.selectedSeparator.id); //'\t');
      if (o.length !== 6) {
        return;
      }

      if (isNaN(+o[1]) || +o[1] < 1) {
        this.quantityWarning = true;
      }

      if(isNaN(+o[4]) || +o[4] < 1) {
        this.unitPriceWarning = true;
      }

      result.push({
        fullCode: o[0].trim(),
        quantity: !isNaN(+o[1]) ? +o[1] : 0,
        title: o[2].trim(),
        group: o[3].trim() || '',
        unitSalePrice: +o[4] || null,
        discount: +o[5] || null,
      });
    });

    return result;
  }

  convertToCSV(objArray: ImportableItem[]) {
    if (!objArray || !objArray.length) {
      return '';
    }

    let str = '';

    for (let i = 0; i < objArray.length; i++) {
      let line = '';
      for (const index in objArray[i]) {
        if (line != '') line += '\t';

        line += objArray[i][index];
      }

      str += line + '\r\n';
    }

    return str;
  }
}
