import {InjectionToken, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ModuleWithProviders} from '@angular/core';
import {PermissionInterface, DecisionMakerService} from './decision-maker.service';
import {UserService} from './fake-user.service';
import {PermissionsUserService, PermissionsUserServiceInterface} from './user.model';
import {ElementVisibilityDecisionMakerDirective} from './element-visibility-decision-maker.directive';
import {PERMISSIONS, PERMISSIONS_DECISION_MAKER_SERVICE, ROLES} from "./injection-tokens";

export interface PermissionsModuleConfigInterface {
  permissions?: PermissionInterface[];
  roles?: string[];
  decisionMaker?: any;
  userService?: any;
}

const permissionsDecisionMakerServiceFactory = (permissions: PermissionInterface[],
                                                roles: string[],
                                                permissionsUserService: PermissionsUserServiceInterface) => {
  const service = new DecisionMakerService(permissionsUserService);

  service
    .setPermissions(permissions)
    .setRoles(roles);

  return service;
};

export const permissionsUserServiceProvider = {provide: PermissionsUserService, useClass: UserService};

export const permissionsDecisionMakerServiceProvider = {
  provide: PERMISSIONS_DECISION_MAKER_SERVICE,
  useFactory: permissionsDecisionMakerServiceFactory,
  deps: [PERMISSIONS, ROLES, PermissionsUserService]
};

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [ElementVisibilityDecisionMakerDirective],
  providers: [
    // permissionsDecisionMakerServiceProvider
  ],
  exports: [
    ElementVisibilityDecisionMakerDirective
  ]
})
export class PermissionsModule {
  static forRoot(config: PermissionsModuleConfigInterface = {}): ModuleWithProviders<PermissionsModule> {
    return {
      ngModule: PermissionsModule,
      providers: [
        {
          provide: PERMISSIONS, useValue: config.permissions,
        },
        {
          provide: ROLES, useValue: config.roles,
        },
        config.decisionMaker || permissionsDecisionMakerServiceProvider,
        config.userService || permissionsUserServiceProvider
      ]
    };
  }

  static forChild(config: PermissionsModuleConfigInterface = {}): ModuleWithProviders<PermissionsModule> {
    return PermissionsModule.forRoot(config);
  }
}
