import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'displayedValueLimit'
})
export class DisplayedValueLimitPipe implements PipeTransform {

  transform(value: any, args: number): any {
    if (value > args) {
      return `${args}+`;
    }
    return value;
  }

}
