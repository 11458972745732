import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextFieldComponent } from './text-field/text-field.component';
import { TextFieldErrorsDirective } from './text-field/directives/text-field-errors.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TextareaAutosizeDirective } from '../../shared/directives/textarea-autosize/textarea-autosize.directive';
import { TextFieldActionsDirective } from './text-field/directives/text-field-actions.directive';
import { SharedModule } from '../../shared/shared.module';
import { DatepickerModule } from '../datepicker/datepicker.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, FormsModule, TranslateModule, SharedModule, BsDatepickerModule, DatepickerModule],
  exports: [TextFieldComponent, TextFieldErrorsDirective, TextFieldActionsDirective, TextareaAutosizeDirective],
  declarations: [TextFieldComponent, TextFieldErrorsDirective, TextFieldActionsDirective, TextareaAutosizeDirective],
})
export class TextFieldModule {}
