import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DraggingDetectorService {
  private dragging$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  
  constructor() {
  }

  setStatus(status: boolean) {
    this.dragging$.next(status);
  }

  getStatusAsObservable(): Observable<boolean> {
    return this.dragging$.asObservable();
  }
}
