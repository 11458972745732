import { Component, OnInit, OnChanges, Input, SimpleChanges, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  ArticlePropertyClassPropertyInterface,
  MigrationChangeInterface,
  MigrationLogEntryPropInterface,
  ProductResponseAdditionalDataInterface
} from '../../../core/models/configurator.model';
import { ConfiguratorModalService } from '../../configurator-modal.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-migration-issues-tooltip',
  templateUrl: './migration-issues-tooltip.component.html',
})
export class MigrationIssuesTooltipComponent implements OnInit, OnChanges, OnDestroy {
  @Input() property!: ArticlePropertyClassPropertyInterface;
  @Input() hideMigrationIssues: boolean = false;
  @Input() additionalData: ProductResponseAdditionalDataInterface;

  public migrationTooltipText = '';
  public from = '';
  public to = '';
  public acceptedMigrationsMap: Map<string, MigrationLogEntryPropInterface> = new Map();
  public articleChanges: MigrationChangeInterface;

  private subscriptions: Subscription = new Subscription();

  constructor(private translate: TranslateService, private configuratorModalService: ConfiguratorModalService) { }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  /**
   * Strips 'G' and 'S' from beginning of string if applicable
   * @param originalName string
   * @returns string
   */
  private cleanArticleOriginalName(originalName: string): string {
    if (
      (originalName.startsWith('G') || originalName.startsWith('S')) &&
      originalName.slice(1, 2).toLocaleUpperCase() === originalName.slice(1, 2)
    ) {
      return originalName.substring(1);
    }

    return originalName;
  }

  ngOnInit() {
    this.subscriptions.add(
      this.configuratorModalService.acceptedMigrationsMapObservable().subscribe((map) => {
        this.acceptedMigrationsMap = map;
      })
    )
  }

  ngOnChanges(changes: SimpleChanges) {
    this.articleChanges = this.getPropertyMigrationFromAdditionalData();

    let tooltipText = '';
    if (!this.property.migratedFrom || this.hideMigrationIssues) {
      this.migrationTooltipText = tooltipText;

      return;
    }

    const safeOriginalName = this.cleanArticleOriginalName(this.property.originalName);

    if (this.property.migratedFrom.key === safeOriginalName) {
      if (this.property.migratedFrom.codeTo === '') {
        // property was removed
        this.translate
          .get('CONFIGURATOR_MODAL.ARTICLE_MIGRATION.PROPERTY_REMOVED', {
            from: this.articleChanges?.Props[safeOriginalName]?.FromValue.Title ?? this.property.migratedFrom.codeFrom,
          })
          .subscribe(translation => {
            tooltipText = translation;
          });
      } else if (this.property.migratedFrom.codeFrom === '' && this.property.migratedFrom.codeTo !== '') {
        // added
        this.translate
          .get('CONFIGURATOR_MODAL.ARTICLE_MIGRATION.PROPERTY_ADDED', {
            to: this.property.currentValue.title ?? this.property.migratedFrom.codeTo,
          })
          .subscribe(translation => {
            tooltipText = translation;
          });
      } else {
        // changes
        this.translate
          .get('CONFIGURATOR_MODAL.ARTICLE_MIGRATION.PROPERTY_CHANGED', {
            from: this.articleChanges?.Props[safeOriginalName]?.FromValue.Title ?? this.property.migratedFrom.codeFrom,
            to: this.property.currentValue.title ?? this.property.migratedFrom.codeTo,
          })
          .subscribe(translation => {
            tooltipText = translation;
          });
      }
    }

    /**
     * migrated from and propertyChanges are bypassed directly from concache system
     * and it uses it's own naming scheme and not a camelCase (To, From, etc.)
     */
    let defaultFrom = this.articleChanges?.Props[this.property.originalName]?.FromValue.Title;
    if (!defaultFrom && this.property.migratedFrom.propertyChanges) {
      defaultFrom = this.property.migratedFrom.propertyChanges[safeOriginalName]?.From;
    }

    let defaultTo = this.property.currentValue.title;
    if (!defaultTo && this.property.migratedFrom.propertyChanges) {
      defaultTo = this.property.migratedFrom.propertyChanges[safeOriginalName]?.To;
    }

    if (this.property.migratedFrom.propertyChanges && this.property.migratedFrom.propertyChanges[safeOriginalName] !== undefined) {
      if (this.property.migratedFrom.propertyChanges[safeOriginalName]?.To === '') {
        this.from = defaultFrom;

        // removed
        this.translate
          .get('CONFIGURATOR_MODAL.ARTICLE_MIGRATION.VALUE_REMOVED', {
            from: defaultFrom,
          })
          .subscribe(translation => {
            tooltipText = translation;
          });
      } else if (this.property.migratedFrom.propertyChanges[safeOriginalName].From === '') {
        this.to = defaultTo;
        // added
        this.translate.get('CONFIGURATOR_MODAL.ARTICLE_MIGRATION.VALUE_ADDED').subscribe(translation => {
          tooltipText = translation;
        });
      } else {
        this.from = defaultFrom;
        this.to = defaultTo;

        this.translate
          .get('CONFIGURATOR_MODAL.ARTICLE_MIGRATION.VALUE_CHANGED', {
            from: defaultFrom,
            to: defaultTo,
          })
          .subscribe(translation => {
            tooltipText = translation;
          });
      }
    }

    this.migrationTooltipText = tooltipText;
  }

  onMarkChangeAsAccepted(event, property) {
    this.configuratorModalService.markChangeAsAccepted(property);
  }

  private getPropertyMigrationFromAdditionalData(): MigrationChangeInterface {
    if (!this.additionalData || !this.additionalData.migration || !this.additionalData.migration.Changes) {
      return;
    }

    return this.additionalData.migration.Changes.find(migration => migration.ItemID === this.property.configuratorId);
  }
}
