import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {PermissionsUserInterface, PermissionsUserService, PermissionsUserServiceInterface} from './user.model';
import {UserInterface} from '../core/models/user.model';

export interface PermissionInterface {
  key: string;
  value: string[];
}

export interface PermissionsDecisionMakerServiceInterface {
  shouldAllow(actions: string[]): Observable<boolean>;

  setPermissions(permissions: PermissionInterface[]);

  setRoles(roles: string[]);
}

export abstract class PermissionsDecisionMakerService implements PermissionsDecisionMakerServiceInterface {
  abstract shouldAllow(actions: string[]): Observable<boolean>;

  abstract setPermissions(permissions: PermissionInterface[]);

  abstract setRoles(roles: string[]);
}

@Injectable()
export class DecisionMakerService extends PermissionsDecisionMakerService {
  private roles: string[] = [];
  private permissions: PermissionInterface[] = [];

  constructor(private permissionsUserService: PermissionsUserService) {
    super();
  }

  setPermissions(permissions: PermissionInterface[] = []) {
    this.permissions = permissions;
    return this;
  }

  setRoles(roles: string[] = []) {
    this.roles = roles;
    return this;
  }

  shouldAllow(actions: string[]): Observable<boolean> {
    return new Observable(observer => {
      this.permissionsUserService.getUser().subscribe((user: PermissionsUserInterface) => {
        const shouldAllow = this.deepCheck(actions, user);
        observer.next(shouldAllow);
        observer.complete();
      });
    });
  }

  private deepCheck(actions: string[], user: UserInterface): boolean {
    return 1
      && user?.role?.name
      && this.roles.includes(user.role.name)
      && this.permissions.filter(
        (permission: PermissionInterface) => 1
          && actions.includes(permission.key)
          && permission.value.includes(user.role.name)
      ).length > 0;
  }
}
