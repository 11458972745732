export enum UserRole {
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_DEALER = 'ROLE_DEALER',
  ROLE_PM = 'ROLE_PM',
  ROLE_PM_NARBUTAS = 'ROLE_PM_NARBUTAS',
  ROLE_PM_RU = 'ROLE_PM_RU'
}


/**
 * @TODO: If anyone is willing to explain to me what is the problem here - please do let me know -
 * https://stackoverflow.com/questions/51707220/angular-typescript-enum-to-array-is-null-in-module-forroot-configuration
 */
export const UserRolesArray = ['ROLE_ADMIN', 'ROLE_DEALER', 'ROLE_PM', 'ROLE_PM_NARBUTAS', 'ROLE_PM_RU'];
