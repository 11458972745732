import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DetectedAdblockerModalComponent } from './detected-adblocker-modal/detected-adblocker-modal.component';
import { GenericModalModule } from '../generic-modal/generic-modal.module';
import { SharedModule } from '../../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    GenericModalModule,
    SharedModule,
    TranslateModule
  ],
  declarations: [DetectedAdblockerModalComponent],
  exports: [DetectedAdblockerModalComponent]
})
export class DetectedAdblockerModalModule {
}
