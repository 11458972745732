<div class="customer-list__wrapper" [ngSwitch]="selectedMode">
  <ul class="list-unstyled customer-list m-0 overflow-auto" *ngSwitchCase="customerMode.LIST">
    <app-loader #loader [cssClasses]="'loader--relative'"></app-loader>
    <li
      class="d-flex bg-color-white hover-color-black hover-bg-white cursor-pointer p-3 pe-4 justify-content-between border-bottom"
      *ngFor="let client of clients"
      (click)="onClientSelect(client)"
      [class.bg-gray-200]="selected?.id !== client.id"
    >
      <span class="ms-2 d-flex align-items-center">
        <i class="icon ni-office me-3"></i>
        <span
          class="max-text-lines-2 text-break--all text-start"
          [class.color-black]="selected?.id === client.id"
        >{{ client.companyName }} 
          &bull; {{ client?.address }}, 
          {{ client?.city }}, 
          {{ client?.country?.title || client?.country?.code }}
        </span>
      </span>
      <i *ngIf="selected?.id === client.id" class="icon ni-tick-circle color-primary"></i>
    </li>
  </ul>

  <div class="bg-white" *ngSwitchCase="customerMode.NEW">
    <div class="p-3 px-4 overflow-auto mvh-sm-60">
      <app-client-form [countries]="countries" (changed)="onObjectChanged($event)"></app-client-form>
    </div>
    <div class="d-flex customer-form-buttons border-top border-color-gray-400">
      <button class="btn btn-lg btn-negative w-50 rounded-0" (click)="onCancel()">
        <span> {{ 'CUSTOMERS_LIST.CANCEL' | translate }} </span>
      </button>
      <button class="btn btn-lg btn-primary w-50 rounded-0" (click)="onCreateClient()" [disabled]="!formValid">
        <span>{{ 'CUSTOMERS_LIST.SAVE_NEW_CLIENT' | translate }}</span>
      </button>
    </div>
  </div>
</div>
