import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { Theme } from '../../ui-elements/accordion-toggler/accordion-toggler-themes.enum';
import { ConfiguratorModalService } from '../configurator-modal.service';
import { TemplatePortal } from '@angular/cdk/portal';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import {
  ArticlePropertyClassPropertyInterface,
  ArticlePropertyValueInterface,
  ProductResponseAdditionalDataInterface
} from '../../core/models/configurator.model';
import { Subscription } from 'rxjs';

export enum PropertyValueSelectorThemes {
  LIST = 'list',
  IMAGES_LIST = 'images-list',
  IMAGES_SMALL = 'images-small',
  IMAGES_LARGE = 'images-large',
}

export interface PropertyValueChangeEventInterface {
  configuratorId: string;
  propertyClass: string;
  propertyName: string;
  valueFrom: string;
  orderArticle?: number;
}

export enum PropertyTypes {
  ADDITIONAL_PART = 'additional-part',
  ADDED_ADDITIONAL_PART = 'added-additional-part',
  SIMPLE = 'simple',
  DEFAULT = 'simple',
}

export const ITEMS_IN_ROW_LIMIT = 6;

@Component({
  selector: 'app-property-values-selector',
  templateUrl: './property-values-selector.component.html',
  styleUrls: ['./property-values-selector.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PropertyValuesSelectorComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('triggerElement', { read: ElementRef }) private triggerElement: ElementRef<HTMLElement>;
  @ViewChild('popupTemplate') private popupTemplate: TemplateRef<any>;

  @Input() property: ArticlePropertyClassPropertyInterface;
  @Input() accordionTheme: Theme = Theme.DEFAULT;
  @Input() showTranslatedSelected? = true;
  @Input() expanded = false;
  @Input() showOriginalSelected = true;
  @Input() theme: PropertyValueSelectorThemes = PropertyValueSelectorThemes.LIST;
  @Input() name?: null;
  @Input() propertyType: PropertyTypes[] = [PropertyTypes.SIMPLE];
  @Input() actionButtonTemplate: TemplateRef<any> | null = null;
  @Input() isRootControllingProperty = false;

  @Output() propertyChange = new EventEmitter<PropertyValueChangeEventInterface>();

  selectedPropertyValue: ArticlePropertyValueInterface | undefined;
  hideMigrationIssues = false;
  isEmptyValueSelected = false;
  accordionThemes = Theme;
  propertyValueSelectorThemes = PropertyValueSelectorThemes;
  propertyTypes = PropertyTypes;
  itemsInRowLimit = ITEMS_IN_ROW_LIMIT; // used only for grid layout
  initLoad = true;
  additionalData: ProductResponseAdditionalDataInterface;

  private propertyValueOverlay: OverlayRef | null = null;
  private subscriptions: Subscription = new Subscription();
  selectedArticleId: string;

  constructor(
    private configuratorModalService: ConfiguratorModalService,
    @Inject(Overlay) private overlay: Overlay,
    @Inject(ViewContainerRef) private _viewContainerRef: ViewContainerRef,
    @Inject(ChangeDetectorRef) private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.configuratorModalService.hideMigrationIssuesObservable().subscribe((hideMigrationIssuesValue) => {
      this.hideMigrationIssues = hideMigrationIssuesValue;
    });

    this.subscriptions.add(
      this.configuratorModalService.getSelectedArticleIdAsObservable().subscribe(id => {
        this.selectedArticleId = id;
      })
    );

    this.subscriptions.add(
      this.configuratorModalService.additionalDataObservable().subscribe((data) => {
        this.additionalData = data;
      })
    )
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.property && changes.property.currentValue) {
      if (!this.property.editable) {
        this.accordionTheme = Theme.NON_EDITABLE;
      }
      this.selectedPropertyValue = this.property.currentValue;
      this.isEmptyValueSelected = this.property.emptyValue === this.property.currentValue.valueFrom;
      this.property.shouldDisplayPropertyImages = this.shouldDisplayPropertyImage();
    }
  }

  private shouldDisplayPropertyImage(): boolean {
    return this.property.articlePropertyValues.filter((value) => value.img).length >= this.property.articlePropertyValues.length / 2;
  }

  onPropertyChange(propertyName: string, valueFrom: string, configuratorId: string, propertyClass: string) {
    this.closeOverlay();
    window.dataLayer.push({
      event: 'configuratorPropertyChanged',
      prop_name: propertyName,
      prop_value: valueFrom,
    });

    this.configuratorModalService.markChangeAsAccepted(this.property);

    this.propertyChange.emit({ propertyName, valueFrom, configuratorId, propertyClass });
  }

  openOrSelectArticle(event: MouseEvent) {
    event.stopPropagation();

    if (this.property.controlledItemID && this.selectedArticleId !== this.property.controlledItemID && !this.isRootControllingProperty) {
      this.configuratorModalService.setSelectedArticleId(this.property.controlledItemID);

      return;
    }

    this.open();
  }

  open() {
    if (!this.property.editable) {
      return;
    }

    const { nativeElement } = this.triggerElement;

    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo(nativeElement)
      .withPositions([{
        originX: 'start',
        originY: 'top',
        overlayX: 'start',
        overlayY: 'top',
      }]);

    positionStrategy.positionChanges.subscribe(() => {
      this.changeDetectorRef.detectChanges();
    });

    const overlayRef = this.overlay.create({
      hasBackdrop: true,
      disposeOnNavigation: true,
      width: `${nativeElement.clientWidth}px`,
      positionStrategy
    });

    overlayRef.backdropClick().subscribe(() => {
      overlayRef.detach();
      this.changeDetectorRef.detectChanges();
    });

    const componentPortal = new TemplatePortal(this.popupTemplate, this._viewContainerRef);
    overlayRef.attach(componentPortal);

    this.propertyValueOverlay = overlayRef;
    this.propertyValueOverlay?.updatePosition();
  }

  closeOverlay() {
    this.propertyValueOverlay?.detach();
    this.propertyValueOverlay = null;

    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
