import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  ActivatedRoute
} from '@angular/router';
import { Observable, Subscription, first, forkJoin, map, tap } from 'rxjs';
import { TreeService } from '../core/tree/tree.service';
import { ContentService } from './content.service';
import { RouteTypeEnums } from '../core/enums/route-types.enum';
import { ContentTreeModel } from './content-tree.model';
import { NavbarElementInterface } from '../core/tree/navbar-elements';
import { DOCUMENT } from '@angular/common';

/** Performs actions shared between different content pages */
@Component({
  selector: 'app-content-parent',
  template: `<router-outlet></router-outlet>`
})
export class ContentParentComponent implements OnInit , OnDestroy{
  private subscriptions = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private treeService: TreeService,
    private contentService: ContentService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(this.getContentSubnavigationItems().subscribe());
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private getContentSubnavigationItems(): Observable<NavbarElementInterface[]> {
    const mainNavElement = this.treeService.getRootNavElement(RouteTypeEnums.INFO);
    const routeData = this.route.snapshot.data;
    const rootUri = routeData.uri ?? null;
    if (null === rootUri) {
      mainNavElement.children = [];
      this.treeService.modifyState({first: mainNavElement});
      return;
    }

    return this.getCategories(rootUri).pipe(
      map((categories: ContentTreeModel.Data[]): NavbarElementInterface[] => {
        const navElements: NavbarElementInterface[] = categories.map((category) => ({
          id: category.id,
          title: category.basicTranslation?.title,
          path: category.basicTranslation?.uri,
          externalPath: category.link,
          externalPathTarget: category.linkTarget,
          children: [],
          sortable: false,
        }));

        return navElements;
      }),
      tap((items) => {
        mainNavElement.children = items;
        this.treeService.modifyState({ first: mainNavElement });
        this.markSubmenuCurrentItem();
      })
    );
  }

  private getCategories(rootUri: string | null) {
    return this.contentService.getContentTree(rootUri).pipe(
      first(),
      map((response) => response.data)
    );
  }

  private markSubmenuCurrentItem() {
    const categoryUri = this.document.location.pathname.split('/')[2] ?? null;
    let current = null;

    if (null !== categoryUri) {
      current = this.treeService
        .getRootNavElement(RouteTypeEnums.INFO)
        .children
        .find(category => category.path === categoryUri);
    }

    this.treeService.modifyState({second: current});
  }
}
