<nav class="w-100 navbar-third"
     (clickOutside)="onNavbarClose()"
     [exclude]="'.messages-modal, .nav-link, nav'"
     [excludeBeforeClick]="true"
     [style.top.px]="resizeObservser.getCurrentValue().navbarBorderBox?.height || 0"
>
  <div class="nav-wrapper">
    <div class="bg-gray-200">
      <div class="container-xxl">
        <div class="row navbar-row px-md-3 py-3">
          <div class="outer py-3 ps-0"
               [ngClass]="isDetailsBlockHidden ? 'col' : 'col-9'"
               [style.height.px]="flattenElements.length * rowHeight"
          >
            <app-loader #menuItemsLoader></app-loader>
            <div class="outer__flexable-nav" #htmlElementsBounds>
              <ng-container *ngFor="let element of flattenElements; let i = index">
                <div
                  #htmlElements
                  class="category-group__link hover-color-primary"
                  [id]="generateIdByCategoryPathId(element.pathId)"
                  [ngClass]="{
                        'category-group__link--child': element.parent,
                        'category-group__link--parent d-flex align-items-center': !element.parent,
                        'ms-4 ps-2': this.parentElement.id === CatalogueRouteType.CATEGORY && element.parent
                      }"
                  [class.category-group__link--child--active]="element.parent && hoveredSubCategory?.id === element?.id"
                  [class.category-group]="!flattenElements[i + 1]?.parent"
                  (click)="onClick(element)"
                  (mouseenter)="element.parent && onMouseEnter(element)"
                  (mouseleave)="element.parent && onMouseLeave()"
                >
                  <i
                    class="icon icon-large me-1"
                    [ngClass]="iconToCategoryMap[generateIdByCategoryPathId(element.pathId)]"
                    *ngIf="!element.parent && iconToCategoryMap && iconToCategoryMap[generateIdByCategoryPathId(element.pathId)]"
                  ></i>
                  <span [ngClass]="{ 'ps-1': this.parentElement.id === CatalogueRouteType.CATEGORY && element.parent }">
                    {{ element.isNameModified ? element.name : (element.name | titlecase) }}
                  </span>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="col-3 my-n3 pe-0" *ngIf="!isDetailsBlockHidden">
            <app-navbar-details *ngIf="hoveredSubCategory" [subCategory]="hoveredSubCategory.id"></app-navbar-details>
          </div>
        </div>
      </div>
    </div>
    <div class="navbar-background" (click)="onNavbarClose()"></div>
  </div>
</nav>
