import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { UserService } from '../../../core/services/user/user.service';
import { UserInterface, UserUpdateInterface } from '../../../core/models/user.model';
import { CountryInterface } from '../../../core/models/country.model';
import { TranslateService } from '@ngx-translate/core';
import { LoaderComponent } from '../../../ui-elements/loader/loader.component';
import { CountryService } from '../../../core/services/country/country.service';
import { TextFieldThemeTypes } from '../../../ui-elements/text-field/text-field/theme-types.enum';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ToastService } from '../../../ui-elements/toast/toast.service';
import { ProfileFormService } from '../../profile-form/profile-form.service';

interface ErrorsResponseInterface {
  children: {
    [key: string]: {
      errors: string[];
    };
  };
}

interface ErrorInterface {
  [key: string]: string[];
}

@Component({
  selector: 'app-user-settings-profile',
  templateUrl: './profile.component.html',
  providers: [ProfileFormService]
})
export class ProfileComponent implements OnInit {
  @ViewChild('loader', { static: true }) loader: LoaderComponent;

  @Output() savedSuccessfully: EventEmitter<Boolean> = new EventEmitter<Boolean>();

  form: UntypedFormGroup = this.profileFormService.getForm();
  user: UserInterface;
  updatedUser: UserUpdateInterface;
  countries: Array<CountryInterface>;
  textFieldThemeTypes = TextFieldThemeTypes;
  errors: ErrorInterface = {};

  constructor(
    private userService: UserService,
    private countryService: CountryService,
    private toastService: ToastService,
    private translator: TranslateService,
    private profileFormService: ProfileFormService
  ) {}

  ngOnInit() {
    this.loader.show();
    this.countryService.all().subscribe(result => {
      this.countries = result;
    });
    this.refresh();
  }

  onSubmit() {
    if (this.form.valid) {
      this.loader.show();
      this.updatedUser = this.form.value as UserUpdateInterface;
      this.userService.update(this.user.id, this.updatedUser).subscribe(
        result => {
          this.translator.get('SETTINGS.PROFILE.SUCCESS').subscribe(translation => {
            this.toastService.success(translation);
            this.loader.hide();
          });
          this.savedSuccessfully.emit(true);
        },
        () => {
          this.displayErrorMessage();
        }
      );
    } else {
      this.displayErrorMessage();
    }
  }

  private displayErrorMessage() {
    this.translator.get('SETTINGS.PROFILE.FAILURE').subscribe(translation => {
      this.toastService.danger(translation);
      this.loader.hide();
    });
  }

  onReset() {
    this.refresh();
    this.savedSuccessfully.emit(false);
  }

  refresh() {
    this.loader.show();
    this.userService.fromStorage().subscribe(result => {
      this.user = result;
      this.form.patchValue(result);
      this.form.get('country').setValue(this.user?.country?.id ?? null);
      this.loader.hide();
    });
  }

  onProfilePictureChange(event: { file: File; fileUrl: string } | null) {
    if (!event) {
      return;
    }
    this.userService
      .uploadProfilePicture(this.user.id, event.file)
      .pipe(
        catchError(({ error }) => {
          const { errors }: { errors: ErrorsResponseInterface } = error;
          const profilePicture = errors.children.profilePicture;

          if (profilePicture.errors) {
            const translationKeys = profilePicture.errors.map((errorCode: string) => {
              return `SETTINGS.PROFILE.ERRORS.${errorCode}`;
            });

            this.translator.get(translationKeys).subscribe(translations => {
              this.errors['profilePicture'] = Object.values(translations);
            });
          }

          return throwError({
            ...this.user,
            profilePicture: this.user.profilePicture
              ? {
                  ...this.user.profilePicture,
                  url: `${this.user.profilePicture.url}?date=${Date.now()}`,
                }
              : null,
          });
        })
      )
      .subscribe(
        (user: UserInterface) => {
          this.user = user;
          this.errors = {};
        }
      );
  }
}
