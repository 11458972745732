import MeshExtended from '../mesh/mesh-extended';
import BoundingBox from './bounding-box';
import MeshNode from '../mesh/mesh-node';
import { Injectable } from '@angular/core';
import { ModelViewerService } from '../model-viewer/model-viewer.service';
import { Subscription } from 'rxjs';
import { ConfiguratorModalService } from '../configurator-modal.service';
import { ArticleRef } from '../model-viewer/model-viewer.component';

@Injectable()
export class BoundingBoxService {
  private drawnList: MeshExtended[] = [];
  private currentArticleId?: string = null;
  private viewer: any;
  private subscriptions: Subscription = new Subscription();
  private articleRef: ArticleRef = null;

  constructor(private modelViewerService: ModelViewerService, private configuratorModalService: ConfiguratorModalService) {
    this.subscriptions.add(
      this.modelViewerService.viewerRef$.subscribe(viewerRef => {
        if (null === viewerRef) {

          return;
        }

        this.setViewer(viewerRef.viewer).setStyle();
      })
    );

    this.subscriptions.add(
      this.configuratorModalService.getArticleRefAsObservable().subscribe(articleRef => {
        this.articleRef = articleRef;
        if (null === articleRef) {

          return;
        }

        this.redraw(articleRef.node);
      })
    );

    this.subscriptions.add(
      this.configuratorModalService.getSelectedArticleIdAsObservable().subscribe(nextId => {
        this.toggle(nextId);
      })
    );
  }

  destruct() {
    this.subscriptions.unsubscribe();
  }

  public setViewer(viewer): this {
    this.viewer = viewer;

    return this;
  }

  public toggle(nextId?: string) {
    this.hideAll();

    if (null === nextId) {
      this.currentArticleId = null;
      this.viewer.requestRenderFrame();
      return;
    }

    const rootMesh = this.articleRef?.node || null;

    if (null === rootMesh) {

      return;
    }

    const mesh = rootMesh.findById(nextId);

    if (null === mesh) {
      window.console.error(`Can't find next node [${nextId}]`);
      return;
    }

    if (undefined === mesh.boundingBox) {
      this.add(mesh);
    }

    mesh.boundingBox.node.showBoundingBox = true;
    this.currentArticleId = nextId;
    this.viewer.requestRenderFrame();
  }

  public setStyle(showBackLines: boolean = false, frontColorHex: string = '#4A93FF', backColorHex?: string) {
    const bbr = this.viewer.scene.getBoundingBoxRenderer();
    bbr.showBackLines = false;
    bbr.frontColor = new ViewerDependencies.Color3.FromHexString(frontColorHex);

    if (undefined !== backColorHex) {
      bbr.backColor = new ViewerDependencies.Color3.FromHexString(backColorHex);
    }
  }

  public redraw(mesh: MeshExtended) {
    if (undefined === this.currentArticleId) {
      return false;
    }

    if (this.currentArticleId === mesh.getArticleId()) {
      this.toggle(this.currentArticleId);
      return true;
    }

    if (undefined !== mesh.node.mChildren) {
      for (const node of mesh.node.mChildren) {
        if (this.redraw(node.mExtended)) {
          return true;
        }
      }
    }

    return false;
  }

  add(mesh: MeshExtended) {
    mesh.boundingBox = new BoundingBox(mesh);
    mesh.node.onDisposeObservable.add((node: MeshNode) => this.remove(node.mExtended));
    this.drawnList.push(mesh);
  }

  remove(mesh: MeshExtended) {
    for (let i = 0; i < this.drawnList.length; i++) {
      if (this.drawnList[i].getArticleId() === mesh.getArticleId()) {
        this.drawnList[i].boundingBox.node.dispose();
        this.drawnList.splice(i, 1);
      }
    }
  }

  hideAll() {
    for (const mesh of this.drawnList) {
      mesh.boundingBox.node.showBoundingBox = false;
    }
  }

  getCurrentArticleId(): string {
    return this.currentArticleId;
  }
}
