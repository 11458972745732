import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {AuthService} from '../../auth/auth.service';
import {RedirectConstants} from '../constants/redirect.constants';
import {Observable} from 'rxjs';

@Injectable()
export class AuthGuard  {
  constructor(private router: Router, private authService: AuthService) {
  }

  canActivate(next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.authService.getUser()) {
      return true;
    }

    if (state.url.trim().length > 1 && state.url !== '/logout') {
      sessionStorage.setItem(RedirectConstants.SESSION_STORAGE_KEY, JSON.stringify({url: state.url}));
    }
    this.router.navigate(['sign-in']);
    return false;
  }
}
