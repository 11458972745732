<form class="overflow-auto" [formGroup]="form" #formEl>
  <h4 class="fw-bold color-gray mb-3 pb-2 border-bottom border-color-gray-400">
    {{ 'ORDERS.NON_STANDARD_ITEM_MODAL.PROPERTIES' | translate }}
  </h4>

  <div class="mb-3">
    <select class="form-select hover-color-black"
            formControlName="category"
            [class.color-gray-600]="!form.get('category').value || form.get('category').value === availableCategories[0]?.name"
            (change)="onCategoryChange($event)"
    >
      <option *ngFor="let category of availableCategories"
              [value]="0 === category.id ? '' : category.name"
      >{{ category.name }}</option>
    </select>
  </div>

  <div class="mb-3" *ngIf="isCustomCategory">
    <label for="inputShortText" class="form-label">
      {{ ('ORDERS.NON_STANDARD_ITEM_MODAL.FORM.CUSTOM_CATEGORY' | translate | requiredFieldsAsterisk: requiredFields:'shortText') }}
    </label>
    <input type="text" class="form-control" id="inputShortText"
      formControlName="shortText"
      [placeholder]="'ORDERS.NON_STANDARD_ITEM_MODAL.FORM_PLACEHOLDERS.CUSTOM_CATEGORY' | translate"
      [class.is-invalid]="form.get('shortText').touched && form.get('shortText').invalid"
    />
    <div class="invalid-feedback">
      {{ form.get('shortText').errors | translateValidationErrors }}
    </div>
  </div>

  <div class="mb-3">
    <select class="form-select hover-color-black"
            formControlName="system"
            [class.color-gray-600]="!form.get('system').value || form.get('system').value === availableSystems[0]?.name"
    >
      <option *ngFor="let system of availableSystems"
              [value]="0 === system.id ? '' : system.name"
      >{{ system.name }}</option>
    </select>
  </div>

  <div class="mb-3">
    <label for="inputCode" class="form-label">
      {{ ('ORDERS.NON_STANDARD_ITEM_MODAL.FORM.PRODUCT_CODE' | translate | requiredFieldsAsterisk: requiredFields:'code') }}
    </label>
    <input type="text" class="form-control" id="inputCode"
           formControlName="code"
           [placeholder]="'ORDERS.NON_STANDARD_ITEM_MODAL.FORM_PLACEHOLDERS.PRODUCT_CODE' | translate"
           [class.is-invalid]="form.get('code').touched && form.get('code').invalid"
    />
    <div class="invalid-feedback">
      {{ form.get('code').errors | translateValidationErrors }}
    </div>
  </div>

  <app-order-article-material-input
    name="materials"
    [placeholder]="'ORDERS.NON_STANDARD_ITEM_MODAL.FORM_PLACEHOLDERS.MATERIALS' | translate"
    [text]="'ORDERS.NON_STANDARD_ITEM_MODAL.FORM.MATERIALS' | translate"
    formControlName="typeInformation"
    defaultImg="assets/icons/icon-material-placeholder-large.svg"
  ></app-order-article-material-input>

  <h4 class="fw-bold color-gray my-3 py-2 border-bottom border-color-gray-400">
    {{ 'ORDERS.OTHER_SUPPLIER_ITEM_MODAL.PRICES' | translate }}
  </h4>

  <div class="mb-3 row g-0" *ngIf="dealerMode || pmMode">
    <label for="inputCustomPrice" class="col-6 col-form-label text-end pe-4">
      {{ (customPriceText | requiredFieldsAsterisk: requiredFields:'customPrice') }}
    </label>
    <div class="col-6">
      <input type="text" class="form-control" id="inputCustomPrice"
             formControlName="customPrice"
             [placeholder]="customPricePlaceholder"
             [class.is-invalid]="form.get('customPrice').touched && form.get('customPrice').invalid"
             (blur)="formatPrice('customPrice')"
      />
      <div class="invalid-feedback">
        {{ form.get('customPrice').errors | translateValidationErrors }}
      </div>
    </div>
  </div>

  <div class="mb-3 row g-0" *ngIf="!pmMode">
    <label for="inputPricelistPrice" class="col-6 col-form-label text-end pe-4">
      {{ ('ORDERS.NON_STANDARD_ITEM_MODAL.FORM.SALES_PRICE' | translate | requiredFieldsAsterisk: requiredFields:'pricelistPrice') }}
    </label>
    <div class="col-6">
      <input type="text" class="form-control" id="inputPricelistPrice"
             formControlName="pricelistPrice"
             [placeholder]="'ORDERS.NON_STANDARD_ITEM_MODAL.FORM_PLACEHOLDERS.SALES_PRICE' | translate"
             [class.is-invalid]="form.get('pricelistPrice').touched && form.get('pricelistPrice').invalid"
             (blur)="formatPrice('pricelistPrice')"
      />
      <div class="invalid-feedback">
        {{ form.get('pricelistPrice').errors | translateValidationErrors }}
      </div>
    </div>
  </div>

  <h4 class="fw-bold color-gray my-3 py-2 border-bottom border-color-gray-400">
    {{ 'ORDERS.OTHER_SUPPLIER_ITEM_MODAL.DIMENSIONS' | translate }}
  </h4>

  <div class="mb-3 row g-0">
    <label for="inputHeight" class="col-6 col-form-label text-end pe-4">
      {{ ('ORDERS.NON_STANDARD_ITEM_MODAL.FORM.HEIGHT' | translate | requiredFieldsAsterisk: requiredFields:'height') }}
    </label>
    <div class="col-6">
      <input type="text" class="form-control" id="inputHeight"
             formControlName="height"
             [placeholder]="'ORDERS.NON_STANDARD_ITEM_MODAL.FORM_PLACEHOLDERS.HEIGHT' | translate"
             [class.is-invalid]="form.get('height').touched && form.get('height').invalid"
      />
      <div class="invalid-feedback">
        {{ form.get('height').errors | translateValidationErrors }}
      </div>
    </div>
  </div>

  <div class="mb-3 row g-0">
    <label for="inputDepth" class="col-6 col-form-label text-end pe-4">
      {{ ('ORDERS.NON_STANDARD_ITEM_MODAL.FORM.DEPTH' | translate | requiredFieldsAsterisk: requiredFields:'depth') }}
    </label>
    <div class="col-6">
      <input type="text" class="form-control" id="inputDepth"
             formControlName="depth"
             [placeholder]="'ORDERS.NON_STANDARD_ITEM_MODAL.FORM_PLACEHOLDERS.DEPTH' | translate"
             [class.is-invalid]="form.get('depth').touched && form.get('depth').invalid"
      />
      <div class="invalid-feedback">
        {{ form.get('depth').errors | translateValidationErrors }}
      </div>
    </div>
  </div>

  <div class="mb-3 row g-0">
    <label for="inputWidth" class="col-6 col-form-label text-end pe-4">
      {{ ('ORDERS.NON_STANDARD_ITEM_MODAL.FORM.WIDTH' | translate | requiredFieldsAsterisk: requiredFields:'width') }}
    </label>
    <div class="col-6">
      <input type="text" class="form-control" id="inputWidth"
             formControlName="width"
             [placeholder]="'ORDERS.NON_STANDARD_ITEM_MODAL.FORM_PLACEHOLDERS.WIDTH' | translate"
             [class.is-invalid]="form.get('width').touched && form.get('width').invalid"
      />
      <div class="invalid-feedback">
        {{ form.get('width').errors | translateValidationErrors }}
      </div>
    </div>
  </div>

  <div class="mb-3 row g-0">
    <label for="inputVolume" class="col-6 col-form-label text-end pe-4">
      {{ ('ORDERS.NON_STANDARD_ITEM_MODAL.FORM.VOLUME' | translate | requiredFieldsAsterisk: requiredFields:'volume') }}
    </label>
    <div class="col-6">
      <input type="text" class="form-control" id="inputVolume"
             formControlName="volume"
             [placeholder]="'ORDERS.NON_STANDARD_ITEM_MODAL.FORM_PLACEHOLDERS.VOLUME' | translate"
             [class.is-invalid]="form.get('volume').touched && form.get('volume').invalid"
             (blur)="formatFloatNumber('volume')"
      />
      <div class="invalid-feedback">
        {{ form.get('volume').errors | translateValidationErrors }}
      </div>
    </div>
  </div>

  <div class="mb-3 row g-0">
    <label for="inputWeight" class="col-6 col-form-label text-end pe-4">
      {{ ('ORDERS.NON_STANDARD_ITEM_MODAL.FORM.WEIGHT' | translate | requiredFieldsAsterisk: requiredFields:'weight') }}
    </label>
    <div class="col-6">
      <input type="text" class="form-control" id="inputWeight"
              formControlName="weight"
              [placeholder]="'ORDERS.NON_STANDARD_ITEM_MODAL.FORM_PLACEHOLDERS.WEIGHT' | translate"
              [class.is-invalid]="form.get('weight').touched && form.get('weight').invalid"
              (blur)="formatFloatNumber('weight')"
      />
      <div class="invalid-feedback">
        {{ form.get('weight').errors | translateValidationErrors }}
      </div>
    </div>
  </div>
</form>
