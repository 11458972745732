<form [formGroup]="form" class="navbar-form color-gray hover-color-black">
  <div class="position-relative">
    <input
      type="text"
      name="query"
      formControlName="query"
      class="form-control navbar-form__search-input ms-1 pe-5"
      id="query"
      [placeholder]="getPlaceholder() | translate"
    />
    <div class="position-absolute end-0 top-0 bottom-0 p-2">
      <i *ngIf="!form.controls.query.value" class="icon ni-search"></i>
      <i *ngIf="form.controls.query.value"
        class="icon ni-cancel-fill"
        (click)="clear()"
      ></i>
    </div>
  </div>
</form>
