import BoundingBox from '../bounding-box/bounding-box';
import MeshNode from './mesh-node';
import { MeshType } from './mesh-type.enum';

export default class MeshExtended {
  type: string;
  name: string;

  boundingBox?: BoundingBox;

  constructor(public node: MeshNode, public scene) {
    this.addType();
    this.addName();
  }

  addType() {
    if (undefined === this.node.geometry) {
      switch (true) {
        case undefined === this.node.parent:
          this.type = MeshType.ROOT;
          break;
        case undefined !== this.node.mOrderNumber:
          this.type = MeshType.MAIN;
          break;
        default:
          this.type = MeshType.GROUP;
      }
    } else {
      this.type = MeshType.MESH;
    }
  }

  addName() {
    let meshName = 'gfj_';

    switch (this.type) {
      case MeshType.ROOT:
        meshName += `article_${this.node.mOrderNumber}`;
        break;
      case MeshType.MAIN:
        meshName += `subarticle_${this.node.mOrderNumber}`;
        break;
      case MeshType.GROUP:
        meshName += 'group';
        break;
      default:
        meshName += 'mesh';
    }

    this.name = this.node.name = meshName;
  }

  extendChildren(mesh: MeshExtended, callable, ...params) {
    if (undefined !== mesh.node.mChildren) {
      for (const child of mesh.node.mChildren) {
        callable(child, ...params);
      }
    }
  }

  getRoot(mesh?: MeshExtended): MeshExtended {
    if (undefined === mesh) {
      mesh = this;
    }

    if (undefined !== mesh.node.parent) {
      return mesh.getRoot(mesh.node.parent.mExtended);
    }

    return mesh;
  }

  getMain(mesh?: MeshExtended): MeshExtended | undefined {
    if (undefined === mesh) {
      mesh = this;
    }

    if (undefined !== mesh.node.mArticleId) {
      return mesh;
    }

    if (undefined !== mesh.node.parent) {
      return mesh.getMain(mesh.node.parent.mExtended);
    }

    return null;
  }

  getArticleId(): string | undefined {
    return this.node.mArticleId;
  }

  findById(id): MeshExtended | null {
    if (id === this.getArticleId()) {
      return this;
    }

    if (undefined !== this.node.mChildren) {
      for (const child of this.node.mChildren) {
        const found = child.mExtended.findById(id);

        if (found) {
          return found;
        }
      }
    }

    return null;
  }
}
