import {Observable} from 'rxjs/index';
import {UserRole} from "../core/enums/user-role.enum";

export interface PermissionsUserRoleInterface {
  name: UserRole;
}

export interface PermissionsUserInterface {
  role?: PermissionsUserRoleInterface;
}

export interface PermissionsUserServiceInterface {
  getUser(): Observable<PermissionsUserInterface>;
}

export abstract class PermissionsUserService implements PermissionsUserServiceInterface {
  abstract getUser(): Observable<PermissionsUserInterface>;
}
