import {
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, ValidationErrors, FormControl } from '@angular/forms';
import { TextFieldErrorsDirective } from './directives/text-field-errors.directive';
import { TextFieldThemeTypes } from './theme-types.enum';
import { TextFieldActionsDirective } from './directives/text-field-actions.directive';

export const TEXT_FIELD_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TextFieldComponent),
  multi: true,
};

export interface TextFieldConfig {
  type: InputFieldType;
  collapse?: boolean; // Used to identify if textarea should collapse on blur and expand on focus
  minHeight?: number;
  maxRows?: number;
}

type InputFieldType = 'input' | 'textarea' | 'datepicker';

type TextFieldErrorType = ValidationErrors | ValidationErrors[];

@Component({
  selector: 'app-text-field',
  templateUrl: './text-field.component.html',
  providers: [TEXT_FIELD_VALUE_ACCESSOR],
})
export class TextFieldComponent implements OnInit, ControlValueAccessor {
  private _errors: TextFieldErrorType;
  private _invalid: boolean;
  private _disabledProp: boolean;
  private onChangeCallback: Function;
  private onTouchedCallback: Function;
  @ContentChild(TextFieldErrorsDirective, { read: TemplateRef }) templateOfErrors;
  @ContentChild(TextFieldActionsDirective, { read: TemplateRef }) templateOfActions;
  @ViewChild('input') input: ElementRef;

  @Input() placeholder?: string = null;
  @Input() value;
  @Input() text?: string;
  @Input() type?: 'text' | 'number' | 'textarea' | 'date' = 'text';
  @Input() name?: string;
  @Input() id?: string;
  @Input() blurCallbackFn?: any;

  @Input()
  set errors(errors: TextFieldErrorType) {
    this._errors = errors;
  }

  get errors(): TextFieldErrorType {
    return this._errors || this.formControl.errors;
  }

  @Input()
  set invalid(invalid: boolean) {
    this._invalid = invalid;
  }

  get invalid(): boolean {
    if (this.formControl) {
      return this.formControl.invalid && this.formControl.touched;
    }
    return this._invalid;
  }
  @Input() theme: TextFieldThemeTypes | TextFieldThemeTypes[] = [TextFieldThemeTypes.DEFAULT];
  @Input() focus?: boolean;
  @Input()
  set disabledProp(isDisabled: boolean) {
    this._disabledProp = isDisabled;
  }

  get disabledProp(): boolean {
    if (this.formControl) {
      return this.formControl.disabled;
    }
    return this._disabledProp;
  }
  @Input() shouldInstantlyChange = true;
  @Input() hideDefaultTemplateOfErrors = false;
  @Input() formControl: FormControl;

  @Input() inputType?: TextFieldConfig = { type: 'input', collapse: false };

  @Output() changed: EventEmitter<any> = new EventEmitter<any>();
  @Output() instantChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() keyDown: EventEmitter<any> = new EventEmitter<any>();

  @Output() isFocused: EventEmitter<boolean> = new EventEmitter<boolean>();

  focused = false;

  constructor() {}

  ngOnInit() {
    if (this.focus) {
      setTimeout(() => {
        this.onFocus();
      }, 0);
    }
  }

  onChange(value) {
    this.value = value;
    this.changed.emit(value);
    this.onChangeCallback(value);
  }

  onKeyDown({ key }) {
    if (key === 'Enter' || key === 'Escape') {
      this.keyDown.emit(key);
      this.onBlur();
    }
    // if (value) {
    //   this.instantChange.emit({value: value});
    //   this.onChangeCallback(value);
    // }
  }

  onKeyup(event) {
    const { value } = event.target;
    if (value && this.shouldInstantlyChange) {
      this.instantChange.emit({ value: value });
      this.onChangeCallback(value);
    }
  }

  onFocus() {
    this.focused = true;
    this.isFocused.emit(true);
    this.input.nativeElement.focus();
  }

  onBlur() {
    this.onTouchedCallback();
    this.focused = false;
    this.isFocused.emit(false);
    this.input.nativeElement.blur();
    if (this.blurCallbackFn) {
      this.blurCallbackFn.call(this);
    }
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }
}
