export enum ConfiguratorPropertyClassesType {
  ADDED_ADDITIONAL_PARTS = 1,
  DEFAULT = 2,
  ADDITIONAL_PARTS = 3,
}

/**
 * textOnly: true, image: true, mandatory: true, emptyValue: true = small image, no without value
 * textOnly: true, image: true, mandatory: true, emptyValue: false = small image, no without value
 * textOnly: true, image: true, mandatory: false, emptyValue: true = small image, show X
 * textOnly: true, image: true, mandatory: false, emptyValue: false = small image, without value as image (should show X?)
 * textOnly: true, image: false, mandatory: true, emptyValue: true = only text, no without value
 * textOnly: true, image: false, mandatory: true, emptyValue: false = only text, no without value
 * textOnly: true, image: false, mandatory: false, emptyValue: true = only text, without value as text
 * textOnly: true, image: false, mandatory: false, emptyValue: false = only text, without value as text
 * textOnly: false, image: false = ?
 * textOnly: false, image: false = ?
 * textOnly: false, image: true, mandatory: true, emptyValue: true = large image (additional part), no without value
 * textOnly: false, image: true, mandatory: true, emptyValue: false = large image (additional part), no without value
 * textOnly: false, image: true, mandatory: false, emptyValue: true = large image (additional part), show X
 * textOnly: false, image: true, mandatory: false, emptyValue: false = large image (additional part), (should show X?)
 */
export const ConfiguratorPropertyClassCombinations = {
  // textOnly, image
  SMALL_IMAGES: [[true, true]],
  TEXT_ONLY: [[true, false], [false, false]],
  LARGE_IMAGES: [[false, true]],
};

export const ConfiguratorPropertyClassCombinationKeys = {
  SMALL_IMAGES: 'SMALL_IMAGES',
  TEXT_ONLY: 'TEXT_ONLY',
  LARGE_IMAGES: 'LARGE_IMAGES'
};


