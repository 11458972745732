import { Injectable } from '@angular/core';

const determineWindow = (): any => {
  return window;
};

@Injectable()
export class WindowRef {
  constructor() { }

  get nativeWindow(): any {
    return determineWindow();
  }
}
