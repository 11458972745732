import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from '../../shared/services/notification/notification.service';
import { NotificationInterface, NotificationObjectType } from '../../core/models/notification.model';
import { UserService } from '../../core/services/user/user.service';
import { NotificationOpenerService } from '../opener-services/notification-opener.service';
import { RouteTypeEnums, OrderListRouteType, InquiriesRouteType } from '../../core/enums/route-types.enum';
import { MessageCodeTranslationService } from '../../shared/services/message-code-translation/message-code-translation.service';
import { TreeService } from '../../core/tree/tree.service';
import { NavbarElements } from '../../core/tree/navbar-elements';
import { NotificationStatus } from '../../core/enums/notification-status.enum';
import { switchMap } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-notifications-modal',
  templateUrl: './notifications-modal.component.html'
})
export class NotificationsModalComponent implements OnInit {
  notifications: NotificationInterface[] = [];
  loading = true;
  currentPage = 1;
  totalPages = 1;
  showInPage: number = 20;

  notificationStatus = NotificationStatus;

  constructor(
    private activeModalService: NgbActiveModal,
    private notificationService: NotificationService,
    private userService: UserService,
    private notificationOpenerService: NotificationOpenerService,
    private messageCodeTranslationService: MessageCodeTranslationService,
    private treeService: TreeService,
    private translationService: TranslateService,
  ) {}

  ngOnInit() {
    this.fetchNotifications();
  }

  fetchNotifications() {
    this.loading = true;
    this.notificationService.getAll(this.currentPage).noCache().subscribe(({ data, meta }) => {
      this.notifications = [...this.notifications, ...data];
      this.totalPages = meta['total-pages'];
      this.showInPage = meta['show-in-page'];
      this.loading = false;
    });
  }

  onMarkAllAsRead() {
    this.notificationService.markAllAsSeen().subscribe(() => {
      this.ngOnInit();
      this.userService.reload().subscribe(); // Update user notifications
    });
  }

  onClose() {
    this.activeModalService.dismiss();
  }

  onOpen(notification: NotificationInterface) {
    this.notificationService.markAsSeen(notification.id).pipe(
      switchMap(() => this.userService.reload()) // Update user notifications count
    ).subscribe();

    let breadcrumbsRoute: RouteTypeEnums;
    let routeType: OrderListRouteType | InquiriesRouteType;

    switch (notification.object) {
      case NotificationObjectType.CLAIM:
        breadcrumbsRoute = RouteTypeEnums.INQUIRIES;
        routeType = InquiriesRouteType.CLAIMS;
        break;
      case NotificationObjectType.PROJECT_RESERVATION:
        breadcrumbsRoute = RouteTypeEnums.INQUIRIES;
        routeType = InquiriesRouteType.PROJECT_RESERVATIONS;
        break;
      default:
      case NotificationObjectType.ORDER:
        breadcrumbsRoute = RouteTypeEnums.ORDERS;
        routeType = OrderListRouteType.ORDERS;
        break;
    }

    const navbarElement = NavbarElements.find(({ id }) => id === breadcrumbsRoute);
    const secondElement = navbarElement.children.find(({id}) => id === routeType);
    this.treeService.open(navbarElement, secondElement);

    this.notificationOpenerService.open(notification);
    this.onClose();
  }

  getTranslation(notification: NotificationInterface) {
    return this.messageCodeTranslationService.getMessageCodeTranslation(notification);
  }

  allNotificationsSeen(notifications: NotificationInterface[]) {
    return notifications.every(notification => notification.status === NotificationStatus.SEEN);
  }

  onScrolled() {
    if(this.currentPage < this.totalPages) {
      this.currentPage++;
      this.fetchNotifications();
    }
  }

  getNotificationTitle(notification: NotificationInterface) {
    const { title, object } = notification;

    return this.translationService.instant('NOTIFICATIONS.OBJECT_TITLE.' + object.toUpperCase(), { title });
  }
}
