<div [class.hide-arrows]="images.length === 1" id="gallery-container">
  <ngb-carousel *ngIf="images.length > 0" [showNavigationIndicators]="false">
    <ng-template ngbSlide *ngFor="let image of images">
      <div
        class="image"
        [class.image--empty]="!image.url"
        [style.background-image]="
          image.url
            ? 'url(' + ((image.url | encodeUri | uncache | async) ?? '') + ')'
            : 'url(\'../../../assets/images/catalogue-list/no-article-image.svg\')'
        "
      ></div>
      <div class="d-flex justify-content-center border-top">
        <button class="btn btn-secondary my-2" *ngIf="image.url" (click)="downloadAll([image])">
          <span class="d-flex align-items-center">
            <i class="icon ni-download me-2"></i>
            {{ 'GALLERY.DOWNLOAD' | translate }}
          </span>
        </button>
        <button class="btn btn-secondary my-2" *ngIf="image.url && !noDownloadAll" (click)="downloadAll(images)">
          <span class="d-flex align-items-center">
            <i class="icon ni-download me-2"></i>
            {{ 'GALLERY.DOWNLOAD_ALL' | translate }}
          </span>
        </button>
      </div>
    </ng-template>
  </ngb-carousel>
</div>
