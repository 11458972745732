import { ComponentFactoryResolver, ComponentRef, Injectable, ViewContainerRef } from '@angular/core';
import { SimpleArticlePropertiesConfiguratorComponent } from './simple-article-properties-configurator/simple-article-properties-configurator.component';
import { BasePropertiesConfiguratorComponentFactoryService } from '../properties-configurator-component-factory/base-properties-configurator-component-factory.service';
import { TransformedResponseInterface } from '../../core/models/configurator.model';

@Injectable({
  providedIn: 'root',
})
export class SimpleArticlePropertiesConfiguratorComponentFactoryService extends BasePropertiesConfiguratorComponentFactoryService {
  constructor(protected componentFactoryResolver: ComponentFactoryResolver) {
    super(componentFactoryResolver);
  }

  create(element: ViewContainerRef, transformedResponse: TransformedResponseInterface): ComponentRef<any> {
    const componentRef: ComponentRef<SimpleArticlePropertiesConfiguratorComponent> = this.createInstance(
      element,
      SimpleArticlePropertiesConfiguratorComponent
    );

    componentRef.instance.propertyClasses = transformedResponse.propertyClasses;
    return componentRef;
  }
}
