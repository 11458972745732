import { Component, EventEmitter, HostListener, OnDestroy, OnInit, Output, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ProjectReservationInterface } from '../../../core/models/project-reservation.model';
import { Observable, Subscription, debounceTime, map } from 'rxjs';
import { NgbActiveModal, NgbModal, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import {
    ProjectReservationCreateInterface,
    ProjectReservationResponseInterface,
    ProjectReservationService,
    ProjectReservationWonUpdateInterface
} from '../../project-reservation.service';
import { CountryInterface } from '../../../core/models/country.model';
import { CountryService } from '../../../core/services/country/country.service';
import { CategoryPathInterface } from '../../../core/models/category-path.model';
import moment from 'moment';
import { ProjectReservationPreviewComponent } from '../project-reservation-preview/project-reservation-preview.component';
import { numbers } from '../../../shared/class/custom-validators';
import { UserService } from '../../../core/services/user/user.service';
import * as CustomValidators from '../../../shared/class/custom-validators';

export interface SelectedProductSystemInterface {
  name: string;
}

@Component({
  selector: 'app-project-reservation-modal',
  templateUrl: './project-reservation-modal.component.html',
  styleUrls: ['./project-reservation-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ProjectReservationModalComponent implements OnInit, OnDestroy {
  form: FormGroup = null;
  countries: CountryInterface[];
  selectedProductSystems: SelectedProductSystemInterface[] = [];
  productSystemsList: (CategoryPathInterface | SelectedProductSystemInterface)[] = [];

  productSystemTypeaheadLimit = 15;
  isDisabled = false;
  preventSubmit = false;
  updateMode = false;
  userCountryId: CountryInterface['id'] = 0;

  protected subscriptions: Subscription = new Subscription();
  projectReservationItem: ProjectReservationInterface;

  @ViewChild('projectReservationToast') public projectReservationToastRef: TemplateRef<any>;
  @Output() saved: EventEmitter<ProjectReservationResponseInterface> = new EventEmitter<ProjectReservationResponseInterface>();
  @Output() updated: EventEmitter<ProjectReservationWonUpdateInterface> = new EventEmitter<ProjectReservationWonUpdateInterface>();

  @HostListener('window:beforeunload', ['$event']) beforeUnload(e: BeforeUnloadEvent) {
    if (this.form.dirty) {
      e.preventDefault();
      e.returnValue = '';
    }
  }

  constructor(
    protected activeModal: NgbActiveModal,
    protected projectReservationService: ProjectReservationService,
    protected countryService: CountryService,
    private modalService: NgbModal,
    private userService: UserService
  ) {
    this.subscriptions.add(this.userService.fromStorage().subscribe(user => {
      this.userCountryId = user.country.id;
    }));
  }

  ngOnInit() {

    if (!this.form) {
      this.constructForm();
    }

    this.subscriptions.add(this.countryService.all().subscribe(result => {
      this.countries = result;
    }));

    this.subscriptions.add(this.projectReservationService.getProductSystems().subscribe(result => {
      this.productSystemsList = result.data;
    }));
  }

  constructForm(fields?: ProjectReservationInterface, disabled: boolean = false) {
    this.preventSubmit = false;
    this.isDisabled = disabled;
    if (fields?.productSystems.length) {
      this.selectedProductSystems = fields.productSystems.map(item => {
        return { name: item } as SelectedProductSystemInterface
      });
    }

    const estimatedWinDateFormatted = fields?.estimatedWinDateTimestamp ? moment.unix(fields?.estimatedWinDateTimestamp).format("YYYY-MM-DD") : undefined;
    const executionDateFormatted = fields?.executionDateTimestamp ? moment.unix(fields?.executionDateTimestamp).format("YYYY-MM-DD") : undefined;

    this.form = new FormGroup({
      id: new FormControl<number>({ value: fields?.id, disabled: disabled }, []),
      title: new FormControl<string>({ value: fields?.title, disabled: disabled }, [ Validators.required ]),
      country: new FormControl<number>({ value: fields?.country.id || this.userCountryId || null, disabled: disabled }, [ Validators.required ]),
      city: new FormControl<string>({ value: fields?.city, disabled: disabled }, [ Validators.required ]),
      value: new FormControl<number>({ value: fields?.value, disabled: disabled }, [ Validators.required ]),
      workplaceCount: new FormControl<number>({ value: fields?.workplaceCount, disabled: disabled }, [ numbers, ]),
      estimatedWinDate: new FormControl<string>({ value: estimatedWinDateFormatted, disabled: disabled || this.updateMode }, [
        Validators.required,
        CustomValidators.validDateFormat,
        CustomValidators.minDate(new Date())
      ]),
      executionDate: new FormControl<string>({ value: executionDateFormatted, disabled: disabled }, [
        Validators.required,
        CustomValidators.validDateFormat,
        CustomValidators.minDate(new Date())
      ]),
      productSystems: new FormControl<string[]>({ value: fields?.productSystems, disabled: disabled }, [ Validators.required ]),
      description: new FormControl<string>({ value: fields?.description, disabled: disabled }, []),
      outcome: new FormControl<string>({ value: fields?.outcome, disabled: disabled }, []),
      upcomingOrdersCount: new FormControl<number>({ value: fields?.upcomingOrdersCount, disabled: disabled }, this.updateMode ? [ Validators.required ] : []),
    });
  }

  onSubmit() {
    if (this.form.invalid || this.preventSubmit) {
      return;
    }

    this.preventSubmit = true;
    const formData: ProjectReservationCreateInterface = this.form.getRawValue();

    Object.keys(formData).forEach((item) => {
      if (item === undefined) {
        delete formData[item];
      }
    });

    this.subscriptions.add(this.projectReservationService.create(formData).subscribe((response) => {
      this.saved.emit(response);
      this.activeModal.close();
    }));
  }

  onUpdate() {
    if (this.form.invalid || this.preventSubmit) {
      return;
    }

    this.preventSubmit = true;
    const formData: ProjectReservationWonUpdateInterface = this.form.getRawValue();

    const updateData: ProjectReservationWonUpdateInterface = {
      lostReasons: formData.lostReasons,
      otherLostReasons: formData.otherLostReasons,
      outcome: formData.outcome,
      upcomingOrdersCount: formData.upcomingOrdersCount,
      value: formData.value,
      executionDate: moment(formData.executionDate).format('YYYY-MM-DD'),
      productSystems: formData.productSystems,
      description: formData.description,
      workplaceCount: formData.workplaceCount
    };

    this.updated.emit(updateData);
    this.activeModal.close();
  }

  onDiscard() {
    this.activeModal.close();
  }

  removeSelectedSystem(system: CategoryPathInterface | SelectedProductSystemInterface) {
    this.selectedProductSystems = this.selectedProductSystems.filter(item => item !== system);
    this.form.controls.productSystems.reset();
    this.form.controls.productSystems.markAsTouched();
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      map((term) =>
        term === ''
          ? []
          : this.findMatch(term),
      ),
    );

  private markTypeaheadButtonsDisabled() {
    // there is no way to set some properties directly on typeahead button, only on child of that button
    // so to mark button disabled we will reflect disabled state from child div class
    const container = document.querySelectorAll('ngb-typeahead-window.product-systems-typeahead button');
    container.forEach((i: HTMLButtonElement) => i.querySelector('div.disabled') ? i.disabled = true : '');
  }

  private findMatch(term: string) {
    const defaultEntry: SelectedProductSystemInterface = {
      name: 'NON STANDARD', // this serves like product system name which we do not translate
    };

    const matches = this.productSystemsList
      .map((v) => this.selectedProductSystems.includes(v) ? {...v, disabled: true} : v)
      .filter((v) => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, this.productSystemTypeaheadLimit);

    setTimeout(() => {
      // wait a moment for typeahead dom to be already updated
      this.markTypeaheadButtonsDisabled();
    }, 0);

    if (!matches.length) {
      return [defaultEntry];
    }

    return matches;
  }

  onSystemSelected($event: NgbTypeaheadSelectItemEvent, input: HTMLInputElement) {
    $event.preventDefault();
    if ($event.item.disabled) {
      input.value = '';

      return;
    }

    if (this.selectedProductSystems.indexOf($event.item) === -1) {
      this.selectedProductSystems.push($event.item);
    }

    this.form.patchValue({ productSystems: this.selectedProductSystems.map(item => item.name) });

    input.value = '';
  }

  onTouchedCallback(input: HTMLInputElement) {
    input.value = '';
    this.form.controls.productSystems.markAsTouched();
  }

  onToastButtonClick() {
    const modalRef = this.modalService.open(ProjectReservationPreviewComponent, {
      fullscreen: true,
      keyboard: true,
    });

    const modalInstance: ProjectReservationPreviewComponent = modalRef.componentInstance;
    modalInstance.item = this.projectReservationItem;
    this.subscriptions.add(modalInstance.onProjectReservationSaved.subscribe(() => {
      modalRef.close();
    }));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
