import { NgClass } from '@angular/common';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-check-mark',
  templateUrl: './check-mark.component.html',
})
export class CheckMarkComponent implements OnInit {
  @Input() value: any;
  @Input() name: string | number = '';
  @Input() text?: string;
  @Input() cssClasses?: NgClass['ngClass'] = '';
  @Input() labelClasses = '';
  @Input() disabled = false;

  @Input() placeholder?: string;

  @Output() changed: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {}

  onChange(value: any) {
    this.value = value;
    this.changed.emit(this.value);
  }
}
