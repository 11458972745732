import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessagesModalComponent } from './messages-modal/messages-modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { MessagesService } from './messages.service';
import { SharedModule } from '../shared/shared.module';
import { MessagesWindowModule } from '../messages-window/messages-window.module';
import { CloseButtonModule } from '../ui-elements/close-button/close-button.module';
import { LoaderModule } from '../ui-elements/loader/loader.module';
import { ConversationModalComponent } from './conversation-modal/conversation-modal.component';
import { NotificationComponentsModule } from '../notification-components/notification-components.module';
import { ScrollToModule } from '../shared/directives/scroll-to/scroll-to.module';
import { ConversationModalOpenerService } from './conversation-modal-opener.service';
import { OrdersService } from '../orders/orders.service';
import { CommunicationTabModule } from '../ui-elements/communication-tab/communication-tab.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forRoot(),
    FormsModule,
    SharedModule,
    MessagesWindowModule,
    CloseButtonModule,
    LoaderModule,
    NotificationComponentsModule,
    ScrollToModule,
    CommunicationTabModule,
  ],
  declarations: [
    MessagesModalComponent,
    ConversationModalComponent,
  ],
  providers: [MessagesService, OrdersService, ConversationModalOpenerService]
})
export class MessagesModalModule { }
