<div 
  [formGroup]="form" 
  class="d-flex align-items-end message-field-container p-3"
>
  <label
    for="upload-files"
    class="btn btn-light rounded-0 p-2"
    [class.btn-disabled]="isSending"
  >
    <i class="icon ni-attachment-rotate position-relative d-flex"></i>
  </label>
  <input 
    #fileInput
    class="d-none"
    id="upload-files" 
    type="file" 
    (change)="onFileChange($event)" 
    [accept]="accept" 
    [multiple]="multiple"
  />
  <div class="flex-grow-1 mx-3 d-flex flex-column gap-3 position-relative" #filesList>
    <div
      *ngIf="form.controls.files.value.length"
      class="d-flex gap-3"
    >
      <div
        *ngFor="let file of visibleFiles; let i = index"
        class="chat-file-preview bg-gray-200 position-relative hover-border-color-primary deep-hover-d-block"
        [ngbTooltip]="file.name"
        [class.border-color-danger]="validationErrors?.length && validationErrors[i]"
      >
        <ng-switch [ngSwitch]="isImage(file)">
          <ng-container *ngSwitchCase="true">
            <img
              [src]="generateImagePreview(file)"
              alt="file"
              class="w-100 h-100"
            />
          </ng-container>
          <ng-container *ngSwitchDefault>
            <div class="absolute w-100 h-100 d-flex align-items-center justify-content-center text-lg">
              <i class="icon ni-file icon-large"></i>
            </div>
          </ng-container>
        </ng-switch>

        <div *ngIf="!isSending" class="position-absolute top-0 right-0 p-1 d-none deep-hover">
          <button
            class="btn btn-danger rounded-0 p-1"
            (click)="removeFileAtIndex(i)"
          >
            <span class="d-flex">
              <i class="icon ni-trash"></i>
            </span>
          </button>
        </div>
      </div>
    
      <div 
        *ngIf="form.controls.files.value.length > availablePreviewSpots"
        class="bg-gray-200 d-flex flex-column justify-content-center align-items-center"
        [style.width.px]="80"
        [style.height.px]="80"
      >
        <h6 class="bg-primary text-white rounded-pill px-1">
          +{{ form.controls.files.value.length - availablePreviewSpots }}
        </h6>
      </div>
    </div>
    <textarea
      type="text"
      formControlName="text"
      class="form-control w-100 no-resize overflow-hidden"
      placeholder="{{ 'MESSAGES.PLACEHOLDER' | translate }}"
      rows="1"
      autosize
      [autosizeMinHeight]="40"
      [autosizeMaxRows]="5"
      (keydown)="onKeyDown($event)"
    ></textarea>
  </div>
  <button class="btn btn-primary rounded-0 p-2"
    [ngClass]="{'disabled': !form.valid}"
    (click)="onSend()"
    [disabled]="!form.valid || isSending"
  >
    <span class="d-flex">
      <i class="icon ni-paper-plane"></i>
    </span>
  </button>
</div>

<div
  *ngIf="(isDragging || !!fileDropErrorCode) && !isSending"
  class="position-absolute top-0 right-0 bottom-0 left-0 drag-drop-overlay d-flex flex-column justify-content-center align-items-center gap-3"
  (click)="clearDropErrorCode()"
>
  <span
    [class.text-primary]="theme === themeEnum.DEFAULT"
    [class.text-gray-800]="theme === themeEnum.COMMUNICATION_TAB"
  >
    <div [inlineSVG]="'/assets/images/drag-drop-indicator/drag-drop-indicator.svg'"></div>
  </span>
  <div class="d-flex flex-column gap-1 align-items-center">
    <h5
      [class.text-gray-800]="theme == themeEnum.DEFAULT && isDragging"
      [class.text-primary]="theme == themeEnum.COMMUNICATION_TAB && isDragging"
      [class.text-danger]="fileDropErrorCode"
    >
      {{(fileDropErrorCode ? 'ERRORS.BY_ERROR_CODE.' + fileDropErrorCode : 'DRAG_DROP.TITLE') | translate}}
    </h5>
    <span class="text-gray-500">{{ 'DRAG_DROP.UP_TO_SIZE' | translate: {size: (maxFileSize | filesizeFormat)} }}</span>
  </div>
</div>
