import { AfterViewInit } from '@angular/core';
import { Directive, ElementRef, EventEmitter, Input, OnDestroy, Output } from '@angular/core';

import { BorderBoxSizeInterface, ResizeNotifierService, ObservedUIElementSizes } from './resize-notifier.service';

/**
 * Directive that uses ResizeObserver API to observe border box sizes changes of element.
 * Assigned value should be unique, it is used for identifying observed sizes.
 */
@Directive({
  selector: '[appBorderBoxResizeObserver]'
})
export class BorderboxResizeObserverDirective implements AfterViewInit, OnDestroy {
  @Input('appBorderBoxResizeObserver') identifier: keyof ObservedUIElementSizes | '' = '';
  @Input('appBorderBoxResizeObserverEnabled') enabled = true;
  @Output() onSizeChange = new EventEmitter<BorderBoxSizeInterface>();

  private observer: ResizeObserver;

  constructor(
    private el: ElementRef<HTMLElement>,
    private resizeNotifier: ResizeNotifierService
  ) { }

  ngAfterViewInit(): void {
    this.observer = new ResizeObserver((entries) => {
      if (!entries?.length) {
        return;
      }

      const borderBoxSize: ResizeObserverSize | undefined = entries[0]?.borderBoxSize?.[0];
      const value: BorderBoxSizeInterface = {
        width: borderBoxSize?.inlineSize ?? this.el.nativeElement.offsetWidth,
        height: borderBoxSize?.blockSize ?? this.el.nativeElement.offsetHeight
      };

      if (this.enabled) {
        this.onSizeChange.emit(value);
        this.resizeNotifier.notify({ [this.identifier]: value });
      }
    });

    this.observer.observe(this.el.nativeElement);
  }

  ngOnDestroy(): void {
    this.observer.disconnect();
    if (this.enabled) {
      // unset value when element is removed
      this.resizeNotifier.notify({ [this.identifier]: null });
    }
  }
}
