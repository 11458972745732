import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DeliveryOptions } from '../../core/enums/delivery-options.enum';
import { AddressInterface } from '../../core/models/address.model';
import { noWhitespace, extendedNumbers, textWithSeparators, email, textAndDashes } from '../../shared/class/custom-validators';

@Injectable()
export class AddressFormService {
  constructor() {}

  constructForm(fields: AddressInterface, disabled: boolean = false) {
    return new UntypedFormGroup({
      address: new UntypedFormControl({ value: fields.address, disabled: disabled }, [
        Validators.required,
        noWhitespace,
        Validators.maxLength(248),
      ]),
      postCode: new UntypedFormControl({ value: fields.postCode, disabled: disabled }, [
        Validators.required,
        noWhitespace,
        Validators.maxLength(50),
      ]),
      city: new UntypedFormControl({ value: fields.city, disabled: disabled }, [
        Validators.required,
        noWhitespace,
        textWithSeparators,
        Validators.maxLength(50),
      ]),
      country: new UntypedFormControl({ value: fields.country, disabled: disabled }, [
        Validators.required,
      ]),
      countryDisabled: new UntypedFormControl(
        { value: fields.country ? `${fields.country.title}, ${fields.country.code}` : '', disabled: disabled },
        []
      ),
      contactPerson: new UntypedFormControl({ value: fields.contactPerson, disabled: disabled }, [
        Validators.required,
        noWhitespace,
        textAndDashes,
        Validators.maxLength(50),
      ]),
      deliveryAddressee: new UntypedFormControl({ value: fields.deliveryAddressee, disabled: disabled }, [
        Validators.required,
        Validators.maxLength(110)
      ]),
      contactWorkingHours: new UntypedFormControl({ value: fields.contactWorkingHours, disabled: disabled }, [
        Validators.required,
        Validators.maxLength(50)
      ]),
      email: new UntypedFormControl({ value: fields.email, disabled: disabled }, [
        Validators.required,
        email
      ]),
      phone: new UntypedFormControl({ value: fields.phone, disabled: disabled }, [
        Validators.required,
        noWhitespace,
        extendedNumbers,
        Validators.maxLength(50),
      ]),
      /* deliveryOption: new FormControl({ value: fields.deliveryOption ?? DeliveryOptions.STANDARD_DELIVERY, disabled: disabled }, [
        Validators.required
      ]), */
    });
  }
}
