import {Injectable, Injector, StaticProvider, TemplateRef} from '@angular/core';
import {PopoverRef} from './popover-ref';
import {ConnectionPositionPair, Overlay, OverlayConfig, OverlayRef, PositionStrategy} from '@angular/cdk/overlay';
import {PopoverComponent} from './popover/popover.component';
import {ComponentPortal} from '@angular/cdk/portal';

export interface PopoverParams<T> {
  width?: string | number;
  height?: string | number;
  content: TemplateRef<any>;
  origin: HTMLElement;
  data?: T;
}

@Injectable({
  providedIn: 'root'
})
export class PopoverService {
  private overlayRef: OverlayRef;

  constructor(
    private overlay: Overlay,
    private injector: Injector
  ) {}

  open<T>({origin, content, data, width, height}: PopoverParams<T>, offsetY: number = 0): PopoverRef<T> {
    const overlayRef = this.overlay.create(this.getOverlayConfig({origin, width, height}, offsetY));
    const popoverRef = new PopoverRef<T>(overlayRef, content, data);

    const injector = this.createInjector(popoverRef, this.injector);
    overlayRef.attach(new ComponentPortal(PopoverComponent, null, injector));
    this.overlayRef = overlayRef;
    return popoverRef;
  }

  private getOverlayConfig({origin, width, height}, offsetY: number): OverlayConfig {
    return new OverlayConfig({
      hasBackdrop: false,
      width,
      height,
      positionStrategy: this.getOverlayPosition(origin, offsetY),
      scrollStrategy: this.overlay.scrollStrategies.reposition()
    });
  }

  private getOverlayPosition(origin: HTMLElement, offsetY: number): PositionStrategy {
    return this.overlay.position()
      .flexibleConnectedTo(origin)
      .withPositions(this.getPositions())
      .withFlexibleDimensions(false)
      .withPush(false)
      .withDefaultOffsetY(offsetY);
  }

  createInjector(popoverRef: PopoverRef, injector: Injector) {
    const tokens: StaticProvider[] = [
      { provide: PopoverRef, useValue: popoverRef }
    ];
    return Injector.create({ providers: tokens, parent: injector });
  }

  private getPositions(): ConnectionPositionPair[] {
    return [
      {
        originX: 'center',
        originY: 'bottom',
        overlayX: 'center',
        overlayY: 'top'
      }
    ];
  }

}
