import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContainerComponent } from '../order-articles-list/components/container/container.component';
import { DraggableItemComponent } from '../order-articles-list/components/draggable-item/draggable-item.component';
import { SharedModule } from '../../../shared/shared.module';
import { DraggingDetectorService } from './services/dragging-detector/dragging-detector.service';
import { DraggableContentDirective } from '../order-articles-list/directives/draggable-content/draggable-content.directive';
import { DrakeStoreService } from './services/drake-store/drake-store.service';
import { DroppableDirective } from './directives/ngx-droppable.directive';
import { DraggableDirective } from './directives/ngx-draggable.directive';
import { DragHandleDirective } from './directives/ngx-drag-handle.directive';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, SharedModule, TranslateModule],
  declarations: [ContainerComponent, DraggableItemComponent, DraggableContentDirective, DraggableDirective, DroppableDirective, DragHandleDirective],
  exports: [ContainerComponent, DraggableItemComponent, DraggableContentDirective, DraggableDirective, DroppableDirective, DragHandleDirective],
  providers: [DraggingDetectorService, DrakeStoreService],
})
export class DraggableModule {}
