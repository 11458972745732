export enum FieldType {
  TITLE = 'title',
  PRICE = 'price',
  IMG = 'img',
  CATEGORY = 'category',
  SYSTEM = 'system',
  SHORT_TEXT = 'shortText',
  ORDER_ARTICLE_MATERIALS = 'orderArticleMaterials',
  ORDER_ARTICLE_MATERIALS_INLINE = 'orderArticleMaterialsInline',
  CODE = 'code',
  DIMENSIONS = 'dimensions',
  WEIGHT = 'weight',
  VOLUME = 'volume',
  TYPE = 'type',
  DISCOUNT = 'discount',
}
