<ul class="list-unstyled">
  <li class="d-flex align-items-center mb-2">
    <ng-container *ngTemplateOutlet="indicator; context: { requirementMet: isValid('minlength') }"></ng-container>
    {{ 'PASSWORD.PASSWORD_REQUIREMENTS.PASSWORD_LENGTH' | translate }}
  </li>
  <li class="d-flex align-items-center mb-2">
    <ng-container *ngTemplateOutlet="indicator; context: { requirementMet: isValid('hasUpperCaseLetters') }"></ng-container>
    {{ 'PASSWORD.PASSWORD_REQUIREMENTS.UPPER_CASE' | translate }}
  </li>
  <li class="d-flex align-items-center mb-2">
    <ng-container *ngTemplateOutlet="indicator; context: { requirementMet: isValid('hasLowerCaseLetters') }"></ng-container>
    {{ 'PASSWORD.PASSWORD_REQUIREMENTS.LOWER_CASE' | translate }}
  </li>
  <li class="d-flex align-items-center mb-2">
    <ng-container *ngTemplateOutlet="indicator; context: { requirementMet: isValid('hasNumbers') }"></ng-container>
    {{ 'PASSWORD.PASSWORD_REQUIREMENTS.NUMBER' | translate }}
  </li>
  <li class="d-flex align-items-center mb-2">
    <ng-container *ngTemplateOutlet="indicator; context: { requirementMet: isValid('hasSpecialChars') }"></ng-container>
    {{ 'PASSWORD.PASSWORD_REQUIREMENTS.SPECIAL_CHARACTER' | translate }}
  </li>
</ul>

<ng-template #indicator let-requirementMet="requirementMet">
  <i class="icon me-2" [ngClass]="{
    'ni-check color-success': requirementMet,
    'ni-close color-danger': !requirementMet
  }"></i>
</ng-template>
