import { EventEmitter, Input, Output, Directive } from '@angular/core';
import { PropertyValueChangeEventInterface } from './property-values-selector/property-values-selector.component';
import { ArticlePropertyClassInterface } from '../core/models/configurator.model';

export interface PropertyClassChangeEventEmitterComponentInterface {
  propertyClassChange: EventEmitter<PropertyValueChangeEventInterface>;

  onPropertyChange(event: PropertyValueChangeEventInterface): void;
}

export interface PropertyClassChangeComponentInterface extends PropertyClassChangeEventEmitterComponentInterface {
  propertyClasses: ArticlePropertyClassInterface[];
}

@Directive()
export abstract class PropertyClassChangeEventEmitterComponent implements PropertyClassChangeEventEmitterComponentInterface {
  @Output() propertyClassChange: EventEmitter<PropertyValueChangeEventInterface> = new EventEmitter<PropertyValueChangeEventInterface>();

  onPropertyChange(event: PropertyValueChangeEventInterface) {
    this.propertyClassChange.emit(event);
  }
}

@Directive()
export abstract class PropertyClassChangeComponent
  extends PropertyClassChangeEventEmitterComponent
  implements PropertyClassChangeComponentInterface {
  @Output() propertyClassChange: EventEmitter<PropertyValueChangeEventInterface> = new EventEmitter<PropertyValueChangeEventInterface>();
  @Input() propertyClasses: ArticlePropertyClassInterface[] = [];

  constructor() {
    super();
  }

  onPropertyChange(event: PropertyValueChangeEventInterface) {
    this.propertyClassChange.emit(event);
  }
}
