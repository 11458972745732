import { Injectable } from '@angular/core';
import {
  GROUPS_DEPTH_LIMIT,
  OrderItemsGroupService,
  OTHER_ITEMS_DEPTH_LIMIT,
} from '../../../../../../core/services/order-items-group/order-items-group.service';
import { areItemsInSameGroup, OrderArticlesListRow, RowTypes } from '../../../../order-articles-list.interface';
import {flattenDeep} from "../../../../../../core/util/flatten-deep.helper";

@Injectable({
  providedIn: 'root',
})
export class OrderItemsGroupingValidatorService {
  constructor(private orderItemsGroupService: OrderItemsGroupService) {}

  shouldAllowGroup(items, itemsToGroup): boolean {
    if (!itemsToGroup.length) {
      return false;
    }

    return items.length ? this.shouldAllowGroupDeeper(items) && areItemsInSameGroup(itemsToGroup) : true;
  }

  shouldAllowGroupDeeper(items): boolean {
    const higherLevelItemsExist = items.some(
      row =>
        (row.rowType === RowTypes.GROUP && row.groupLevel >= GROUPS_DEPTH_LIMIT) ||
        (row.rowType !== RowTypes.GROUP && row.groupLevel >= OTHER_ITEMS_DEPTH_LIMIT)
    );
    if (higherLevelItemsExist) {
      return false;
    }

    const groups = items.filter(item => item.rowType === RowTypes.GROUP);
    if (groups.length > 0) {
      return groups.every(group => {
        const groupChildren = flattenDeep([group]);
        return groupChildren.filter(child => child.rowType === RowTypes.GROUP).every(child => child.groupLevel < GROUPS_DEPTH_LIMIT);
      });
    }

    return true;
  }

  shouldAllowAddGroup(selectedRows: OrderArticlesListRow[]): boolean {
    const lowestGroupLevel = Math.min.apply(
      Math,
      selectedRows.map(({ groupLevel }) => groupLevel)
    );
    const lowestGroupLevelRows = selectedRows.filter(row => row.groupLevel === lowestGroupLevel);
    const lastSelected = lowestGroupLevelRows[lowestGroupLevelRows.length - 1];
    return lastSelected.groupLevel + 1 <= GROUPS_DEPTH_LIMIT;
  }

  shouldAllowDropInGroup(items, dropZoneGroupLevel: number): boolean {
    const flatten = flattenDeep(items);
    return !flatten.find(row => row.groupLevel + (row.rowType === RowTypes.GROUP ? 1 : 0) >= GROUPS_DEPTH_LIMIT);
  }
}
