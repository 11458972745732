<div class="card border-0">
  <div class="card-header py-0 px-2">
    <ul *ngIf="tabs" class="nav nav-pills">
      <li *ngFor="let item of tabs" class="nav-item cursor-pointer">
        <a class="nav-link"
          (click)="select($event, item)"
          [href]="getTermsUrl(item.uri)"
          [class.active]="selected && item.uri === selected.uri"
        >
          {{ item.basicTranslation.title }}
        </a>
      </li>
    </ul>
  </div>
  <div class="card-body bg-white overflow-auto p-4" *ngIf="selected">
    <div [innerHTML]="selected.translation.description"></div>
  </div>
</div>
