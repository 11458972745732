<div 
  class="d-flex flex-column preview-file-container gap-1 cursor-pointer"
  [previewFile]="file"
  [title]="file.filename"
>
  <div class="preview-file bg-gray-200 position-relative deep-hover-d-flex">
    <ng-container *ngIf="!inProgress">
      <ng-switch [ngSwitch]="isImage">
        <ng-container *ngSwitchCase="true">
          <img class="preview-img" [src]="(file.url | resizeImage: imageSizes.SIZE_160x160) ?? ''" width="160" height="160" />
        </ng-container>
        <ng-container *ngSwitchDefault>
          <div class="absolute w-100 h-100 d-flex align-items-center justify-content-center text-lg">
            <i class="icon ni-file icon-large"></i>
          </div>
        </ng-container>
      </ng-switch>
    </ng-container>
    
    <button 
      *ngIf="removable && !inProgress"
      (click)="onRemoveFile($event)"
      class="btn btn-danger me-1 mt-1 p-2 position-absolute top-0 right-0 d-none deep-hover"
    >
      <i class="icon ni-delete-1"></i>
    </button>

    <div
      *ngIf="inProgress"
      class="position-absolute top-0 right-0 bottom-0 left-0 d-flex justify-content-center align-items-center"
    >
      <div role="status" class="spinner-border text-primary"></div>
    </div>
  </div>
  <div class="d-flex px-1 small" [title]="file.name">
    <div class="text-truncate file-name">{{filenameWithoutExtension}}</div>
    <div>.{{file.extension}}</div>
  </div>
</div>
