import {Injectable} from '@angular/core';
import {ApiService} from '../../api.service';
import {SaleCoefficientInterface, SaleCoefficientsDataInterface} from '../../core/models/sale-coefficient.model';
import {CatalogueType} from '../../core/enums/catalogue-type.enum';
import {CacheableObservable} from '../../cacheable-observable/cacheable-observable.model';

@Injectable()
export class SaleCoefficientsService {

  constructor(private api: ApiService) {
  }

  getByType(type: CatalogueType): CacheableObservable<SaleCoefficientsDataInterface> {
    return this.api.get(`sale-coefficient/${type}`) as CacheableObservable<SaleCoefficientsDataInterface>;
  }

  delete() {
    return this.api.delete(`sale-coefficient/`, null);
  }
}
