<div class="nav-wrapper">
  <nav class="navbar navbar-expand-md navbar-dark mw-1280p px-0" role="navigation">
    <div class="container-fluid h-100 px-3">
      <div class="ps-md-3 d-flex align-items-center h-100">
        <ng-content select="[navbar-left-side]"></ng-content>
      </div>
      <div class="pe-md-3 h-100">
        <ng-content select="[navbar-right-side]"></ng-content>
      </div>
    </div>
  </nav>
</div>
