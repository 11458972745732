import { Directive } from '@angular/core';

@Directive({
  selector: '[appDraggableContent]'
})
export class DraggableContentDirective {

  constructor() { }

}
