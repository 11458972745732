import { Injectable } from '@angular/core';
import { PermissionsUserInterface, PermissionsUserService, PermissionsUserServiceInterface } from './user.model';
import { Observable } from 'rxjs';

export const FAKE_EMPTY_USER: PermissionsUserInterface = {
  role: {
    name: null,
  },
};

@Injectable()
export class UserService implements PermissionsUserService {
  constructor() {}

  getUser(): Observable<PermissionsUserInterface> {
    return new Observable((observer) => {
      observer.next(FAKE_EMPTY_USER);
      observer.complete();
    });
  }
}
