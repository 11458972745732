import { Validators } from "@angular/forms";
import { containsLowerCaseLetters, containsNumbers, containsUpperCaseLetters, notEqualTo, containsSpecialChars } from "../shared/class/custom-validators";

export const PASSWORD_VALIDATION = [
  Validators.required,
  Validators.minLength(12),
  containsUpperCaseLetters,
  containsLowerCaseLetters,
  containsNumbers,
  containsSpecialChars,
  notEqualTo('Cartoon-Duck-14-Coffee-Glvs'),
];
