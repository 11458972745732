import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientService } from '../../../../core/services/client/client.service';
import { LoaderComponent } from '../../../../ui-elements/loader/loader.component';
import { TranslateService } from '@ngx-translate/core';
import { ClientInterface } from '../../../../core/models/client.model';
import { ButtonInterface } from '../../../../shared/interfaces/modal/button.model';
import { ToastService } from '../../../../ui-elements/toast/toast.service';

@Component({
  selector: 'app-user-settings-modal-delete',
  templateUrl: './delete-modal.component.html',
})
export class ClientDeleteModalComponent implements OnInit {
  @ViewChild('loader') loader: LoaderComponent;
  @Input() client: ClientInterface;

  @Output() deleted: EventEmitter<boolean | null | undefined> = new EventEmitter<boolean | null | undefined>();

  buttons: ButtonInterface[] = [
    {
      text: 'SETTINGS.CANCEL',
      action: () => this.activeModal.close(),
      classes: 'btn-secondary',
    },
    {
      text: 'SETTINGS.DELETE',
      action: () => this.onDelete(),
      classes: 'btn-danger',
    },
  ];

  constructor(
    private clientService: ClientService,
    private activeModal: NgbActiveModal,
    private toastService: ToastService,
    private translator: TranslateService
  ) {}

  ngOnInit() {}

  onDelete() {
    this.loader.show();
    this.clientService.delete(this.client.id).subscribe(() => {
      this.translator.get('SETTINGS.DELETED_SUCCESS').subscribe(
        translation => {
          this.toastService.success(translation);
          this.loader.hide();
          this.deleted.emit();
          this.activeModal.dismiss('success');
        },
        err => {
          this.loader.hide();
        }
      );
    });
  }
}
