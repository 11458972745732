import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { SelectedRowsService } from '../../../services/selected-rows/selected-rows.service';
import { UntypedFormBuilder } from '@angular/forms';
import { ExtraItemsService } from './extra-items.service';
import { OrderArticlesRowsService } from '../../../services/order-articles-rows/order-articles-rows.service';
import { ExtraRowComponentModel } from '../../models/extra-row-component.model';
import { ListModeSwitchService } from '../../../../../shared/components/list-mode-switch/list-mode-switch.service';

@Component({
  selector: 'app-order-articles-list-extra-row',
  templateUrl: './extra-row.component.html',
  styleUrls: ['./extra-row.component.scss'],
})
export class ExtraRowComponent extends ExtraRowComponentModel {
  constructor(
    protected fb: UntypedFormBuilder,
    protected extraListElementService: ExtraItemsService,
    protected selectedRowsService: SelectedRowsService,
    protected listModeSwitchService: ListModeSwitchService,
    protected orderArticlesRowsService: OrderArticlesRowsService,
    protected cdr: ChangeDetectorRef
  ) {
    super(fb, extraListElementService, selectedRowsService, listModeSwitchService, orderArticlesRowsService, cdr);
  }

  onToggleSelect() {
    this.onSelect(!this.selected);
  }
}
