import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AccordionTogglerComponent} from './accordion-toggler.component';
import {TooltipModule} from '../tooltip/tooltip.module';

@NgModule({
  imports: [
    CommonModule,
    TooltipModule
  ],
  declarations: [
    AccordionTogglerComponent
  ],
  exports: [
    AccordionTogglerComponent
  ]
})
export class AccordionTogglerModule {
}
