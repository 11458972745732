import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {filter} from 'rxjs/internal/operators/filter';
import {TranslateService} from "@ngx-translate/core";

/** Limit is arbitrary and not due to some technical limitations. */
const MAXIMUM_LIMIT = 10000;
const MINIMUM_LIMIT = 1;

const quantityValidator = (max: number) => {
  return (control: UntypedFormControl) => {
    const value = +control.value;
    if (value <= max) {
      return null;
    }

    return {
      maximum: {value},
    };
  };
};

export interface QuantitySpinnerChangeEvent {
  quantity: number;
  valid: boolean;
}

@Component({
  exportAs: 'quantitySpinner',
  selector: 'app-ui-elements-quantity-spinner',
  templateUrl: './quantity-spinner.component.html',
  styleUrls: ['./quantity-spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuantitySpinnerComponent implements OnInit {
  @Input() set quantity(value: number) {
    this.input.setValue(value, { emitEvent: false, });
  }
  @Input() debounceTime = 400;
  @Input() disabled = false;
  @Output() onChange: EventEmitter<QuantitySpinnerChangeEvent> = new EventEmitter<QuantitySpinnerChangeEvent>();

  input: UntypedFormControl = new UntypedFormControl(0, [
    Validators.required,
    Validators.pattern(/^([1-9]{1})([0-9]*)?$/),
    quantityValidator(MAXIMUM_LIMIT),
  ]);
  inputId: string = Math.random().toString(36).substring(7);

  constructor(private translateService: TranslateService) {
  }

  ngOnInit() {
    this.input.valueChanges
      .pipe(
        debounceTime(this.debounceTime),
        filter(() => !!this.input.value),
        distinctUntilChanged()
      )
      .subscribe(() => {
        this.onBlur();
        this.onChange.emit({quantity: this.input.value, valid: this.input.valid});
      });
  }

  onBlur() {
    if (this.input.value < MINIMUM_LIMIT) {
      this.input.patchValue(MINIMUM_LIMIT);
    }
  }

  increaseQuantity() {
    const newValue = this.getQuantity() + 1;

    if (newValue <= MAXIMUM_LIMIT) {
      this.input.patchValue(newValue);
    }
  }

  decreaseQuantity(): void {
    const newValue = this.getQuantity() - 1;

    if (newValue >= MINIMUM_LIMIT) {
      this.input.patchValue(newValue);
    }
  }

  private getQuantity(): number {
    return +this.input.value;
  }

  errorMessage(): string {
    if (this.input.valid) {
      return '';
    }

    if (this.input.errors.maximum) {
      return this.translateService.instant('QUANTITY_SPINNER.ERRORS.MAXIMUM_VALUE_LIMIT_REACHED');
    }
    if (this.input.errors.pattern) {
      return this.translateService.instant('QUANTITY_SPINNER.ERRORS.QUANTITY_MUST_BE_NUMBER');
    }
    if (this.input.errors.required) {
      return this.translateService.instant('QUANTITY_SPINNER.ERRORS.REQUIRED');
    }

    return '';
  }

  canIncreaseQuantity() {
    return +this.input.value >= MAXIMUM_LIMIT;
  }

  canDecreaseQuantity() {
    return +this.input.value <= MINIMUM_LIMIT
  }
}
