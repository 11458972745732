import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ButtonInterface} from '../../interfaces/modal/button.model';
import {Subject} from 'rxjs';


@Component({
  selector: 'app-leave-order-article-modal',
  templateUrl: './leave-modal.component.html',
  styleUrls: ['./leave-modal.component.scss']
})
export class LeaveModalComponent implements OnInit {
  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  buttons: ButtonInterface[] = [];

  constructor(private activeModal: NgbActiveModal) {
  }

  ngOnInit() {

    this.buttons = [{
      text: 'LEAVE_MODAL.CANCEL',
      action: () => this.onCancel(),
      classes: 'btn-link'
    },
      {
        text: `LEAVE_MODAL.LEAVE`,
        action: () => this.onLeave(),
        classes: 'btn-orange'
      }];
  }

  public onCancel(): void {
    this.onClose.next(false);
    this.activeModal.close();
  }

  onLeave() {
    this.onClose.next(true);
    this.activeModal.close();
  }
}
