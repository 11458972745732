import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { FileSizeEnum } from '../../../api.service';

@Pipe({
  name: 'filesizeFormat'
})
export class FilesizeFormatPipe implements PipeTransform {
  constructor(public decimalPipe: DecimalPipe) {}

  transform(size: number): string {
    if (size >= FileSizeEnum.SIZE_1GB) {
      return this.decimalPipe.transform((size / FileSizeEnum.SIZE_1GB), '.0-2') + ' GB';
    } else if (size >= FileSizeEnum.SIZE_1MB) {
      return this.decimalPipe.transform((size / FileSizeEnum.SIZE_1MB), '.0-2') + ' MB';
    } else if (size >= FileSizeEnum.SIZE_1KB) {
      return this.decimalPipe.transform((size / FileSizeEnum.SIZE_1KB), '.0-2') + ' KB';
    } else {
      return size + ' B';
    }
  }

}
