import { Component, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { FileData } from '../../../core/models/file-data.model';
import { ImageSizeEnum } from '../../pipes/resize-image/resize-image.pipe';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GalleryComponent implements OnChanges {
  @Input() images: FileData[] = [];
  @Input() noDownloadAll: boolean = false;

  imageSizes = ImageSizeEnum;

  constructor(private config: NgbCarouselConfig) {
    config.interval = 0;
  }

  ngOnChanges() {}

  private sleep(sleepDuration: number) {
    const now = new Date().getTime();
    while (new Date().getTime() < now + sleepDuration) {
      /* do nothing */
    }
  }

  downloadAll(images: FileData[]) {
    const downloads = document.createElement('div');
    downloads.style.display = 'none';
    const container = document.getElementById('gallery-container');
    container.appendChild(downloads);

    const clickPromises = [];
    for (let i = 0; i < images.length; i++) {
      const temporaryDownloadLink = document.createElement('a');
      temporaryDownloadLink.setAttribute('href', images[i].url);
      temporaryDownloadLink.setAttribute('download', '');

      downloads.appendChild(temporaryDownloadLink);

      clickPromises.push(
        new Promise((resolve, reject) => {
          temporaryDownloadLink.click();
          this.sleep(250);
          resolve(true);
        })
      );
    }

    Promise.all(clickPromises).then(() => {
      container.removeChild(downloads);
    });
  }
}
