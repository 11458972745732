import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { AbstractSearchService } from './search-service.model';

@Injectable({ providedIn: 'root' })
export class SearchService {
  private currentService: AbstractSearchService | null = null;
  searchTerm: string | null = null;
  term$ = new BehaviorSubject<string | null>(null);

  termChangeSubscription: Subscription | null = null;

  constructor() {}

  changeTerm(term: string | null) {
    this.currentService.changeTerm(term);
  }

  getTermObservable(): Observable<string | null> {
    return this.term$.asObservable();
  }

  getTerm(): string | null {
    return this.term$.getValue();
  }

  isSearchActive() {
    return this.currentService?.isSearchActive();
  }

  hasSearchService() {
    return !!this.currentService;
  }

  switchSearchService(componentSearchService: AbstractSearchService | null) {
    this.termChangeSubscription?.unsubscribe();
    this.currentService = componentSearchService;
    if (this.currentService) {
      this.termChangeSubscription = this.currentService.term$.subscribe(term => {
        this.searchTerm = term;
        this.term$.next(term);
      });
    }
  }

  getInputPlaceHolder(): string {
    return this.currentService?.placeholder;
  }
}
