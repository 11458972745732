import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CheckboxFilterComponent } from '../checkbox-filter.component';

@Component({
  selector: 'app-checkbox-filter-option',
  template: `
    <div class="form-check">
      <label class="cursor-pointer">
        <input type="checkbox"
          class="form-check-input"
          [ngModel]="checked"
          (ngModelChange)="checkedChange($event)"
        >
        <ng-content></ng-content>
      </label>
    </div>
  `,
  host: { class: 'd-block' }
})
export class CheckboxFilterOptionComponent {
  @Input() value: any = null;
  @Output() onChange = new EventEmitter();

  get checked() {
    return this.checkboxFilter.isChecked(this.value);
  };

  constructor(
    private checkboxFilter: CheckboxFilterComponent
  ) {}

  checkedChange(checked: boolean) {
    this.checkboxFilter.change(this.value, checked);
  }
}
