<div class="d-flex border-top" [style.height.rem]="4">
  <!-- Quantity -->
  <div class="d-flex align-items-center border-end px-3" [style.width.rem]="20">
    <app-ui-elements-quantity-spinner #quantitySpinner="quantitySpinner"
      class="d-block mx-2"
      [quantity]="quantity"
      (onChange)="onQuantityChange($event)"
    ></app-ui-elements-quantity-spinner>
  </div>

  <!-- Select order -->
  <div class="flex-grow-1 d-flex align-items-center"
       [appDisableByRole]="[userRole.ROLE_ADMIN]"
  >
    <app-order-select-menu-toggler class="w-100 h-100"></app-order-select-menu-toggler>
  </div>

  <!-- Price -->
  <div class="d-flex align-items-center justify-content-end">
    <app-price-details [details]="priceDetails" *ngIf="priceDetails" (detailedViewClicked)="onDetailedViewClick()"></app-price-details>
    <div class="fw-bold color-black pe-3 me-2">
      {{ 'CONFIGURATOR_MODAL.FOOTER.PRICE_TOTAL' | translate }}:
      <visibility-by-variables [hideIf]="['hidePrices']" [replacement]="'-'">
        {{ price ? (price | priceListCurrency) : '-' }}
      </visibility-by-variables>
    </div>
  </div>

  <!-- Actions -->
  <div #createOrderBounds>
    <app-loader #loader [cssClasses]="'loader--white'"></app-loader>

    <ng-container *ngIf="hasMigrationIssues && hasMigrationIssues > acceptedMigrationIssuesCount && !hideMigrationIssues; else: orderActionButton">
      <button
        class="btn btn-warning btn-lg fw-bold d-flex align-items-center justify-content-center h-100 no-outline text-white"
        [style.width.rem]="23.4375"
        (click)="onOpenMigrationLog()"
      >
        {{ 'CONFIGURATOR_MODAL.CONTROLS.REVIEW_CHANGES' | translate }}
      </button>
    </ng-container>
  </div>
</div>

<ng-template #selectOffer>
  <button
    class="btn btn-warning btn-lg fw-bold d-flex align-items-center justify-content-center h-100 no-outline text-white"
    [style.width.rem]="23.4375"
    (click)="openOrderSelect()"
  >
    {{ (isDealer ? 'CONFIGURATOR_MODAL.FOOTER.SELECT_OFFER' : 'CONFIGURATOR_MODAL.FOOTER.SELECT_ORDER') | translate }}
  </button>
</ng-template>

<ng-template #orderActionButton>
  <button
    class="btn btn-primary btn-lg fw-bold d-flex align-items-center justify-content-center h-100 no-outline"
    [class.disabled]="!canOrder || isDisabled || !quantitySpinner.input.valid"
    [style.width.rem]="23.4375"
    (click)="onCreateOrder()"
    [ngbPopover]="getWarningMessage() | translate"
    [disablePopover]="canOrder && !isDisabled"
    container="body"
    triggers="mouseenter:mouseleave"
    popoverClass="shadow"
    *ngIf="selectedOrder; else selectOffer"
  >
    <ng-container [ngSwitch]="action">
      <span *ngSwitchCase="configuratorModalActionType.ADD_TO_OFFER">
        {{ 'CONFIGURATOR_MODAL.FOOTER.ADD_TO_OFFER' | translate }}
      </span>
      <span *ngSwitchCase="configuratorModalActionType.ADD_TO_ORDER">
        {{ 'CONFIGURATOR_MODAL.FOOTER.ADD_TO_ORDER' | translate }}
      </span>
      <span *ngSwitchCase="configuratorModalActionType.EDIT">
        {{ 'CONFIGURATOR_MODAL.FOOTER.SAVE' | translate }}
      </span>
    </ng-container>
    <i class="icon ni-info ms-2 my-1" *ngIf="!canOrder || isDisabled"></i>
  </button>
</ng-template>
