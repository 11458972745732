export enum OrderArticleDiscountType {
  CUSTOM = 10,
  QUANTITY = 20,
}

export enum OrderArticleSaleDiscountType {
  PERCENTAGE = 10,
  NUMERIC_VALUE = 20,
}

export enum OrderArticleSaleCoefficientType {
  DEFAULT = 10,
  CUSTOM = 20,
}
