import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ClientFormInterface, ClientFormService } from './client-form.service';
import { FormGroup } from '@angular/forms';
import { ClientInterface } from '../../core/models/client.model';
import { CountryInterface } from '../../core/models/country.model';
import { TextFieldThemeTypes } from '../../ui-elements/text-field/text-field/theme-types.enum';
import { PermissionActions } from '../../permissions.config';
import { Subscription } from 'rxjs';

export interface ClientModelInstanceChangeEventInterface {
  client: ClientInterface;
  valid: boolean;
}

@Component({
  selector: 'app-client-form',
  templateUrl: './client-form.component.html',
})
export class ClientFormComponent implements OnInit {
  @Input() model?: ClientInterface;
  @Input() countries: CountryInterface[] = [];
  @Input() readOnly? = false;

  @Output() changed: EventEmitter<ClientModelInstanceChangeEventInterface> = new EventEmitter<ClientModelInstanceChangeEventInterface>();

  form: FormGroup<ClientFormInterface>;
  textFieldThemeTypes = TextFieldThemeTypes;
  permissionActions = PermissionActions;

  private subscription: Subscription;

  constructor(private clientFormService: ClientFormService) {}

  ngOnInit() {
    const firstCountry = this.countries && this.countries.length > 0 ? this.countries[0] : null;
    this.clientFormService.constructForm({ ...{ country: firstCountry }, ...this.model }, this.readOnly).subscribe((form: FormGroup<ClientFormInterface>) => {
      this.form = form;

      if (this.model) {
        // try to match model with form
        this.form.patchValue(this.model);
        // discard whatever was not matched
        this.model = this.form.value as ClientInterface;

        if (this.readOnly) {
          return;
        }

        // send back the new model
        this.changed.emit({ client: this.model, valid: this.form.valid });
      }
      this.updateOnChange();
    });
  }

  private updateOnChange() {
    this.subscription = this.form.valueChanges.subscribe(() => {
      if (this.form.value) {
        this.model = this.form.value as ClientInterface;
        this.changed.emit({ client: this.model, valid: this.form.valid });
      }
    });
  }

  compareCountry(c1: CountryInterface, c2: CountryInterface) {
    return c1 && c2 && c1.id === c2.id;
  }
}
