import { Directive, DoCheck, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { DroppableDirective } from './ngx-droppable.directive';
import { DrakeStoreService } from '../services/drake-store/drake-store.service';
import { Subscription } from 'rxjs';

/**
 * Adds properties and events to draggable elements
 *
 * @export
 */
@Directive({ selector: '[appDraggable]' })
export class DraggableDirective implements OnInit, OnDestroy, DoCheck {
  @Input() model: any;

  @Input()
  set dropZones(val: any) {
    this.dropZonesValue = val;
  }

  get dropZones(): any {
    return this.dropZonesValue || this.parentDropzones;
  }

  // tslint:disable-next-line: no-input-rename
  @Input('moves') movesInput: boolean | ((...args: any[]) => any) = true;

  handles: any[] = [];

  get hasHandle() {
    return !!this.handles.length;
  }

  @Output() drag: EventEmitter<any> = new EventEmitter<any>();

  private subscription: Subscription = new Subscription();

  dragDelay = 200; // milliseconds
  dragDelayed = true;

  touchTimeout: any;

  dropZonesValue: string[];
  parentDropzones: string[];

  get element(): any {
    return this.el.nativeElement;
  }

  constructor(private el: ElementRef, private drakesService: DrakeStoreService, private droppableDirective: DroppableDirective) {}

  @HostListener('touchmove', ['$event'])
  onMove(e: Event) {
    if (!this.movesInput || this.dragDelayed) {
      e.stopPropagation();
      clearTimeout(this.touchTimeout);
    }
  }

  @HostListener('touchstart')
  onDown() {
    if (this.movesInput) {
      this.touchTimeout = setTimeout(() => {
        this.dragDelayed = false;
      }, this.dragDelay);
    }
  }

  @HostListener('touchend')
  onUp() {
    if (this.movesInput) {
      clearTimeout(<number>this.touchTimeout);
      this.dragDelayed = true;
    }
  }

  ngOnInit(): void {
    this.update();
  }

  update(): void {
    this.parentDropzones = [this.droppableDirective.dropZone];
    this.drakesService.registerDraggable(this);
    this.updateElements();
  }

  updateElements(): void {
    const nativeElement = this.el.nativeElement;
    const handles: NodeList = nativeElement.querySelectorAll('[appDragHandle]');
    this.handles = Array.from(handles).filter((h: any) => findFirstDraggableParent(h) === nativeElement);

    function findFirstDraggableParent(c: any) {
      while (c.parentNode) {
        c = c.parentNode;
        if (c.hasAttribute && c.hasAttribute('appdraggable')) {
          return c;
        }
      }
    }
  }

  canMove(source?: any, handle?: any, sibling?: any): boolean {
    if (typeof this.movesInput === 'boolean') {
      return this.movesInput;
    }
    if (typeof this.movesInput === 'function') {
      return this.movesInput(this.model, source, handle, sibling);
    }
    return true;
  }

  moves(source: any, handle: any, sibling: any): boolean {
    if (!this.canMove(source, handle, sibling)) {
      return false;
    }

    return this.hasHandle ? this.handles.some((h) => handelFor(handle, h)) : true;

    function handelFor(c: any, p: any) {
      if (c === p) {
        return true;
      }
      while ((c = c.parentNode) && c !== p);
      return !!c;
    }
  }

  ngDoCheck(): void {
    this.updateElements();
  }

  ngOnDestroy(): void {
    this.drakesService.removeDraggable(this);
    this.subscription.unsubscribe();
  }
}
