import { Injectable } from '@angular/core';
import { DocumentTemplate } from './document-template.interface';

@Injectable({
  providedIn: 'root'
})
export class TemplateTypeOrderMapperService {
  mapped: Map<number, number> = new Map();
  constructor() { }

  set(order: number, template: number) {
    this.mapped.set(order, template);
  }

  get(id: number) {
    return this.mapped.get(id);
  }

  has(id: number) {
    return this.mapped.has(id);
  }
}
