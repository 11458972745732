import { Component, OnInit } from '@angular/core';
import { PermissionActions } from '../../permissions.config';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
})
export class SettingsComponent implements OnInit {
  permissionActions = PermissionActions;
  constructor() {}

  ngOnInit() {}
}
