import { Component, OnInit } from '@angular/core';
import { UserService } from '../../core/services/user/user.service';
import { PriceListStoreHandlerService } from '../../core/services/price-list/price-list-store-handler.service';
import { PriceListInterface } from '../../core/models/pricelist.model';
import { UserInterface } from '../../core/models/user.model';
import { CurrencyInterface } from '../../core/models/currency.model';

@Component({
  selector: 'app-pricelist-currency',
  templateUrl: './pricelist-currency.component.html',
  styleUrls: ['./pricelist-currency.component.scss'],
})
export class PriceListCurrencyComponent implements OnInit {
  currency: CurrencyInterface;
  private priceList: PriceListInterface;

  constructor(private userService: UserService, private priceListStoreHandler: PriceListStoreHandlerService) {}

  ngOnInit() {
    this.userService.fromStorage().subscribe((user: UserInterface) => {
      this.priceList = this.priceListStoreHandler.getCurrentValue() || user.priceList;
      if (!(this.priceList && this.priceList.currency)) {
        this.currency = { id: null, name: '-' };
        return;
      }
      this.currency = this.priceList.currency;
    });
  }
}
