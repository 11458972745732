import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfiguratorModalComponent } from './configurator-modal.component';
import { FooterComponent } from './footer/footer.component';
import { ModelViewerComponent } from './model-viewer/model-viewer.component';
import { ModelViewerService } from './model-viewer/model-viewer.service';
import { ConfiguratorModalService } from './configurator-modal.service';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { OrderArticleService } from '../core/services/order-article/order-article.service';
import { LoaderService } from '../ui-elements/loader/loader.service';
import { SessionModule } from './session/session.module';
import { SessionService } from './session/session.service';
import { TranslateModule } from '@ngx-translate/core';
import { MissingTranslationHandler } from '@ngx-translate/core';
import { CustomMissingTranslationHandler } from '../core/models/custom-missing-translation-handler.model';
import { TextFieldModule } from '../ui-elements/text-field/text-field.module';
import { RefreshButtonModule } from '../ui-elements/refresh-button/refresh-button.module';
import { AccordionTogglerModule } from '../ui-elements/accordion-toggler/accordion-toggler.module';
import { PropertyValuesSelectorModule } from './property-values-selector/property-values-selector.module';
import {
  SimpleArticlePropertiesConfiguratorModule
} from './simple-article-properties-configurator/simple-article-properties-configurator.module';
import { LoaderModule } from '../ui-elements/loader/loader.module';
import { CloseButtonModule } from '../ui-elements/close-button/close-button.module';
import { QuantitySpinnerModule } from '../ui-elements/quantity-spinner/quantity-spinner.module';
import { OrderSelectMenuModule } from '../order-select-menu/order-select-menu.module';
import { VisibilityByVariablesModule } from '../visibility-by-variables/visibility-by-variables.module';
import { PriceDetailsComponent } from './footer/price-details/price-details.component';
import { PriceListDetailsModule } from '../pricelist-details/pricelist-details.module';
import { IconModule } from '../ui-elements/icon/icon.module';
import { PriceDetailsModalComponent } from './price-details-modal/price-details-modal.component';
import { RouterModule } from "@angular/router";
import { OpenConfiguratorModalComponent } from "./open-configurator-modal.component";
import { GenericModalModule } from "../ui-elements/generic-modal/generic-modal.module";
import { ResizeObserverModule } from '../resize-observer/resize-observer.module';
import { OverlayModule } from '@angular/cdk/overlay';
import { ArticleSelectorComponent } from './article-selector/article-selector.component';
import { MigrationsSidebarModalComponent } from './migrations-sidebar-modal/migrations-sidebar-modal.component';
import { PropertiesConfiguratorPropertyClassesModule } from './properties-configurator-property-classes/properties-configurator-property-classes.module';

const configuratorRoutes = [
  {
    path: ':system/:code/:variant',
    component: OpenConfiguratorModalComponent,
  },
];

@NgModule({
  exports: [
    ConfiguratorModalComponent,
    RouterModule
  ],
  imports: [
    RouterModule.forChild(configuratorRoutes),
    OrderSelectMenuModule,
    CommonModule,
    NgbAccordionModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    SessionModule,
    TranslateModule.forChild({
      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: CustomMissingTranslationHandler },
    }),
    TextFieldModule,
    RefreshButtonModule,
    AccordionTogglerModule,
    CloseButtonModule,
    PropertyValuesSelectorModule,
    SimpleArticlePropertiesConfiguratorModule,
    LoaderModule,
    QuantitySpinnerModule,
    OrderSelectMenuModule,
    VisibilityByVariablesModule,
    PriceListDetailsModule,
    IconModule,
    GenericModalModule,
    ResizeObserverModule,
    OverlayModule,
    PropertiesConfiguratorPropertyClassesModule
  ],
  declarations: [
    ConfiguratorModalComponent,
    FooterComponent,
    ModelViewerComponent,
    PriceDetailsComponent,
    PriceDetailsModalComponent,
    OpenConfiguratorModalComponent,
    ArticleSelectorComponent,
    MigrationsSidebarModalComponent,
  ],
  providers: [
    ModelViewerService,
    ConfiguratorModalService,
    OrderArticleService,
    LoaderService,
    SessionService
  ]
})
export class ConfiguratorModalModule {
}
