<div class="inline-field">
  <div class="text-wrap d-flex align-items-center justify-content-end pe-3">
    <ng-content select="label"></ng-content>
  </div>
  <div class="inline-field-right">
    <ng-content></ng-content>
  </div>
  <div *ngIf="defaultErrors && ngControl?.invalid && ngControl?.touched"
    class="invalid-feedback d-block inline-field-right"
  >
    <ng-container *ngIf="ngControl?.errors">{{ ngControl.errors | translateValidationErrors }}</ng-container>
  </div>
  <div *ngIf="errorsTemplate" class="invalid-feedback d-block inline-field-right">
    <ng-container *ngTemplateOutlet="errorsTemplate, context: { $implicit: ngControl?.errors }"></ng-container>
  </div>
  <div *ngIf="additionalInfoTemplate" class="inline-field-right">
    <ng-container *ngTemplateOutlet="additionalInfoTemplate"></ng-container>
  </div>
</div>
