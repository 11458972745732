export function outerHeight(domEl: HTMLElement): number {
  const computedStyle = window.getComputedStyle(domEl);
  let height = domEl.clientHeight;
  height += parseInt(computedStyle.marginTop, 10);
  height += parseInt(computedStyle.marginBottom, 10);
  height += parseInt(computedStyle.borderTopWidth, 10);
  height += parseInt(computedStyle.borderBottomWidth, 10);
  return height;
}

export function innerHeight(domEl: HTMLElement): number {
  const computedStyle = window.getComputedStyle(domEl);
  let height = domEl.clientHeight;
  height -= parseInt(computedStyle.paddingTop, 10);
  height -= parseInt(computedStyle.paddingBottom, 10);
  return height;
}
