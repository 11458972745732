import { Directive } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

@Directive({
  selector: 'ng-template[appInlineFieldErrorsTemplate]'
})
export class InlineFieldErrorsTemplateDirective {
  static ngTemplateContextGuard(
    dir: InlineFieldErrorsTemplateDirective,
    ctx: unknown
  ): ctx is { $implicit: ValidationErrors | null | undefined } {
    return true;
  }
}
