import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { Moment } from 'moment';

interface DatepickerDayInterface {
  value: number;
  available: boolean;
  isWeekend?: boolean;
}

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
})
export class DatepickerComponent implements OnInit {
  private _selectedDate: Moment;
  @Input()
  set selectedDate(value: Moment | Date) {
    this._selectedDate = moment(value);
  }

  get selectedDate(): Moment | Date {
    return this._selectedDate;
  }

  @Output() select = new EventEmitter<any>();

  navDate: Moment;
  weekDaysHeaderArr: string[] = [];
  monthDays: DatepickerDayInterface[] = [];

  constructor() {}

  ngOnInit() {
    this.navDate = moment();
    this.makeHeader();
    this.makeGrid();
  }

  changeNavMonth(num: number) {
    this.navDate.add(num, 'month');
    this.makeGrid();
  }

  makeHeader() {
    const weekDaysArr: number[] = [1, 2, 3, 4, 5, 6, 7]; // start from monday
    this.weekDaysHeaderArr = weekDaysArr.reduce((acc, day) => {
      acc.push(
        moment()
          .weekday(day)
          .format('ddd')
          .toUpperCase()
      );
      return acc;
    }, []);
  }

  makeGrid() {
    const currentDate = moment(this.navDate);
    this.monthDays = [];

    const firstDayDate = currentDate.startOf('month');
    const initialEmptyCells = firstDayDate.isoWeekday() - 1;
    const lastDayDate = currentDate.endOf('month');
    const lastEmptyCells = 7 - lastDayDate.isoWeekday();
    const daysInMonth = this.navDate.daysInMonth();
    const arrayLength = initialEmptyCells + lastEmptyCells + daysInMonth;

    for (let i = 0; i < arrayLength; i++) {
      let obj: DatepickerDayInterface = { value: 0, available: false };
      if (!(i < initialEmptyCells || i > initialEmptyCells + daysInMonth - 1)) {
        const value = i - initialEmptyCells + 1;
        obj.value = value;
        obj.available = this.isAvailable(value);
        obj.isWeekend = this.isWeekend(value);
      }
      this.monthDays.push(obj);
    }
  }

  isWeekend(num: number): boolean {
    let dateToCheck = this.dateFromNum(num, this.navDate);
    const day = dateToCheck.isoWeekday();
    const { saturday, sunday } = { saturday: 6, sunday: 7 };
    return day === saturday || day === sunday;
  }

  private isAvailable(num: number): boolean {
    return true;
  }

  dateFromNum(num: number, referenceDate = this.navDate): Moment {
    return moment(referenceDate).date(num);
  }

  selectDay(day: DatepickerDayInterface) {
    if (day.available) {
      this.selectedDate = this.dateFromNum(day.value);
      this.select.emit(this.selectedDate.format('YYYY-MM-DD'));
    }
  }
}
