<form [formGroup]="form" class="overflow-auto" #formEl>
  <h4 class="fw-bold color-gray mb-3 pb-2 border-bottom border-color-gray-400">
    {{ 'ORDERS.OTHER_SUPPLIER_ITEM_MODAL.PROPERTIES' | translate }}
  </h4>

  <div class="mb-3">
    <label for="inputCategory" class="form-label">
      {{ ('ORDERS.NON_STANDARD_ITEM_MODAL.FORM.CATEGORY' | translate | requiredFieldsAsterisk: requiredFields:'category') }}
    </label>
    <input type="text" class="form-control" id="inputCategory"
           formControlName="category"
           [placeholder]="'ORDERS.NON_STANDARD_ITEM_MODAL.FORM_PLACEHOLDERS.CUSTOM_CATEGORY' | translate"
           [class.is-invalid]="form.get('category').touched && form.get('category').invalid"
    />
    <div class="invalid-feedback">
      {{ form.get('category').errors | translateValidationErrors }}
    </div>
  </div>

  <div class="mb-3">
    <label for="inputCode" class="form-label">
      {{ ('ORDERS.NON_STANDARD_ITEM_MODAL.FORM.PRODUCT_CODE' | translate | requiredFieldsAsterisk: requiredFields:'code') }}
    </label>
    <input type="text" class="form-control" id="inputCode"
           formControlName="code"
           [placeholder]="'ORDERS.NON_STANDARD_ITEM_MODAL.FORM_PLACEHOLDERS.PRODUCT_CODE' | translate"
           [class.is-invalid]="form.get('code').touched && form.get('code').invalid"
    />
    <div class="invalid-feedback">
      {{ form.get('code').errors | translateValidationErrors }}
    </div>
  </div>

  <div class="mb-3">
    <label for="inputTypeInformation" class="form-label">
      {{ ('ORDERS.NON_STANDARD_ITEM_MODAL.FORM.MATERIALS' | translate | requiredFieldsAsterisk: requiredFields:'typeInformation') }}
    </label>
    <input type="text" class="form-control" id="inputTypeInformation"
           formControlName="typeInformation"
           [placeholder]="'ORDERS.NON_STANDARD_ITEM_MODAL.FORM_PLACEHOLDERS.MATERIALS' | translate"
           [class.is-invalid]="form.get('typeInformation').touched && form.get('typeInformation').invalid"
    />
    <div class="invalid-feedback">
      {{ form.get('typeInformation').errors | translateValidationErrors }}
    </div>
  </div>

  <h4 class="fw-bold color-gray my-3 py-2 border-bottom border-color-gray-400">
    {{ 'ORDERS.OTHER_SUPPLIER_ITEM_MODAL.PRICES' | translate }}
  </h4>

  <div class="mb-3 row g-0">
    <label for="inputPricelistPrice" class="col-6 col-form-label text-end pe-4">
      {{ 'ORDERS.NON_STANDARD_ITEM_MODAL.FORM.SALES_PRICE' | translate | requiredFieldsAsterisk: requiredFields:'pricelistPrice' }}
    </label>
    <div class="col-6">
      <input type="text" class="form-control" id="inputPricelistPrice"
             formControlName="pricelistPrice"
             [placeholder]="'ORDERS.NON_STANDARD_ITEM_MODAL.FORM_PLACEHOLDERS.SALES_PRICE' | translate"
             [class.is-invalid]="form.get('pricelistPrice').touched && form.get('pricelistPrice').invalid"
             (blur)="formatPrice('pricelistPrice')"
      />
      <div class="invalid-feedback text-end">
        {{ form.get('pricelistPrice').errors | translateValidationErrors }}
      </div>
    </div>
  </div>

  <h4 class="fw-bold color-gray my-3 py-2 border-bottom border-color-gray-400">
    {{ 'ORDERS.OTHER_SUPPLIER_ITEM_MODAL.DIMENSIONS' | translate }}
  </h4>

  <div class="mb-3 row g-0">
    <label for="inputHeight" class="col-6 col-form-label text-end pe-4">
      {{ ('ORDERS.NON_STANDARD_ITEM_MODAL.FORM.HEIGHT' | translate | requiredFieldsAsterisk: requiredFields:'height') }}
    </label>
    <div class="col-6">
      <input type="text" class="form-control" id="inputHeight"
             formControlName="height"
             [placeholder]="'ORDERS.NON_STANDARD_ITEM_MODAL.FORM_PLACEHOLDERS.HEIGHT' | translate"
             [class.is-invalid]="form.get('height').touched && form.get('height').invalid"
      />
      <div class="invalid-feedback text-end">
        {{ form.get('height').errors | translateValidationErrors }}
      </div>
    </div>
  </div>

  <div class="mb-3 row g-0">
    <label for="inputDepth" class="col-6 col-form-label text-end pe-4">
      {{ ('ORDERS.NON_STANDARD_ITEM_MODAL.FORM.DEPTH' | translate | requiredFieldsAsterisk: requiredFields:'depth') }}
    </label>
    <div class="col-6">
      <input type="text" class="form-control" id="inputDepth"
             formControlName="depth"
             [placeholder]="'ORDERS.NON_STANDARD_ITEM_MODAL.FORM_PLACEHOLDERS.DEPTH' | translate"
             [class.is-invalid]="form.get('depth').touched && form.get('depth').invalid"
      />
      <div class="invalid-feedback text-end">
        {{ form.get('depth').errors | translateValidationErrors }}
      </div>
    </div>
  </div>

  <div class="mb-3 row g-0">
    <label for="inputWidth" class="col-6 col-form-label text-end pe-4">
      {{ ('ORDERS.NON_STANDARD_ITEM_MODAL.FORM.WIDTH' | translate | requiredFieldsAsterisk: requiredFields:'width') }}
    </label>
    <div class="col-6">
      <input type="text" class="form-control" id="inputWidth"
             formControlName="width"
             [placeholder]="'ORDERS.NON_STANDARD_ITEM_MODAL.FORM_PLACEHOLDERS.WIDTH' | translate"
             [class.is-invalid]="form.get('width').touched && form.get('width').invalid"
      />
      <div class="invalid-feedback text-end">
        {{ form.get('width').errors | translateValidationErrors }}
      </div>
    </div>
  </div>

  <div class="mb-3 row g-0">
    <label for="inputVolume" class="col-6 col-form-label text-end pe-4">
      {{ ('ORDERS.NON_STANDARD_ITEM_MODAL.FORM.VOLUME' | translate | requiredFieldsAsterisk: requiredFields:'volume') }}
    </label>
    <div class="col-6">
      <input type="text" class="form-control" id="inputVolume"
             formControlName="volume"
             [placeholder]="'ORDERS.NON_STANDARD_ITEM_MODAL.FORM_PLACEHOLDERS.VOLUME' | translate"
             [class.is-invalid]="form.get('volume').touched && form.get('volume').invalid"
             (blur)="formatFloatNumber('volume')"
      />
      <div class="invalid-feedback text-end">
        {{ form.get('volume').errors | translateValidationErrors }}
      </div>
    </div>
  </div>

  <div class="mb-3 row g-0">
    <label for="inputWeight" class="col-6 col-form-label text-end pe-4">
      {{ ('ORDERS.NON_STANDARD_ITEM_MODAL.FORM.WEIGHT' | translate | requiredFieldsAsterisk: requiredFields:'weight') }}
    </label>
    <div class="col-6">
      <input type="text" class="form-control" id="inputWeight"
             formControlName="weight"
             [placeholder]="'ORDERS.NON_STANDARD_ITEM_MODAL.FORM_PLACEHOLDERS.WEIGHT' | translate"
             [class.is-invalid]="form.get('weight').touched && form.get('weight').invalid"
             (blur)="formatFloatNumber('weight')"
      />
      <div class="invalid-feedback text-end">
        {{ form.get('weight').errors | translateValidationErrors }}
      </div>
    </div>
  </div>
</form>
