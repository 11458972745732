import { Inject, Injectable } from '@angular/core';
import { PermissionsDecisionMakerServiceInterface } from '../../../permissions/decision-maker.service';
import { PriceListStoreHandlerService } from '../price-list/price-list-store-handler.service';
import { Observable, ReplaySubject } from 'rxjs';
import { AuthService } from '../../../auth/auth.service';
import { filter } from 'rxjs/operators';
import { PERMISSIONS_DECISION_MAKER_SERVICE } from '../../../permissions/injection-tokens';
import { CurrencyInterface } from '../../models/currency.model';

@Injectable({
  providedIn: 'root',
})
export class PriceListCurrencyService {
  private currency$: ReplaySubject<CurrencyInterface> = new ReplaySubject<CurrencyInterface>(1);

  constructor(
    private authService: AuthService,
    @Inject(PERMISSIONS_DECISION_MAKER_SERVICE) private permissionsDecisionMakerService: PermissionsDecisionMakerServiceInterface,
    private priceListStoreHandlerService: PriceListStoreHandlerService
  ) {
    this.setPriceList();
  }

  private setPriceList() {
    this.priceListStoreHandlerService
      .getCurrentAsObservable()
      .pipe(filter(currentPriceList => currentPriceList))
      .subscribe(currentPriceList => {
        this.currency$.next(currentPriceList.currency);
      });
  }

  getCurrencyAsObservable(): Observable<CurrencyInterface> {
    return this.currency$.asObservable();
  }
}
