<ng-container *ngIf="!!plan?.dateFrom && !dismissed">
  <div class="mt-5" *ngIf="!belowFooter"></div>
  <div
    class="banner-wrapper"
    [ngClass]="{ 'position-fixed left-0 right-0 bottom-0': !belowFooter }"
  >
    <app-alert
      [variant]="lessThanTwoHoursLeft ? 'danger' : 'dark-gray'"
      (onActionCloseClick)="dismiss()"
    >
      {{ 'MAINTENANCE.BANNER_TEXT' | translate: { date: plan.dateFrom | translatedDate: 'MMMM DD', startTime: plan.dateFrom | date: 'HH:mm', endTime: plan.dateTo | date: 'HH:mm' } }}
    </app-alert>
  </div>
</ng-container>
