import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ThirdNavbarService {
  constructor() {}

  reorderSystems(systems) {
    systems = systems.sort((a, b) => a.name.localeCompare(b.name));
    let index = 1;
    const systemsOrdered = systems.reduce((carry, system) => {
      const firstLetter = system.name.charAt(0);
      const foundElement = carry.find(element => element.name === firstLetter);
      if (foundElement) {
        carry = carry.map(element => {
          if (element.name === firstLetter) {
            element.children.push(system);
          }

          return element;
        });
      } else {
        carry.push({
          name: firstLetter,
          originalName: firstLetter,
          id: index++,
          children: [system],
          slug: firstLetter,
        });
      }

      return carry;
    }, []);

    return systemsOrdered.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
  }
}
