import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ArticlePropertyProperty, ProductArticleVariant, ValuesGroupedByMaterialFamilyInterface } from '../../product.model';

export interface SelectedValuesGroupDataInterface {
  propertyId: ArticlePropertyProperty['id'];
  groupId: string;
  articleVariantNames: ProductArticleVariant['names'];
  isGroupOfMaterialFamilyValues: boolean;
}

@Injectable()
export class PropertyValueGroupsService {
  private selectedValueGroupsData$: BehaviorSubject<SelectedValuesGroupDataInterface[]> = new BehaviorSubject([]);

  getSelectedValueGroupsDataAsObservable(): Observable<SelectedValuesGroupDataInterface[]> {
    return this.selectedValueGroupsData$.asObservable();
  }

  selectPropertyValuesGroup(
    group: ValuesGroupedByMaterialFamilyInterface,
    groupId: string,
    propertyId: ArticlePropertyProperty['id']
  ): void {
    const currentValue = this.selectedValueGroupsData$.getValue();
    const selectedGroupData = {
      propertyId,
      groupId,
      articleVariantNames: group.articleVariantNames,
      isGroupOfMaterialFamilyValues: !!group.materialFamily,
    };

    if (!currentValue.length) {
      this.selectedValueGroupsData$.next([selectedGroupData]);

      return;
    }

    const notCompatibleGroupIsSelected = !currentValue.some((propertyValuesGroup) =>
      propertyValuesGroup.articleVariantNames.some((name) => group.articleVariantNames.includes(name))
    );

    if (notCompatibleGroupIsSelected) {
      this.selectedValueGroupsData$.next([selectedGroupData]);

      return;
    }

    const alreadySelectedGroupIndex = currentValue.findIndex((propertyValuesGroup) => propertyValuesGroup.groupId === groupId);

    if (alreadySelectedGroupIndex >= 0) {
      currentValue.splice(alreadySelectedGroupIndex, 1);

      this.selectedValueGroupsData$.next(currentValue);

      return;
    }

    const alreadySelectedGroupForSamePropertyIndex = currentValue.findIndex(
      (propertyValuesGroup) => propertyValuesGroup.propertyId === propertyId
    );

    if (alreadySelectedGroupForSamePropertyIndex >= 0) {
      currentValue.splice(alreadySelectedGroupForSamePropertyIndex, 1);

      const updatedPropertyValueGroupsData = [...currentValue, selectedGroupData];
      this.selectedValueGroupsData$.next(updatedPropertyValueGroupsData);

      return;
    }

    const otherPropertiesContainSelectedMaterialFamilyGroup = currentValue.some(
      (propertyValuesGroup) => propertyValuesGroup.isGroupOfMaterialFamilyValues
    );

    if (!group.materialFamily || !otherPropertiesContainSelectedMaterialFamilyGroup) {
      this.selectedValueGroupsData$.next([selectedGroupData]);

      return;
    }

    const updatedPropertyValueGroupsData = [...currentValue, selectedGroupData];
    this.selectedValueGroupsData$.next(updatedPropertyValueGroupsData);
  }
}
