<ng-container *ngIf="(messages$ | async) || [] as messages">
  <app-notifications-header [enableMarkAll]="allMessagesSeen(messages)"
    (markAllAsRead)="markAllAsRead()"
    (close)="close()"
  ></app-notifications-header>

  <app-loader #loader [loading]="loading"></app-loader>
  <ng-container *ngIf="!loader.loading">
    <ng-container *ngIf="messages.length; else noMessages">
      <app-abstract-notification *ngFor="let message of messages"
        (click)="open(message)"
        [showBadge]="message.status === messageStatus.NEW"
      >
        <ng-container notification-icon>
          <i class="icon ni-mail color-gray"></i>
        </ng-container>
        <ng-container notification-title>{{ message.name }}</ng-container>
        <ng-container notification-text>{{ message.message }}</ng-container>
        <ng-container notification-subtext>
          {{message.time * 1000 | timeAgo | async }}
        </ng-container>
      </app-abstract-notification>
    </ng-container>
    <ng-template #noMessages>
      <div class="small color-gray p-4 text-center border-bottom">
        {{ 'NOTIFICATIONS.NO_DATA' | translate }}
      </div>
    </ng-template>
  </ng-container>
</ng-container>
