import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-notifications-header',
  templateUrl: './notifications-header.component.html',
  styleUrls: ['./notifications-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationsHeaderComponent {
  @Input() enableMarkAll = false;

  @Output() markAllAsRead = new EventEmitter();
  @Output() close = new EventEmitter();

  onMarkAll() {
    this.markAllAsRead.emit();
  }

  onClose() {
    this.close.emit();
  }
}
