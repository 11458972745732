<div class="datepicker">
  <div class="datepicker__navigation">
    <span>{{ ("MONTHS." + navDate.format('MMMM').toUpperCase())|translate }}, {{ navDate.format('YYYY') }}</span>
    <div class="d-flex align-items-center datepicker__nav-buttons">
      <button class="btn btn-link" (click)="changeNavMonth(-1)">
        <svg class="icon icon--arrow icon--arrow--left">
          <use xlink:href="#icon-arrow-up" />
        </svg>
      </button>
      <button class="btn btn-link" (click)="changeNavMonth(1)">
        <svg class="icon icon--arrow icon--arrow--right">
          <use xlink:href="#icon-arrow-up" />
        </svg>
      </button>
    </div>
  </div>
  <div class="calendar">
    <div class="calendar__header">
      <div class="calendar__date" *ngFor="let weekDay of weekDaysHeaderArr">
        {{ ("DAYS." + weekDay) | translate }}
      </div>
    </div>
    <div class="calendar__body">
      <button
        *ngFor="let day of monthDays"
        class="btn btn-link calendar__date"
        [disabled]="!day.available"
        [ngClass]="{
          'calendar__date--weekend': day.isWeekend,
          'calendar__date--selected': dateFromNum(day.value).isSame(selectedDate, 'day')
        }"
        (click)="day.available && selectDay(day)"
      >
        {{ day.value || '' }}
      </button>
    </div>
  </div>
</div>
