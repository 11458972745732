import { AddressInterface } from "../../core/models/address.model";

export function isAddressFullyFilled(address: AddressInterface | null): boolean {
  if (!address) {
    return false;
  }

  const expectedKeys: (keyof AddressInterface)[] = [
    'deliveryAddressee',
    'address',
    'postCode',
    'city',
    'country',
    'contactWorkingHours',
    // 'deliveryOption',
    'contactPerson',
    'email',
    'phone'
  ];

  return expectedKeys.every(key => {
    const part = address[key] ?? null;
    return part !== null;
  });
}
