export interface ButtonInterface {
  text: string;
  action: Function | any | CallableFunction;
  classes?: string;
  disabled?: boolean | Function;
  id?: ButtonType;
  tooltipText?: string;
  tooltip?: boolean;
}

export enum ButtonType {
  CANCEL,
  SAVE
}
