export enum NotificationTranslationType {
  TRANSLATION_ORDER_ARTICLE_REMOVED = 'translation_code_4',
  TRANSLATION_AX_ADDED_ITEM = 'translation_code_5',
  TRANSLATION_AX_CHANGED_QNT = 'translation_code_6',
  TRANSLATION_AX_ORDER_UPDATE = 'translation_code_7',
  TRANSLATION_AX_DATE_CHANGE = 'translation_code_8',
  TRANSLATION_AX_ADDRESS_CHANGE = 'translation_code_9',
  TRANSLATION_AX_DELETED_LINE = 'translation_code_10',
  TRANSLATION_AX_PRICE_CHANGE = 'translation_code_11',
  TRANSLATION_ORDER_ARTICLE_ADDED = 'translation_code_12',
  TRANSLATION_ORDER_ARTICLE_UPDATED = 'translation_code_13',
  TRANSLATION_ORDER_STATE_CHANGED_ARCHIVED = 'translation_code_14',
  TRANSLATION_ORDER_STATE_CHANGED_LOADED = 'translation_code_15',
  TRANSLATION_ORDER_STATE_CHANGED_CONFIRMED = 'translation_code_16',
  TRANSLATION_ORDER_STATE_CHANGED_WAITING = 'translation_code_17',
  TRANSLATION_ARTICLE_PHOTOS_IMPORTED = 'translation_code_18',
  TRANSLATION_NON_STANDARD_ORDER_ARTICLE_UPDATED = 'translation_code_19',
  TRANSLATION_OTHER_SUPPLIER_ORDER_ARTICLE_UPDATED = 'translation_code_20',
  TRANSLATION_STANDARD_ORDER_ARTICLE_UPDATED = 'translation_code_21',
  TRANSLATION_CLAIM_NEW_MESSAGE = 'translation_code_22',
  TRANSLATION_CLAIM_STATE_CHANGE = 'translation_code_23',
  TRANSLATION_PROJECT_RESERVATION_NEW_MESSAGE = 'translation_code_24',
  TRANSLATION_PROJECT_RESERVATION_STATE_CHANGE = 'translation_code_25',
}
