import { Directive, OnInit, ElementRef, ViewChild, AfterViewInit, ContentChild, TemplateRef } from '@angular/core';
import { NgControl } from '@angular/forms';
import { TextFieldComponent } from '../../../ui-elements/text-field/text-field/text-field.component';

@Directive({
  selector: '[formControlName]',
})
export class FormNativeElementDirective implements OnInit, AfterViewInit {
  get control() {
    return this.controlDirective.control;
  }

  constructor(private controlDirective: NgControl, private host: ElementRef<HTMLFormElement>) {}

  ngAfterViewInit() {
    if (!this.control) {
      return;
    }
    if (this.controlDirective.valueAccessor && this.controlDirective.valueAccessor instanceof TextFieldComponent) {
      const { valueAccessor } = this.controlDirective;
      (this.control as any).nativeComponent = valueAccessor;
      if (valueAccessor.input) {
        (this.control as any).nativeElement = valueAccessor.input.nativeElement;
      }
    } else {
      (this.control as any).nativeElement = this.host;
    }
  }

  ngOnInit() {}
}
