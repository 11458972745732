import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ApiService } from '../api.service';

import { EstimateResponse } from './estimates.interface';

@Injectable({
  providedIn: 'root'
})
export class EstimatesService {

  constructor(
    private api: ApiService
  ) { }

  get(): Observable<EstimateResponse> {
    return this.api.get('ax/delivery-dates-rules/').noCache().pipe(
      map(data => data.data),
    );
  }
}
