import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextFieldModule } from '../ui-elements/text-field/text-field.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MenuTogglerComponent } from './menu-toggler/menu-toggler.component';
import { OrderSelectMenuComponent } from './order-select-menu.component';
import { OrderSelectMenuService } from './order-select-menu.service';
import { PermissionsModule } from '../permissions/permissions.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { ExtraItemsService } from '../orders/order-articles-list/order-articles-list/components/extra-row/extra-items.service';
import { LoaderModule } from '../ui-elements/loader/loader.module';
import { TooltipModule } from '../ui-elements/tooltip/tooltip.module';
import { NewItemComponent } from './new-item/new-item.component';
import { GroupSelectListComponent } from './group-select-list/group-select-list.component';
import { DraggableModule } from '../orders/order-articles-list/draggable/draggable.module';
import { OrderArticlesListService } from '../orders/order-articles-list/services/order-articles-list/order-articles-list.service';
import { SharedModule } from '../shared/shared.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

@NgModule({
  imports: [
    CommonModule,
    TextFieldModule,
    ReactiveFormsModule,
    FormsModule,
    PermissionsModule,
    TranslateModule.forChild(),
    NgClickOutsideDirective,
    ReactiveFormsModule,
    LoaderModule,
    TooltipModule,
    DraggableModule,
    SharedModule,
    InfiniteScrollModule,
  ],
  declarations: [OrderSelectMenuComponent, MenuTogglerComponent, GroupSelectListComponent, NewItemComponent],
  exports: [OrderSelectMenuComponent, MenuTogglerComponent],
  providers: [OrderSelectMenuService, ExtraItemsService, OrderArticlesListService],
})
export class OrderSelectMenuModule {}
