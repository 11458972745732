import {forwardRef, Injectable} from '@angular/core';
import { HTTP_INTERCEPTORS, HttpHandler, HttpHeaderResponse, HttpInterceptor, HttpProgressEvent, HttpRequest, HttpResponse, HttpSentEvent, HttpUserEvent } from '@angular/common/http';
import {SessionInterface, SessionService} from './session.service';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

export const CONFIGURATOR_SESSION_CONFIGURATION_IDENTIFIER_HEADER_KEY = 'X-PriceList7-Configurator-Session-Configuration-Identifier';

export const CONFIGURATOR_SESSION_INTERCEPTOR_PROVIDER = {
  provide: HTTP_INTERCEPTORS,
  useClass: forwardRef(() => SessionInterceptor),
  multi: true
};

@Injectable()
export class SessionInterceptor implements HttpInterceptor {

  constructor(private session: SessionService) {
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    const parsedSession: SessionInterface = this.session.latestFromStorage();
    if (parsedSession && parsedSession.session) {
      const headers = {};
      headers[CONFIGURATOR_SESSION_CONFIGURATION_IDENTIFIER_HEADER_KEY] = parsedSession.session;

      req = req.clone({
        setHeaders: headers
      });
    }

    return next.handle(req).pipe(tap(_ => {}));
  }

}
