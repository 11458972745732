<div class="address-item flex-grow-1 color-gray-700">
  <h4 *ngIf="deliveryAddressee" class="address-item-title">{{ deliveryAddressee }}</h4>

  <div *ngIf="addressData" class="small">
    <ng-container *ngFor="let item of addressData; let last = last">
      {{ item }}{{ !last ? ', ' : '' }}
    </ng-container>
    <ng-container *ngIf="contactWorkingHours">
      <span class="px-2">|</span> {{ contactWorkingHours}}
    </ng-container>
  </div>

  <div *ngIf="deliveryOption" class="small">
    {{ deliveryOption }}
  </div>

  <div *ngIf="!isAddressFullyFilled && !hideMissingInfoError" class="small color-danger">
    {{ 'ADDRESS.MISSING_REQUIRED_INFO' | translate }}
  </div>

  <div *ngIf="contactPersonData" class="mt-2 small">
    <ng-container *ngFor="let item of contactPersonData; let last = last">
      {{ item }}
      <ng-container *ngIf="!last">
        <span class="px-2">|</span>
      </ng-container>
    </ng-container>
  </div>
</div>
<ng-content></ng-content>
