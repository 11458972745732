import { Directive, ElementRef, EventEmitter, HostListener, Input, OnChanges, Output } from '@angular/core';
import { SaleCoefficientFormatPipe } from '../../pipes/sale-coefficient-format/sale-coefficient-format.pipe';

@Directive({
  selector: 'input[appSaleCoefficientFormat]',
})
export class SaleCoefficientFormatDirective implements OnChanges {
  @Input('appSaleCoefficientFormatValue') value;
  @Output('appSaleCoefficientFormat') update: EventEmitter<any> = new EventEmitter<any>();

  constructor(private elRef: ElementRef, private saleCoefficientFormatPipe: SaleCoefficientFormatPipe) {}

  ngOnChanges() {
    this.elRef.nativeElement.value = this.saleCoefficientFormatPipe.transform(this.value);
  }

  @HostListener('blur', ['$event.target.value'])
  onBlur(value) {
    this.update.emit(value);
  }
}
