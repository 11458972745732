import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription } from 'rxjs';

import { PropertyImageSizes } from '../../../core/enums/property-image-sizes.enum';
import { UncachePipe } from '../../../uncacheable-resource/uncache.pipe';
import { InfoBlockComponent } from '../../info-block/info-block.component';
import { CatalogueSystemsInterface, ProductArticleVariant, ProductsArticleInterface } from '../product.model';
import { ConfiguratorOpenerService } from '../products-configurator-opener.service';
import { ProductsViewConstants } from './products-view.constants';
import { SystemDirective } from './system.directive';

@Component({
  selector: 'app-products-list-view',
  templateUrl: './products-list-view.component.html',
  styleUrls: ['./products-list-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductsListViewComponent implements OnChanges, OnDestroy, AfterViewInit {
  @ViewChild('loadThreshold', { static: true }) private loadThresholdElement!: ElementRef<HTMLDivElement>;
  @ViewChildren(SystemDirective) set systemsRendered(value) {
    if (this.isElementInView(this.loadThresholdElement.nativeElement)) {
      this.showMore.emit();
    }
  }

  @Input() searchQuery: string;
  @Input() systems: CatalogueSystemsInterface[] = [];
  @Output() showMore = new EventEmitter<void>();

  descriptionColumnVisible = true;
  codeColumnVisible = true;
  highestPricesBySystems: Map<number, number> = new Map<number, number>();

  private intersectionObserver!: IntersectionObserver;
  private subscriptions = new Subscription();

  constructor(private modalService: NgbModal, private uncachepipe: UncachePipe, private configuratorOpener: ConfiguratorOpenerService) {}

  ngOnChanges() {
    this.findHighestCountOfPrices();
    this.setColumnsVisibility();

    if (this.systems && this.systems.length) {
      this.systems.map((system) => {
        system.articlePropertyClasses.map((articlePropertyClass) => {
          articlePropertyClass.properties.map((property) => {
            property.articlePropertyValues.map((valueGroup) => {
              valueGroup.valuesGroupedByMaterialFamily.map((valueGroup) => {
                valueGroup.values.map((value) => {
                  this.getPropertyImage({ img_thumbnails: value.img_thumbnails, img: value.img }).subscribe((thumb) => {
                    value.thumbnail = thumb;
                  });
                });
              });
            });
          });
        });
      });
    }
  }

  ngAfterViewInit() {
    this.intersectionObserver = new IntersectionObserver((entries: IntersectionObserverEntry[]) => {
      if (entries[0].isIntersecting) {
        this.showMore.emit();
      }
    });
    this.intersectionObserver.observe(this.loadThresholdElement.nativeElement);
  }

  private isElementInView(el: HTMLElement): boolean {
    const rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  openConfiguratorModal(system: CatalogueSystemsInterface, article: ProductsArticleInterface, articleVariant?: ProductArticleVariant) {
    this.configuratorOpener.openConfiguratorModal(system, article, articleVariant);
  }

  trackById(index: number, item: CatalogueSystemsInterface) {
    return item.id;
  }

  /**
   * finds highest count of prices in each system
   */
  findHighestCountOfPrices() {
    this.highestPricesBySystems.clear();
    this.systems.forEach((system, index) => {
      if (system.articlePropertyClasses && system.articlePropertyClasses.length > 0) {
        let maxPrices = 0;
        const [articlePropertyClass] = system.articlePropertyClasses;
        articlePropertyClass.articles.forEach((articleGroup) => {
          articleGroup.forEach((article) => {
            if (maxPrices < article.articleVariants.length) {
              maxPrices = article.articleVariants.length;
            }
          });
        });
        this.highestPricesBySystems.set(index, maxPrices);
      }
    });
  }

  /**
   * Sets column "DESCRIPTION" or/and "CODE" visibility to false if system count of prices is more than column constant
   */
  setColumnsVisibility() {
    for (const key of Array.from(this.highestPricesBySystems.keys())) {
      this.descriptionColumnVisible =
        this.highestPricesBySystems.get(key) < ProductsViewConstants.MAX_COUNT_OF_PRICES_TO_HIDE_DESCRIPTION_COLUMN;

      this.codeColumnVisible = this.highestPricesBySystems.get(key) < ProductsViewConstants.MAX_COUNT_OF_PRICES_TO_HIDE_CODE_COLUMN;
    }
  }

  openInfoBlock(system: CatalogueSystemsInterface) {
    const modalRef = this.modalService.open(InfoBlockComponent, {
      modalDialogClass: 'modal-dialog-scrollable',
      size: 'xl',
    });
    const componentInstance = modalRef.componentInstance as InfoBlockComponent;
    componentInstance.system = system;
  }

  private getPropertyImage({ img_thumbnails, img }): Observable<string> {
    const value = img_thumbnails
      ? img_thumbnails[PropertyImageSizes.SMALL] || img_thumbnails[PropertyImageSizes.MEDIUM] || img_thumbnails[PropertyImageSizes.LARGE]
      : img || '';
    return this.uncachepipe.transform(value);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.intersectionObserver.disconnect();
  }
}
