import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { Subscription } from 'rxjs';
import { PriceListCurrencyService } from '../../../core/services/price-list-currency/price-list-currency.service';

export const PRICELIST_CURRENCIES_MAP = (currency, value) => {
  return {
    EUR: `€${value}`,
    USD: `$${value}`,
    GBP: `£${value}`,
    RBL: `₽${value}`,
    SEK: `${value} kr`,
  }[currency];
};

const DEFAULT_CURRENCY = 'EUR';

@Pipe({
  name: 'pricelistCurrencySymbol',
})
export class PricelistCurrencySymbolPipe implements PipeTransform, OnDestroy {
  private currency: string = DEFAULT_CURRENCY;
  private subscriptions: Subscription = new Subscription();

  constructor(private priceListCurrencyService: PriceListCurrencyService) {
    this.priceListCurrencyService.getCurrencyAsObservable().subscribe(currency => {
      this.currency = currency.name;
    });
  }

  transform(value: any): any {
    return PRICELIST_CURRENCIES_MAP(this.currency || DEFAULT_CURRENCY, value);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
