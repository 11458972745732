import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';

/**
 * Highlights parts of `text` that `searchString` matches, outputs values
 * into DOM without need for sanitization.
 *
 * ```text.replace(regex, `<mark>$1</mark>`);``` and outputting returned
 * string into DOM with `[innerHTML]="..."` would be simplier, but [innerHTML] assignments
 * are(and should be) sanitized and sanitization negatively impacts performance.
 */
@Component({
  selector: 'app-search-highlight',
  template: `
    <ng-container *ngFor="let item of textArray; let even = even"
    >
      <ng-container *ngIf="even">{{ item }}</ng-container>
      <ng-container *ngIf="!even"><span [class]="highlightClass">{{ item }}</span></ng-container>
    </ng-container>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchHighlightComponent implements OnChanges {
  @Input() text = '';
  @Input() searchString?: string | null;
  @Input() highlightClass = 'highlight';
  textArray: string[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    this.textArray = this.getTextArray(this.text, this.searchString);
  }

  private getTextArray(text: string, searchString?: string | null) {
    if (!searchString) {
      return [text];
    }

    const pattern = searchString.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
    const regex = new RegExp(`(${pattern})`, 'gi');
    return text.split(regex);
  }
}
