import {Inject, Pipe, PipeTransform} from '@angular/core';
import {UNCACHEABLE_RESOURCE_QUERY_PARAM, UNCACHEABLE_RESOURCE_QUERY_PARAM_KEY} from './injection.tokens';
import {Observable} from 'rxjs';

@Pipe({
  name: 'uncache'
})
export class UncachePipe implements PipeTransform {
  constructor(
    @Inject(UNCACHEABLE_RESOURCE_QUERY_PARAM) private paramObservable: Observable<string>,
    @Inject(UNCACHEABLE_RESOURCE_QUERY_PARAM_KEY) private paramKey: string
  ) {
  }

  transform(value: any, args?: any): Observable<string> {
    return Observable.create(observer => {
      this.paramObservable.subscribe((param) => {
        if (value) {
          observer.next(`${value}?${this.paramKey}=${param}`);
          observer.complete();
        }
      });
    });
  }

}
