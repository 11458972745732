import {Injectable} from '@angular/core';
import {ApiService} from '../../../api.service';
import {merge, Observable, of} from 'rxjs';
import {distinctUntilChanged, filter, first, map, share, shareReplay, switchMap } from 'rxjs/operators';
import {FilterAssign, FilterInterface} from './filter.model';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {CacheableObservable} from '../../../cacheable-observable/cacheable-observable.model';
import { FilterPathGeneratorService } from '../../../core/services/filter-path-generator/filter-path-generator.service';
import { CataloguePathService } from '../../../core/services/catalogue-path/catalogue-path.service';

@Injectable()
export class FilterService {

  private filtersObservable$: Observable<Observable<FilterInterface[]>> = this.cataloguePathService.getCataloguePath$().pipe(
    filter(items => !!(items.child || items.parent)),
    map(({ parent, child }) => {
      return {
        parent: parent?.originalName?.toLowerCase() ?? null,
        child: child?.originalName?.toLowerCase() ?? null
      };
    }),
    // Check if category and subcategory was changed
    distinctUntilChanged((previous, current) => {
      return previous.parent === current.parent && previous.child === current.child;
    }),
    switchMap(({ parent, child }) => of(
      this.fetch(
        this.filterPathGenerator.generate(parent, child)
      ).pipe(share())
    )),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  /** Emits filters data fetched from API */
  private filters$ = this.filtersObservable$.pipe(
    switchMap(observable => observable),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  /** Emits filter values taken from queryParams */
  private filterQueryParams$: Observable<Params | {}> = this.filters$.pipe(
    map(filters => {
      return filters
      .flatMap(f => f.filterAssigns.map(filterAssign => filterAssign.property))
      .sort();
    }),
    switchMap(filterPropertyNames => this.route.queryParams.pipe(
      map(queryParams => this.pickFilterValuesFromQueryParams(filterPropertyNames, queryParams)),
      /** Check if params changed, emit if yes */
      distinctUntilChanged((previousParams, currentParams) => {
        const previousParamsUrl = this.router.createUrlTree(['/'], { queryParams: previousParams }).toString();
        const currentParamsUrl = this.router.createUrlTree(['/'], { queryParams: currentParams }).toString();
        return previousParamsUrl === currentParamsUrl;
      })
    ))
  );

  private filtersLoading$ = merge(
    this.filtersObservable$.pipe(map(() => true)),
    this.filtersObservable$.pipe(switchMap(observable => observable), map(() => false))
  ).pipe(
    shareReplay({ bufferSize: 1, refCount: true })
  );

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private filterPathGenerator: FilterPathGeneratorService,
    private cataloguePathService: CataloguePathService
  ) {}

  applyQueryParam(filterAssign: FilterAssign) {
    this.filterQueryParams$.pipe(first()).subscribe(enabledQueryParams => {
      this.navigateTo(this.convertToQueryParams(filterAssign, enabledQueryParams));
    });
  }

  private convertToQueryParams({property, filterAssignValues}: FilterAssign, enabledQueryParams: Params): Params {
    enabledQueryParams[property] = filterAssignValues.filter((value) => value.status)
      .map(({value}) => value);
    if (!enabledQueryParams[property].length) {
      enabledQueryParams[property] = null;
    }
    return enabledQueryParams;
  }

  private navigateTo(queryParams: Params): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams,
      queryParamsHandling: 'merge'
    });
  }

  fetch(path?: string): CacheableObservable<FilterInterface[]> {
    return this.api.get('filters/', {path})
      .noCache()
      .pipe(map(({data}) => data)) as CacheableObservable<FilterInterface[]>;
  }

  getAvailableFilters() {
    return this.filters$;
  }

  getFiltersLoadingState() {
    return this.filtersLoading$;
  }

  getFilterQueryParamsAsObservable(): Observable<Params> {
    return this.filterQueryParams$;
  }

  private pickFilterValuesFromQueryParams(filterPropertyNames: string[], queryParams: Params) {
    const pickedQueryParams: Params | {} = {};

    filterPropertyNames.forEach(name => {
      if (name in queryParams) {
        pickedQueryParams[name] = queryParams[name];
      }
    });

    return pickedQueryParams;
  }
}
