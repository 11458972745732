<app-navbar-wrapper>
  <ng-container navbar-left-side>
    <a
      class="navbar-image mx-0"
      routerLink="/news"
      routerLinkActive="active"
      (click)="onClick(getNewsItem())"
      app-logo
    ><!-- Logo SVG is added through `app-logo` attribute component --></a>
    <div class="ms-3 d-flex align-items-center h-100">
      <a
        *ngFor="let navElement of elements"
        [routerLink]="getRouterLinkPath(navElement)"
        [routerLinkActive]="'active'"
        (click)="onClick(navElement)"
        class="nav-link"
      >
        {{ navElement.title | translate }}
      </a>
    </div>
  </ng-container>

  <ng-container navbar-right-side>
    <ul class="h-100 d-flex align-items-center list-unstyled m-0 p-0">
      <li class="nav-item nav-icon mx-0 px-0"
          *permissionsElementVisibilityDecisionMaker="[permissionActions.CAN_CHANGE_PRICELIST]"
      >
        <app-drop-down
          [data]="priceList"
          cssClasses="d-flex"
          class="d-flex"
          linkCssClasses="p-3 color-gray-400 cursor-pointer hover-color-white"
          appActivatePricelist
        ></app-drop-down>
      </li>
      <li class="nav-item nav-icon mx-0 px-0">
        <button class="btn btn-default nav-link px-3 border-0" (click)="onNotificationsModalOpen()">
          <i class="icon ni-notification-bell">
            <app-notification [notifications]="user?.unseenNotifications"></app-notification>
          </i>
        </button>
      </li>
      <li class="nav-item nav-icon mx-0 px-0">
        <button class="btn btn-default nav-link px-3 border-0" (click)="onMessagesModalOpen()">
          <i class="icon ni-chat-square-plain">
            <app-notification [notifications]="user?.unreadMessages"></app-notification>
          </i>
        </button>
      </li>
      <li class="nav-item nav-icon">
        <button class="btn btn-default nav-link px-3 border-0">
          <i class="icon ni-faq"></i>
        </button>
      </li>
      <li class="nav-item nav-icon">
        <app-language-switch></app-language-switch>
      </li>
      <li class="nav-item nav-icon">
        <app-unimpersonate></app-unimpersonate>
      </li>
      <li #userDropdown="ngbDropdown"
          ngbDropdown
          autoClose="outside"
          placement="bottom-end"
          class="nav-item user pe-0">
        <button
          ngbDropdownToggle
          class="btn btn-default px-0 nav-link d-flex align-items-center h-100 no-underline no-outline bg-black hover-bg-black"
          [ngClass]="{ 'sale-mode': saleMode, 'deep-hover-bg-gray-900 deep-hover-color-white': !saleMode }"
        >
          <div
            class="d-flex rounded-pill align-items-center ps-3 pe-1 py-1 deep-hover"
            [ngClass]="{ 'bg-warning color-white sale-mode': saleMode, 'shown-bg-gray-900 shown-color-gray-400': !saleMode }"
          >
            <span>
              {{ user?.firstName || user?.email }}
            </span>
            <img
              [src]="user && user.profilePicture ? user.profilePicture.url : 'assets/images/user/user@2x.png'"
              alt=""
              class="user__image"
            />
          </div>
        </button>
        <div ngbDropdownMenu class="shadow p-0 dropdown-menu-end">
          <app-list-mode-switch
            *permissionsElementVisibilityDecisionMaker="[permissionActions.CAN_SWITCH_LIST_MODES]"
          ></app-list-mode-switch>
          <a
            class="dropdown-item btn btn-secondary text-nowrap d-flex border-top rounded-0"
            routerLink="/user/sale-coefficients"
            *permissionsElementVisibilityDecisionMaker="[permissionActions.CAN_CHANGE_SALE_COEFFICIENTS]"
            (click)="userDropdown.close()"
          >
            <i class="icon ni-discount me-3"></i>
            <span>{{ 'SALE_COEFFICIENTS.SALE_COEFFICIENTS' | translate }}</span>
          </a>
          <a
            class="dropdown-item btn btn-secondary text-nowrap d-flex border-top rounded-0"
            routerLink="/user/settings"
            (click)="userDropdown.close()"
          >
            <i class="icon ni-settings me-3"></i>
            <span>{{ 'SETTINGS.TITLE' | translate }}</span>
          </a>
          <a
            class="dropdown-item btn btn-secondary text-nowrap d-flex border-top rounded-0"
            routerLink="/terms"
            (click)="userDropdown.close()"
          >
            <i class="icon ni-privacy me-3"></i>
            <span>{{ 'TERMS.PRIVACY_AND_TERMS' | translate }}</span>
          </a>
          <a
            class="dropdown-item btn btn-secondary text-nowrap d-flex border-top"
            (click)="onLogout(); userDropdown.close()"
          >
            <i class="icon ni-logout-bracket me-3"></i>
            <span>{{ 'USER.LOGOUT' | translate }}</span>
          </a>
        </div>
      </li>
    </ul>
  </ng-container>
</app-navbar-wrapper>
