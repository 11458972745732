import { Injectable } from '@angular/core';
import * as dragulaNamespace from 'dragula';
import { ResizeNotifierService } from '../../../../../resize-observer/resize-notifier.service';
import { DraggableDirective } from '../../directives/ngx-draggable.directive';
import { DroppableDirective } from '../../directives/ngx-droppable.directive';
import { AutoScrollerFactory } from '../../../../../../../libs/dom-autoscroller.js';

const dragula = dragulaNamespace;

/**
 * Central service that handles all events
 *
 * @export
 */
@Injectable({ providedIn: 'root' })
export class DrakeStoreService {
  private droppableMap = new WeakMap<any, DroppableDirective>();
  private draggableMap = new WeakMap<any, DraggableDirective>();
  private dragulaOptions: dragulaNamespace.DragulaOptions;
  private drake: dragulaNamespace.Drake;
  private scroll;

  constructor(
    private resizeNotifier: ResizeNotifierService
  ) {
    this.dragulaOptions = this.createDrakeOptions();
    this.drake = dragula([], this.dragulaOptions);

    this.registerEvents();
  }

  registerDroppable(droppable: DroppableDirective) {
    this.droppableMap.set(droppable.container, droppable);
    this.drake.containers.push(droppable.container);
  }

  removeDroppable(droppable: DroppableDirective) {
    this.droppableMap.delete(droppable.container);
    const idx = this.drake.containers.indexOf(droppable.container);
    if (idx > -1) {
      this.drake.containers.splice(idx, 1);
    }
  }

  registerDraggable(draggable: DraggableDirective) {
    this.draggableMap.set(draggable.element, draggable);
  }

  removeDraggable(draggable: DraggableDirective) {
    this.draggableMap.delete(draggable.element);
  }

  createDrakeOptions(): dragulaNamespace.DragulaOptions {
    const accepts = (el: any, target: any /*, source: any, sibling: any */) => {
      if (el.contains(target)) {
        return false;
      }
      const elementComponent = this.draggableMap.get(el);
      const targetComponent = this.droppableMap.get(target);

      if (elementComponent && targetComponent) {
        return elementComponent.dropZones.includes(targetComponent.dropZone) && targetComponent.accepts(elementComponent, targetComponent);
      }
      return true;
    };

    const copy = (_: any, source: any) => {
      const sourceComponent = this.droppableMap.get(source);
      return sourceComponent && sourceComponent.copy;
    };

    const moves = (el?: any, source?: any, handle?: any, sibling?: any) => {
      // prevent empty group placeholder from moving
      if (source.classList.contains('gu-empty')) {
        return false;
      }

      const elementComponent = this.draggableMap.get(el);
      if (elementComponent) {
        return elementComponent.moves(source, handle, sibling);
      }
      return true;
    };

    return { accepts, copy, moves, revertOnSpill: false, direction: 'vertical' };
  }

  registerEvents(): void {
    let dragElement: any;
    let draggedItem: any;
    let selectedItems: NodeList | HTMLElement[];
    let mirrorContainer;

    this.drake.on('drag', (el: any, source: any) => {
      draggedItem = undefined;
      dragElement = el;

      if (!el || !source) {
        return;
      }

      selectedItems = document.querySelectorAll('.selectedItem');

      if (Array.from(selectedItems).length > 1) {
        // @TODO: refactor multiple dragging
        /** Timeout used to avoid document.querySelector('.gu-mirror') = undefined value, because first of all fires drag event and
         * later displays mirror container with drag items
         **/
        setTimeout(() => {
          mirrorContainer = document.querySelector('.gu-mirror');
          if (mirrorContainer) {
            mirrorContainer.classList.remove('selectedItem');
            mirrorContainer.classList.add('position-relative');

            const badge = document.createElement('div');
            badge.classList.add('selected-count');
            badge.innerHTML = `<span>${Array.from(selectedItems).length}</span>`;
            mirrorContainer.appendChild(badge);
          }
          Array.from(selectedItems).forEach((item: Element) => {
            item.classList.add('gu-transit');
            if (el !== item) {
              // @ts-ignore
              item.style.display = 'none';
            }
          });
        }, 0);
      }

      const drake = this.drake;

      const sizes = this.resizeNotifier.getCurrentValue();

      const navbarBoxHeight = sizes.navbarBorderBox?.height ?? 0;
      const orderControlsBorderBoxHeight = sizes.orderControlsBorderBox?.height ?? 0;
      const orderTableHeaderBorderBoxHeight = sizes.orderTableHeaderBorderBox?.height ?? 0;
      const height = navbarBoxHeight + orderControlsBorderBoxHeight + orderTableHeaderBorderBoxHeight;
      this.scroll = AutoScrollerFactory([window], {
        margin: 60,
        marginTop: height + 60,
        marginBottom: 57 + 60,
        maxSpeed: 10,
        scrollWhenOutside: true,
        autoScroll: function () {
          return drake.dragging;
        },
      });

      if (this.draggableMap.has(el)) {
        const elementComponent = this.draggableMap.get(el);
        draggedItem = elementComponent.model;
        elementComponent.drag.emit({
          type: 'drag',
          el,
          source,
          value: draggedItem,
        });
      }

      if (this.droppableMap.has(source)) {
        const sourceComponent = this.droppableMap.get(source);
        this.dragulaOptions.removeOnSpill = sourceComponent.removeOnSpill;

        sourceComponent.drag.emit({
          type: 'drag',
          el,
          source,
          sourceComponent,
          value: draggedItem,
        });
      }
    });

    this.drake.on('dragend', () => {
      if (this.scroll) {
        // @ts-ignore
        this.scroll.destroy();
      }
    });

    this.drake.on('drop', (el: any, target: any, source: any) => {
      const targetComponent = this.droppableMap.get(target);
      if (!targetComponent) {
        // not a target, abort
        return;
      }

      const dropIndex = Array.prototype.indexOf.call(target.children, el);
      if (dropIndex < 0) {
        this.drake.cancel(true);
        return;
      }

      const dropElmModel = draggedItem;
      const sourceModel = this.droppableMap.get(source).model;
      const parentSourceModel = this.droppableMap.get((el as Element).parentElement).model;

      const getParentSourceModel = (element: HTMLElement) => {
        return this.droppableMap.get(element.parentElement).model;
      };

      const findElementIndexInSourceModel = (elementParentSourceModel, element: HTMLElement) => {
        return elementParentSourceModel.findIndex((s) => s.id === this.draggableMap.get(element).model.id);
      };

      if (Array.from(selectedItems).length) {
        const selectedModels = [];

        Array.from(selectedItems).forEach((element: HTMLElement) => {
          let foundIndex = findElementIndexInSourceModel(sourceModel, element);
          if (foundIndex > -1) {
            selectedModels.push(sourceModel.splice(foundIndex, 1)[0]);
          } else {
            const elementParentSourceModel = getParentSourceModel(element);
            foundIndex = findElementIndexInSourceModel(elementParentSourceModel, element);
            if (foundIndex > -1) {
              selectedModels.push(elementParentSourceModel.splice(foundIndex, 1)[0]);
            }
          }
          (element as HTMLElement).style.display = null;
          (element as HTMLElement).classList.remove('gu-transit');
        });

        parentSourceModel.splice(dropIndex, 0, ...selectedModels);
      } else {
        parentSourceModel.splice(
          dropIndex,
          0,
          sourceModel.splice(
            sourceModel.findIndex((s) => s.id === this.draggableMap.get(el).model.id),
            1
          )[0]
        );
      }

      targetComponent.drop.emit({
        type: 'drop',
        el,
        source,
        value: dropElmModel,
        dropIndex,
      });
    });

    this.drake.on('cancel', (el: any, container: any, source: any) => {
      // Show hidden elements after drop out of bounds
      Array.from(selectedItems).forEach((item: Element) => {
        item.classList.remove('gu-transit');
        (item as any).style.display = 'block';
      });
      if (this.droppableMap.has(container)) {
        const containerComponent = this.droppableMap.get(container);
        containerComponent.cancel.emit({
          type: 'cancel',
          el,
          container,
          source,
          value: draggedItem,
        });
      }
    });
  }
}
