import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-checkbox-filter',
  templateUrl: './checkbox-filter.component.html',
  styleUrls: ['./checkbox-filter.component.scss']
})
export class CheckboxFilterComponent {
  @Input() title = '';
  @Input() expanded = false;
  @Output() expandedChange = new EventEmitter<boolean>();
  @Output() onChange = new EventEmitter<any[]>();

  checkedValues = new Set<any>();

  hasCheckedFilter(): boolean {
    return Boolean(this.checkedValues.size);
  }

  toggleExpansion() {
    this.expanded = !this.expanded;
    this.expandedChange.emit(this.expanded);
  }

  onClear() {
    this.checkedValues.clear();
    this.emitCurrentValue();
  }

  change(value: any, checked: boolean) {
    if (!this.checkedValues.has(value) && checked) {
      this.checkedValues.add(value);
    } else if (this.checkedValues.has(value) && !checked) {
      this.checkedValues.delete(value);
    }

    this.emitCurrentValue();
  }

  private emitCurrentValue() {
    this.onChange.emit(Array.from(this.checkedValues.values()));
  }

  isChecked(value: any) {
    return this.checkedValues.has(value);
  }
}
