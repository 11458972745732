import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import { OrderInterface } from '../core/models/order.model';
import { ExtraListElementInterface } from '../orders/order-articles-list/order-articles-list/components/extra-row/extra-items.model';
import { AddItemHelperService } from '../orders/controls/add-item-helper.service';

@Injectable()
export class OrderSelectMenuService {

  private open$ = new BehaviorSubject<boolean>(false);
  private openedOrder$ = new BehaviorSubject<OrderInterface>(null);
  private selectedOrder$ = new BehaviorSubject<OrderInterface>(null);
  private selectedGroup$ = new BehaviorSubject<ExtraListElementInterface>(null);

  constructor(private addItemHelperService: AddItemHelperService) {
  }

  open() {
    this.open$.next(true);
  }

  close() {
    this.open$.next(false);
  }

  toggle() {
    this.open$.next(!this.open$.getValue());
  }

  openObservable(): Observable<boolean> {
    return this.open$.asObservable();
  }

  openOrder(order: OrderInterface) {
    this.openedOrder$.next(order);
  }

  selectOrder(order: OrderInterface) {
    this.selectedOrder$.next(order);
    this.addItemHelperService.setLastOrder(order);
  }

  selectGroups(group: ExtraListElementInterface) {
    !group ? this.selectedGroup$.next(null) : this.selectedGroup$.next(group);
  }

  selectGroup(group: ExtraListElementInterface) {
    this.selectedGroup$.next(group);
    this.addItemHelperService.setLastGroup(group);
  }

  selectedOrderAsObservable(): Observable<OrderInterface> {
    return this.selectedOrder$.asObservable();
  }

  selectedGroupAsObservable(): Observable<ExtraListElementInterface> {
    return this.selectedGroup$.asObservable();
  }

  openedOrderAsObservable(): Observable<OrderInterface> {
    return this.openedOrder$.asObservable();
  }
}
