<div class="mb-3 d-flex flex-column w-100" [ngClass]="theme">
  <div class="d-flex align-items-center w-100">
    <label *ngIf="text" [for]="id || name" class="w-50 text-nowrap text-end pe-3 mb-0 color-gray form-label">{{ text }}<span *ngIf="formControl | isFormControlRequired">*</span></label>
    <ng-content *ngIf="!text" select="[label]"></ng-content>
    <ng-container [ngSwitch]="inputType.type">
      <input
        *ngSwitchCase="'input'"
        [type]="type"
        [name]="name"
        [attr.id]="id || name"
        [value]="value"
        [placeholder]="placeholder || ''"
        [(ngModel)]="value"
        (ngModelChange)="onChange($event)"
        (blur)="onBlur()"
        (focus)="onFocus()"
        #input
        [disabled]="disabledProp"
        (keydown)="onKeyDown($event)"
        (keyup)="onKeyup($event)"
        class="form-control"
        [class.w-50]="text"
        [class.is-invalid]="invalid"
      />
      <textarea
        *ngSwitchCase="'textarea'"
        type="text"
        [value]="value"
        autosize
        [autosizeCollapse]="inputType.collapse"
        [autosizeFocused]="focused"
        [autosizeMaxRows]="inputType.maxRows"
        [autosizeMinHeight]="inputType.minHeight"
        [placeholder]="placeholder ? placeholder : ''"
        [(ngModel)]="value"
        (ngModelChange)="onChange($event)"
        (blur)="onBlur()"
        (focus)="onFocus()"
        [attr.id]="id || name"
        [disabled]="disabledProp"
        #input
        class="form-control overflow-hidden"
        [class.w-50]="text"
        [class.is-invalid]="invalid"
      ></textarea>
      <div
        *ngSwitchCase="'datepicker'"
        class="datepicker-wrapper"
        ngbDropdown
        #datepickerDropdown="ngbDropdown"
        [autoClose]="'outside'"
        [placement]="'bottom-end'"
      >
        <input class="datepicker" [formControl]="formControl" [attr.id]="id || name" [placeholder]="placeholder || ''" [name]="name" />
        <button class="btn btn-link" ngbDropdownToggle>
          <svg class="icon icon--calendar">
            <use xlink:href="#icon-calendar" />
          </svg>
        </button>
        <app-datepicker
          [selectedDate]="formControl.value"
          (select)="formControl.patchValue($event); datepickerDropdown.close()"
          ngbDropdownMenu
        ></app-datepicker>
      </div>
    </ng-container>
  </div>
  <div class="input__errors text-danger mt-2 small" [class.offset-6]="text" *ngIf="invalid && errors && !hideDefaultTemplateOfErrors">
    <ng-container *ngTemplateOutlet="errorsTemplate"></ng-container>
    <ng-container *ngTemplateOutlet="templateOfErrors"></ng-container>
  </div>
  <div class="input__actions">
    <ng-container *ngTemplateOutlet="templateOfActions"></ng-container>
  </div>
</div>

<ng-template #errorsTemplate>
  {{ errors | translateValidationErrors }}
</ng-template>
