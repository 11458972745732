import {Component, OnInit, ViewChild} from '@angular/core';
import {UserNotificationGroupTranslationMap, UserNotificationInterface, UserNotificationViewInterface} from '../../../core/models/user-notification.model';
import {UserNotificationService} from '../../../shared/services/user-notification/user-notification.service';
import {TranslateService} from '@ngx-translate/core';
import {LoaderService} from '../../../ui-elements/loader/loader.service';
import {LoaderComponent} from '../../../ui-elements/loader/loader.component';
import {GroupByService} from '../../../shared/services/group-by/group-by.service';
import {GroupableItemInterface} from '../../../core/models/groupable-item.model';
import { ToastService } from '../../../ui-elements/toast/toast.service';

@Component({
  selector: 'app-user-settings-notifications',
  templateUrl: './notifications.component.html',
})
export class NotificationsComponent implements OnInit {

  constructor(private userNotificationService: UserNotificationService, private toastService: ToastService,
              private translator: TranslateService, private loaderService: LoaderService, private groupByService: GroupByService) {
  }

  options: GroupableItemInterface[];
  userNotificationGroupTranslationMap = UserNotificationGroupTranslationMap;
  @ViewChild('loader', { static: true }) loader: LoaderComponent;

  ngOnInit() {
    this.refreshData();
  }

  refreshData() {
    this.loader.show();
    this.userNotificationService.getAll().subscribe((options: Array<UserNotificationViewInterface>) => {
      this.userNotificationService.getByUser().noCache().subscribe((userOptions: Array<UserNotificationInterface>) => {
        this.options = this.groupByService.groupBy(options.map(option => {
          option.checked = !!(userOptions.find(userOption => option.id === userOption.id));
          return option;
        }), 'notificationGroup');
        this.loader.hide();
      }, err => {
        console.log(err);
        this.loader.hide();
      });
    }, err => {
      console.log(err);
      this.loader.hide();
    });
  }

  onSave() {
    this.loader.show();
    const update = [];
    this.options.forEach(optionGroup => {
      optionGroup.values.forEach(option => {
        update.push({id: option.id, status: option.checked});
      });
    });
    this.userNotificationService.updateMultiple(update).subscribe(success => {
      this.translator.get('SETTINGS.SUCCESS').subscribe(translation => {
        this.toastService.success(translation);
        this.loader.hide();
      });
    }, err => {
      console.log(err);
      this.loader.hide();
    });
  }

  onReset() {
    this.refreshData();
  }
}
