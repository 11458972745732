export enum OrderState {
  DRAFT = 'draft',
  WAITING = 'waiting',
  DELETED = 'deleted',
  IN_AX = 'in_ax',
  AX_ARCHIVED = 'ax_archived',
  AX_CONFIRMED = 'ax_confirmed',
  AX_ARCHIVE_CONFIRMED = 'ax_archive_confirmed',
  AX_LOADED = 'ax_loaded',
}

export enum OrderImportState {
  NONE = 'none',
  NEW = 'new',
  IMPORTING = 'importing',
  IMPORTED = 'imported',
  REJECTED = 'rejected',
  ERROR = 'error',
  STUCK = 'stuck'
}

export enum OrderMigrationState {
  NONE = 'none',
  MIGRATING = 'migrating',
  MIGRATED = 'migrated',
  STUCK = 'stuck',
  FAILED = 'failed',
}
