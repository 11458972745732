import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-detected-adblocker-modal',
  templateUrl: './detected-adblocker-modal.component.html',
  styleUrls: ['./detected-adblocker-modal.component.scss']
})
export class DetectedAdblockerModalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  onRefresh() {
    window.location.reload();
  }

}
