<div class="card-body pb-0">
  <app-loader #loader [cssClasses]="'loader--white'"></app-loader>
  <div class="p-2 mb-3">
    <h2>{{'SETTINGS.SHIPPING_ADDRESSES.TITLE' | translate}}</h2>
    <p class="m-0 color-gray">{{'SETTINGS.SHIPPING_ADDRESSES.TITLE_TEXT' | translate}}</p>
  </div>
  <div class="mx-n3">
    <ul class="list-unstyled mb-0">
      <li class="d-flex justify-content-between align-items-baseline
                  bg-gray-100 hover-bg-white deep-hover-d-flex
                  border-top"
        *ngFor="let address of addresses"
      >
        <app-address-item class="py-3 px-4" [address]="address">
          <div class="w-25 d-flex justify-content-end align-items-start">
            <div class="deep-hover d-none">
              <button class="btn btn-danger no-outline p-1 me-2 d-flex" (click)="onDelete(address)">
                <i class="icon ni-delete-1"></i>
              </button>
              <button class="btn btn-primary color-white no-outline p-1 d-flex" (click)="onEdit(address);">
                <i class="icon ni-create"></i>
              </button>
            </div>
          </div>
        </app-address-item>
      </li>
    </ul>
  </div>
</div>
