import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CheckboxFilterComponent } from './checkbox-filter.component';
import { TranslateModule } from '@ngx-translate/core';
import { CheckboxFilterOptionComponent } from './checkbox-filter-option/checkbox-filter-option.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    CheckboxFilterComponent,
    CheckboxFilterOptionComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
  ],
  exports: [
    CheckboxFilterComponent,
    CheckboxFilterOptionComponent,
  ]
})
export class CheckboxFilterModule { }
