import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, map } from 'rxjs';
import { OrderArticlesListRow } from '../../order-articles-list.interface';

export enum OrderArticleRowsFiltersEnum {
  ALL_ROWS = "all rows",
  MIGRATED_ROWS = "migrated rows"
};

@Injectable()
export class OrderArticleRowsFilteringService {
  private enabledFilter = new ReplaySubject<OrderArticleRowsFiltersEnum>(1);

  filter(filter: OrderArticleRowsFiltersEnum, originalRows: OrderArticlesListRow[]): OrderArticlesListRow[] {
    this.enabledFilter.next(filter);

    switch (filter) {
      case OrderArticleRowsFiltersEnum.ALL_ROWS:
        return originalRows;
      case OrderArticleRowsFiltersEnum.MIGRATED_ROWS:
        return this.filterMigratedRows(originalRows);
    }
  }

  getEnabledFilterAsObservable(): Observable<OrderArticleRowsFiltersEnum> {
    return this.enabledFilter.asObservable();
  }

  getIsFilterEnabledObservable(): Observable<boolean> {
    return this.enabledFilter.asObservable().pipe(map((enabledFilter) => enabledFilter !== OrderArticleRowsFiltersEnum.ALL_ROWS));
  }

  private filterMigratedRows(rows: OrderArticlesListRow[]): OrderArticlesListRow[] {
    const filteredRows = rows
      .filter((row) => this.isMigratedOrHasMigratedItems(row))
      .map((filteredRow) => this.removeNonMigratedChildren(filteredRow));
    
    return filteredRows;
  }

  private isMigratedOrHasMigratedItems(row: OrderArticlesListRow): boolean {
    if ('versionOutdated' in row && 'migrationStatus' in row && (row.versionOutdated || row.migrationStatus)) {
      return true;
    }

    if (row.children?.length) {
      return row.children.some((child) => this.isMigratedOrHasMigratedItems(child));
    }

    return false;
  }

  private removeNonMigratedChildren(row: OrderArticlesListRow): OrderArticlesListRow {
    if (!row.children?.length) {
      return row;
    }

    row.children = row.children
      .filter((child) => this.isMigratedOrHasMigratedItems(child))
      .map((child) => this.removeNonMigratedChildren(child));

    return row;
  }
}
