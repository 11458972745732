import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { TermsModalComponent } from './terms-modal/terms-modal.component';
import { TermsPageComponent } from './terms-page/terms-page.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { CloseButtonModule } from '../ui-elements/close-button/close-button.module';
import { TranslateModule } from '@ngx-translate/core';
import { LoaderModule } from '../ui-elements/loader/loader.module';
import { TermsAndConditionsRoutingModule } from './terms-and-conditions-routing.module';

@NgModule({
  declarations: [
    TermsModalComponent,
    TermsAndConditionsComponent,
    TermsPageComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    CloseButtonModule,
    TranslateModule,
    LoaderModule,
    TermsAndConditionsRoutingModule
  ],
  exports: [
    TermsModalComponent,
    TermsAndConditionsComponent
  ]
})
export class TermsModule { }
