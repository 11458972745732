import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'discount'
})
export class DiscountPipe implements PipeTransform {
  transform(discount: any, symbol: string = '%', replacement: string = '-'): any {
    return discount > 0 ?
      `${discount}${symbol}` :
      `${replacement}`;
  }

}
