import {ApiService} from '../../api.service';
import {map} from 'rxjs/operators';
import {CacheableObservable} from '../../cacheable-observable/cacheable-observable.model';
import {Observable, ReplaySubject} from 'rxjs';

export abstract class StoreHandlerService {
  protected values$: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  protected currentValue$: ReplaySubject<any> = new ReplaySubject<any>(1);
  protected currentValue;

  protected constructor(protected api: ApiService, private apiPath: string, private sessionStorageKey: string) {
  }

  abstract init(): void;

  abstract switch(value: any): void;

  protected abstract send(value: any): void;

  getAll(): Observable<any> {
    return this.values$.asObservable();
  }

  getCurrentValue() {
    return this.currentValue;
  }

  getCurrentAsObservable() {
    return this.currentValue$.asObservable();
  }

  setCurrentValue(value: any) {
    this.currentValue = value;
    this.currentValue$.next(this.currentValue);
  }

  fetch() {
    return this.api.get(this.apiPath).pipe(map(({data}) => data)) as CacheableObservable<any>;
  }

  protected getFromStorage() {
    return JSON.parse(sessionStorage.getItem(this.sessionStorageKey));
  }

  protected saveToStorage(value) {
    sessionStorage.setItem(this.sessionStorageKey, JSON.stringify(value));
  }

  protected clearSessionStorage() {
    sessionStorage.removeItem(this.sessionStorageKey);
  }
}
