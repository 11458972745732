import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatepickerComponent } from './datepicker.component';
import { SharedModule } from '../../shared/shared.module';
import {MissingTranslationHandler, TranslateModule} from '@ngx-translate/core';
import {CustomMissingTranslationHandler} from '../../core/models/custom-missing-translation-handler.model';

@NgModule({
  declarations: [DatepickerComponent],
  exports: [DatepickerComponent],
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule.forChild({
      missingTranslationHandler: {provide: MissingTranslationHandler, useClass: CustomMissingTranslationHandler},
    }),
  ],
})
export class DatepickerModule {}
