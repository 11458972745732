import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CatalogueService } from '../catalogue.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ViewMode } from '../../core/enums/view.enum';
import { TreeService } from '../../core/tree/tree.service';
import { RouteTypeEnums } from '../../core/enums/route-types.enum';
import { QueryParams } from '../../core/enums/query-params.enum';
import { SearchService } from '../../core/tree/search/search.service';
import { CatalogueExportFormat } from '../../core/enums/catalogue-export-format.enum';
import {
  combinedRouteSaleModeObservables,
  PriceListStoreHandlerService,
} from '../../core/services/price-list/price-list-store-handler.service';
import { PermissionActions } from '../../permissions.config';
import { FilterService } from './filter-sidebar/filter.service';
import { combineLatest, iif, merge, Observable, of, Subject, Subscription } from 'rxjs';
import { delay, filter, map, shareReplay, startWith, switchMap } from 'rxjs/operators';
import { BreadcrumbModel } from '../../shared/components/breadcrumbs/breadcrumb.model';
import { LoaderComponent } from '../../ui-elements/loader/loader.component';
import { AutoDownloadInterface, AutoDownloadType } from './auto-download/auto-download.model';
import { CatalogueSystemsInterface, GroupedSystem, ProductArticleInterface, GroupedSystemWithMetaType, ArticleCategoryInterface, ArticleArticleCategory } from './product.model';
import { ListModeSwitchService } from '../../shared/components/list-mode-switch/list-mode-switch.service';
import { ConfiguratorOpenerService } from './products-configurator-opener.service';
import { ResizeNotifierService } from '../../resize-observer/resize-notifier.service';
import { CatalogueSearchService } from '../catalogue-search.service';
import {CataloguePathService} from "../../core/services/catalogue-path/catalogue-path.service";

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
  providers: [ConfiguratorOpenerService, CataloguePathService, FilterService]
})
export class ProductsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('loader', { static: true }) loader: LoaderComponent;
  @ViewChild('sidebarFilters') sidebarFiltersElement: ElementRef<HTMLDivElement>;

  private subscriptions: Subscription = new Subscription();

  pageTitle?: string;
  isNameModified?: boolean;

  idOfCategory: number;
  viewValue: ViewMode = ViewMode.LIST;
  catalogueExportFormat = CatalogueExportFormat;
  viewMode = ViewMode;
  permissionActions = PermissionActions;
  topSidebarPosition = 0;
  searchQuery = "";
  private filterParams: Params = {};
  private previousFilterParams: Params = {};

  get wrappedSearchQuery(): string {
    return `<span class="color-black">"${this.searchQuery}"</span>`;
  }

  private searchParamsChange$ = combineLatest([
    combinedRouteSaleModeObservables(
      this.route.queryParams,
      this.listModeSwitchService.saleModeAsObservable(),
      this.priceListStoreHandlerService.getCurrentAsObservable()
    ),
    this.filterService.getFilterQueryParamsAsObservable()
  ]).pipe(
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private searchResults$ = this.searchParamsChange$.pipe(
    delay(0),
    switchMap(([{ params }]) => {
      if (this.searchService.isSearchActive()) {
        const term = params[QueryParams.TERM];
        this.searchQuery = term;
        return this.catalogueSearch.get(term);
      }
      throw new Error('Search service not defined');
    }),
    map(({ data, message }) => data),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private catalogueParamsChange$ = combineLatest([
    combinedRouteSaleModeObservables(
      this.route.params,
      this.listModeSwitchService.saleModeAsObservable(),
      this.priceListStoreHandlerService.getCurrentAsObservable()
    ),
    this.filterService.getFilterQueryParamsAsObservable()
  ]).pipe(
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private catalogueResults$ = this.catalogueParamsChange$.pipe(
    switchMap(([{ params }, filterParams]) => {
      const hasChanged = JSON.stringify(this.previousFilterParams) !== JSON.stringify(this.filterParams);
  
      window.scrollTo(0, 0);
      const path = this.cataloguePathService.getCurrentCataloguePath();
      this.idOfCategory = path.child?.id ?? path.parent?.id;

      if (this.idOfCategory) {
        const res = this.catalogueService.getArticlesBySystemId(this.idOfCategory, filterParams);

        return hasChanged ? res.noCache() : res;
      }

      return of(null);
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  isSearch$ = this.catalogueSearch.searchActive$.pipe(
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private articles$ = this.isSearch$.pipe(
    switchMap(isSearch => isSearch ? this.searchResults$ : this.catalogueResults$ ),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  breadcrumbs$ = this.isSearch$.pipe(
    switchMap(isSearch => iif(
      () => isSearch,
      of([]),
      this.catalogueParamsChange$.pipe(
        switchMap(([{params}]) => {
          return this.createBreadcrumbs(params.slug, params.childSlug)
        })
      )
    )),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  articlesLength$ = this.articles$.pipe(map(articles => articles.length));

  showMore$ = new Subject<void>();

  systems$ = this.articles$.pipe(
    map(data => this.mapSystems(data)),
    switchMap(data => this.lazyRender(data, this.showMore$)),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  groupedSystems$ = this.articles$.pipe(
    map(data => this.groupSystems(data)),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private loadingStart$ = this.isSearch$.pipe(
    switchMap(isSearch => iif(
      () => isSearch,
      this.searchParamsChange$,
      this.catalogueParamsChange$
    ))
  );
  /** Emits true when products list is being loaded */
  isLoading$ = merge(
    this.loadingStart$.pipe(map(() => true)),
    this.articles$.pipe(map(() => false))
  );

  filters$ = this.filterService.getAvailableFilters();
  filtersLoading$ = this.filterService.getFiltersLoadingState();

  constructor(
    private route: ActivatedRoute,
    private catalogueService: CatalogueService,
    private cataloguePathService: CataloguePathService,
    private router: Router,
    private treeService: TreeService,
    private searchService: SearchService,
    private catalogueSearch: CatalogueSearchService,
    private listModeSwitchService: ListModeSwitchService,
    private priceListStoreHandlerService: PriceListStoreHandlerService,
    private filterService: FilterService,
    private changeDetector: ChangeDetectorRef,
    private resizeNotifier: ResizeNotifierService
  ) {}

  ngAfterViewInit(): void {
    this.adjustStickyTopPosition();
  }

  ngOnInit() {
    this.subscriptions.add(
      this.route.queryParams.subscribe(params => {
        this.viewValue = params.view ? params.view : ViewMode.LIST;
      })
    );

    this.subscriptions.add(
      this.filterService.getFilterQueryParamsAsObservable().subscribe(res => {
        this.previousFilterParams = this.filterParams;
        const saleMode = this.listModeSwitchService.getSaleMode();
        this.filterParams = { ...res, saleMode };
      })
    );

    this.subscriptions.add(
      this.listModeSwitchService.saleModeAsObservable().subscribe(saleMode => {
        this.previousFilterParams = this.filterParams;
        this.filterParams = { ...this.filterParams, saleMode };
      }
    ));
  }

  @HostListener('window:resize', [])
  onResize() {
    this.adjustStickyTopPosition();
  }

  private createBreadcrumbs(slug: string, childSlug?: string): Observable<any> {
    const breadcrumbs = [];

    const routeType = this.route.snapshot.data['id'];
    return this.treeService.getCatalogue(routeType).pipe(
      map(categories => {
        if (!categories) {
          return;
        }
        const existingCategory = categories.find(category => category.slug === slug);
        let existingSubCategory = null;
        if (existingCategory) {
          existingSubCategory = existingCategory.children.find(child => child.slug === childSlug);
        } else {
          existingSubCategory = categories.find(child => child.slug === childSlug);
        }

        const category = existingSubCategory ?? existingCategory;
        
        this.pageTitle = category.name;
        this.isNameModified = category.isNameModified;

        if (existingCategory && existingSubCategory) {
          breadcrumbs.push({
            title: existingCategory.name,
            onClick: (elem: BreadcrumbModel, $event: MouseEvent) => {
              $event.preventDefault();
              this.router.navigate(['../'], {relativeTo: this.route})
            },
          });
        }

        return breadcrumbs;
      })
    );
  }

  private lazyRender(data: CatalogueSystemsInterface[], trigger: Observable<any>) {
    let virtualPage = 0;
    const itemsPerPage = 1;

    return trigger.pipe(
      startWith(1), // immediately emit once
      filter(() => itemsPerPage * virtualPage < data.length || !data.length),
      map(() => {
        virtualPage += 1;
        return data.slice(0, itemsPerPage * virtualPage);
      })
    );
  }

  showMore() {
    this.showMore$.next();
    this.changeDetector.detectChanges();
  }

  viewModeQueryParams(view: ViewMode) {
    return { [QueryParams.VIEW]: view };
  }

  onExport(format: string) {
    this.loader.show();
    const filters = this.filterParams;
    if (this.route.snapshot.data['id'] !== RouteTypeEnums.SEARCH) {
      this.catalogueService.getCatalogueExportUrl(this.idOfCategory, format, filters).add(() => {
        this.loader.hide();
      });
    } else {
      this.catalogueService.getCatalogueSearchExportUrl(this.searchService.searchTerm, format, filters).add(() => {
        this.loader.hide();
      });
    }
  }

  onExportCallback(format: string) {
    return () => this.onExport(format);
  }

  getAutoDownloadObject(format: string = CatalogueExportFormat.XLSX) {
    const filters = this.filterParams;
    return <AutoDownloadInterface>{
      type: this.route.snapshot.data['id'] !== RouteTypeEnums.SEARCH ? AutoDownloadType.CATALOGUE : AutoDownloadType.SEARCH,
      idOfCategory: this.idOfCategory,
      searchTerm: this.searchService.searchTerm,
      format: format,
      filters: filters,
    };
  }

  onFilterToggle() {
    this.adjustStickyTopPosition();
  }

  /**
   * Check if sidebar overflows browser height and recalculate sidebar sticky item positions top value
   */
  private adjustStickyTopPosition() {
    setTimeout(() => {
      const navbarHeight = this.resizeNotifier.getCurrentValue().navbarBorderBox?.height ?? 0;
      if (this.sidebarFiltersElement && this.sidebarFiltersElement.nativeElement.offsetHeight >= window.innerHeight - navbarHeight) {
        this.topSidebarPosition = window.innerHeight - this.sidebarFiltersElement.nativeElement.offsetHeight;
      } else {
        this.topSidebarPosition = navbarHeight;
      }

      this.changeDetector.detectChanges();
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  getSystemInfoBlock(system: ProductArticleInterface): ArticleCategoryInterface | null {
    let infoBlock: ArticleCategoryInterface | null = null;
    // infoBlock is being kept at system.articlePropertyClasses[0].articles[0][0].articleArticleCategories[0].articleCategory
    // style path, and it may be different in other object branches, so to make sure that it is really null everywhere, we need to search
    // the whole object until it is found.
    if (system.articlePropertyClasses?.length) {
      system.articlePropertyClasses.some(articlePropertyClass => {
        if (articlePropertyClass.articles?.length) {
          return articlePropertyClass.articles.some(articles => {
            return articles.some(article => {
              const articleCategories = Array.isArray(article.articleArticleCategories)
                ? article.articleArticleCategories
                : Object.keys(article.articleArticleCategories)
                    .map(key => article.articleArticleCategories[key]) as ArticleArticleCategory[];

                if (articleCategories.length) {
                return articleCategories.some(articleArticleCategory => {
                  const articleCategory = articleArticleCategory.articleCategory;
                  if (articleCategory.hasFinishFamily || articleCategory.hasInfoBlock) {
                    infoBlock = articleCategory;
                    return true;
                  }
                });
              }
            });
          });
        }
      });
    }
    return infoBlock;
  };

  groupSystems(systems: ProductArticleInterface[]) {
    return systems
      .reduce((carry, system) => {
        carry.push(
          ...system.articlePropertyClasses.map(item => {
            return {
              articles: [].concat.apply([], item.articles),
              articlePropertyClass: item.articlePropertyClass,
              system: system.system
            };
          })
        );
        return carry;
      }, [] as GroupedSystem[])
      .reduce((acc, system) => {
        const groupedArticles = system.articles.reduce((carry, article) => {
          // creating an array of grouped articles by systems and metatypes
          let systemCarry: GroupedSystemWithMetaType = carry.find(carriedSystem => carriedSystem.metatype === article.metatype);

          if (!systemCarry) {
            systemCarry = {
              system: system.system,
              metatype: article.metatype,
              articlePropertyClass: system.articlePropertyClass,
              articles: [],
            };

            carry.push(systemCarry);
          }
          systemCarry.articles.push(article);

          return carry;
        }, [] as GroupedSystemWithMetaType[]);
        acc.push(...groupedArticles);
        return acc;
      }, [] as GroupedSystemWithMetaType[]);
  };

  mapSystems(systems: ProductArticleInterface[]): CatalogueSystemsInterface[] {
    return systems.map(system => ({
      ...system,
      infoBlock: this.getSystemInfoBlock(system),
    }));
  };
}
