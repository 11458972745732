import { CacheObject } from './cache-object.model';
import { Observable, Operator } from 'rxjs';

const cache = new Map<string, CacheObject>();

export function clearCache() {
  cache.clear();
}

/**
 * CacheableObservable, adds noCache method
 */
export class CacheableObservable<T> extends Observable<T> implements Observable<T> {
  source;
  operator;

  constructor(private uncachedObservable: Observable<T>, source) {
    super();
    this.source = source;
  }

  static create(observable: Observable<any>, path) {
    const cacheableObservable = new Observable(observer => {
      const cacheKey = JSON.stringify(path);

      let cacheObject = cache.get(cacheKey);

      if (!cacheObject) {
        cacheObject = new CacheObject(cacheKey, observable);

        cache.set(cacheKey, cacheObject);
      }

      cacheObject.getObservable().subscribe({
        next: res => {
          cacheObject.setResponse(res);
          observer.next(res);
          observer.complete();
        },
        error: err => {
          observer.error(err);
          observer.complete();
        },
      });

      /**
       * Calls next on cacheObject subject if not called yet.
       */
      cacheObject.call();
    });

    return new CacheableObservable(observable, cacheableObservable);
  }

  noCache(): Observable<T> {
    this.source = this.uncachedObservable;
    return this;
  }

  lift<R>(operator: Operator<T, R>): CacheableObservable<R> {
    const obs = new CacheableObservable<any>(this.uncachedObservable, this);
    obs.operator = operator;
    return obs;
  }
}

/**
 * Will try to find cache key by provided needle and will clear found key from cache
 * @param needle
 */
export function clearCacheMatching(needle: string) {
  cache.forEach((value, key, map) => {
    if (key.indexOf(needle) !== -1) {
      cache.delete(key);
    }
  });
}
