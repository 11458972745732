import { Component, OnInit, Output } from '@angular/core';
import { ImpersonateService } from '../impersonate.service';
import { AuthService } from '../../../auth/auth.service';
import { UserInterface } from '../../models/user.model';
import { UserRole } from '../../enums/user-role.enum';
import { Router } from '@angular/router';

@Component({
  selector: 'app-unimpersonate',
  templateUrl: './unimpersonate.component.html',
  styleUrls: ['./unimpersonate.component.scss'],
})
export class UnimpersonateComponent implements OnInit {
  visible = false;

  constructor(private impersonateService: ImpersonateService, private authService: AuthService, private router: Router) {}

  ngOnInit() {
    this.authService.authenticatedObservable().subscribe((authenticated: boolean) => {
      const user = this.authService.getRealUser();
      this.visible = authenticated && user && user.role && user.role.name === UserRole.ROLE_ADMIN && this.impersonateService.impersonated();
    });
  }

  unimpersonate() {
    this.impersonateService.unImpersonate();
    const user: UserInterface = this.authService.getUser();
    if (user && user.role && user.role.name === UserRole.ROLE_ADMIN && user.redirect) {
      window.location.href = user.redirect;
    } else {
      this.authService.logout();
    }
  }
}
