import { Component, OnInit } from '@angular/core';
import { SaleCoefficientsBaseComponent } from '../sale-coefficients.base.component';
import { CatalogueType } from '../../../core/enums/catalogue-type.enum';
import { SaleCoefficientsDataInterface } from '../../../core/models/sale-coefficient.model';

@Component({
  selector: 'app-user-sale-coefficients-products',
  templateUrl: '../coefficient-table.component.html',
  styleUrls: ['../sale-coefficients.component.scss'],
})
export class ProductsComponent extends SaleCoefficientsBaseComponent {
  protected type = CatalogueType.CATEGORY;
  public currentSaleCoefficient: SaleCoefficientsDataInterface;
}
