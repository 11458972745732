import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DefaultPropertyClassesComponent} from './default-property-classes/default-property-classes.component';
import {CustomMissingTranslationHandler} from '../../core/models/custom-missing-translation-handler.model';
import {MissingTranslationHandler, TranslateModule} from '@ngx-translate/core';
import {PropertyValuesSelectorModule} from '../property-values-selector/property-values-selector.module';
import {PipesModule} from '../pipes/pipes.module';
import {CloseButtonModule} from '../../ui-elements/close-button/close-button.module';
import {ModelPropertyClassesComponent} from './model-property-classes/model-property-classes.component';
import {SharedModule} from '../../shared/shared.module';
import {DefaultPropertiesComponent} from './default-property-classes/default-properties/default-properties.component';
import { GenericPropertyComponent } from './default-property-classes/generic-property/generic-property.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forChild({
      missingTranslationHandler: {provide: MissingTranslationHandler, useClass: CustomMissingTranslationHandler},
    }),
    PropertyValuesSelectorModule,
    PipesModule,
    CloseButtonModule,
    SharedModule
  ],
  declarations: [
    DefaultPropertyClassesComponent,
    ModelPropertyClassesComponent,
    DefaultPropertiesComponent,
    GenericPropertyComponent
  ],
  exports: [
    DefaultPropertyClassesComponent,
    ModelPropertyClassesComponent,
    DefaultPropertiesComponent,
    GenericPropertyComponent,
  ]
})
export class PropertiesConfiguratorPropertyClassesModule {
}
