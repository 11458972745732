import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CatalogueService} from '../../catalogue.service';
import {LoaderComponent} from '../../../ui-elements/loader/loader.component';
import {AutoDownloadInterface, AUTO_DOWNLOAD_KEY, AutoDownloadType} from './auto-download.model';

@Component({
  selector: 'auto-download-components',
  templateUrl: './auto-download.component.html',
  styleUrls: ['./auto-download.component.scss']
})

export class AutoDownloadComponent implements OnInit, OnDestroy {
  @ViewChild('loader', {static: true}) loader: LoaderComponent;

  constructor(
    private catalogueService: CatalogueService) {
  }

  state: AutoDownloadInterface;

  ngOnInit() {
    this.state = JSON.parse(localStorage.getItem(AUTO_DOWNLOAD_KEY));
    if (this.state.type === AutoDownloadType.CATALOGUE || this.state.type === AutoDownloadType.SEARCH) {
      this.onExportCatalogue();
    }
  }

  ngOnDestroy() {
  }

  onExportCatalogue() {
    this.loader.show();
    if (this.state.type === AutoDownloadType.CATALOGUE) {
      this.catalogueService.getCatalogueExportUrl(
        this.state.idOfCategory,
        this.state.format,
        this.state.filters
      ).add(() => {
        this.loader.hide();
        localStorage.removeItem(AUTO_DOWNLOAD_KEY);
      });
    } else {
      this.catalogueService.getCatalogueSearchExportUrl(
        this.state.searchTerm,
        this.state.format,
        this.state.filters
      ).add(() => {
        this.loader.hide();
        localStorage.removeItem(AUTO_DOWNLOAD_KEY);
      });
    }
  }
}
