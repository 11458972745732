import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {QuantitySpinnerComponent} from './quantity-spinner.component';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TooltipModule} from "../tooltip/tooltip.module";

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    FormsModule,
    ReactiveFormsModule,
    TooltipModule,
  ],
  declarations: [QuantitySpinnerComponent],
  exports: [QuantitySpinnerComponent]
})
export class QuantitySpinnerModule {
}
