import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ClaimFileType, ClaimFileTypeTranslationMap, ClaimInterface, ClaimType, ClaimTypeFile, ClaimTypeItemInterfaceType, ClaimTypeItemType, ClaimTypeTranslationMap, ClaimTypeWithFiles } from '../../core/models/claim.model';
import { ClaimsService } from '../claims.service';
import { ClaimStatus } from '../../core/enums/claim-state.enum';
import { imageFileExtension } from '../../core/models/file-data.model';

export enum ClaimPreviewTabs {
  REPORT = 'report',
  DELIVERY_INFORMATION = 'delivery',
  COMMUNICATION = 'communication'
}

@Component({
  selector: 'app-claim-preview',
  templateUrl: './claim-preview.component.html',
  styleUrls: ['./../claim-modals.base.scss'],
})

export class ClaimPreviewComponent {
  @Input() item: ClaimInterface;
  @Input() activeTab: ClaimPreviewTabs = ClaimPreviewTabs.REPORT;

  tabs = ClaimPreviewTabs;
  typesTranslationMap = ClaimTypeTranslationMap;
  uploadedFileTypesTranslationMap = ClaimFileTypeTranslationMap;
  expandedTypes: ClaimType[] = [];
  claimFileTypes = ClaimFileType;

  constructor(
    private claimService: ClaimsService
  ) {

  }

  ngOnInit() {
    this.expandedTypes = [...this.item.claimTypes.map((claimType) => claimType.type)];

    if ([ClaimStatus.WAITING, ClaimStatus.COMPLETED].includes(this.item.status)) {
      this.activeTab = ClaimPreviewTabs.COMMUNICATION;
    }

    this.claimService.getOne(this.item.id).noCache().subscribe((claim) => {
      this.item = claim;
    });
  }

  public switchTab(tab) {
    this.activeTab = tab;
  }

  get fullDeliveryAddress() {
    const addressData = [];
    const { address } = this.item;

    if (!address) {
      return null;
    }

    if (address.address) {
      addressData.push(address.address);
    }

    if (address.postCode) {
      addressData.push(`${address.postCode} ${address.city ? address.city : ''}`);
    }

    if (address.country?.title) {
      addressData.push(address.country.title);
    } else if (address.country?.code) {
      addressData.push(address.country.code);
    }

    return addressData.join(', ');
  }

  onTypeToggle(type: ClaimType) {
    if (this.expandedTypes.includes(type)) {
      this.expandedTypes = this.expandedTypes.filter(t => t !== type);

      return;
    }

    this.expandedTypes.push(type);
  }

  countDifferentAttachmentTypes(claimType: ClaimTypeItemType): number {
    const fileList: ClaimTypeFile[] = (claimType as ClaimTypeWithFiles).claimTypeFiles || [];

    return [...new Set(fileList.map(file => file.type) || [])].length;
  }

  filterLeftPartAttachments(claimType: ClaimTypeItemType) {
    const fileList: ClaimTypeFile[] = (claimType as ClaimTypeWithFiles).claimTypeFiles || [];

    return this.filterAttachments(fileList, [ClaimFileType.ATTACHMENT, ClaimFileType.DAMAGE, ClaimFileType.ITEM_PICTURE, ClaimFileType.PROBLEM_PICTURE]);
  }

  filterRightPartAttachments(claimType: ClaimTypeItemType) {
    const fileList: ClaimTypeFile[] = (claimType as ClaimTypeWithFiles).claimTypeFiles || [];

    return this.filterAttachments(fileList, [ClaimFileType.CMR_DOCUMENT, ClaimFileType.PRODUCT_LABEL_PICTURE]);
  }

  filterAttachments(fileList: ClaimTypeFile[], type: ClaimFileType | ClaimFileType[]) {
    return fileList.filter(file => file.type === type || type?.includes(file.type));
  }

  isImageFile(fileName: string): boolean {
    // @todo: refactor this when mime type information will be available on the API side
    const fileNameParts = fileName.split('.');

    return imageFileExtension.includes(fileNameParts[fileNameParts.length - 1]);
  }

  claimItemQuantity(item: ClaimTypeItemInterfaceType): number | undefined {
    return 'quantity' in item ? item.quantity as number : undefined;
  }
}
