import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'replaceComma'
})
export class CommaPipe implements PipeTransform {

  transform(value: number|string, replaceValue = ''): number|string {
    if (value) {
      let valueString = value.toString();
      const matches = valueString.match(/[\.\,]/g);

      if (matches?.length > 1) {
        const lastMatch = matches.pop();

        for(let match of matches) {
          valueString = valueString.replace(match, '');
        }

        return valueString.replace(lastMatch, replaceValue);
      }

      return valueString.replace(',', replaceValue);
    }

    return value;
  }

}
