import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BorderboxResizeObserverDirective } from './border-box-resize-observer.directive';
import { ResizeObserverSetStyleTopDirective } from './resize-observer-set-style-top.directive';

@NgModule({
  declarations: [
    BorderboxResizeObserverDirective,
    ResizeObserverSetStyleTopDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    BorderboxResizeObserverDirective,
    ResizeObserverSetStyleTopDirective
  ]
})
export class ResizeObserverModule { }
