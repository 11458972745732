import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '../../api.service';
import { FinishFamily, FinishItem, FinishPageLinks } from './finishes.model';

@Injectable({ providedIn: 'root' })
export class FinishesService {

  constructor(
    private api: ApiService
  ) {}

  get(id: number): Observable<FinishItem> {
    return this.api.get(`finishes/${id}`).noCache();
  }

  getFinishes(): Observable<FinishItem[]> {
    return this.api.get(`finishes/`).noCache();
  }

  getFinishesByPath(categoryType: number, categoryPath: string): Observable<FinishItem[]> {
    return this.api.get(`finishes/`, { categoryType, categoryPath }).noCache();
  }

  getFinishFamily(id: number): Observable<FinishFamily[]> {
    return this.api.get(`finishes/${id}/families`).noCache();
  }

  getFamilyByPath(categoryType: number, categoryPath: string): Observable<FinishFamily[]> {
    return this.api.get(`finish-families/`, { categoryType, categoryPath }).noCache();
  }

  getFinishPageLinks(): Observable<FinishPageLinks[]> {
    return this.api.get(`links/finishes_page`).noCache();
  }
}
