import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
  name: 'priceFormat',
})
export class PriceFormatPipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe) {}

  /**
   * @param value The number to be formatted.
   * @param args null or Object with optional parameters:
   *             digitsInfo - Decimal representation options, specified by a string
   *                 in the following format:<br>
   *                 <code>{minIntegerDigits}.{minFractionDigits}-{maxFractionDigits}</code>.
   *                   - `minIntegerDigits`: The minimum number of integer digits before the decimal point.
   *                 Default is `1`.
   *                   - `minFractionDigits`: The minimum number of digits after the decimal point.
   *                 Default is `0`.
   *                   - `maxFractionDigits`: The maximum number of digits after the decimal point.
   *                 Default is `3`.
   *             zeroReplacement - string to output instead of value if value is zero
   */
  transform(value: any, args?: any): any {
    if (value && value.toString().indexOf(',') > -1) {
      value = value.toString().replace(',', '.');
    }
    if (args && args.zeroReplacement != null && (value ? +value : 0) === 0) {
      return args.zeroReplacement;
    }

    const transformed = this.decimalPipe.transform(value ? +value : 0, args && args.digitsInfo ? args.digitsInfo : '1.0-2');

    if (!transformed) {
      return null;
    }

    return transformed.toString().replace(',', '');
  }
}
