import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ProductsListViewComponent } from './products/products-list-view/products-list-view.component';
import { FilterSidebarComponent } from './products/filter-sidebar/filter-sidebar.component';
import { InfoBlockComponent } from './info-block/info-block.component';
import { StaticInfoComponent } from './info-block/static-info/static-info.component';
import { ProductsComponent } from './products/products.component';
import { DownloadsComponent } from './info-block/downloads/downloads.component';
import { ArticleComponentsComponent } from './products/products-list-view/article-components/article-components.component';
import { CatalogueRoutingModule } from './catalogue-routing.module';
import { SharedModule } from '../shared/shared.module';
import { ConfiguratorModalModule } from '../configurator-modal/configurator-modal.module';
import { CustomMissingTranslationHandler } from '../core/models/custom-missing-translation-handler.model';
import { CatalogueService } from './catalogue.service';
import { ProductsService } from './products/products.service';
import { WindowRef } from '../core/services/window-ref/window-ref.service';
import { InfoBlockService } from './info-block/info-block.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MissingTranslationHandler, TranslateModule } from '@ngx-translate/core';
import { CheckMarkModule } from '../ui-elements/check-mark/check-mark.module';
import { PermissionsModule } from '../permissions/permissions.module';
import { LoaderModule } from '../ui-elements/loader/loader.module';
import { PriceListDetailsModule } from '../pricelist-details/pricelist-details.module';
import { VisibilityByVariablesModule } from '../visibility-by-variables/visibility-by-variables.module';
import { UncacheableResourceModule } from '../uncacheable-resource/uncacheable-resource.module';
import { TooltipModule } from '../ui-elements/tooltip/tooltip.module';
import { AutoDownloadComponent } from './products/auto-download/auto-download.component';
import { AutoDownloadDirective } from './products/auto-download/auto-download.directive';
import { PopoverModule } from '../ui-elements/popover/popover.module';
import { UncachePipe } from '../uncacheable-resource/uncache.pipe';
import { CloseButtonModule } from '../ui-elements/close-button/close-button.module';
import { SearchHighlightComponent } from './products/products-list-view/search-highlight.component';
import { ProductsThumbailViewComponent } from './products/products-thumbail-view/products-thumbail-view.component';
import { SystemDirective } from './products/products-list-view/system.directive';
import { ProductsListItemSkeletonComponent } from './products/products-list-item-skeleton/products-list-item-skeleton.component';
import { CatalogueParentComponent } from './catalogue-parent.component';
import { FinishesInfoComponent } from './info-block/finishes-info/finishes-info.component';
import { FinishesDownloadsComponent } from './info-block/finishes-downloads/finishes-downloads.component';
import { PropertyValuesGroupComponent } from './products/products-list-view/property-values-group/property-values-group.component';
import { ProductsBlockComponent } from './products/products-list-view/products-block/products-block.component';

@NgModule({
  declarations: [
    ProductsListViewComponent,
    FilterSidebarComponent,
    InfoBlockComponent,
    StaticInfoComponent,
    ProductsComponent,
    DownloadsComponent,
    ArticleComponentsComponent,
    AutoDownloadComponent,
    AutoDownloadDirective,
    SearchHighlightComponent,
    ProductsThumbailViewComponent,
    SystemDirective,
    ProductsListItemSkeletonComponent,
    CatalogueParentComponent,
    FinishesInfoComponent,
    FinishesDownloadsComponent,
    PropertyValuesGroupComponent,
    ProductsBlockComponent
  ],
  imports: [
    CheckMarkModule,
    CommonModule,
    CatalogueRoutingModule,
    NgbModule,
    FormsModule,
    SharedModule,
    ReactiveFormsModule,
    ConfiguratorModalModule,
    TranslateModule.forChild({
      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: CustomMissingTranslationHandler },
    }),
    PermissionsModule,
    LoaderModule,
    PriceListDetailsModule,
    VisibilityByVariablesModule,
    UncacheableResourceModule,
    TooltipModule,
    PopoverModule,
    CloseButtonModule,
  ],
  providers: [CatalogueService, ProductsService, WindowRef, InfoBlockService, NgbActiveModal, UncachePipe],
  exports: [SearchHighlightComponent],
})
export class CatalogueModule {}
