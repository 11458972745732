import { Injectable, TemplateRef } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export interface Toast {
  body: string | TemplateRef<any>;
  /** CSS class or classes to be applied on toast body. */
  classname?: string;
  /** Duration in miliseconds for how long toast should be shown. */
  delay?: number;
  /** Name of icon from nSpace icon font. Example: `ni-check` */
  icon?: string;
  /** Configures toast to stay open when `false` value is provided. */
  autohide?: boolean;
  afterClose?: () => void;
}

type ToastOptions = Omit<Toast, 'body'>;

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private toasts$ = new BehaviorSubject<Toast[]>([]);

  /**
   * Method to receive observable that will store and emit Toast objects.
   */
  getToasts(): Observable<Toast[]> {
    return this.toasts$.asObservable();
  }

  /**
   * Opens up a toast
   * @param body Text to be shown or TemplateRef to be rendered
   * @param options Options that impact looks or functionality of toast
   * @returns
   */
  private show(body: string | TemplateRef<any>, options: ToastOptions = {}): Toast {
    const toast = { body, ...options };
    const value = this.toasts$.value;

    value.push(toast);
    this.toasts$.next(value);

    return toast;
  }

  /**
   * Opens up prestyled success toast message
   */
  success(body: string | TemplateRef<any>, options: ToastOptions = {}): Toast {
    return this.show(body, { classname: 'bg-success color-white', icon: 'ni-check', ...options });
  }

  /**
   * Opens up prestyled danger/failure toast message
   */
  danger(body: string | TemplateRef<any>, options: ToastOptions = {}): Toast {
    return this.show(body, { classname: 'bg-danger color-white', icon: 'ni-warning-circle', ...options });
  }

  warning(body: string | TemplateRef<any>, options: ToastOptions = {}): Toast {
    return this.show(body, { classname: 'bg-warning-500 color-white', icon: 'ni-warning-circle', ...options });
  }

  info(body: string | TemplateRef<any>, options: ToastOptions = {}): Toast {
    return this.show(body, { classname: 'bg-gray-700 color-white', icon: 'ni-info', ...options });
  }

  /** Removes single toast. */
  remove(toast: Toast) {
    this.toasts$.next(this.toasts$.value.filter(t => t !== toast));
    toast.afterClose?.();
  }
}
