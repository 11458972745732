import {Injectable} from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {Observable} from 'rxjs';
import {UserService} from '../services/user/user.service';
import {UserInterface} from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard  {
  constructor(private route: ActivatedRoute, private router: Router, private userService: UserService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return new Observable<boolean>(observer => {
      this
        .userService
        .fromStorage()
        .subscribe((user: UserInterface) => {
          observer.next((!route.data.allowedRoles) ||
            (user && route.data.allowedRoles && route.data.allowedRoles.includes(user.role.name)));
          observer.complete();
        });
    });
  }
}
