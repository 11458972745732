import { Injectable } from '@angular/core';

import { ApiService } from '../../api.service';
import { CatalogueRouteType, RouteTypeEnums } from '../enums/route-types.enum';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { CacheableObservable } from '../../cacheable-observable/cacheable-observable.model';
import { CatalogCategoryDataInterface } from './third-navbar/details/details.component';
import { AuthService } from '../../auth/auth.service';
import { NavbarElementInterface, NavbarElements } from './navbar-elements';
import { UserService } from '../services/user/user.service';
import { UserInterface } from '../models/user.model';
import { HttpHeaders } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { CategoryPathInterface } from "../models/category-path.model";

export interface NavbarState {
  first: NavbarElementInterface;
  second: NavbarElementInterface;
  third?: boolean;
}

interface ModifyNavbarStateInterface {
  first?: NavbarElementInterface;
  second?: NavbarElementInterface;
  third?: boolean;
}

const INIT_STATE = {
  first: null,
  second: null,
  third: false,
};

@Injectable({
  providedIn: 'root',
})
export class TreeService {
  private state$: BehaviorSubject<NavbarState> = new BehaviorSubject<NavbarState>(INIT_STATE);
  private user: UserInterface;

  constructor(
    private api: ApiService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private userService: UserService,
    private translateService: TranslateService
  ) {
    const navigationEnded$ = router.events.pipe(
      filter(data => data instanceof NavigationEnd),
      shareReplay({ bufferSize: 1, refCount: true })
    );
    /**
     * This service is never destroyed, so by logging out the previous user's routing data is still kept.
     * Once cleared - this subscription needs to be activated again. Previous implementation had pipe(takeWhile(!selectedElement)),
     * which does not work in this case, because takeWhile waits till predicate is true and unsubscribers the observable.
     * Current solution listens always for the RoutesRecognized event and check if route match to navbar elements (this done in tap pipe).
     * Subscribe used only for first page init to active default navbar routes.
     * Also unsubscribe route events when user logs out to avoid memory leak.
     */
    authService
      .authenticatedObservable()
      .pipe(
        switchMap(isAuth => {
          if (isAuth) {
            return userService.getUser();
          }
          return of(null);
        }),
        tap(user => (this.user = user)),
        filter(user => !!user),
        switchMap(() => navigationEnded$)
      )
      .subscribe(() => {
        const [firstNavElement, secondNavElement] = this.matchOpenNavElements(NavbarElements);
        if (firstNavElement) {
          this.modifyState({
            first: firstNavElement,
            second: secondNavElement,
            third: firstNavElement.id === RouteTypeEnums.CATALOGUE ? this.getStateValue().third : false
          });
        } else {
          this.closeAll();
        }
      });
  }

  open(first: NavbarElementInterface, second?: NavbarElementInterface, navigate = false) {
    const stateValue = this.getStateValue();

    if (first) {
      stateValue.first = first;

      if (!second) {
        // Find first applicable sub-nav element for current user
        [second] = first.children
          .filter(({ allowedRoles }) => !allowedRoles || allowedRoles.indexOf(this.user.role.name) > -1)
          .sort((a, b) => {
            if (!(a.positions && b.positions)) {
              return 0;
            }
            return (
              a.positions.find(({ role }) => role === this.user.role.name).position -
              b.positions.find(({ role }) => role === this.user.role.name).position
            );
          });
      }
    }

    if (second) {
      stateValue.second = second;
    }

    stateValue.third = stateValue.first.id === RouteTypeEnums.CATALOGUE ? this.getStateValue().third : false;

    if (navigate) {
      this.router.navigate([stateValue.first.path, stateValue.second.path]);
    }

    this.changeState(stateValue);
  }

  /** Recursively goes through NavbarElementInterface[] and tries to match deepest child */
  private matchOpenNavElements(elements?: NavbarElementInterface[], parentPath: string = ''): NavbarElementInterface[] {
    if (!elements?.length) {
      return [];
    }

    const found = elements.find(element => {
      return this.router.isActive(
        this.router.createUrlTree([parentPath, element.path]),
        {
          paths: 'subset',
          fragment: 'ignored',
          matrixParams: 'ignored',
          queryParams: 'ignored'
        }
      );
    });

    if (!found) {
      return [];
    }

    const parentURL = this.router.createUrlTree([parentPath, found.path]).toString();
    return [found, ...this.matchOpenNavElements(found.children, parentURL)];
  }

  private changeState(value: NavbarState) {
    this.state$.next(value);
  }

  public modifyState(value: ModifyNavbarStateInterface) {
    const currentState = this.getStateValue();
    this.changeState({
      ...currentState,
      ...value,
    });
  }

  closeNavbar(prop: string) {
    this.modifyState({ [prop]: null });
  }

  closeAll() {
    this.modifyState({ first: null, second: null });
  }

  resetState() {
    this.changeState(INIT_STATE);
  }

  toggleThirdNavbarState() {
    const currentState = this.getStateValue();
    if (currentState.first && currentState.first.id === RouteTypeEnums.CATALOGUE) {
      this.modifyState({ third: !currentState.third });
    }
  }

  onSearch() {
    this.modifyState({ second: null, third: false });
  }

  getState(): Observable<NavbarState> {
    return this.state$.asObservable();
  }

  getStateValue(): NavbarState {
    return this.state$.getValue();
  }

  getCategoryOrSubCategoryData(subCategoryId): CacheableObservable<CatalogCategoryDataInterface> {
    return this.api.get(`catalog/${subCategoryId}/data`).pipe(map(({ data }) => data)) as CacheableObservable<CatalogCategoryDataInterface>;
  }

  getCatalogue(catalogueType: CatalogueRouteType): Observable<CategoryPathInterface[]> {
    const paths = {
      categories: 'categories',
      systems: 'systems',
      components: 'components',
    };

    let selectedPath = null;

    if (!this.authService.isAuthenticated()) {
      this.authService.logout();
      return of(null);
    }
    switch (catalogueType) {
      case CatalogueRouteType.CATEGORY:
        selectedPath = paths.categories;
        break;
      case CatalogueRouteType.SYSTEMS:
        selectedPath = paths.systems;
        break;
      case CatalogueRouteType.COMPONENTS:
        selectedPath = paths.components;
        break;
      default:
        // catalogueType can be null here if user hits browser's back button twice
        // we need to force going back one more level because first level in catalog is only a placeholder
        window.history.back();
    }

    /**
     * Add set Accept-language header to request, to avoid race conditions because request fires faster than new language is set
     */
    return this.userService.getUser().pipe(
      switchMap(user => {
        let headers = new HttpHeaders();
        const language =
          this.translateService.currentLang ||
          (user.language && user.language.short ? user.language.short : this.translateService.defaultLang);

        if (language) {
          headers = headers.set('Accept-Language', language);
        }

        if (!selectedPath) {
          return of([]);
        }

        return this.api.get(`catalog/${selectedPath}`, null, null, headers).pipe(map(({ data }) => data));
      })
    );
  }

  getRootNavElement(id) {
    return NavbarElements.find(item => item.id === id);
  }
}
