import {Subject} from 'rxjs';
import {OverlayRef} from '@angular/cdk/overlay';
import {TemplateRef} from '@angular/core';

export interface PopoverCloseEvent<T = any> {
  type: 'backdropClick' | 'close';
  data: T;
}

export class PopoverRef<T = any> {
  private afterClosed = new Subject<PopoverCloseEvent<T>>();

  constructor(public overlay: OverlayRef,
              public content: TemplateRef<any>,
              public data: T
  ) {
    overlay.backdropClick()
      .subscribe(() => {
        this.close(null, 'backdropClick');
      });
  }

  close(data?: T, type: 'backdropClick' | 'close' = 'close') {
    this.overlay.dispose();
    this.afterClosed.next({
      type,
      data
    });
    this.afterClosed.complete();
  }
}
