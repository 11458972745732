import {Component, Input} from '@angular/core';
import {BreadcrumbModel} from './breadcrumb.model';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
})
export class BreadcrumbsComponent {
  @Input() breadcrumbs: BreadcrumbModel[];
  @Input() withLeadingChevron = false;

  constructor() {}
}
