import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NotificationsModalComponent } from './notifications-modal/notifications-modal.component';
import { LoaderModule } from '../ui-elements/loader/loader.module';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../shared/shared.module';
import { NotificationComponentsModule } from '../notification-components/notification-components.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

@NgModule({
  imports: [
    CommonModule,
    LoaderModule,
    TranslateModule,
    SharedModule,
    NotificationComponentsModule,
    InfiniteScrollModule,
  ],
  declarations: [NotificationsModalComponent]
})
export class NotificationsModalModule { }
