import { CommaPipe } from './../../../../shared/pipes/comma/comma.pipe';
import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {UserInterface} from '../../../../core/models/user.model';
import {UserRole} from '../../../../core/enums/user-role.enum';
import {CustomOrderArticleItemChangesInterface} from '../../custom-article-modal.model';
import {ValidatorService} from '../../../../shared/services/validator/validator.service';
import {TranslateService} from '@ngx-translate/core';
import {OtherSupplierOrderArticleInterface} from '../../../../core/models/order-article.model';
import {TreeService} from '../../../../core/tree/tree.service';
import {CatalogueRouteType} from '../../../../core/enums/route-types.enum';
import {CategoryPathInterface} from '../../../../core/models/category-path.model';
import {OrderInterface} from '../../../../core/models/order.model';
import {PriceFormatPipe} from '../../../../shared/pipes/price-format/price-format.pipe';
import {FormComponentModel} from "../../form-component.model";
import {first} from "rxjs/operators";
import {OrderState} from "../../../../core/enums/order.state.enum";

@Component({
  selector: 'app-non-standard-item-order-article-form',
  templateUrl: './form.component.html',
})
export class FormComponent extends FormComponentModel implements OnInit {
  @Input() set item(item: OtherSupplierOrderArticleInterface) {
    this.form.setValue(this.formValues(item));

    if (!item.category) {
      this.form.get('category').setValue('');
    }
  
    if (!item.system) {
      this.form.get('system').setValue('');
    }
  };
  get item(): OtherSupplierOrderArticleInterface {
    return this.form.value;
  }

  @Input() user?: UserInterface;
  @Input() selectedOrder?: OrderInterface;
  @Output() itemChanges: EventEmitter<CustomOrderArticleItemChangesInterface> = new EventEmitter();
  @ViewChild('formEl') formEl: ElementRef;

  requiredFields: string[] = ['title'];
  dealerMode = true;
  pmMode = false;
  availableCategories: CategoryPathInterface[] = [];
  availableSystems: CategoryPathInterface[] = [];
  selectedCategory: CategoryPathInterface;
  selectedSystem: CategoryPathInterface;
  isCustomCategory = false;
  customPriceText = '';
  customPricePlaceholder = '';

  constructor(
    fb: UntypedFormBuilder,
    priceFormatPipe: PriceFormatPipe,
    protected validatorService: ValidatorService,
    private translate: TranslateService,
    private treeService: TreeService,
    commaPipe: CommaPipe
  ) {
    super(fb, priceFormatPipe, commaPipe);
  }

  ngOnInit() {
    this.setDealerMode(this.user);

    // [UserRole.ROLE_PM_NARBUTAS, UserRole.ROLE_PM].indexOf(this.user.role.name)
    switch (this.user.role.name) {
      case UserRole.ROLE_PM:
      case UserRole.ROLE_PM_RU:
        this.requiredFields.push('customPrice');
        this.form.get('customPrice').addValidators([Validators.required]);
        this.form.get('customPrice').updateValueAndValidity();
      // tslint:disable-next-line:no-switch-case-fall-through
      case UserRole.ROLE_PM_NARBUTAS:
        this.requiredFields.push('code');
        this.form.get('code').addValidators([Validators.required, Validators.pattern(/^((?!\*).)*$/)]);
        this.form.get('code').updateValueAndValidity();
        break;
    }

    this.translate.get('ORDERS.NON_STANDARD_ITEM_MODAL.FORM.CATEGORY').subscribe((translation: string) => {
      this.availableCategories.push({ id: 0, name: translation, originalName: translation, path: '/', type: 0, children: null, slug: '' });

      if (!this.item.category) {
        this.form.get('category').setValue('');
      }
    });

    this.translate.get('ORDERS.NON_STANDARD_ITEM_MODAL.FORM.SYSTEM').subscribe((translation: string) => {
      this.availableSystems.push({ id: 0, name: translation, originalName: translation, path: '/', type: 0, children: null, slug: ''});

      if (!this.item.system) {
        this.form.get('system').setValue('');
      }
    });

    let translationKey = 'PURCHASE_PRICE';
    if (this.isPmRole(this.user.role.name)) {
      translationKey = 'UNIT_PRICE';
    }

    this.translate.get('ORDERS.NON_STANDARD_ITEM_MODAL.FORM_PLACEHOLDERS.' + translationKey).subscribe((translation: string) => {
      this.customPricePlaceholder = translation;
    });
    this.translate.get('ORDERS.NON_STANDARD_ITEM_MODAL.FORM.' + translationKey).subscribe((translation: string) => {
      this.customPriceText = translation;
    });

    this.getAvailableCategories();
    this.getAvailableSystems();

    if (this.selectedOrder?.state !== OrderState.DRAFT && this.dealerMode) {
      this.form.controls.typeInformation.disable();
    }

    super.ngOnInit();
  }

  private isPmRole(userRoleName: string) {
    return UserRole.ROLE_PM === userRoleName || UserRole.ROLE_PM_RU === userRoleName;
  }

  setIsCustomCategory(categoryValue?: string) {
    const filtered = this.availableCategories.find(item => item.name === categoryValue);
    this.isCustomCategory = filtered?.id === -1;
  }

  initSelectedSystem(systemName: string) {
    if (!this.availableSystems || !this.availableSystems.length) {
      return;
    }

    const filtered = this.availableSystems.find(item => item.name === systemName);

    if (filtered) {
      this.selectedSystem = filtered;
    }
  }

  setDealerMode(user) {
    this.pmMode = false;
    switch (user.role.name) {
      case UserRole.ROLE_PM:
      case UserRole.ROLE_PM_RU:
        this.pmMode = true;
      // tslint:disable-next-line:no-switch-case-fall-through
      case UserRole.ROLE_PM_NARBUTAS:
        this.dealerMode = false;
        break;
      case UserRole.ROLE_DEALER:
      default:
        this.dealerMode = true;
        break;
    }
  }

  onCategoryChange($event) {
    const value = $event.target.value;
    this.setIsCustomCategory(value);

    if (!this.isCustomCategory) {
      // Remember? short text is custom category huh
      this.form.get('shortText').setValue('');
    }
  }

  getAvailableCategories(): void {
    this.treeService
      .getCatalogue(CatalogueRouteType.CATEGORY)
      .pipe(first())
      .subscribe((res: CategoryPathInterface[]) => {
        this.availableCategories.push(...res);

        this.translate.get('ORDERS.NON_STANDARD_ITEM_MODAL.FORM').subscribe((translation: any) => {
          const shippingTxt = translation.SHIPPING || 'Shipping';
          const customCategoryTxt = translation.CUSTOM_CATEGORY || 'Custom category';
          this.availableCategories.push({
            id: -2,
            name: shippingTxt.toUpperCase(),
            originalName: shippingTxt,
            path: '/',
            type: 0,
            children: null,
            slug: '',
          });
          this.availableCategories.push({
            id: -1,
            name: customCategoryTxt.toUpperCase(),
            originalName: customCategoryTxt,
            path: '/',
            type: 0,
            children: null,
            slug: '',
          });
        });

        this.setIsCustomCategory(this.item.category);
      });
  }

  getAvailableSystems(): void {
    this.treeService
      .getCatalogue(CatalogueRouteType.SYSTEMS)
      .pipe(first())
      .subscribe((res: CategoryPathInterface[]) => {
        this.availableSystems.push(...res);

        this.translate.get('ORDERS.NON_STANDARD_ITEM_MODAL.FORM.OTHER').subscribe((translation: string) => {
          this.availableSystems.push({
            id: -1,
            name: translation.toUpperCase(),
            originalName: translation,
            path: '/',
            type: 0,
            children: null,
            slug: '',
          });
          this.initSelectedSystem(this.item.system);
        });
      });
  }
}
