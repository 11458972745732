import {Component, Inject, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {Observable, Subscription} from 'rxjs';
import {ContentModel} from '../content.model';
import {ContentService} from '../content.service';
import {DOCUMENT} from "@angular/common";
import { ImageSizeEnum } from '../../shared/pipes/resize-image/resize-image.pipe';

const PAGE_QUERY_PARAM = 'page';

@Component({
  selector: 'app-content-list',
  templateUrl: './content-list.component.html',
  styleUrls: ['./content-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ContentListComponent implements OnInit, OnDestroy {
  @Input() baseUri: string;
  @Input() currentUri: string;
  @Input() currentPage: number;

  item$: Observable<ContentModel.Data> = this.contentService.getItemAsObservable();
  list$: Observable<ContentModel.Data[]> = this.contentService.getListAsObservable();
  pagination$: Observable<ContentModel.Pagination> = this.contentService.getPaginationAsObservable();
  imageSizes = ImageSizeEnum;

  get showTitle(): boolean {
    return window.location.pathname
      .split('/')
      .filter(v => !!v)
      .length > 1
  }

  private subscriptions: Subscription = new Subscription();

  constructor(
    private router: Router,
    private contentService: ContentService,
    @Inject(DOCUMENT) private document: Document
  ) {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onPageChange(page: number) {
    this.router.navigate([], { queryParams: { [PAGE_QUERY_PARAM]: page } });
  }

  getItemLink(item: ContentModel.Data) {
    const location = this.document.location;
    const segments = location.pathname.split('/').filter(s => !!s);

    segments.push(item.translation.uri ?? item.uri);

    return '/' + segments.join('/');
  }

  onItemClick(item: ContentModel.Data) {
    const target = item.linkTarget ?? '_self';
    const link = item.link ?? this.getItemLink(item);

    window.open(link, target);
  }
}
