import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  SimpleArticlePropertiesConfiguratorComponent
} from './simple-article-properties-configurator/simple-article-properties-configurator.component';
import {
  PropertiesConfiguratorPropertyClassesModule
} from '../properties-configurator-property-classes/properties-configurator-property-classes.module';
import { LoaderModule } from '../../ui-elements/loader/loader.module';

@NgModule({
  imports: [
    CommonModule,
    PropertiesConfiguratorPropertyClassesModule,
    LoaderModule
  ],
  declarations: [
    SimpleArticlePropertiesConfiguratorComponent
  ],
  exports: [
    SimpleArticlePropertiesConfiguratorComponent
  ]
})
export class SimpleArticlePropertiesConfiguratorModule {
}
