import {Component, OnChanges, OnInit, SimpleChange, SimpleChanges} from '@angular/core';
import {PropertyClassChangeComponent} from '../../property-class-change.component';
import {Theme} from '../../../ui-elements/accordion-toggler/accordion-toggler-themes.enum';
import {PropertyValueSelectorThemes} from '../../property-values-selector/property-values-selector.component';

@Component({
  selector: 'app-default-property-classes',
  templateUrl: './default-property-classes.component.html',
  styleUrls: ['./default-property-classes.component.scss']
})
export class DefaultPropertyClassesComponent extends PropertyClassChangeComponent implements OnInit, OnChanges {

  propertyValueSelectorThemes = PropertyValueSelectorThemes;
  accordionThemes = Theme;

  constructor() {
    super();
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

}
