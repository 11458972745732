<ng-container *ngIf="{
  finishFamilies: finishFamily$ | async
} as data">
  <div class="container-xxl">
    <div class="row">
      <div class="col">
        <div class="d-flex justify-content-between align-items-end my-3">
          <div>
            <app-breadcrumbs
              *ngIf="breadcrumbs.length"
              [breadcrumbs]="breadcrumbs"
              [withLeadingChevron]="true"
            ></app-breadcrumbs>
            <h1
              *ngIf="finish$ | async as finish"
              class="mb-0"
            >{{ finish.title }}</h1>
          </div>
          <div>
            <a *ngFor="let family of data.finishFamilies"
              [routerLink]="[]"
              [fragment]="family.title"
              class="btn btn-light ms-3"
            >
              {{ family.title }}
            </a>
          </div>
        </div>
      </div>
    </div>

    <ng-container *ngIf="data.finishFamilies">
      <div *ngFor="let family of data.finishFamilies" class="row">
        <div class="col-12">
          <hr class="mt-0" />
        </div>
        <div class="col-3 mb-3">
          <h2 [attr.id]="family.title">{{ family.title }}</h2>
          <div [innerHTML]="family.description" class="text-break"></div>
          <div class="mb-3">
            <a
              *ngFor="let file of filterByLanguage(family.files)"
              [href]="file.file.url"
              download
              class="btn btn-link w-100 mb-2"
            >
              <span class="d-flex justify-content-center align-items-center">
                {{ 'FINISHES.DOWNLOAD_PDF' | translate }}
                <i class="icon ni-download"></i>
              </span>
            </a>
            <button *ngIf="family.swatchCardArticleSystem && family.swatchCardArticleCode"
              (click)="orderSwatchCard(family.swatchCardArticleSystem, family.swatchCardArticleCode)"
              class="btn btn-primary w-100"
            >
              {{ 'FINISHES.ORDER_SWATCH_CARD' | translate }}
            </button>
          </div>
          <div class="d-flex bg-gray-400 color-black p-3 mb-3">
            <i class="icon ni-warning-circle ms-1 me-3"></i>
            {{ 'FINISHES.DISPLAYED_COLOR_MAY_DIFFER' | translate }}
          </div>
        </div>

        <div class="col-9">
          <div class="row">
            <div
              *ngFor="let texture of family.articlePropertyValueTextures"
              class="col-4 mb-3"
            >
              <div class="finish-item mb-3 d-flex flex-column bg-white p-3" [style.height.rem]="18" >
                <h4 class="mb-0">{{ texture.valueFrom }} <span *ngIf="texture.manufacturerCode" class="float-end">{{ texture.manufacturerCode }}</span></h4>
                <p class="small mb-2">{{ texture.title }}</p>
                <div class="finish-item-image overflow-hidden flex-grow-1">
                  <img
                    [src]="(texture.images['240x240'] || texture.images['80x80'] || texture.images['32x32']) | resizeImage: imageSizes.SIZE_280x140"
                    [alt]="texture.valueFrom"
                    class="w-100 h-100"
                  />
                </div>
                <div class="finish-item-bottom overflow-hidden">
                  <a
                    class="btn btn-secondary w-100 mt-2 no-outline"
                    [href]="texture.images['600x600'] || texture.images['240x240'] || texture.images['80x80'] || texture.images['32x32']"
                    download
                  >
                    <span class="d-flex justify-content-center align-items-center">
                      {{ 'FINISHES.DOWNLOAD' | translate }}
                      <i class="icon ni-download"></i>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>
