import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FilterAssign, FilterAssignValue, FilterInterface } from './filter.model';
import { FilterService } from './filter.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-filter-sidebar',
  templateUrl: './filter-sidebar.component.html',
  styleUrls: ['./filter-sidebar.component.scss']
})
export class FilterSidebarComponent {
  @Output() toggleFilter: EventEmitter<void> = new EventEmitter<void>();

  private _filters: FilterInterface[] = [];
  @Input() set filters(value: FilterInterface[]) {
    this._filters = value ?? [];
    this.hasChild = this.filters.every(item => !!item.filterAssigns.length);
  };

  get filters() {
    return this._filters;
  }

  private subscriptions: Subscription = new Subscription();

  openFilters: number[] = [];
  hasChild: boolean;

  constructor(
    private filterService: FilterService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      this.route.queryParams.subscribe(params => {
        this.activateFilters(params);
      })
    );
  }

  private activateFilters(params: Params) {
    const filteredParams = Object.keys(params);
    this.filters.forEach(filter => filter.filterAssigns.forEach(filterAssigns => {
      // reset any checked states that might be left while hitting browsers back button
      filterAssigns.filterAssignValues.forEach(filterAssign => {
        filterAssign.status = false;
        }
      )

      if (filteredParams.includes(filterAssigns.property)) {
        if (!this.openFilters.includes(filterAssigns.id)) {
          this.openFilters.push(filterAssigns.id);
        }

        filterAssigns.filterAssignValues.forEach(filterAssign => {
          if (Array.isArray(params[filterAssigns.property])) {
            filterAssign.status =  params[filterAssigns.property].find(item => item === filterAssign.value) ? true : false;
          } else {
            filterAssign.status =  params[filterAssigns.property] === filterAssign.value ? true : false;
          }
          }
        )
      }
    }
    ));
  }

  onOpenFilterBody(filterAssign: number) {
    this.openFilters.includes(filterAssign)
      ? this.openFilters.splice(this.openFilters.findIndex(id => filterAssign === id), 1)
      : this.openFilters.push(filterAssign);
    this.toggleFilter.emit();
  }

  /**
   * Update filter reference without creating new instance
   * @param {FilterAssign} filterAssign
   */
  onClear(filterAssign: FilterAssign) {
    filterAssign.filterAssignValues.map(value => {
      value.status = false;
    });
    this.filterService.applyQueryParam(filterAssign);
  }

  onCheckMarkChange(status: boolean, filterAssignValue: FilterAssignValue, filterAssign: FilterAssign) {
    filterAssignValue.status = status;
    this.filterService.applyQueryParam(filterAssign);
  }

  hasCheckedFilter(filterAssign: FilterAssign) {
    return filterAssign.filterAssignValues.filter(value => value.status).length;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
