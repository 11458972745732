import { Injectable } from '@angular/core';
import { NotificationInterface } from '../../core/models/notification.model';
import { Router } from '@angular/router';
import { InquiriesRoutePath } from '../../core/enums/route-types.enum';
import { ClaimPreviewTabs } from '../../inquiries/claim-preview/claim-preview.component';

@Injectable({
  providedIn: 'root',
})
export class ClaimNotificationOpenerService {
  constructor(
    private router: Router
  ) {}

  open(notification: NotificationInterface) {
    this.router.navigate(['/', InquiriesRoutePath.ROOT, InquiriesRoutePath.CLAIMS, notification.objectId, ClaimPreviewTabs.COMMUNICATION]);
  }
}
