<app-generic-modal [closable]="true" bodyClasses="p-0 bg-white d-flex flex-column"
  cssClasses="d-flex flex-column vh-100">
  <ng-template appModalHeader>
    <div class="container-fluid p-0">
      <div class="row g-0">
        <div class="col">
          <div class="w-25 d-flex align-items-center">
            <h1 class="m-0">{{ item.title }}</h1>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template appModalBody>
    <div class="d-flex flex-column h-100">
      <div class="bg-white row g-0 border-bottom">
        <div class="col">
          <div class="d-flex justify-content-between px-2">
            <ul class="nav nav-pills d-flex pe-3 m-0 me-2">
              <li class="nav-item cursor-pointer" (click)="switchTab(tabs.REPORT)">
                <span class="nav-link d-flex gap-1" [ngClass]="{'active': activeTab === tabs.REPORT}">
                  <i class="icon ni-clipboard"></i>
                  {{ "INQUIRIES.CLAIMS.PREVIEW.TABS.REPORT" | translate }}
                </span>
              </li>
              <li class="nav-item cursor-pointer" (click)="switchTab(tabs.DELIVERY_INFORMATION)">
                <span class="nav-link d-flex gap-1" [ngClass]="{'active': activeTab === tabs.DELIVERY_INFORMATION}">
                  <i class="icon ni-shipping"></i>
                  {{ "INQUIRIES.CLAIMS.PREVIEW.TABS.DELIVERY_INFORMATION" | translate }}
                </span>
              </li>
              <li class="nav-item cursor-pointer" (click)="switchTab(tabs.COMMUNICATION)">
                <span class="nav-link d-flex gap-1" [ngClass]="{'active': activeTab === tabs.COMMUNICATION}">
                  <i class="icon ni-chat-square-plain"></i>
                  {{ "INQUIRIES.CLAIMS.PREVIEW.TABS.COMMUNICATION" | translate }}
                </span>
              </li>
            </ul>
            <div class="d-flex align-items-center px-3 gap-4">
              <div>{{ "INQUIRIES.CLAIMS.PREVIEW.TABS.SO" | translate }}: <span class="color-black fw-bold">{{
                  item.orderNum }}</span></div>
              <div *ngIf="item.purchaseOrderNum">{{ "INQUIRIES.CLAIMS.PREVIEW.TABS.PO" | translate }}: <span class="color-black fw-bold">{{
                  item.purchaseOrderNum }}</span></div>
              <div *ngIf="item.jiraIssueKey">{{ "INQUIRIES.CLAIMS.PREVIEW.TABS.ID" | translate }}: <span class="color-black fw-bold">{{
                  item.jiraIssueKey }}</span></div>
              <app-claim-status [status]="item.status"></app-claim-status>
            </div>
          </div>
        </div>
      </div>
      <div class="tab-content position-relative pt-0 h-100 flex-grow overflow-auto">
        <ng-container [ngSwitch]="activeTab">
          <div class="pb-4 mb-2" *ngSwitchCase="tabs.REPORT">
            <div *ngFor="let claimType of item.claimTypes; let i = index" class="w-100 text-grey bg-white">
              <div class="px-4"
                [class.border-bottom]="!expandedTypes.includes(claimType.type) || 1" id="headingOne-{{ claimType.type }}"
                >
                <div class="d-flex align-items-center py-3 cursor-pointer mx-4"
                  [class.border-bottom]="expandedTypes.includes(claimType.type)"
                  (click)="onTypeToggle(claimType.type)"
                >
                  <span class="h3 text-truncate overflow-hidden mb-0"
                    [class.color-gray-900]="expandedTypes.includes(claimType.type)"
                    [class.color-gray-600]="!expandedTypes.includes(claimType.type)">
                    {{ typesTranslationMap[claimType.type] | translate }}
                  </span>

                  <i class="ms-2 icon color-gray"
                    [ngClass]="{ 'ni-expand-less': expandedTypes.includes(claimType.type), 'ni-expand-more': !expandedTypes.includes(claimType.type) }"></i>
                </div>

                <div class="mx-4" *ngFor="let claimTypeItem of claimType.claimTypeItems; let i = index">
                  <div *ngIf="expandedTypes.includes(claimType.type)">
                    <div
                      class="d-flex py-2"
                      [class.border-bottom]="countDifferentAttachmentTypes(claimType) > 0"
                    >
                      <div class="w-50">
                        <div class="d-flex pe-2">
                          <div class="py-2 pe-2" [class.w-50]="claimItemQuantity(claimTypeItem)"
                            [class.w-100]="!claimItemQuantity(claimTypeItem)">
                            <label class="mb-3" [for]="'productCode-' + i">
                              {{ 'INQUIRIES.CLAIMS.NEW.PRODUCT_CODE' | translate }} *
                            </label>
                            <div class="color-gray-900 fw-bold">{{ claimTypeItem.productCode }}</div>
                          </div>
                          <div *ngIf="claimItemQuantity(claimTypeItem)" class="py-2 ps-2 w-50">
                            <label class="mb-3" [for]="'quantity-' + i">
                              {{ 'INQUIRIES.CLAIMS.NEW.QUANTITY' | translate }} *
                            </label>
                            <div class="color-gray-900 fw-bold">{{ claimItemQuantity(claimTypeItem) }}</div>
                          </div>
                        </div>
                      </div>
                      <div class="w-50 pt-2">
                        <div class="ps-1">
                          <label class="mb-3" [for]="'description-' + i">
                            {{ 'INQUIRIES.CLAIMS.NEW.DESCRIPTION' | translate }} *
                          </label>
                          <div class="d-flex gap-4">
                            <div class="flex-grow-1">
                              <p class="color-gray-900 mb-0 text-break--all">{{ claimTypeItem.description }}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div *ngIf="expandedTypes.includes(claimType.type) && countDifferentAttachmentTypes(claimType) > 0" class="d-flex p-4">
                  <div
                    [ngClass]="countDifferentAttachmentTypes(claimType) === 2 ? 'w-50' : 'w-100'">
                    <div class="d-flex flex-wrap gap-1 position-relative">
                      <ng-container
                        *ngFor="let attachment of filterLeftPartAttachments(claimType); let first = first">
                        <label *ngIf="first" class="form-label mb-3 w-100">
                          {{ uploadedFileTypesTranslationMap[attachment.type] | translate }}
                        </label>

                        <div class="d-inline-flex">
                          <app-file-preview [file]="attachment.file"></app-file-preview>
                        </div>
                      </ng-container>
                    </div>
                  </div>

                  <div
                    *ngIf="countDifferentAttachmentTypes(claimType) === 2"
                    class="w-50"
                  >
                    <div class="d-flex flex-wrap gap-1 position-relative">
                      <ng-container
                        *ngFor="let attachment of filterRightPartAttachments(claimType); let first = first">
                        <label *ngIf="first" class="form-label mb-3 w-100">
                          {{ uploadedFileTypesTranslationMap[attachment.type] | translate }}
                        </label>

                        <div class="d-inline-flex">
                          <app-file-preview [file]="attachment.file"></app-file-preview>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="bg-white row p-4 mb-2" *ngSwitchCase="tabs.DELIVERY_INFORMATION">
            <div class="col-12 col-md-6 offset-md-3">
              <h3 class="mb-2" *ngIf="item.address?.deliveryAddressee">{{ item.address?.deliveryAddressee }}</h3>
              <p class="mb-0" *ngIf="fullDeliveryAddress">{{ fullDeliveryAddress }}</p>
              <p class="mb-0" *ngIf="item.address?.contactWorkingHours">{{ item.address?.contactWorkingHours }}
              </p>

              <h3 class="mb-2 mt-4">{{ 'ADDRESS.FORM.CONTACT_PERSON' | translate }}</h3>
              <p class="mb-0" *ngIf="item.address?.contactPerson">{{ item.address?.contactPerson }}</p>
              <p class="mb-0" *ngIf="item.address?.email">{{ item.address?.email }}</p>
              <p class="mb-0" *ngIf="item.address?.phone">{{ item.address?.phone }}</p>
            </div>
          </div>

          <app-claims-communication-tab *ngSwitchCase="tabs.COMMUNICATION"
            [claimId]="item.id"></app-claims-communication-tab>

        </ng-container>
      </div>
    </div>
  </ng-template>
</app-generic-modal>

<div #filePreviewTemplate class="d-none">
  <div class="dz-preview dz-file-preview bg-gray-200 position-relative deep-hover-d-flex">
    <img class="preview-img" />
    <div class="position-absolute bottom-0 start-0 end-0 px-2 py-1 preview-file-name">
      <div class="dz-details text-break--all">
        <span data-dz-name></span>
      </div>
    </div>
  </div>
</div>
