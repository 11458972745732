import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-products-list-item-skeleton',
  templateUrl: './products-list-item-skeleton.component.html',
  styleUrls: ['./products-list-item-skeleton.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductsListItemSkeletonComponent {
  products = Array(3);
  rows = Array(5);
}
