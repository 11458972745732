import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavbarElementInterface } from '../../tree/navbar-elements';
import { CatalogueRouteType } from '../../enums/route-types.enum';
import { Observable, of, ReplaySubject, Subscription } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { TreeService } from '../../tree/tree.service';
import { CategoryPathInterface, SearchPathInterface } from '../../models/category-path.model';
import { QueryParams } from '../../enums/query-params.enum';
import { AuthService } from '../../../auth/auth.service';

export interface CataloguePathInterface {
  parent: CategoryPathInterface | SearchPathInterface;
  child?: CategoryPathInterface;
}

@Injectable()
export class CataloguePathService implements OnDestroy {
  private cataloguePath$: ReplaySubject<CataloguePathInterface> = new ReplaySubject<CataloguePathInterface>(1);
  private cataloguePath: CataloguePathInterface;
  private catalog: CategoryPathInterface[] = [];

  private subscription = new Subscription();

  constructor(private router: Router, private route: ActivatedRoute, private treeService: TreeService, private authService: AuthService) {
    this.subscription.add(
      this.route.queryParams.pipe(
        filter(params => params[QueryParams.TERM])
      ).subscribe(({ term }) => {
        this.select({
          parent: { name: term },
        });
      })
    );

    this.subscription.add(
      this.treeService.getState().pipe(
        map((navigationState): {
          parent: NavbarElementInterface;
          child: NavbarElementInterface;
        } => {
          return { parent: navigationState.first, child: navigationState.second };
        }),
        filter(({ parent, child }) => !!(parent && child)),
        switchMap(({ parent, child }) => {
          if (!this.authService.getRealUser()) {
            return of(null);
          }

          if (child.id in CatalogueRouteType && this.authService.getRealUser()) {
            return this.treeService.getCatalogue(child.id);
          }

          return of(null);
        }),
        filter(categories => !!categories)
      )
      .subscribe(categories => {
        const productsRoute = this.route.snapshot;
        this.catalog = categories;

        if (productsRoute) {
          const {slug, childSlug} = productsRoute.params;

          if (this.doesMatchCurrentState(slug, childSlug)) {
            return;
          }

          this.select(this.findCategoriesInCatalog(slug, childSlug));
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getCataloguePath$(): Observable<CataloguePathInterface> {
    return this.cataloguePath$;
  }

  getCurrentCataloguePath(): CataloguePathInterface {
    return this.cataloguePath;
  }

  select(cataloguePath: CataloguePathInterface) {
    this.cataloguePath = cataloguePath;
    this.cataloguePath$.next(cataloguePath);
  }

  private findCategoriesInCatalog(slug: string, childSlug?: string): CataloguePathInterface {
    const category = this.catalog.find(cat => cat.slug === slug);
    let childCategory = null;

    if (childSlug && category) {
      childCategory = category.children.find(cat => cat.slug === childSlug);
    }

    return { parent: category, child: childCategory };
  }

  doesMatchCurrentState(slug: string, childSlug?: string): boolean {
    const currentState = this.getCurrentCataloguePath();

    if (childSlug) {
      return currentState?.child?.slug === childSlug;
    }

    return currentState?.parent?.slug === slug;
  }
}
