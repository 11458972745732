import { ComponentRef, Injectable, ViewContainerRef } from '@angular/core';
import { SimpleArticlePropertiesConfiguratorComponentFactoryService } from '../simple-article-properties-configurator/simple-article-properties-configurator-component-factory.service';
import { TransformedResponseInterface } from '../../core/models/configurator.model';

@Injectable({
  providedIn: 'root',
})
export class PropertiesConfiguratorComponentFactoryService {
  constructor(
    private simpleArticlePropertiesConfiguratorComponentFactory: SimpleArticlePropertiesConfiguratorComponentFactoryService,
  ) {}

  create(element: ViewContainerRef, data: TransformedResponseInterface): ComponentRef<any> {
    return this.simpleArticlePropertiesConfiguratorComponentFactory.create(element, data);
  }
}
