import { Injectable } from '@angular/core';
import { ModelViewer } from './model-viewer';
import { BehaviorSubject, Subject } from 'rxjs';
import { ArticleRef, ViewerRef } from './model-viewer.component';

export interface ModelViewerRerendererSubjectInterface {
  shouldRerender: boolean;
}

@Injectable()
export class ModelViewerService {
  viewerRef$: BehaviorSubject<ViewerRef> = new BehaviorSubject<ViewerRef>(null);

  constructor() {}

  create(server, session, item, element, assetsPath?) {
    return new ModelViewer(server, session, item, { viewerElement: element, assetsPath: assetsPath });
  }
}
