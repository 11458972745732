import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PriceListCurrencyComponent} from './pricelist-currency/pricelist-currency.component';
import {PriceListTitleComponent} from './pricelist-title/pricelist-title.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [PriceListCurrencyComponent, PriceListTitleComponent],
  exports: [PriceListCurrencyComponent, PriceListTitleComponent]
})
export class PriceListDetailsModule {
}
