import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CheckMarkComponent} from './check-mark/check-mark.component';
import {FormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule
  ],
  declarations: [CheckMarkComponent],
  exports: [CheckMarkComponent]
})
export class CheckMarkModule {
}
