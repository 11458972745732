import { Component, EventEmitter, Input, OnInit, Output, OnChanges } from '@angular/core';
import { LanguageStoreHandlerService } from '../../core/services/language/language-store-handler.service';
import { UuidGenerator } from '../../core/util/uuid.generator';
import { ObjectWithTranslationsInterface, OptionValueModel } from '../../orders/create-document-modal/document-template/option-value.model';

@Component({
  selector: 'app-ui-elements-form-drop-down',
  templateUrl: './form-drop-down.component.html'
})
export class FormDropDownComponent implements OnInit, OnChanges {
  internalValue: any;
  @Input() values: any[];
  @Input() selectedValue?;
  @Input() disabled = false;
  @Input() groups = [];
  @Input() selectedGroup = "";
  @Input() groupLabel = '';
  @Input() invalid: boolean;
  @Input() id = UuidGenerator.uuid();
  @Input() title?: string;
  @Input() labelField?: string; // used as property to show selected value title
  @Input() valueField = 'id'; // used as object key identifier, for example find specific value by bindValue property

  @Output() onChange = new EventEmitter<any>();

  constructor(
    private languageService: LanguageStoreHandlerService
  ) {}

  ngOnInit() {
    this.updateSelectedValues();
  }

  ngOnChanges(changes: any) {
    this.updateSelectedValues();
  }

  onSelect(value: string) {
    if (this.disabled) {
      return;
    }

    this.internalValue = [value];

    // check if value is in array form
    if (value !== ',') {
      const splitValue = value.split(',');
      if (Array.isArray(splitValue) && splitValue.length > 1) {
        this.onSelectInGroup(splitValue[0], splitValue[1]);
        return;
      }
    }

    const filtered = this.values.find(i => i[this.valueField].toString() === value);
    this.onChange.emit(filtered);
  }

  private onSelectInGroup(value, key) {
    const filtered = this.values.find(i => i[this.valueField].toString() === value);

    if (!filtered) {
      return;
    }

    const freshValue = { ...filtered, _selectedGroup: key };
    this.onChange.emit(freshValue);
  }

  /**
   * This will try to get translation from valueObject.translations array if it exists
   *
   * @param valueObject object
   * @param key string
   */
  getTranslatedValue(valueObject: ObjectWithTranslationsInterface) {
    if (!valueObject.translations || valueObject.translations === undefined) {
      return valueObject[this.groupLabel];
    }

    let result = '';
    this.languageService.getCurrentAsObservable().subscribe(language => {
      const filteredTranslations = valueObject.translations.filter(item => item.locale === language.short);
      result = filteredTranslations[0][this.groupLabel];
    });

    return result;
  }

  private updateSelectedValues() {
    const valParts = [];
    if (this.selectedGroup?.length) {
      if (this.selectedValue !== undefined && this.selectedValue !== null) {
        valParts.push(this.selectedValue[this.valueField]);
      }

      valParts.push(this.selectedGroup);
    } else if (this.selectedValue !== undefined && this.selectedValue && this.selectedValue[this.valueField]) {
      valParts.push(this.selectedValue[this.valueField]);
    } else {
      // this is used when selectedValue is passed as plain value instead of object
      valParts.push(this.selectedValue);
    }

    this.internalValue = [valParts.join(',')];
  }
}
