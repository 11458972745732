import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TextFieldThemeTypes } from '../../../../ui-elements/text-field/text-field/theme-types.enum';
import { ClientService } from '../../../../core/services/client/client.service';
import { ClientInterface } from '../../../../core/models/client.model';
import { LoaderComponent } from '../../../../ui-elements/loader/loader.component';
import { CountryInterface } from '../../../../core/models/country.model';
import { TranslateService } from '@ngx-translate/core';
import { ClientModelInstanceChangeEventInterface } from '../../../../client/client-form/client-form.component';
import { ToastService } from '../../../../ui-elements/toast/toast.service';
import { CountryService } from '../../../../core/services/country/country.service';

@Component({
  selector: 'app-user-settings-modal-edit',
  templateUrl: './edit-modal.component.html',
})
export class ClientEditModalComponent implements OnInit {
  @ViewChild('loader') loader: LoaderComponent;

  @Input() client: ClientInterface;
  @Output() updated: EventEmitter<boolean | null | undefined> = new EventEmitter<boolean | null | undefined>();

  textFieldThemeTypes = TextFieldThemeTypes;
  countries: CountryInterface[] = [];
  isFormValid: boolean;

  clientId: number;

  constructor(
    private clientService: ClientService,
    private activeModal: NgbActiveModal,
    private toastService: ToastService,
    private translator: TranslateService,
    private countryService: CountryService,
  ) {}

  ngOnInit() {
    this.clientId = this.client.id;
    this.countryService.all().subscribe(countries => {
      this.countries = countries;
    });
  }

  onCancel() {
    this.activeModal.dismiss('click');
  }

  onSubmit() {
    this.loader.show();
    delete this.client.id;
    this.clientService.update(this.clientId, this.client).subscribe(
      result => {
        this.translator.get('SETTINGS.SUCCESS').subscribe(translation => {
          this.toastService.success(translation);
          this.loader.hide();
          this.updated.emit();
          this.activeModal.dismiss('success');
        });
      },
      err => {
        this.loader.hide();
      }
    );
  }

  onObjectChanged({ client, valid }: ClientModelInstanceChangeEventInterface) {
    this.isFormValid = valid;
    this.client = { ...this.client, ...client };
  }
}
