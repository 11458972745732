import {Component, OnInit, ViewChild} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {EditModalComponent} from './edit-modal/edit-modal.component';
import {DeleteModalComponent} from './delete-modal/delete-modal.component';
import {AddressService} from '../../../core/services/address/address.service';
import {LoaderComponent} from '../../../ui-elements/loader/loader.component';
import {AddressInterface} from '../../../core/models/address.model';
import {GenericModalTypes} from '../../../ui-elements/generic-modal/generic-modal-types';
import { CountryService } from '../../../core/services/country/country.service';

@Component({
  selector: 'app-user-settings-shipping-addresses',
  templateUrl: './shipping-addresses.component.html',
})
export class ShippingAddressesComponent implements OnInit {
  @ViewChild('loader', { static: true }) loader: LoaderComponent;

  addresses: AddressInterface[];

  constructor(
    private modalService: NgbModal,
    private addressService: AddressService,
    private countryService: CountryService
  ) {}

  ngOnInit() {
    this.onRefresh();
    this.countryService.all().subscribe();
  }

  onRefresh() {
    this.loader.show();

    this.addressService.all(true).noCache().subscribe({
      next: (result) => {
        this.addresses = result;
        this.loader.hide();
      },
      error: err => {
        console.log(err);
        this.loader.hide();
      },
    });
  }

  onEdit(address) {
    const modalRef = this.modalService.open(EditModalComponent, {windowClass: GenericModalTypes.GREEN, size: 'sm', centered: true});
    modalRef.componentInstance.address = address;
    modalRef.componentInstance.updated.subscribe(() => {
      this.onRefresh();
    });
  }

  onDelete(address) {
    const modalRef = this.modalService.open(DeleteModalComponent, {windowClass: GenericModalTypes.ORANGE, size: 'sm', centered: true});
    modalRef.componentInstance.address = address;
    modalRef.componentInstance.deleted.subscribe(() => {
      this.onRefresh();
    });
  }

}
