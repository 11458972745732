export enum TextFieldThemeTypes {
  DEFAULT = 'input__theme--default',
  NO_THEME = 'input__theme--empty',
  INLINE = 'input__theme--inline',
  WITH_ACTIONS = 'input__theme--with-actions',
  TEXTAREA_INLINE = 'input__theme--inline input__theme--textarea',
  TEXTAREA = 'input__theme--textarea',
  NEW = 'input__theme--new',
  TOOLTIP_ERROR = 'input__theme--tooltip-error',
}
