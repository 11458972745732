<div
  class="migration-issue-container w-100 ps-3 pb-2 pe-2"
  *ngIf="!hideMigrationIssues && !acceptedMigrationsMap.has(property.configuratorId+'_'+property.originalName) && (to.length || from.length)"
>
  <div class="d-flex bg-gray-200 rounded-1 align-items-center">
    <div class="d-flex flex-column migration-tooltip-box p-2"
      *ngIf="migrationTooltipText"
    >
      <button
        class="btn btn-small d-flex p-1 btn-white align-items-center deep-hover-d-block s-deep-hover-d-none hover-bg-warning"
        (click)="onMarkChangeAsAccepted($event, property)"
        [ngbTooltip]="'CONFIGURATOR_MODAL.ARTICLE_MIGRATION.CLICK_TO_ACCEPT' | translate"
        triggers="mouseenter:mouseleave" container="body" placement="top" popoverClass="shadow"
      >
        <i class="deep-hover d-none icon ni-check text-white"></i>

        <i class="s-deep-hover icon ni-new-outline text-warning" *ngIf="!from.length"></i>
        <i *ngIf="from.length && to.length" class="s-deep-hover icon ni-loop text-warning"></i>
        <i *ngIf="from.length && !to.length" class="s-deep-hover icon ni-cancel-block text-warning"></i>
      </button>
    </div>
    <div class="ps-2 d-flex flex-column flex-grow-1" [ngbTooltip]="migrationTooltipText"
      triggers="mouseenter:mouseleave" container="body" placement="top" popoverClass="shadow">
      <div class="py-2">
        <h4 *ngIf="from" class="small m-0 text-gray">{{ from }}</h4>
        <h4 *ngIf="to" class="small text-black m-0">{{ to }}</h4>
      </div>
    </div>
  </div>
</div>
