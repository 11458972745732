import { Injectable, forwardRef } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable } from 'rxjs';
import { parse as parseCookie } from 'cookie';
import { environment } from '../../../environments/environment';

export const WITH_XDEBUG_INTERCEPTOR_PROVIDER = {
  provide: HTTP_INTERCEPTORS,
  useClass: forwardRef(() => WithXdebugInterceptor),
  multi: true,
};

@Injectable()
export class WithXdebugInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const cookies = parseCookie(document.cookie);

    if (typeof cookies.XDEBUG_SESSION !== 'undefined') {
      const params = {
        XDEBUG_SESSION: environment.xdebugKey,
      };
      request = request.clone({ setParams: params });
    }

    return next.handle(request);
  }
}
