import { Inject, Injectable, Injector } from '@angular/core';
import { RedirectInterface } from './model';
import { Observable } from 'rxjs';
import { ROLE_REDIRECTOR_ROLES, ROLE_REDIRECTOR_REDIRECTS } from './injection.tokens';

@Injectable({
  providedIn: 'root',
})
export class RedirectorService {
  constructor(
    @Inject(ROLE_REDIRECTOR_REDIRECTS) private redirects: RedirectInterface[],
    @Inject(ROLE_REDIRECTOR_ROLES) private roles: string[],
    private injector: Injector
  ) {}

  resolveRouteParams(role: string): Observable<any[] | boolean> {
    return new Observable<any[] | boolean>(observer => {
      if (role && this.roles.includes(role)) {
        const foundRedirect: RedirectInterface = this.redirects.find((redirect: RedirectInterface) => {
          return redirect.roles.includes(role);
        });

        if (foundRedirect) {
          const deps = foundRedirect.deps
            ? foundRedirect.deps.reduce((carry, dep) => {
                carry.push(this.injector.get(dep));
                return carry;
              }, [])
            : [];

          foundRedirect.redirect(...deps).subscribe((routeParams: any[]) => {
            observer.next(routeParams);
            observer.complete();
          });
        } else {
          observer.error(`There is no way to handle this ${role}`);
        }
      } else {
        observer.error(`There is no way to handle this ${role}`);
      }
    });
  }
}
