import {Component, Input} from '@angular/core';
import {BlockPageFileInterface} from '../block-page.interface';

export interface DownloadsBlockInterface {
  name: string;
  id: number;
  files: Array<any>;
}

@Component({
  selector: 'app-catalogue-downloads',
  templateUrl: './downloads.component.html'
})
export class DownloadsComponent {
  @Input() set filesBlocks(files: BlockPageFileInterface[]) {
    this.downloadBlocks = this.groupFilesByTypes(files);
  };

  downloadBlocks: DownloadsBlockInterface[] = [];

  groupFilesByTypes(filesBlocks) {
    filesBlocks = filesBlocks.filter((block) => {
      return block.file != null;
    });

    return filesBlocks.reduce((prev, cur) => {
      const findBlock = prev.find((block) => block.id === cur.type.id);
      if (!findBlock) {
        prev.push({name: cur.type.name, id: cur.type.id, files: [cur.file]});
      } else {
        findBlock.files.push(cur.file);
      }
      return prev;
    }, []);
  }
}
