import {Directive, Input, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {UserRole} from '../../../core/enums/user-role.enum';
import {UserService} from '../../../core/services/user/user.service';
import {map} from 'rxjs/operators';

@Directive({
  selector: '[appVisibleForUserRole]'
})
export class VisibleForUserRoleDirective implements OnInit {
  @Input() appVisibleForUserRole: UserRole[];

  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private userService: UserService) {
  }

  ngOnInit() {
    this.userService.fromStorage().pipe(map((user) => user.role.name)).subscribe((role: UserRole) => {
        if (this.appVisibleForUserRole.includes(role)) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
          this.viewContainer.clear();
        }
      }
    );
  }
}
