import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OrdersService } from '../../../orders.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, Subject, zip } from 'rxjs';
import { OrderArticleService } from '../../../../core/services/order-article/order-article.service';
import { ExtraItemsService } from '../../order-articles-list/components/extra-row/extra-items.service';
import { ExtraListElementInterface, ExtraListElementRowInterface } from '../../order-articles-list/components/extra-row/extra-items.model';
import { ExtraListElementsTypes } from '../../order-articles-list/components/extra-row/extra-row-types.enum';
import { OrderArticlesListRow, OrderArticlesListRowInterface, OrderArticlesListRowItemInterface, RowTypes } from '../../order-articles-list.interface';
import { FullOrderArticleInterface } from '../../../../core/models/full-order-article.model';
import { CatalogueType } from '../../../../core/enums/catalogue-type.enum';
import { updateOnlyRowsPositionsObject } from '../../../view-component.model';
import { RemoveModalComponent } from '../../order-articles-list/remove-modal/remove-modal.component';
import { GenericModalTypes } from '../../../../ui-elements/generic-modal/generic-modal-types';
import { OrderArticleType, OrderArticleUpdatePositionInterface } from '../../../../core/models/order-article.model';
import { SelectedRowsService } from '../selected-rows/selected-rows.service';
import {RemoveActionType} from "../../../can-modify-product-view.component";
import { ToastService } from '../../../../ui-elements/toast/toast.service';
import { OrderArticleMaterialInterface } from '../../../../ui-elements/order-article-material-input/order-article-material-interface';

export function sortByPosition(prev, next) {
  /**
   * Tries to sort rows by using position property.
   * If positions are the same, uses createdAtTimestamp to sort rows.
   * Newly added orderArticles will have the same position of 1, but their creation timestamps will be different.
   */
  const result = prev.position - next.position;
  return result === 0 ? next.createdAtTimestamp - prev.createdAtTimestamp : result;
}

@Injectable()
export class OrderArticlesListService {
  updateRowsPositionEvent: Subject<any> = new Subject();

  constructor(
    private orderArticleService: OrderArticleService,
    private extraListElementsService: ExtraItemsService,
    private modalService: NgbModal,
    private toastService: ToastService,
    private ordersService: OrdersService,
    private translator: TranslateService,
    private selectedRowsService: SelectedRowsService
  ) {}

  generateExtraRows(items: ExtraListElementInterface[]): ExtraListElementRowInterface[] {
    return items.reduce((prev, next: ExtraListElementInterface) => {
      prev.push({
        ...next,
        item: next.title,
        selected: false,
        rowType: next.type === ExtraListElementsTypes.GROUP ? RowTypes.GROUP : RowTypes.EXTRA,
        justCreated: next.justCreated || false,
        groupLevel: 0,
      });
      return prev;
    }, []);
  }

  generateRows(items: FullOrderArticleInterface[], order: number): OrderArticlesListRowInterface[] {
    return items.reduce((carry, { article, orderArticle }: FullOrderArticleInterface) => {
      const {
        code,
        price,
        totalPrice,
        configurationQuantity,
        quantity,
        weight,
        volume,
        width,
        depth,
        height,
        orderArticleMaterialsWithChildren,
        type,
        details,
        dimensions,
        id,
        pageBreak,
        createdAtTimestamp,
        discount,
        discountType,
        saleDiscount,
        totalSalePrice,
        salePrice,
        typeInformation,
        title,
        formattedTotalConfigurationPurchasePrice,
        formattedConfigurationPurchasePrice,
        formattedConfigurationPrice,
        formattedTotalConfigurationPrice,
        formattedConfigurationDiscount,
        formattedQuantityDiscount,
        formattedDiscount,
        formattedSalePrice,
        formattedSaleDiscount,
        formattedTotalPurchasePrice,
        formattedTotalPrice,
        formattedPurchasePrice,
        formattedPrice,
        formattedConfigurationSalePrice,
      } = orderArticle;

      let item: OrderArticlesListRowItemInterface = {
        system: orderArticle.system,
        category: orderArticle.category,
        code,
        image: orderArticle.img || (article ? article.articlePhoto : null),
        shortText: orderArticle.shortText,
        longText: orderArticle.longText,
        translations: orderArticle.translations,
      };

      if (!item.system && article) {
        const system = article.articleArticleCategories.find(({ articleCategory }) => articleCategory.type === CatalogueType.SYSTEM);
        if (system) {
          item = { ...item, system: system.articleCategory.name };
        }
      }

      if (!item.category && article) {
        const category = article.articleArticleCategories.find(({ articleCategory }) => articleCategory.type === CatalogueType.CATEGORY);
        if (category) {
          item = { ...item, category: category.articleCategory.name };
        }
      }

      const size = {
        weight,
        volume,
        width,
        depth,
        height,
        details,
        dimensions,
      };

      const materialsAndType: { type: string | number; materials: OrderArticleMaterialInterface[] } = { type: type, materials: orderArticleMaterialsWithChildren };
      if ([OrderArticleType.OTHER_SUPPLIER_ITEM, OrderArticleType.CUSTOM_ITEM_TYPE].includes(type)) {
        materialsAndType.type = typeInformation;
      }

      const { children, ...orderArticleWithoutChildren } = orderArticle;

      const additionalParts = [{ ...orderArticleWithoutChildren, position: 0 }, ...children];

      carry.push({
        item,
        size,
        price,
        totalPrice,
        configurationQuantity,
        quantity,
        materialsAndType,
        selected: false,
        id,
        originals: {
          article,
          orderArticle,
        },
        rowType: RowTypes.PRODUCT,
        position: orderArticle.position,
        pageBreak,
        additionalParts,
        createdAtTimestamp,
        discount,
        groupLevel: 0,
        discountType,
        saleDiscount,
        totalSalePrice,
        salePrice,
        order,
        customPrice: orderArticle.customPrice,
        type,
        title,
        formattedTotalConfigurationPurchasePrice,
        formattedConfigurationPurchasePrice,
        formattedConfigurationPrice,
        formattedTotalConfigurationPrice,
        formattedConfigurationDiscount,
        formattedQuantityDiscount,
        formattedDiscount,
        formattedSalePrice,
        formattedSaleDiscount,
        formattedTotalPurchasePrice,
        formattedTotalPrice,
        formattedPurchasePrice,
        formattedPrice,
        formattedConfigurationSalePrice,
        versionOutdated: orderArticle.versionOutdated,
        migrationStatus: orderArticle.orderArticleMigration ? orderArticle.orderArticleMigration.status : null,
      });

      return carry;
    }, []);
  }

  /**
   * Changes all rows positions by element index in array
   */
  recalculateRowsPositions(rows: OrderArticlesListRow[]): OrderArticlesListRow[] {
    return rows.map((row, index) => {
      return { ...row, position: index };
    });
  }

  /**
   * Sort rows and nested/grouped rows
   * @param {OrderArticlesListRow[]} rows
   * @returns {OrderArticlesListRow[]}
   */
  deepRowsSort(
    rows: OrderArticlesListRow[]
  ): OrderArticlesListRow[] {
    rows.sort(sortByPosition); // sort first level rows
    return rows.map(row => {
      if (row.rowType === RowTypes.GROUP){
        row.children.sort(sortByPosition);
        this.deepRowsSort(row.children);
      }
      return row;
    });
  }

  updateOnlyRowPositions(rows: OrderArticlesListRow[], recalculate = true): Observable<OrderArticlesListRow[]> {
    if (rows.length === 0) {
      return of([]);
    }

    return new Observable(observer => {
      const recalculatedRows = recalculate ? this.recalculateRowsPositions(rows) : rows;
      const { articles, extraElements } = {
        articles: updateOnlyRowsPositionsObject(recalculatedRows.filter(row => row.rowType === RowTypes.PRODUCT)),
        extraElements: updateOnlyRowsPositionsObject(
          recalculatedRows.filter(row => row.rowType === RowTypes.EXTRA || row.rowType === RowTypes.GROUP)
        ),
      };
      this.savePositions(articles, extraElements).subscribe(() => {
        observer.next(recalculatedRows);
        observer.complete();
      });
    });
  }

  savePositions(articles: OrderArticleUpdatePositionInterface[], extraItems: OrderArticleUpdatePositionInterface[]): Observable<any> {
    const observables = [];
    if (articles && articles.length) {
      observables.push(this.orderArticleService.updateMultiple(articles));
    }
    if (extraItems && extraItems.length) {
      observables.push(this.extraListElementsService.updateMultiple(extraItems));
    }
    return zip(...observables);
  }

  deleteRows(order: number, rowsToRemove, type?: RemoveActionType): Observable<boolean> {
    const { orderArticles, extraItems } = {
      orderArticles: rowsToRemove
        .filter(row => row.rowType === RowTypes.PRODUCT)
        .map(({ id }) => {
          return { id };
        }),
      extraItems: rowsToRemove
        .filter(row => row.rowType === RowTypes.GROUP || row.rowType === RowTypes.EXTRA)
        .map(({ id }) => {
          return { id };
        }),
    };

    // @TODO: Remove when everything is clear with NAR-3379
    // let itemsToRemoveType = extraItems.length > 1 ? 'ITEMS' : 'ITEM';
    //
    // if (orderArticles.length && !extraItems.length) {
    //   itemsToRemoveType = orderArticles.length > 1 ? 'PRODUCTS' : 'PRODUCT';
    // }
    //
    // if (orderArticles.length && extraItems.length) {
    //   itemsToRemoveType = 'ITEMS';
    // }

    return new Observable(observer => {
      const modalRef = this.modalService.open(RemoveModalComponent, {
        windowClass: GenericModalTypes.ORANGE,
        size: 'sm',
      });
      modalRef.componentInstance.removableItems = rowsToRemove;
      // modalRef.componentInstance.removableExtraItems = extraItems.length;
      modalRef.componentInstance.removeActionType = type;
      // modalRef.componentInstance.itemsToRemoveType = itemsToRemoveType;
      modalRef.componentInstance.cancel.subscribe(() => {
        observer.error();
        observer.complete();
      });
      modalRef.componentInstance.remove.subscribe(() => {
        const translationPostfix = rowsToRemove.length > 1 ? '.PLURAL' : '.SINGULAR';

        rowsToRemove.forEach(row => {
          this.selectedRowsService.onSelect(row, false);
        });

        if (extraItems.length) {
          this.extraListElementsService.deleteMultiple(extraItems).subscribe(() => {
            if (!orderArticles.length) {
              this.showDeleteSuccessToast(type, translationPostfix);
              observer.next();
              observer.complete();
              return;
            }

            this.orderArticleService.deleteMultiple(orderArticles).subscribe(() => {
              this.showDeleteSuccessToast(type, translationPostfix);
              observer.next();
              observer.complete();
            })
          });
        } else if (orderArticles.length) {
          this.orderArticleService.deleteMultiple(orderArticles).subscribe(() => {
            this.showDeleteSuccessToast(type, translationPostfix);
            observer.next();
            observer.complete();
          });
        } else {
          observer.next();
          observer.complete();
        }
      });
    });
  }

  private showDeleteSuccessToast(type: RemoveActionType,translationPostfix: string) {
    const translationKey = `${
      type === RemoveActionType.REMOVE
        ? 'ORDER_ARTICLES.SUCCESSFULLY_REMOVED_ORDER_ARTICLES'
        : 'ORDER_ARTICLES.SUCCESSFULLY_REMOVED_GROUP'
    }${translationPostfix}`;
    this.translator.get(translationKey).subscribe(translation => {
      this.toastService.success(translation);
    });
  }
}
