import { OrderInterface } from './order.model';
import { ConversationMessageType } from '../enums/messages-type.enum';
import { MessageStatus } from '../enums/message-status.enum';
import { UserInterface } from './user.model';
import { NotificationDetails } from './notification.model';
import { FileData } from './file-data.model';

export interface MessagesInterface {
  id?: number;
  order?: OrderInterface;
  title?: string;
  text?: string;
  files?: File[];
  timestamp?: number;
  firstName?: string;
  lastName?: string;
  messageType?: ConversationMessageType;
  status?: MessageStatus;
  user?: UserInterface | null;
  details?: NotificationDetails;
  detailsBeforeChanges?: DetailInterface[];
  detailsAfterChanges?: DetailInterface[];

  changedDetails: ChangedDetailsInterface[];
}

// temporary removed, was copied, still don't know if needed.
export class Messages implements Partial<MessagesInterface> {
  id: number = null;
  title: string = null;
  request: string = null;
}

// tslint:disable-next-line:no-empty-interface
export interface MessagesCreateInterface {
  text?: string;
}

// tslint:disable-next-line:no-empty-interface
export interface MessagesUpdateInterface extends MessagesCreateInterface {
  status?: number;
  title?: string;
}

export interface MessagesFilterInterface {
  types?: number[];
  request?: string;
  title?: string;
  price?: number;
  status?: number;
}

export interface MessagesSummaryInterface {
  orderId?: number;
  order?: any;
  name?: string;
  messages?: MessagesInterface;
  count?: number;
  status?: MessageStatus;
  message?: string;
  time?: number;
  type?: number;
}

export interface MarkAsSeenMessageInterface {
  id: number;
  status: number;
}

export enum MessageObjectType {
  ORDER,
}

export interface DetailInterface {
  name: string;
  value: string;
}

export interface ChangedDetailsInterface {
  changes: DetailInterface[];
  itemCode: string;
}

export enum ChangedFieldName {
  PURCHASE_DISCOUNT = 'PURCHASE_DISCOUNT',
  PURCHASE_PRICE = 'PURCHASE_PRICE',
}

export interface CommunicationMessageFileType {
  id: number;
  name: string;
}

export interface CommunicationMessageFileInterface extends FileData {
  type: CommunicationMessageFileType;
}

export interface CommunicationMessageInterface {
  id?: number;
  user: UserInterface | null;
  serviceDeskUserName?: string;
  serviceDeskUserPhoto?: string;
  text: string;
  dateTimestamp: number;
  files: CommunicationMessageFileInterface[];
}