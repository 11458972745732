<div class="thumbnail-view row px-3">
  <div class="col-3 col-3--custom-padding d-flex align-items-stretch" *ngFor="let system of groupedSystems">
    <div
      class="card border-0 rounded-0 p-0 w-100 cursor-pointer"
      *ngIf="system.articles?.length && system.articles[0]?.articleVariants?.length"
      (click)="
        !system.articles[0].unavailable && openConfiguratorModal(system, system.articles[0], system.articles[0].articleVariants[0])
      "
    >
      <div
        *ngIf="system.articles[0].articlePhoto; else noImage"
        class="card-article-photo w-100"
        [style.background-image]="'url(' + ((system.articles[0].articlePhoto | resizeImage: (hasFilters ? imageSizes.SIZE_271x223 : imageSizes.SIZE_331x272) | uncache | async) ?? '') + ')'"
      ></div>
      <ng-template #noImage>
        <div class="card-article-photo no-image"></div>
      </ng-template>
      <div class="card-body p-3">
        <h4 class="card-title">{{ system.system }}</h4>
        <div class="card-text small color-gray-700">
          {{ system.articles[0].longText }}
        </div>
      </div>
    </div>
  </div>
</div>
