import {Directive, HostListener, Input} from '@angular/core';
import {AUTO_DOWNLOAD_KEY} from './auto-download.model';

@Directive({
  selector: '[appAutoDownload]'
})
export class AutoDownloadDirective {
  @Input() saveData;
  @Input() exportCallback;


  constructor() {
  }

  // stop from going to autodownload page
  @HostListener('click', ['$event'])
  stopDefault(event) {
    if (event.button === 0 && !(event.ctrlKey || event.metaKey)) {
      event.preventDefault();
      this.exportCallback();
    }
  }

  // save needed data to storage, using mousedown instead of click to trigger this code on right click too
  @HostListener('mousedown', ['$event'])
  onclick(event) {
    if (!(event.button === 0 && !(event.ctrlKey || event.metaKey))) {
      localStorage.setItem(AUTO_DOWNLOAD_KEY, JSON.stringify(this.saveData));
    }
  }
}
