import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { OrderArticlesListRow } from '../../../../order-articles-list.interface';

@Injectable({
  providedIn: 'root',
})
export class OpenGroupsService {
  private openGroups$: BehaviorSubject<OrderArticlesListRow[]> = new BehaviorSubject<OrderArticlesListRow[]>([]);

  constructor() {}

  toggle(group: OrderArticlesListRow) {
    let openGroups = this.getOpenGroups();
    const index = openGroups.findIndex(findGroup => findGroup.id === group.id);
    if (index > -1) {
      openGroups.splice(index, 1);
    } else {
      openGroups = [...openGroups, group];
    }

    this.next(openGroups);
  }

  private next(groups: OrderArticlesListRow[]) {
    this.openGroups$.next(groups);
  }

  getOpenGroups(): OrderArticlesListRow[] {
    return this.openGroups$.getValue();
  }

  open(group: OrderArticlesListRow) {
    const openGroups = this.getOpenGroups();

    const index = openGroups.findIndex(findGroup => findGroup.id === group.id);
    if (index < 1) {
      this.next([...openGroups, group]);
      if (group.parent) {
        this.open(group.parent);
      }
    }
  }

  openMultipleGroups(groups: OrderArticlesListRow[]) {
    const closedGroups = groups.filter(group => this.getOpenGroups().findIndex(openGroup => openGroup.id === group.id) < 0);
    this.next([...this.getOpenGroups(), ...closedGroups]);
  }

  close(group: OrderArticlesListRow) {
    const openGroups = this.getOpenGroups();

    const index = openGroups.findIndex(findGroup => findGroup.id === group.id);
    if (index > -1) {
      openGroups.splice(index, 1);
    }
    this.next(openGroups);
  }

  closeAll() {
    this.next([]);
  }

  getOpenGroupsAsObservable(): Observable<OrderArticlesListRow[]> {
    return this.openGroups$.asObservable();
  }

  isOpen(group: OrderArticlesListRow): boolean {
    return (group && this.getOpenGroups().some(findGroup => findGroup.id === group.id));
  }
}
