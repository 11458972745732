import { Component, Input, OnInit } from '@angular/core';
import { UserInterface } from '../../../../core/models/user.model';
import { CommunicationMessageInterface, MessagesInterface } from '../../../../core/models/messages.model';
import { UserService } from '../../../../core/services/user/user.service';
import { ProjectReservationMessagesService } from '../../../project-reservation-messages.service';
import { ProjectReservationStatusEnum } from '../../../../core/models/project-reservation.model';
import { ChatHelperService } from '../../../../shared/services/chat-helper/chat-helper.service';

@Component({
  selector: 'app-project-reservation-communication',
  templateUrl: './project-reservation-communication.component.html',
  providers: [ProjectReservationMessagesService, ChatHelperService],
})
export class ProjectReservationCommunicationComponent implements OnInit {
  @Input() projectReservationId: number;
  @Input() projectReservationStatus: ProjectReservationStatusEnum;

  user?: UserInterface;
  messages: CommunicationMessageInterface[] = [];
  isLoading = true;
  showMessageField = true;

  constructor(
    private projectReservationMessagesService: ProjectReservationMessagesService,
    private userService: UserService,
    private chatHelperService: ChatHelperService,
  ) { }

  ngOnInit() {
    this.userService.fromStorage().subscribe((user: UserInterface) => {
      this.user = user;
    });

    this.projectReservationMessagesService.getMessages(this.projectReservationId).noCache().subscribe((response) => {
      this.messages = response.data;
      this.isLoading = false;
    });

    this.showMessageField = ![ProjectReservationStatusEnum.APPLIED, ProjectReservationStatusEnum.REJECTED, ProjectReservationStatusEnum.LOST].includes(this.projectReservationStatus);
  }

  onSend(message: Partial<MessagesInterface>) {
    this.chatHelperService.setIsSending(true);

    this.projectReservationMessagesService.sendMessage(this.projectReservationId, message).subscribe({
      next: ({ data }) => {
        this.messages = [...this.messages, data];
        this.chatHelperService.setIsSending(false);
      },
      error: (error) => {
        this.chatHelperService.parseValidationErrors(error);
        this.chatHelperService.setIsSending(false);
      },
    });
  }
}
