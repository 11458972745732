import {
  AfterContentInit,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UuidGenerator } from '../../core/util/uuid.generator';
import { FileUploadService } from './file-upload.service';
import { Subscription } from 'rxjs';
import { FileUploadPlaceholderDirective } from './directives/file-upload-placeholder/file-upload-placeholder.directive';
import { LanguageStoreHandlerService } from '../../core/services/language/language-store-handler.service';

export interface FallbackImagesInterface {
  default: string; // 1x
  half: string; // 1.5x
  double: string; // 2x
  triple: string; // 3x
}

export interface LabelsInterface {
  empty: string;
  selected?: string;
}

export type FileUploadData = { file: File; fileUrl: string } | null;

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit, OnChanges, AfterContentInit, OnDestroy {
  @ContentChild('content') content: ElementRef;

  @Input() fallback?: FallbackImagesInterface;
  @Input() inputName = UuidGenerator.uuid();
  @Input() labels?: LabelsInterface;
  @Input() acceptableTypes = 'image/*';
  @Input() fileUrl?: string;
  @Input() removable: boolean;
  @Input() disabled: boolean;
  @Input() inputClass = "d-flex align-items-center";
  @Input() previewClass = "d-flex align-items-center";
  @Input() imgClass = "w-100 img-fluid";
  @Input() showUploadLinkOnly = false;

  @Output() selected: EventEmitter<FileUploadData> = new EventEmitter();
  @Output() remove: EventEmitter<void> = new EventEmitter();

  subscriptions = new Subscription();
  file?: File;
  srcset: string;
  label?: string;
  noContentPassed = true;
  locale: string;
  imgSrc: string;

  constructor(private fileUploadService: FileUploadService, public languageService: LanguageStoreHandlerService) { }

  ngOnInit(): void {
    this.subscriptions.add(
      this.languageService.getCurrentAsObservable().subscribe(lang => {
        this.locale = lang.short;
      })
    );

    this.subscriptions.add(
      this.fileUploadService.resetObservable().subscribe(() => {
        this.checkIfContentIsPassed();
      })
    );

    this.imgSrc = this.fileUrl ?? this.fallback?.default;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.fallback && changes.fallback.currentValue) {
      const addSourceSet = (srcset: string, density: string, src?: string): string => {
        return src ? `${srcset} ${!!srcset ? ',' : ''} ${src} ${density}` : srcset;
      };

      let generated = addSourceSet('', '1x', this.fallback.default);
      generated = addSourceSet(generated, '2x', this.fallback.double);
      generated = addSourceSet(generated, '3x', this.fallback.triple);
      generated = addSourceSet(generated, '1.5x', this.fallback.half);

      this.srcset = generated;
      this.imgSrc = this.fallback.default;
    }

    if (changes.fileUrl){
      this.imgSrc = changes.fileUrl.currentValue;
    }

    if (changes.labels && changes.labels.currentValue) {
      this.updateLabel();
    }

    this.checkIfContentIsPassed();
  }

  ngAfterContentInit() {
    this.checkIfContentIsPassed();
  }

  onSelect(event) {
    if (this.disabled) {
      return;
    }

    if (event.target.files.length === 0) {
      this.selected.emit(null);
      return;
    }
    this.updateImagePreview(event.target.files[0]);
  }

  onDrop(file) {
    if (this.disabled) {
      return;
    }

    this.updateImagePreview(file);
  }

  updateImagePreview(file: File) {
    this.file = file;
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.fileUrl = e.target.result;
      this.imgSrc = this.fileUrl;
      this.selected.emit({ file: this.file, fileUrl: this.fileUrl });
      this.updateLabel();
    };

    reader.readAsDataURL(this.file);
  }

  updateLabel() {
    if (this.labels) {
      this.label = !!this.fileUrl ? this.labels.selected : this.labels.empty;
    }
  }

  private checkIfContentIsPassed() {
    this.noContentPassed = !this.content || (this.content && this.content.nativeElement.childNodes.length === 0);
  }

  onRemove() {
    if (this.disabled) {
      return;
    }

    this.file = null;
    this.remove.emit();
  }

  onActivateFileInput(event: MouseEvent) {
    event.preventDefault();
    document.getElementById(this.inputName).click();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
