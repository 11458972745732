import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ComponentsComponent } from './sale-coefficients/components/components.component';
import { ProductsComponent } from './sale-coefficients/products/products.component';
import { SaleCoefficientsComponent } from './sale-coefficients/sale-coefficients.component';
import { UserRouterType } from '../core/enums/route-types.enum';
import { SettingsComponent } from './settings/settings.component';
import { BasicsComponent } from './settings/basics/basics.component';
import { ProfileComponent } from './settings/profile/profile.component';
import { NotificationsComponent } from './settings/notifications/notifications.component';
import { DefaultsComponent } from './settings/defaults/defaults.component';
import { ShippingAddressesComponent } from './settings/shipping-addresses/shipping-addresses.component';
import { ClientsComponent } from './settings/clients/clients.component';
import { RouteByRolesGuard } from '../core/guards/route-by-roles.guard';
import { UserRole } from '../core/enums/user-role.enum';
import { RoleGuard } from '../core/guards/role.guard';
import { ModalProfileOpenerComponent } from './modal-profile-opener/modal-profile-opener.component';

const userRoutes: Routes = [
  {
    path: 'sale-coefficients',
    data: {
      id: UserRouterType.SALE_COEFFICIENTS,
      allowedRoles: [UserRole.ROLE_DEALER],
    },
    component: SaleCoefficientsComponent,
    canActivateChild: [RouteByRolesGuard],
    children: [
      {
        path: '',
        redirectTo: 'products',
        pathMatch: 'full',
      },
      {
        path: 'components',
        component: ComponentsComponent,
      },
      {
        path: 'products',
        component: ProductsComponent,
      },
    ],
  },
  { path: 'profile', component: ModalProfileOpenerComponent },
  {
    path: 'settings',
    data: { id: UserRouterType.SETTINGS },
    component: SettingsComponent,
    children: [
      { path: '', redirectTo: 'basics', pathMatch: 'full' },
      { path: 'basics', component: BasicsComponent },
      { path: 'profile', component: ProfileComponent },
      { path: 'notifications', component: NotificationsComponent },
      { path: 'defaults', component: DefaultsComponent },
      { path: 'shipping-addresses', component: ShippingAddressesComponent },
      {
        path: 'clients',
        component: ClientsComponent,
        canActivate: [RoleGuard],
        data: {
          allowedRoles: [UserRole.ROLE_PM_NARBUTAS, UserRole.ROLE_DEALER, UserRole.ROLE_ADMIN],
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(userRoutes)],
  exports: [RouterModule],
})
export class UserRoutingModule {}
