<div class="w-100" [ngClass]="cssClasses">
  <div *ngIf="hasHeader" class="modal-header" [ngClass]="headerClasses">
    <ng-container *ngIf="headerTemplate; else defaultHeader">
      <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
      <ng-container *ngIf="closable; then closeButtonBlock; else fakeFocusBlock"></ng-container>
    </ng-container>
  </div>
  <div class="modal-body" [ngClass]="bodyClasses">
    <ng-container *ngTemplateOutlet="bodyTemplate"></ng-container>
  </div>
  <div *ngIf="footerTemplate" class="modal-footer" [ngClass]="footerClasses">
    <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
  </div>
</div>

<ng-template #defaultHeader>
  <h2 *ngIf="heading?.length" class="m-0 me-4">
    {{ heading }}
  </h2>
  <p *ngIf="subHeading?.length" class="mt-3 me-4 mb-0 ms-0">
    {{ subHeading }}
  </p>
  <ng-container *ngIf="closable; then closeButtonBlock; else fakeFocusBlock"></ng-container>
</ng-template>

<ng-template #closeButtonBlock>
  <div class="position-absolute top-0 right-0 m-3">
    <app-ui-elements-close-button class="m-1" (click)="activeModal.dismiss('Cross click')"
    ></app-ui-elements-close-button>
  </div>
</ng-template>

<ng-template #fakeFocusBlock>
  <!-- This prevents form auto focusing first visible element of the modal -->
  <input class="d-none"/>
</ng-template>
