import { Component, ContentChild, Input, OnChanges, OnInit, SimpleChanges, TemplateRef } from '@angular/core';
import { VisibilityDecisionMakerService } from './visibility-decision-maker.service';
import { VisibilityByVariablesReplacementDirective } from './visibility-by-variables-replacement.directive';

@Component({
  selector: 'visibility-by-variables',
  templateUrl: './visibility-by-variables.component.html',
  styleUrls: ['./visibility-by-variables.component.scss'],
})
export class VisibilityByVariablesComponent implements OnInit, OnChanges {
  @ContentChild(VisibilityByVariablesReplacementDirective, { read: TemplateRef }) replacementTemplate;

  @Input() hideIf: string[];
  @Input() replacement?: string;

  hidden = false;

  constructor(private visibilityDecisionMaker: VisibilityDecisionMakerService) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hideIf) {
      this.hidden = this.visibilityDecisionMaker.shouldHide(this.hideIf);
    }
  }
}
