import MeshExtended from '../mesh/mesh-extended';
import ModelService from '../model/model-service';

export default class BoundingBox {
  public node: typeof ViewerDependencies.BabylonMesh;
  private nodes: typeof ViewerDependencies.BabylonMesh[];
  private bounds = {
    origin: {
      min: ViewerDependencies.Vector3,
      max: ViewerDependencies.Vector3,
    },
    scaled: {
      min: ViewerDependencies.Vector3,
      max: ViewerDependencies.Vector3,
    },
  };

  constructor(private mesh: MeshExtended) {
    this.create();
  }

  create() {
    this.nodes = ModelService.listVisibleMeshes(this.mesh.node);

    if (!this.nodes.length) {
      window.console.debug('Mesh without visible items', { mesh: this.mesh });
      return;
    }

    this.node = new ViewerDependencies.BabylonMesh('', this.mesh.scene);

    this.computeBounds();
    this.scale();
    this.center();

    const boundingInfo = new ViewerDependencies.BoundingInfo(this.bounds.scaled.min, this.bounds.scaled.max);
    this.node.setBoundingInfo(boundingInfo);
  }

  computeBounds() {
    let min = this.nodes[0].getBoundingInfo().boundingBox.minimumWorld;
    let max = this.nodes[0].getBoundingInfo().boundingBox.maximumWorld;

    for (const item of this.nodes) {
      const meshMin = item.getBoundingInfo().boundingBox.minimumWorld;
      const meshMax = item.getBoundingInfo().boundingBox.maximumWorld;

      min = ViewerDependencies.Vector3.Minimize(min, meshMin);
      max = ViewerDependencies.Vector3.Maximize(max, meshMax);
    }

    this.bounds.origin.min = min;
    this.bounds.origin.max = max;
  }

  scale(factor: number = 1.025) {
    this.bounds.scaled.min = this.bounds.origin.min.multiplyByFloats(1, 1, factor);
    this.bounds.scaled.max = this.bounds.origin.max.multiplyByFloats(factor, factor, 1);
  }

  center() {
    this.node.position.x -= (this.bounds.scaled.max.x - this.bounds.origin.max.x) / 2;
    this.node.position.y -= (this.bounds.scaled.max.y - this.bounds.origin.max.y) / 2;
    this.node.position.z -= (this.bounds.scaled.min.z - this.bounds.origin.min.z) / 2;
  }
}
