import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FullScreenModeService {
  private enabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {
  }

  toggleMode() {
    this.enabled$.getValue() ? this.changeModeStatus(false) : this.changeModeStatus(true);
  }

  enabledAsObservable(): Observable<boolean> {
    return this.enabled$.asObservable();
  }

  changeModeStatus(enabled: boolean) {
    this.enabled$.next(enabled);
  }

  getValue(): boolean {
    return this.enabled$.getValue();
  }
}

