export interface AutoDownloadInterface {
  type?: AutoDownloadType;
  idOfCategory?: number;
  format?: any;
  searchTerm?: string;
  filters?: any;
}

export enum AutoDownloadType {
  SEARCH,
  CATALOGUE
}

export const AUTO_DOWNLOAD_KEY = 'autoDownload';
