import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import {
  MigrationChangeInterface,
  MigrationLogEntryPropInterface,
  MigrationLogEntryPropsInterface
} from '../../core/models/configurator.model';
import { ConfigurationArticle } from '../configuration-article.model';
import { ConfiguratorModalService } from '../configurator-modal.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'migrations-sidebar-modal',
  templateUrl: './migrations-sidebar-modal.component.html',
  styleUrls: ['./migrations-sidebar-modal.component.scss']
})
export class MigrationsSidebarModalComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() migrationLog: MigrationChangeInterface[];
  @Input() configurationArticles: ConfigurationArticle[];
  @Input() nameToOriginalNameMap: Map<string, string>;
  @Input() configurationArticlesWithMigration: string[];
  @Input() lastUsedProperty: string;
  @Input() scrollToProperty = false;

  @Output() acceptChanges = new EventEmitter<void>();
  @Output() selectArticle = new EventEmitter<string>();
  @Output() selectProperty = new EventEmitter<string>();
  @Output() scrollPosition = new EventEmitter<number>();

  acceptedMigrationsMap: Map<string, MigrationLogEntryPropInterface> = new Map();
  currentPropertyValuesMap: Map<string, string> = new Map();

  private subscriptions: Subscription = new Subscription();

  constructor(private configuratorModalService: ConfiguratorModalService) { }

  ngAfterViewInit(): void {
    this.scrollToLastUsedProperty();
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.configuratorModalService.acceptedMigrationsMapObservable().subscribe((map) => {
        this.acceptedMigrationsMap = map;
      })
    )

    this.subscriptions.add(
      this.configuratorModalService.currentPropertyValuesMapObservable().subscribe((map) => {
        this.currentPropertyValuesMap = map;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getPropertiesList(props: MigrationLogEntryPropsInterface): MigrationLogEntryPropInterface[] {
    return Object.values(props).filter(prop => !prop.Hidden);
  }

  getPropertyKeys(prop: MigrationLogEntryPropsInterface): string[] {
    return Object.keys(prop);
  }

  getConfigurationArticleLongText(migration: MigrationChangeInterface): string {
    let configuratorId = migration.ItemID;
    if (migration.Deleted) {
      configuratorId = migration.FromTitle;
    }

    const found = this.configurationArticles.find(article => article.id === configuratorId);

    return found ? found.longText : configuratorId;
  }

  getChangeStatusText(migration: MigrationChangeInterface): string {
    if (migration.Deleted) {
      return 'CONFIGURATOR_MODAL.MIGRATION_STATUS.REMOVED';
    }

    if (migration.Added) {
      return 'CONFIGURATOR_MODAL.MIGRATION_STATUS.ADDED';
    }

    return 'CONFIGURATOR_MODAL.MIGRATION_STATUS.UPDATED';
  }

  onAcceptChanges() {
    this.configuratorModalService.hideAllMigrationIssues();
    this.acceptChanges.emit();
  }

  onSelectArticle(configuratorId) {
    this.emitScrollPosition();
    this.selectArticle.emit(configuratorId);
  }

  onSelectArticleProperty(configuratorId: string, propertyKey: string) {
    this.configuratorModalService.setSelectedArticleProperty(configuratorId, propertyKey);
    this.emitScrollPosition();
    this.selectProperty.emit(`${configuratorId}_${propertyKey}`);
  }

  onMarkChangeAsAccepted(event: MouseEvent, property: MigrationLogEntryPropInterface, configuratorId: string, propertyKey: string) {
    event.preventDefault();
    event.stopPropagation();

    const key = `${configuratorId}_${propertyKey}`;
    if (this.acceptedMigrationsMap.has(key)) {
      this.acceptedMigrationsMap.delete(key);

      return;
    }

    this.acceptedMigrationsMap.set(key, property);
    this.configuratorModalService.setAcceptedMigrationsMap(this.acceptedMigrationsMap);
  }

  scrollToLastUsedProperty() {
    if (this.lastUsedProperty) {
      setTimeout(() => {
        const element = document.querySelector(`migrations-sidebar-modal .${this.lastUsedProperty}`);

        if (element) {
          if (this.scrollToProperty) {
            element.scrollIntoView({ behavior: 'smooth' , block: 'center', inline: 'nearest' });
          }

          setTimeout(() => {
            element.classList.add('bg-transition'); // bg animation is defined in css .bg-transition definition
            element.classList.add('bg-warning-300');
            element.classList.remove('bg-gray-200');
            setTimeout(() => {
              element.classList.remove('bg-warning-300');
              element.classList.add('bg-gray-200');
            }, 1000); // time to toggle bg classes
          }, 0);
        }
      }, 0);
    }
  }

  emitScrollPosition() {
    this.scrollPosition.emit(document.querySelector('migrations-sidebar-modal > div').scrollTop);
  }
}
