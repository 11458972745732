<div class="container d-flex justify-content-center align-items-center">
  <div class="error text-center">
    <div class="error__text--big">{{"ERRORS.UNSUPPORTED_BROWSER.BIG" | translate}}</div>
    <div class="error__text--small">{{"ERRORS.UNSUPPORTED_BROWSER.SMALL.FIRST" | translate}}
      <br/> {{"ERRORS.UNSUPPORTED_BROWSER.SMALL.SECOND" | translate}}</div>
    <div class="error__support d-flex justify-content-center">
      <div class="error__browser">
        <div class="error__icon error__icon--chrome"></div>
        <div class="error__browser-text">{{"ERRORS.UNSUPPORTED_BROWSER.CHROME" | translate}}</div>
      </div>
      <div class="error__browser">
        <div class="error__icon error__icon--firefox"></div>
        <div class="error__browser-text">{{"ERRORS.UNSUPPORTED_BROWSER.FIREFOX" | translate}}</div>
      </div>
      <div class="error__browser">
        <div class="error__icon error__icon--safari"></div>
        <div class="error__browser-text">{{"ERRORS.UNSUPPORTED_BROWSER.SAFARI" | translate}}</div>
      </div>
      <div class="error__browser">
        <div class="error__icon error__icon--edge"></div>
        <div class="error__browser-text">{{"ERRORS.UNSUPPORTED_BROWSER.EDGE" | translate}}</div>
      </div>
    </div>
  </div>
</div>
