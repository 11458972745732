import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InlineFieldComponent } from './inline-field/inline-field.component';
import { InlineFieldLabelComponent } from './inline-field-label/inline-field-label.component';
import { InlineFieldErrorsTemplateDirective } from './inline-field-errors-template.directive';
import { InlineFieldInfoTemplateDirective } from './inline-field-info-template.directive';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
  declarations: [
    InlineFieldComponent,
    InlineFieldLabelComponent,
    InlineFieldErrorsTemplateDirective,
    InlineFieldInfoTemplateDirective,
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [
    InlineFieldComponent,
    InlineFieldLabelComponent,
    InlineFieldErrorsTemplateDirective,
    InlineFieldInfoTemplateDirective,
  ]
})
export class InlineFieldModule { }
