import {Pipe, PipeTransform} from '@angular/core';
import {ClientInterface} from '../../../core/models/client.model';

@Pipe({
  name: 'clientPipe'
})
export class ClientPipe implements PipeTransform {

  transform(client: ClientInterface, args?: any): any {
    const clientString = (client.companyName ? client.companyName : "") + (client.address ? " • " + client.address : "") + (client.postCode ? ", " + client.postCode : "") + (client.city ? " " + client.city : "") + (client.country && (client.country.title || client.country.code) ? ", " + (client.country.title || client.country.code) : "");
    return clientString;
  }

}
