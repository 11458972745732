import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { Subscription } from 'rxjs';
import { PriceListCurrencyService } from '../../../core/services/price-list-currency/price-list-currency.service';

const DEFAULT_CURRENCY = 'EUR';

@Pipe({
  name: 'priceListCurrency',
  pure: false,
})
export class PriceListCurrencyPipe implements PipeTransform, OnDestroy {
  private currency: string = DEFAULT_CURRENCY;
  private subscriptions: Subscription = new Subscription();

  constructor(private priceListCurrencyService: PriceListCurrencyService, private currencyPipe: CurrencyPipe) {
    this.priceListCurrencyService.getCurrencyAsObservable().subscribe(currency => {
      if (currency && currency.name) {
        this.currency = currency.name;
      }
    });
  }

  transform(
    value: any,
    display: 'code' | 'symbol' | 'symbol-narrow' | string | boolean = 'symbol',
    digitsInfo: string = '1.0',
    currency?: string
  ): any {
    return this.currencyPipe.transform(value, currency || this.currency, display, digitsInfo);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
