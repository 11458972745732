import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PropertyValueTranslatePipe} from './property-value-translate/property-value-translate.pipe';
import {ConfiguratorTranslatePipe} from './configurator-translate.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    PropertyValueTranslatePipe,
    ConfiguratorTranslatePipe
  ],
  exports: [
    PropertyValueTranslatePipe,
    ConfiguratorTranslatePipe
  ]
})
export class PipesModule {
}
