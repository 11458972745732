import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ContentTreeModel } from '../../content/content-tree.model';
import { ContentModel } from '../../content/content.model';
import { TermsUrlService } from '../terms-url.service';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TermsUrlService]
})
export class TermsAndConditionsComponent {
  @Input() tabs: ContentTreeModel.Data[] = [];
  @Input() selected: ContentModel.Data | null = null;

  @Output() selectedChange = new EventEmitter<ContentTreeModel.Data>();

  constructor(
    private termsUrlService: TermsUrlService
  ) {}

  select(event: MouseEvent, tab: ContentTreeModel.Data): boolean | undefined {
    if (!event.ctrlKey) {
      this.selectedChange.emit(tab);
      event.preventDefault();
      return false;
    }
  }

  getTermsUrl(uri: ContentTreeModel.Data['uri']): string {
    return this.termsUrlService.createTermsUrl(uri).toString();
  }
}
