import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileUploadComponent } from './file-upload.component';
import { SharedModule } from '../../shared/shared.module';
import { FileUploadPlaceholderDirective } from './directives/file-upload-placeholder/file-upload-placeholder.directive';
import { MissingTranslationHandler, TranslateModule } from '@ngx-translate/core';
import { CustomMissingTranslationHandler } from '../../core/models/custom-missing-translation-handler.model';

@NgModule({
  declarations: [FileUploadComponent, FileUploadPlaceholderDirective],
  exports: [FileUploadComponent, FileUploadPlaceholderDirective],
  imports: [CommonModule, SharedModule, TranslateModule.forChild({
    missingTranslationHandler: { provide: MissingTranslationHandler, useClass: CustomMissingTranslationHandler },
  })],
})
export class FileUploadModule {}
