import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UncachePipe } from './uncache.pipe';
import { UNCACHEABLE_RESOURCE_QUERY_PARAM_KEY } from './injection.tokens';
import { ModuleWithProviders } from '@angular/core';

export interface UncacheableResourceModuleConfigInterface {
  queryParamKey?: string;
}

@NgModule({
  imports: [CommonModule],
  declarations: [UncachePipe],
  exports: [UncachePipe],
})
export class UncacheableResourceModule {
  static forRoot(config: UncacheableResourceModuleConfigInterface = {}): ModuleWithProviders<UncacheableResourceModule> {
    return {
      ngModule: UncacheableResourceModule,
      providers: [
        {
          provide: UNCACHEABLE_RESOURCE_QUERY_PARAM_KEY,
          useValue: config.queryParamKey || 'version',
        },
      ],
    };
  }

  static forChild(config: UncacheableResourceModuleConfigInterface = {}): ModuleWithProviders<UncacheableResourceModule> {
    return UncacheableResourceModule.forRoot(config);
  }
}
