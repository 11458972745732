import {Injectable, OnDestroy} from '@angular/core';
import {MessageType} from './message-type.enum';
import {MessageOptionsInterface} from './message-options.model';
import {MessageInterface} from './message.model';
import {Subject} from 'rxjs';

@Injectable()
export class HeaderMessageService implements OnDestroy {
  message$: Subject<MessageInterface> = new Subject();

  constructor() {
  }

  open(message: string, type: MessageType, options?: MessageOptionsInterface) {
    this.message$.next({text: message, type, options});
  }

  close() {
    this.message$.next(null);
  }

  getMessageSubject() {
    return this.message$;
  }

  ngOnDestroy() {
    if (typeof this.message$ !== 'undefined') {
      this.message$.unsubscribe();
    }
  }
}
