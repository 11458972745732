/** Maps system languages to angular's locale IDs */
export function languageToDateLocale(lang: string) {
  switch (lang) {
    case 'lt':
      return 'lt-LT';
    case 'ru':
      return 'ru-RU';
    case 'fr':
      return 'fr-FR';
    case 'de':
      return 'de-DE';
    default:
      return 'en-US';
  }
}
