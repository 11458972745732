import { FullOrderArticleInterface } from '../../core/models/full-order-article.model';
import { ExtraListElementRowInterface } from './order-articles-list/components/extra-row/extra-items.model';
import {
  OrderArticleDiscountType,
  OrderArticleSaleDiscountType,
  OrderArticleSaleCoefficientType,
} from '../../core/enums/order-article-discount-type.enum';
import { FormattedPricesInterface, OrderArticleTranslationInterface, OrderArticleType } from '../../core/models/order-article.model';

export type OrderArticlesListRow = OrderArticlesListRowInterface | ExtraListElementRowInterface;

export interface OrderArticlesListRowInterface extends FormattedPricesInterface {
  id: number;
  item: OrderArticlesListRowItemInterface;
  materials?: any[];
  originals?: FullOrderArticleInterface;
  configurationQuantity?: number;
  quantity?: number;
  selected: boolean;
  salePrice?: number;
  size?: OrderArticlesListRowSizeInterface;
  totalPrice?: number;
  totalSalePrice?: number;
  materialsAndType?: { type: number | string; materials: any[] };
  rowType: RowTypes.PRODUCT;
  position: number;
  pageBreak?: any;
  parent?: any;
  discount?: number;
  discountType?: OrderArticleDiscountType;
  saleDiscount?: number;
  saleDiscountType?: OrderArticleSaleDiscountType;
  saleCoefficient?: number;
  saleCoefficientType?: number;
  children?: OrderArticlesListRow[];
  childrenTotalPrice?: number;
  groupLevel?: number;
  createdAtTimestamp?: number;
  code?: string;
  price?: number;
  customPrice?: number;
  pricelistPrice?: number;
  additionalParts?: OrderArticlesListRowAdditionalPartInterface[];
  order?: number;
  hasCustomPrice?: boolean;
  type?: OrderArticleType | null;
  title?: string;
  cut: boolean;
  versionOutdated: boolean;
  migrationStatus?: MigrationStatusTypes;
  totalVolume?: number;
  totalWeight?: number;
  fullCode?: string;
  shortText?: string;
  longText?: string;
  translations?: OrderArticleTranslationInterface[];
  totalConfigurationPrice?: number;
}

export interface OrderArticlesListRowAdditionalPartInterface extends OrderArticlesListRowInterface {
  saleCoefficientType?: OrderArticleSaleCoefficientType;
  saleCoefficient?: number;
  saleDiscount?: number;
  volume?: string;
  weight?: string;
  quantity?: number;
}

export interface OrderArticlesListRowItemInterface {
  category?: string;
  code?: string;
  system?: string;
  image?: string;
  shortText?: string;
  longText?: string;
  title?: string;
  translations: OrderArticleTranslationInterface[];
}

export interface OrderArticlesListRowSizeInterface {
  depth?: number;
  details?: string;
  volume?: string | number;
  weight?: string | number;
  width?: string | number;
  height?: string;
  dimensions?: string;
}

export enum RowTypes {
  PRODUCT,
  EXTRA,
  GROUP,
}

export enum MigrationStatusTypes {
  removed = 'REMOVED',
  updated = 'UPDATED',
}

interface OrderArticlesListColumn {
  prop: string;
  name: string;
  secondName?: string;
  visible: boolean;
  width: number;
}

export interface OrderArticlesListColumns {
  selectBox: OrderArticlesListColumn;
  item: OrderArticlesListColumn;
  materialsAndType: OrderArticlesListColumn;
  details: OrderArticlesListColumn;
  quantity: OrderArticlesListColumn;
  price: OrderArticlesListColumn;
  discount?: OrderArticlesListColumn;
  saleDiscount?: OrderArticlesListColumn;
  totalPrice: OrderArticlesListColumn;
  actions: OrderArticlesListColumn;
}

export function pageBreakKey(item) {
  if (!item && item.rowType == null) {
    return;
  }
  return item.rowType === RowTypes.PRODUCT ? 'pageBreak' : 'parent';
}

export function areItemsInSameGroup(elements) {
  const [firstItem] = elements;
  const firstItemPageBreak = firstItem ? firstItem[pageBreakKey(firstItem)] : null;
  return elements.every(item => {
    const pageBreak = item[pageBreakKey(item)];
    const parentInList = pageBreak && !!elements.find(element => element.rowType === RowTypes.GROUP && element.id === pageBreak.id);
    return (pageBreak && firstItemPageBreak && pageBreak.id === firstItemPageBreak.id) || pageBreak === firstItemPageBreak || parentInList;
  });
}

/** Update row cut property if row is in clipboard cut
 * items array. Also check if group is cut and ignore setting to children items cut property
 */
export function deepMapCutRows(row, cutItems, cutGroups) {
  if (cutGroups.find(group => row[pageBreakKey(row)] && group.id === row[pageBreakKey(row)].id)) {
    return row;
  }
  const foundIndex = cutItems.findIndex(item => item.id === row.id);
  row.cut = foundIndex > -1;

  if (row.children && row.children.length) {
    row.children.map(child => deepMapCutRows(child, cutItems, cutGroups));
  }

  return row;
}
