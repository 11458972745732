import { Component, Input, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
  selector: 'app-password-input',
  templateUrl: './password-input.component.html',
  styleUrls: ['./password-input.component.scss']
})
export class PasswordInputComponent implements ControlValueAccessor {
  @Input() autocomplete: 'new-password' | 'current-password' = 'new-password';
  @Input() placeholder = '';
  @Input() id = '';

  showPassword = false;
  innerValue: string | null = null;
  isDisabled = false;

  private onChange = (value: string) => {};
  private onTouched = () => {};

  constructor(
    @Self() public ngControl: NgControl
  ) {
    ngControl.valueAccessor = this;
  }

  onModelChange(value: string) {
    this.innerValue = value;
    this.onChange(this.innerValue);
  }

  onBlur() {
    this.onTouched();
  }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }

  writeValue(value: string | null): void {
    this.innerValue = value;
  }

  setDisabledState(disabled: boolean) {
    this.isDisabled = disabled;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
