import { APP_INITIALIZER, InjectionToken } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { catchError, of } from "rxjs";

import { LanguageStoreHandlerService } from "../services/language/language-store-handler.service";
import { DEFAULT_APP_LANGUAGE } from "../constants/language.constants";
import { languageToDateLocale } from "./language-to-date-locale";

export const APP_DATE_LOCALE_ID = new InjectionToken('APP_DATE_LOCALE_ID');

export const LANGUAGE_INITIALIZER = {
  provide: APP_INITIALIZER,
  // App initializer waits for LanguageStoreHandlerService to finish fetching language data.
  // It's assumed that LanguageStoreHandlerService will have handled initializing language values
  useFactory: (service: LanguageStoreHandlerService) => {
    return () => service.fetch().pipe(
      catchError(() => of(0))
    );
  },
  deps: [LanguageStoreHandlerService, TranslateService],
  multi: true
};

export const APP_DATE_LOCALE_ID_PROVIDER = {
  provide: APP_DATE_LOCALE_ID,
  useFactory: (translate: TranslateService) => {
    return languageToDateLocale(translate.currentLang || DEFAULT_APP_LANGUAGE);
  },
  deps: [TranslateService]
};
