import {ChangeDetectionStrategy, Component} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { startWith, Subject, switchMap, tap } from 'rxjs';

import {MessagesService} from '../messages.service';
import {MessageObjectType, MessagesSummaryInterface} from '../../core/models/messages.model';
import {ConversationModalComponent} from '../conversation-modal/conversation-modal.component';
import {UserService} from '../../core/services/user/user.service';
import { MessageStatus } from '../../core/enums/message-status.enum';

@Component({
  selector: 'app-messages-modal',
  templateUrl: './messages-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessagesModalComponent {
  loading = true;
  messageStatus = MessageStatus;

  private messagesRefetch$ = new Subject<void>();
  messages$ = this.messagesRefetch$.pipe(
    startWith(0),
    tap(() => this.loading = true),
    switchMap(() => this.messagesService.getAllForUser().noCache()),
    tap(() => this.loading = false)
  );

  constructor(public activeModal: NgbActiveModal,
              private modalService: NgbModal,
              private messagesService: MessagesService,
              private userService: UserService) {
  }

  open(message) {
    let object = null;
    if (message.order) {
      object = {...message.order, type: MessageObjectType.ORDER};
    }
    if (object && message) {
      this.close();
      const modalRef = this.modalService.open(ConversationModalComponent, {
        size: 'lg'
      });
      modalRef.componentInstance.object = object;
      modalRef.componentInstance.orderId = message.orderId;
      modalRef.componentInstance.forceMarkAsSeen = false;
      modalRef.componentInstance.markMessagesAsSeen.subscribe(() => {
        this.userService.reload().subscribe(); // Update user notifications
      });
    }
  }

  markAllAsRead() {
    this.messagesService.markAllAsSeen().subscribe(() => {
      this.messagesRefetch$.next();
      this.userService.reload().subscribe(); // Update user notifications
    });
  }

  close() {
    this.activeModal.dismiss();
  }

  allMessagesSeen(messages: MessagesSummaryInterface[]) {
    return messages.every(message => message.status === MessageStatus.SEEN)
  }
}
