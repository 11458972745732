import {Inject, Injectable} from '@angular/core';
import {PERMISSIONS_DECISION_MAKER_SERVICE} from '../../../permissions/injection-tokens';
import {PermissionsDecisionMakerServiceInterface} from '../../../permissions/decision-maker.service';
import {UserService} from '../../../core/services/user/user.service';
import {FullOrderArticleInterface} from '../../../core/models/full-order-article.model';
import {BehaviorSubject, forkJoin, Observable, zip} from 'rxjs';
import {PermissionActions} from '../../../permissions.config';
import {SaleMode} from '../../../shared/components/list-mode-switch/sale-mode.types';
import { UserRole } from '../../../core/enums/user-role.enum';
import { ListModeSwitchService } from '../../../shared/components/list-mode-switch/list-mode-switch.service';

export interface CustomDiscountVisibilityInterface {
  sale: boolean;
  order: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class CustomDiscountVisibilityDecisionMakerService {
  get visible(): CustomDiscountVisibilityInterface {
    return this.visible$.getValue();
  }

  set visible(value: CustomDiscountVisibilityInterface) {
    this.visible$.next(value);
  }

  private visible$ = new BehaviorSubject<CustomDiscountVisibilityInterface>({ sale: false, order: false });

  constructor(
    @Inject(PERMISSIONS_DECISION_MAKER_SERVICE)
    private permissionsDecisionMaker: PermissionsDecisionMakerServiceInterface,
    private userService: UserService,
    private listModeSwitchService: ListModeSwitchService
  ) {
  }

  decide(fullOrderArticles: FullOrderArticleInterface[]) {
    zip(
      this.permissionsDecisionMaker.shouldAllow([PermissionActions.CAN_SEE_SALE_DISCOUNT_COLUMN]),
      this.permissionsDecisionMaker.shouldAllow([PermissionActions.CAN_SEE_ORDER_DISCOUNT_COLUMN]),
      this.listModeSwitchService.saleModeAsObservable(),
      this.userService.fromStorage(),
    ).subscribe(([canSeeSaleDiscountColumn, canSeeOrderDiscountColumn, saleMode, user]) => {
      saleMode = +saleMode;

      const visible = { sale: false, order: false };
      if (canSeeSaleDiscountColumn) {
        visible.sale = !!fullOrderArticles.find(
          (article: FullOrderArticleInterface) => article.orderArticle.saleDiscount > 0
        ) && saleMode === SaleMode.SALE;
      }

      if (canSeeOrderDiscountColumn) {
        visible.order = !!fullOrderArticles.find(
          (article: FullOrderArticleInterface) => +article.orderArticle.formattedConfigurationDiscount > 0
        ) && (saleMode === SaleMode.ORDER || user.role.name === UserRole.ROLE_PM_NARBUTAS);
      }

      this.visible = visible;
    }).unsubscribe();
  }

  visibleAsObservable(): Observable<CustomDiscountVisibilityInterface> {
    return this.visible$.asObservable();
  }

  public static any(visibility: CustomDiscountVisibilityInterface): boolean {
    return Object.values(visibility).some(v => v);
  }
}
