import {Component, OnInit} from '@angular/core';
import {PriceListInterface} from '../../core/models/pricelist.model';
import {UserService} from '../../core/services/user/user.service';
import {PriceListStoreHandlerService} from '../../core/services/price-list/price-list-store-handler.service';
import {UserInterface} from '../../core/models/user.model';

@Component({
  selector: 'app-pricelist-title',
  templateUrl: './pricelist-title.component.html',
  styleUrls: ['./pricelist-title.component.scss']
})
export class PriceListTitleComponent implements OnInit {

  priceList: PriceListInterface;

  constructor(
    private userService: UserService,
    private priceListStoreHandler: PriceListStoreHandlerService
  ) {
  }

  ngOnInit() {
    this.userService.fromStorage().subscribe((user: UserInterface) => {
      this.priceList = this.priceListStoreHandler.getCurrentValue() || user.priceList;
    });
  }

}
