import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-reload',
  templateUrl: './app-reload.component.html'
})
export class AppReloadComponent implements OnInit {
  @Input() textHeader: string;
  @Input() textInfo: string;
  @Input() textAction: string;

  constructor() {}

  ngOnInit() {}

  doReload() {
    window.location.reload();
  }
}
