<ng-container *ngTemplateOutlet="header; context: {item: item$ | async }"></ng-container>

<div class="container-fluid px-0">
  <div class="row">
    <div class="col-4 pb-2" *ngFor="let item of (list$ | async)">
      <div class="app-card cursor-pointer me-0"
           (click)="onItemClick(item);"
      >
        <div class="title"><h4>{{ item.translation.title ?? '' }}</h4></div>
        <div class="image"
             [style.height.rem]="14"
             [style.background-image]="item.thumbnail?.url
                  ? 'url(' + ((item.thumbnail.url | resizeImage: imageSizes.SIZE_394x224 | uncache | async) ?? '') + ')'
                  : 'url(\'../../../../assets/images/default-icons/card-placeholder.svg\')'"
        ></div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <ng-container *ngTemplateOutlet="pagination; context: { pagination: pagination$ | async }"></ng-container>
    </div>
  </div>
</div>

<ng-template #header let-item="item">
  <div class="d-flex justify-content-between align-items-center" *ngIf="showTitle">
    <h1 class="mb-0">{{item.translation.title ?? ''}}</h1>
    <div class="short-description" [innerHTML]="(item.translation.shortDescription ?? '') | safeHtml"></div>
  </div>
</ng-template>

<ng-template #pagination let-pagination="pagination">
  <div class="d-flex justify-content-center mt-3">
    <ngb-pagination class="mx-auto"
                    *ngIf="pagination['total-pages'] > 1"
                    [(page)]="pagination['current-page']"
                    [pageSize]="pagination['show-in-page']"
                    [collectionSize]="pagination['count']"
                    [maxSize]="7"
                    [rotate]="true"
                    (pageChange)="onPageChange($event)"
    >
      <ng-template ngbPaginationPrevious><i class="icon ni-west"></i></ng-template>
      <ng-template ngbPaginationNext><i class="icon ni-east"></i></ng-template>
      <ng-template ngbPaginationEllipsis>
        <span class="color-black">...</span>
      </ng-template>
    </ngb-pagination>
  </div>
</ng-template>
