import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PageVisibilityService {
  constructor() {}

  isPageVisibleAsObservable(): Observable<boolean> {
    return new Observable<boolean>(observer => {
      document.addEventListener('visibilitychange', () => observer.next(document.hidden));
    });
  }
}
