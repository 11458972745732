<ngb-toast
  *ngFor="let toast of toasts$ | async"
  class="w-100"
  [ngClass]="toast.classname"
  [autohide]="toast.autohide ?? true"
  [delay]="toast.delay || 5000"
  (hidden)="removeToast(toast)"
>
  <div class="p-1 d-flex justify-content-between">
    <div *ngIf="isTemplate(toast); else text">
      <!-- Displays templateRef -->
      <ng-template *ngTemplateOutlet="$any(toast.body)"></ng-template>
    </div>
    <i (click)="removeToast(toast)" class="icon ni-close"></i>
  </div>

  <ng-template #text>
    <!-- Displays simple text toast -->
    <div class="d-flex">
      <i *ngIf="toast.icon" class="icon {{toast.icon}} pe-2"></i>
      <span class="fw-bold">{{ toast.body }}</span>
    </div>
  </ng-template>
</ngb-toast>
