import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessagesWindowComponent } from './messages-window.component';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoaderModule } from '../ui-elements/loader/loader.module';
import { TextFieldModule } from '../ui-elements/text-field/text-field.module';
import { TranslateModule } from '@ngx-translate/core';
import { ScrollToModule } from '../shared/directives/scroll-to/scroll-to.module';
import { IconModule } from '../ui-elements/icon/icon.module';
import { UncacheableResourceModule } from '../uncacheable-resource/uncacheable-resource.module';
import { MessageDetailsValueFormatPipe } from './message-details-value-format.pipe';
import { PriceListCurrencyPipe } from '../shared/pipes/price-list-currency/price-list-currency.pipe';
import { CommunicationTabModule } from "../ui-elements/communication-tab/communication-tab.module";

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    LoaderModule,
    TextFieldModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    ScrollToModule,
    IconModule,
    UncacheableResourceModule,
    CommunicationTabModule,
],
  exports: [MessagesWindowComponent],
  declarations: [MessagesWindowComponent, MessageDetailsValueFormatPipe],
  providers: [MessageDetailsValueFormatPipe, PriceListCurrencyPipe],
})
export class MessagesWindowModule {}
