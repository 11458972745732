import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommunicationTabComponent } from './communication-tab.component';
import { LoaderModule } from "../loader/loader.module";
import { SharedModule } from '../../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { MessageFieldComponent } from './message-field/message-field.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FilesListComponent } from './files-list/files-list.component';
import { PreviewFileModule } from '../preview-file/preview-file.module';
import { TextFieldModule } from '../text-field/text-field.module';
import { InlineSVGModule } from 'ng-inline-svg-2';

@NgModule({
    declarations: [
        CommunicationTabComponent,
        MessageFieldComponent,
        FilesListComponent,
    ],
    exports: [
        CommunicationTabComponent,
        MessageFieldComponent,
        FilesListComponent,
    ],
    imports: [
        SharedModule,
        CommonModule,
        LoaderModule,
        TranslateModule,
        ReactiveFormsModule,
        PreviewFileModule,
        TextFieldModule,
        InlineSVGModule,
    ]
})
export class CommunicationTabModule { }
