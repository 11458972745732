<div class="container-xxl">
  <div class="row">
    <div class="col">
      <h1 class="my-4">{{ 'FINISHES.TITLE' | translate }}</h1>
    </div>
  </div>

  <div class="row">
    <div class="col-3">
      <div class="mb-3">
        <app-checkbox-filter
          [title]="'FINISHES.FINISH_TYPE' | translate"
          (onChange)="filterFinishTypes($event)"
        >
          <app-checkbox-filter-option
            *ngFor="let item of (finishTypes$ | async), let last = last"
            [value]="item.id"
            [class.mb-2]="!last"
          >
            {{ item.title }}
          </app-checkbox-filter-option>
        </app-checkbox-filter>
      </div>

      <a
        [routerLink]="marketingMaterialsURL"
        class="btn btn-primary w-100 mb-2"
      >
        {{ 'FINISHES.ORDER_MARKETING_MATERIAL' | translate }}
      </a>

      <ng-container *ngIf="links$ | async as links">
        <a
          *ngFor="let link of links"
          [href]="link.file.url"
          download
          class="btn btn-link w-100 mb-2"
        >
          <span class="d-flex justify-content-center align-items-center">
            {{ link.title }}
            <i class="icon ni-download"></i>
          </span>
        </a>
      </ng-container>
    </div>
    <div class="col-9 position-relative" *ngIf="{
      filteredFinishes: filteredFinishes$ | async,
      loading: loading$ | async
    } as data">
      <app-loader [loading]="data.loading"></app-loader>
      <ng-container *ngIf="!data.loading">
        <a
          *ngFor="let finish of data.filteredFinishes"
          [routerLink]="['/info/finishes', finish.id]"
        >
          <div class="finish-item bg-white mb-4">
            <h3 class="p-3 mb-0">{{ finish.title }}</h3>
            <div class="finish-image overflow-hidden">
              <img
                *ngIf="finish.image?.url"
                [src]="finish.image.url"
                [alt]="finish.title"
                class="w-100 h-100"
              />
            </div>
          </div>
        </a>
      </ng-container>
    </div>
  </div>
</div>
