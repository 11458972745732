<div class="row-details-list">
  <div class="py-3" *ngIf="rowsGroups.length; else noResultsFound">
    <div class="mb-2" *ngFor="let group of rowsGroups">
      <div
        class="row-details-list__row d-flex"
        [class.with-discount]="isDiscountVisible()"
        *ngFor="let row of group.rows"
      >
        <div class="article-img" [style.min-width.rem]="13.25"></div>

        <div class="pe-3 d-flex my-auto flex-grow-1" [title]="getTranslatedDescription(row, uiLanguage)">
          {{ getTranslatedDescription(row, uiLanguage) }}
        </div>

        <div class="ps-2 pe-2 my-auto flex-shrink-0" [style.width.rem]="columnConfig.materialsAndType.width">
          {{ row.code }}
        </div>

        <div *ngIf="isDimensionsVisible()" class="pe-2 my-auto flex-shrink-0" [style.width.rem]="columnConfig.details.width">
          {{ row.dimensions }}
        </div>

        <div class="text-end pe-2 my-auto flex-shrink-0" [style.width.rem]="columnConfig.quantity.width">
          {{ row.quantity }}
        </div>

        <div class="text-end my-auto pe-2 flex-shrink-0" [style.width.rem]="columnConfig.price.width">
          <visibility-by-variables [hideIf]="['hidePrices']" [replacement]="'-'">
            {{
              [orderArticleTypes.OTHER_SUPPLIER_ITEM, orderArticleTypes.CUSTOM_ITEM_TYPE].includes(row.type) && !row.customPrice
                ? 'N/A'
                : (row.formattedPurchasePrice | priceFormat: { zeroReplacement: 'N/A' })
            }}
          </visibility-by-variables>
        </div>

        <div *ngIf="columnConfig.discount.visible"
          class="text-end my-auto pe-2 flex-shrink-0"
          [appHideByFieldType]="fieldTypes.DISCOUNT"
          [style.width.rem]="columnConfig.discount.width"
        >
          {{ row.formattedDiscount | discount }}
        </div>

        <div class="text-end my-auto flex-shrink-0" [style.width.rem]="columnConfig.totalPrice.width">
          <visibility-by-variables [hideIf]="['hidePrices']" [replacement]="'-'">
            {{
              [orderArticleTypes.OTHER_SUPPLIER_ITEM, orderArticleTypes.CUSTOM_ITEM_TYPE].includes(row.type) && !row.customPrice
                ? 'N/A'
                : (row.totalPrice | priceFormat: { zeroReplacement: 'N/A' })
            }}
          </visibility-by-variables>
        </div>

        <div class="flex-shrink-0" [style.width.rem]="columnConfig.actions.width">
          <!-- placeholder for grid -->
        </div>
      </div>
    </div>
  </div>
  <ng-template #noResultsFound>
    <app-no-results-found></app-no-results-found>
  </ng-template>
</div>
