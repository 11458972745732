<div class="shadow-sm bg-white mb-3">
  <div class="d-flex justify-content-between align-items-center p-3">
    <div class="skeleton-block py-3" [style.width.rem]="14.75"></div>
    <div class="skeleton-block py-3" [style.width.rem]="13.5"></div>
  </div>
  <div class="skeleton-block pt-3"></div>
  <div class="row mx-0">
    <div class="col-9 p-3 d-flex align-items-end">
      <div class="skeleton-block pt-3 pb-1" [style.width.rem]="15.625"></div>
    </div>
    <div class="col-3 p-3">
      <div class="skeleton-block py-3 mb-3"></div>
      <div class="skeleton-block py-3 mb-3"></div>
      <div class="skeleton-block py-3"></div>
    </div>
  </div>
  <div
    class="d-flex align-items-center w-100 px-1 py-2 border-top"
  >
    <div class="skeleton-block me-2" [style.width.rem]="10" [style.height.rem]="10"></div>
    <div class="skeleton-block py-3 me-2" style="flex-grow: 3"></div>
    <div class="d-flex flex-column justify-content-between" style="flex-grow: 4;">
      <div class="d-flex align-items-center">
        <div class="skeleton-block pt-3 pb-1 me-2" style="flex-grow: 4"></div>
        <div class="skeleton-block pt-3 pb-1 me-2" style="flex-grow: 4"></div>
        <div class="skeleton-block pt-3 pb-1 me-2" style="flex-grow: 4"></div>
        <div class="skeleton-block pt-3 pb-1 me-2" style="flex-grow: 13"></div>
        <div class="skeleton-block pt-3 pb-1" style="flex-grow: 26"></div>
      </div>
    </div>
  </div>
</div>

<div class="shadow-sm bg-white mb-3">
  <div class="skeleton-block pt-3"></div>
  <div class="row mx-0">
    <div class="col-9 p-3 d-flex align-items-end">
      <div class="skeleton-block pt-3 pb-1" [style.width.rem]="15.625"></div>
    </div>
    <div class="col-3 py-3 pe-1 ps-2">
      <div class="skeleton-block py-3 mb-3"></div>
      <div class="d-flex align-items-center w-100">
        <div class="skeleton-block pt-3 pb-1 flex-grow-1"></div>
        <span class="border-right mx-1 pb-1 pt-3"></span>
        <div class="skeleton-block pt-3 pb-1 flex-grow-1"></div>
      </div>
    </div>
  </div>
  <div *ngFor="let item of products"
    class="d-flex align-items-center w-100 px-1 py-2 border-top"
  >
    <div class="skeleton-block me-2" [style.width.rem]="10" [style.height.rem]="10"></div>
    <div class="skeleton-block py-3 me-2" style="flex-grow: 3"></div>
    <div class="d-flex flex-column justify-content-between" style="flex-grow: 4;">
      <div *ngFor="let item of rows; let last = last" class="d-flex align-items-center" [class.mb-3]="!last">
        <div class="skeleton-block pt-3 pb-1 me-2" style="flex-grow: 4"></div>
        <div class="skeleton-block pt-3 pb-1 me-2" style="flex-grow: 4"></div>
        <div class="skeleton-block pt-3 pb-1 me-2" style="flex-grow: 4"></div>
        <div class="skeleton-block pt-3 pb-1 me-2" style="flex-grow: 13"></div>
        <div class="skeleton-block pt-3 pb-1 me-1" style="flex-grow: 13"></div>
        <div class="skeleton-block pt-3 pb-1" style="flex-grow: 13"></div>
      </div>
    </div>
  </div>
</div>
