import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { AddressService } from '../../../../core/services/address/address.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../../../core/services/user/user.service';
import { CountryService } from '../../../../core/services/country/country.service';
import { CountryInterface } from '../../../../core/models/country.model';
import { LoaderComponent } from '../../../../ui-elements/loader/loader.component';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from '../../../../ui-elements/loader/loader.service';
import { AddressInterface, AddressUpdateInterface } from '../../../../core/models/address.model';
import { TextFieldThemeTypes } from '../../../../ui-elements/text-field/text-field/theme-types.enum';
import { AddressModelInstanceChangeEventInterface } from '../../../../address/address-form/address-form.component';
import { ButtonInterface, ButtonType } from '../../../../shared/interfaces/modal/button.model';
import { ToastService } from '../../../../ui-elements/toast/toast.service';

@Component({
  selector: 'app-settings-shipping-addresses-edit-modal',
  templateUrl: './edit-modal.component.html',
})
export class EditModalComponent implements OnInit {
  @ViewChild('loader') loader: LoaderComponent;

  @Output() updated: EventEmitter<AddressInterface> = new EventEmitter<AddressInterface>();

  submitted = false;
  address: AddressInterface;
  formModel: AddressUpdateInterface;
  isFormValid = true;
  countries: CountryInterface[];
  textFieldThemeTypes = TextFieldThemeTypes;

  buttons: ButtonInterface[] = [
    {
      text: 'SETTINGS.CANCEL',
      action: () => this.activeModal.close(),
      classes: 'btn-secondary',
      id: ButtonType.CANCEL,
    },
    {
      text: 'SETTINGS.SAVE',
      action: () => this.onSubmit(),
      classes: 'btn-primary',
      id: ButtonType.SAVE,
      disabled: !this.isFormValid,
    },
  ];

  constructor(
    private addressService: AddressService,
    private activeModal: NgbActiveModal,
    private userService: UserService,
    private countryService: CountryService,
    private toastService: ToastService,
    private translator: TranslateService,
    private loaderService: LoaderService
  ) {}

  ngOnInit() {
    this.countryService.all().subscribe((countries: CountryInterface[]) => {
      this.countries = countries;
    });
  }

  onChanged({ address, valid, formModel }: AddressModelInstanceChangeEventInterface) {
    this.address = address;
    this.formModel = formModel;
    this.isFormValid = valid;
    this.buttons.find((button) => button.id === ButtonType.SAVE).disabled = !this.isFormValid;
  }

  onSubmit() {
    this.loaderService
      .load(this.addressService.update(this.address.id, this.formModel as AddressUpdateInterface), this.loader)
      .subscribe((address: AddressInterface) => {
        this.translator.get('SETTINGS.SUCCESS').subscribe({
          next: translation => {
            this.toastService.success(translation);
            this.updated.emit(address);
            this.activeModal.dismiss('success');
          },
          error: err => {
            console.log(err);
            this.loader.hide();
          }
        });
      });
  }
}
