<div class="bg-gray-200" [ngClass]="{ 'hover-bg-white': !limitedView }">
  <div *ngIf="limitedView" class="d-flex p-3 color-gray-600">
    <div
      class="article-img border me-2 bg-white"
      [appHideByFieldType]="fieldTypes.IMG"
      [class.article-img--empty]="!row.item.image"
      [style.background-image]="
        row.item.image
          ? 'url(' + ((row.item.image | resizeImage: imageSizes.SIZE_140x140 | uncache | async) ?? '') + ')'
          : 'url(\'../../../../assets/images/catalogue-list/no-article-image.svg\')'
      "
    ></div>
    <div class="w-auto ms-1">
      <span class="h5 mb-0 fw-bold" [appHideByFieldType]="fieldTypes.SYSTEM">{{ row.item.system || row.title }}</span>
      <ng-container *ngTemplateOutlet="rowBadges"></ng-container>
      <p class="m-0 small">
        <span [appHideByFieldType]="fieldTypes.CODE">{{ row.originals | fullCodes }}</span>
        <span class="ms-1" [appHideByFieldType]="fieldTypes.CATEGORY" *ngIf="row.item?.category?.length">{{ row.item.category }}</span>
      </p>
      <p class="m-0 small" [title]="getTranslatedDescription(row.item, uiLanguage)">
        {{ getTranslatedDescription(row.item, uiLanguage) }}
      </p>
    </div>
  </div>

  <div
    *ngIf="!limitedView"
    class="row deep-hover-color-gray-400 list-row cursor--pointer"
    [ngClass]="{ selected: selected, 'invalid-row': invalidRow === true }"
    (click)="onToggleSelect()"
  >
    <div class="col-12 d-flex">

      <!-- drag handle -->
      <button class="btn btn-link d-flex color-gray-200 deep-hover drag-handle m-0 py-3 px-1" appDragHandle appClickStopPropagation
        [style.min-width.rem]="2"
        [class.invisible]="isFilterEnabled"
      ><i class="icon ni-reorder"></i></button>
      <!-- / drag handle -->

      <div class="first-column">
        <!-- placeholder which will get some space in nested levels -->
      </div>

      <!-- checkbox col -->
      <div class="py-3"
        [style.min-width.rem]="1.5"
      >
        <app-check-mark
          class="checkbox"
          [name]="row.id"
          [value]="selected"
          (changed)="onSelect($event)"
          appClickStopPropagation
        ></app-check-mark>
      </div>
      <!-- / checkbox col -->

      <!-- img col -->
      <div class="ps-2 py-3" [appHideByFieldType]="fieldTypes.IMG"
        [style.min-width.rem]="9.25"
      >
        <div class="article-img">
          <div
            class="article-img border bg-white position-relative"

            [class.article-img--empty]="!row.item.image"
            [style.background-image]="
              row.item.image
                ? 'url(' + ((row.item.image | resizeImage: imageSizes.SIZE_140x140 | uncache | async) ?? '') + ')'
                : 'url(\'../../../../assets/images/catalogue-list/no-article-image.svg\')'
            "
          >
            <a *ngIf="row.item.image" [href]="row.item.image" (click)="$event.stopPropagation()">
              <i
                [title]="'GALLERY.DOWNLOAD' | translate"
                class="bottom-0 color-white deep-hover hover-color-gray-500 icon ni-file-down position-absolute right-0 pb-0125r"
              ></i>
            </a>
          </div>
        </div>
      </div>
      <!-- / img col -->

      <!-- description col -->
      <div
        class="description ps-2 py-3 flex-grow-1 flex-shrink-1"
      >
        <span
          class="list-row__column__item-info__system color-black fw-bold"
          [appHideByFieldType]="fieldTypes.SYSTEM"
        >{{ row.item.system || row.title }}</span>

        <ng-container *ngTemplateOutlet="rowBadges"></ng-container>

        <div [appHideByFieldType]="[fieldTypes.CODE, fieldTypes.CATEGORY]" class="list-row__column__item-info__code small separated-items separated-items--line">
          <span [appHideByFieldType]="fieldTypes.CODE">{{ row.originals | fullCodes }}</span>
          <span [appHideByFieldType]="fieldTypes.CATEGORY" *ngIf="row.item?.category?.length">{{ row.item.category }}</span>
        </div>
        <span
          class="list-row__column__item-info__short-text small"
          [appHideByFieldType]="fieldTypes.SHORT_TEXT"
          [title]="getTranslatedDescription(row.item, uiLanguage)"
          [class.limited-short-text]="row.type === orderArticleTypes.OTHER_SUPPLIER_ITEM || row.type === orderArticleTypes.CUSTOM_ITEM_TYPE"
        >
          {{ getTranslatedDescription(row.item, uiLanguage) }}
        </span>
      </div>
      <!-- / description col -->

      <!-- materials col -->
      <div
        *ngIf="isColumnsVisible([fieldTypes.TYPE, fieldTypes.ORDER_ARTICLE_MATERIALS, fieldTypes.ORDER_ARTICLE_MATERIALS_INLINE])"
        class="ps-2 materials flex-shrink-0"
        [style.width.rem]="columns.materialsAndType.width"
      >
        <span [appHideByFieldType]="fieldTypes.TYPE" class="list-row__column__type text-break--word  py-3">
          {{ row.materialsAndType.type }}
        </span>

        <div class="values-list py-3 flex-column" [appHideByFieldType]="fieldTypes.ORDER_ARTICLE_MATERIALS">
          <!-- Other supplier item -->
          <ng-container *ngIf="row.type === orderArticleTypes.OTHER_SUPPLIER_ITEM">
            <div class="small">
              {{ row.materialsAndType.type }}
            </div>
          </ng-container>

          <!-- Non standard item -->
          <ng-container *ngIf="row.type === orderArticleTypes.CUSTOM_ITEM_TYPE">
            <div class="values-list__value" *ngFor="let material of rowMaterials">
              <ng-container *ngIf="material?.img; else defaultPatternImage">
                <div
                  (mouseenter)="onOpenPopover(material, popover, origin)"
                  (mouseleave)="onClosePopover()"
                  #origin
                  [ngClass]="{ 'd-flex': fieldsToShow.includes(fieldTypes.ORDER_ARTICLE_MATERIALS_INLINE) }"
                >
                  <div class="values-list__value--img" [style.background-image]="'url(' + ((material?.img | resizeImage: imageSizes.SIZE_16x16 | uncache | async) ?? '') + ')'"></div>
                  <div [appHideByFieldType]="fieldTypes.ORDER_ARTICLE_MATERIALS_INLINE" class="small">
                    {{ material.translation }}
                  </div>
                </div>
              </ng-container>
              <ng-template #defaultPatternImage>
                <span
                  class="values-list__value--text small"
                  (mouseenter)="onOpenPopover(material, popover, origin)"
                  (mouseleave)="onClosePopover()"
                  #origin
                >
                  {{ material.translation }}
                </span>
              </ng-template>
            </div>
          </ng-container>

          <!-- Regular product -->
          <ng-container *ngIf="row.type !== orderArticleTypes.OTHER_SUPPLIER_ITEM && row.type !== orderArticleTypes.CUSTOM_ITEM_TYPE">
            <div class="values-list__value" *ngFor="let material of row.materialsAndType.materials">
              <ng-container *ngIf="material?.img; else noPatternImage">
                <div
                  (mouseenter)="onOpenPopover(material, popover, origin)"
                  (mouseleave)="onClosePopover()"
                  #origin
                  [ngClass]="{ 'd-flex': fieldsToShow.includes(fieldTypes.ORDER_ARTICLE_MATERIALS_INLINE) }"
                >
                  <div class="values-list__value--img" [style.background-image]="'url(' + ((material?.img | uncache | async) ?? '') + ')'"></div>
                  <div [appHideByFieldType]="fieldTypes.ORDER_ARTICLE_MATERIALS_INLINE" class="small">
                    {{ getMaterialDetailsText([material.code, material.translation]) }}
                  </div>
                </div>
              </ng-container>
              <ng-template #noPatternImage>
                <span
                  class="values-list__value--text"
                  (mouseenter)="onOpenPopover(material, popover, origin)"
                  (mouseleave)="onClosePopover()"
                  #origin
                >
                  {{ getMaterialDetailsText([material.code, material.translation]) }}
                </span>
              </ng-template>
            </div>
          </ng-container>
        </div>
      </div>
        <!-- / materials col -->

      <!-- dimensions col -->
      <div
        *ngIf="isColumnsVisible([fieldTypes.DIMENSIONS, fieldTypes.WEIGHT, fieldTypes.VOLUME])"
        class="details pe-2 py-3 flex-shrink-0 small"
        [style.width.rem]="columns.details.width"
      >
        <span [appHideByFieldType]="fieldTypes.DIMENSIONS">
          {{ formatDimensions(row.size) }}
        </span>
        <span [appHideByFieldType]="fieldTypes.WEIGHT">
          {{ 'ORDER_ARTICLES.LIST.SIZE_INFO.BRUTO_WEIGHT' | translate }}: {{ row.totalWeight }}
        </span>
        <span [appHideByFieldType]="fieldTypes.VOLUME">
          {{ 'ORDER_ARTICLES.LIST.SIZE_INFO.VOLUME' | translate }}: {{ row.totalVolume }}
        </span>
      </div>
      <!-- / dimensions col -->

      <!-- quantity col -->
      <div class="quantity text-end pe-2 py-3 flex-shrink-0"
        [style.width.rem]="columns.quantity.width"
      >
        <span class="list-row__column__number color-gray-900">
          {{ row.configurationQuantity ? row.configurationQuantity : row.quantity }}
        </span>
      </div>
      <!-- / quantity col -->

      <!-- single price col -->
      <div class="single-price text-end py-3 pe-2 flex-shrink-0" [class.active-mode--sale]="selectedMode === saleMode.SALE"
        [style.width.rem]="columns.price.width"
      >
        <span class="list-row__column__number color-gray-900">
          <visibility-by-variables [hideIf]="['hidePrices']" [replacement]="'-'">
            {{ (selectedMode === saleMode.SALE ? row.formattedConfigurationSalePrice : row.formattedConfigurationPurchasePrice) | priceFormat: { zeroReplacement: 'N/A' } }}
          </visibility-by-variables>
        </span>
      </div>
      <!-- / single price col -->

      <!-- discount col -->
      <div
        *ngIf="columns.discount.visible || columns.saleDiscount.visible"
        class="list-row__column text-end flex-shrink-0 ps-0 pe-2 py-3"
        [class.active-mode--sale]="selectedMode === saleMode.SALE"
        [appHideByFieldType]="fieldTypes.DISCOUNT"
        [style.width.rem]="columns.discount.width"
      >
        <span class="list-row__column__number color-gray-900">
          {{ (columns.saleDiscount.visible ? row.formattedSaleDiscount : row.formattedConfigurationDiscount) | discount }}
        </span>
      </div>
      <!-- / discount col -->

      <!-- total price col -->
      <div class="total-price text-end py-3 flex-shrink-0" [class.active-mode--sale]="selectedMode === saleMode.SALE"
        [style.width.rem]="columns.totalPrice.width"
      >
        <span class="list-row__column__number color-gray-900">
          <visibility-by-variables [hideIf]="['hidePrices']" [replacement]="'-'">
            <ng-container
              *ngIf="[orderArticleTypes.OTHER_SUPPLIER_ITEM, orderArticleTypes.CUSTOM_ITEM_TYPE].includes(row.type); else totalPriceStandardTemplate"
            >
              {{ selectedMode === saleMode.SALE || row.customPrice ? (row.formattedTotalConfigurationPrice | priceFormat) : 'N/A' }}
            </ng-container>
            <ng-template #totalPriceStandardTemplate>
              {{ row.formattedTotalConfigurationPrice | priceFormat: { zeroReplacement: 'N/A' } }}
            </ng-template>
          </visibility-by-variables>
        </span>
      </div>
      <!-- / total price col -->

      <!-- row actions col -->
      <div class="actions px-3 py-3 flex-shrink-0" [class.active-mode--sale]="selectedMode === saleMode.SALE"
        [style.width.rem]="columns.actions.width"
      >
        <button
          class="btn no-outline btn--action d-flex p-1 m-0"
          *ngIf="orderState === orderStates.AX_ARCHIVE_CONFIRMED || selectedMode === saleMode.ORDER || user?.role?.name === userRole.ROLE_PM_NARBUTAS; else pencil"
          (click)="onToggleRowDetails(row.id)"
          appClickStopPropagation
          [ngClass]="{
            'bg-gray-600 color-white hover-color-gray-300': openRowDetails === row.id,
            'color-gray bg-gray-200 hover-color-black': openRowDetails !== row.id
          }"
        >
          <i class="icon ni-list-alt"></i>
        </button>
        <ng-template #pencil>
          <button
            class="btn bg-gray-600 no-outline p-1 m-0 color-white hover-color-white hover-bg-gray-700 btn--action d-flex"
            (click)="onApplyCustomDiscount(row)"
            appClickStopPropagation
          >
            <i class="icon ni-create"></i>
          </button>
        </ng-template>
      </div>
      <!-- / row actions col -->
    </div>
  </div>

  <div class="first-column article-list-border" [class.no-img]="!isColumnsVisible([fieldTypes.IMG])" *ngIf="openRowDetails === row.id">
    <div class="border-top"></div>
  </div>
  <div class="first-column">
    <app-order-articles-list-row-details-list
      [columns]="fieldsToShow"
      [columnConfig]="columns"
      [row]="row"
      *ngIf="openRowDetails === row.id"
    ></app-order-articles-list-row-details-list>
  </div>
</div>

<ng-template #popover let-img="img" let-value="value">
  <div class="preview-popover" [ngClass]="{ 'm-0': !img }">
    <ng-container>
      <img
        class="bg-white"
        id="image0"
        [attr.width]="img ? 110 : 120"
        height="110"
        [attr.src]="(img | resizeImage: imageSizes.SIZE_120x120) || '../../../assets/icons/icon-material-placeholder-large.svg'"
      />
    </ng-container>
    <div class="preview-popover__value">
      {{ value }}
    </div>
  </div>
</ng-template>

<ng-template #rowBadges>
  <div [appHideByFieldType]="[fieldTypes.SYSTEM, fieldTypes.CODE, fieldTypes.CATEGORY, fieldTypes.SHORT_TEXT]">
    <div *ngIf="row.migrationStatus" class="d-flex">
      <span class="align-items-center badge rounded-pill bg-danger color-white d-flex float-start p-0 w-auto">
        <i class="icon ni-flaky"></i>
        <span class="ps-1 pe-2" [title]="row.migrationStatus">{{ row.migrationStatus }}</span>
      </span>
    </div>

    <div *ngIf="row.type === orderArticleTypes.CUSTOM_ITEM_TYPE" class="d-flex mb-1">
      <span class="align-items-center badge rounded-pill bg-gray-600 color-white d-flex float-start p-0 w-auto">
        <i class="icon ni-add-task"></i>
        <span class="ps-1 pe-2">{{ 'ORDERS.ITEM_TYPE.NON_STANDARD' | translate }}</span>
      </span>
    </div>
    <div *ngIf="row.type === orderArticleTypes.OTHER_SUPPLIER_ITEM" class="d-flex mb-1">
      <span class="align-items-center badge rounded-pill bg-gray-600 color-white d-flex float-start p-0 w-auto">
        <i class="icon ni-blur-circular"></i>
        <span class="ps-1 pe-2">{{ 'ORDERS.ITEM_TYPE.OTHER_SUPPLIER' | translate }}</span>
      </span>
    </div>
  </div>
</ng-template>
