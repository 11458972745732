<ng-container *ngIf="property">
  <ng-container *ngIf="property.added && property.removable; then addedRemovable"></ng-container>
  <ng-container *ngIf="property.added && property.mandatory; then addedMandatory"></ng-container>
  <ng-container *ngIf="!property.added; then other"></ng-container>

  <ng-template #addedMandatory>
    <div class="w-100 added-mandatory" [attr.added-additional-part-mandatory]="1">
      <app-property-values-selector
        [property]="property"
        (propertyChange)="onPropertyChange($event)"
        [theme]="selectorTheme"
        [accordionTheme]="accordionThemes.DEFAULT"
        [id]="property?.configuratorId + '_' + property?.originalName"
        [isRootControllingProperty]="isRootControllingProperty"
      ></app-property-values-selector>
    </div>
  </ng-template>

  <ng-template #addedRemovable>
    <div class="w-100 added-removable" [attr.added-additional-part]="1">
      <app-property-values-selector
        [property]="property"
        [theme]="selectorTheme"
        [accordionTheme]="accordionTheme()"
        [propertyType]="propertyType()"
        (propertyChange)="onPropertyChange($event)"
        [actionButtonTemplate]="property.editable ? removeButton : null"
        [isRootControllingProperty]="isRootControllingProperty"
      ></app-property-values-selector>
    </div>
  </ng-template>

  <ng-template #other>
    <app-property-values-selector
      class="added-other"
      [property]="property"
      [theme]="selectorTheme"
      [accordionTheme]="accordionTheme()"
      [propertyType]="propertyType()"
      (propertyChange)="onPropertyChange($event)"
      [id]="property?.configuratorId + '_' + property?.originalName"
      [isRootControllingProperty]="isRootControllingProperty"
      [actionButtonTemplate]="propertyCanBeeRemoved(property) ? removeButton : null"
    ></app-property-values-selector>
  </ng-template>
</ng-container>

<ng-template #removeButton>
  <button (click)="onRemove($event)" class="btn btn-default p-0">
    <i class="icon ni-remove-circle-outline d-flex color-danger"></i>
  </button>
</ng-template>
