import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { LocalStorageKeys } from '../../../core/constants/local-storage-keys.constants';

@Injectable({
  providedIn: 'root',
})
export class InfoPopoversService {
  private openedInfoPopover$: Subject<string | null> = new Subject();

  getOpenedInfoPopoverAsObservable(): Observable<string | null> {
    return this.openedInfoPopover$.asObservable();
  }

  setOpenedInfoPopover(materialFamilyGroupId: string): void {
    this.openedInfoPopover$.next(materialFamilyGroupId);
  }

  dismissInfoPopovers(): void {
    localStorage.setItem(LocalStorageKeys.COMPATIBILITY_INFO_POPOVER_DISMISSED, 'true');

    this.openedInfoPopover$.next(null);
  }
}
