import { Directive, OnInit } from '@angular/core';
import { DateInputComponent } from './date-input.component';

/**
 * Sets today's date as minimum date on app-date-input component
 */
@Directive({
  selector: 'app-date-input[appMinDateNow]'
})
export class MinDateNowDirective implements OnInit {

  constructor(
    private dateInputComponent: DateInputComponent
  ) { }

  ngOnInit() {
    const nowDate = new Date();
    this.dateInputComponent.minDate = {
      year: nowDate.getFullYear(),
      month: nowDate.getMonth() + 1,
      day: nowDate.getDate(),
    }
  }
}
