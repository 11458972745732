import { Component, Input, TemplateRef } from '@angular/core';
import { FinishItem, FinishTexture, FinishType } from '../../../content/finishes/finishes.model';
import { PropertyImageSizes } from '../../../core/enums/property-image-sizes.enum';
import { Observable } from 'rxjs';
import { UncachePipe } from '../../../uncacheable-resource/uncache.pipe';
import { PopoverService } from '../../../ui-elements/popover/popover.service';
import { PopoverRef } from '../../../ui-elements/popover/popover-ref';

type FinishTypeGroup = { finishType: FinishType, finishItems: FinishItem[] };

@Component({
  selector: 'app-finishes-info',
  templateUrl: './finishes-info.component.html',
  styleUrls: ['./finishes-info.component.scss']
})
export class FinishesInfoComponent {
  finishesGroupedByType: FinishTypeGroup[];
  @Input() set finishes(value: FinishItem[]) {
    this.finishesGroupedByType = this.groupByType(value);
  };

  popover: PopoverRef;

  constructor(
    private uncachepipe: UncachePipe,
    private popoverService: PopoverService
  ) {}

  groupByType(finishes: FinishItem[]): FinishTypeGroup[] {
    const typeMap = new Map<FinishType['id'], FinishTypeGroup>();

    finishes.forEach(finish => {
      if (!typeMap.has(finish.finishType.id)) {
        typeMap.set(finish.finishType.id, {
          finishItems: [finish],
          finishType: finish.finishType
        })
      } else {
        typeMap.get(finish.finishType.id).finishItems.push(finish);
      }
    })

    return Array.from(typeMap.values());
  }

  getUncachedImageBackgroundLowestToHighest(
    images: FinishTexture['images'],
  ): Observable<string> {
    const value =
      (images && (
        images[PropertyImageSizes.SMALL] ||
        images[PropertyImageSizes.MEDIUM] ||
        images[PropertyImageSizes.EXTRA_LARGE]
      )) ?? '';
    return this.uncachepipe.transform(value);
  }

  onClosePopover() {
    this.popover.close();
  }

  onOpenPopover(family: FinishItem, finish: FinishTexture, templateRef: TemplateRef<any>, origin: HTMLElement) {
    const description = [finish.valueFrom, finish.title].filter(Boolean).join(' ');
    const images = finish.images;
    this.popover = this.popoverService.open<{ img: string; value: string }>({
      content: templateRef,
      origin,
      width: '6.875rem',
      height: '6.875rem',
      data: {
        img: images[PropertyImageSizes.EXTRA_LARGE] ||
          images[PropertyImageSizes.MEDIUM] ||
          images[PropertyImageSizes.SMALL],
        value: description
      },
    });
  }
}
