export interface MessageOptionsInterface {
  closable?: boolean;
  autoClose?: boolean;
  closeAfter?: number;
  onButtonClick?: Function;
  buttonComponent?: any;
}

export class MessageOptions implements MessageOptionsInterface {
  closable = true;
  autoClose = true;
  closeAfter = 6000;
}
