<app-navbar-wrapper>
  <ng-container navbar-left-side>
    <a
      class="navbar-image mx-0"
      routerLink="/sign-in"
      app-logo
    ><!-- Logo SVG is added through `app-logo` attribute component --></a>
  </ng-container>

  <ng-container navbar-right-side>
    <ul class="h-100 d-flex align-items-center list-unstyled m-0 p-0">
      <li class="nav-item nav-icon">
        <app-language-switch></app-language-switch>
      </li>
      <li class="nav-item nav-icon ps-3">
        <a routerLink="/sign-in" class="btn btn-primary">{{ 'AUTH.SIGN_IN.SIGN_IN' | translate }}</a>
      </li>
    </ul>
  </ng-container>
</app-navbar-wrapper>
