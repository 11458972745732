import { Component, Input, Output, EventEmitter } from '@angular/core';

export type AlertVariant = 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark' | 'gray' | 'dark-gray';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})

export class AlertComponent {
  @Input() icon: string = 'ni-info';
  @Input() actionOne: string;
  @Input() actionTwo: string;
  @Input() actionOneIcon: string;
  @Input() actionTwoIcon: string;
  @Input() variant: AlertVariant | null = null;
  @Input() closeable: boolean = true;

  @Output() onActionIconClick = new EventEmitter<MouseEvent>();
  @Output() onActionOneClick = new EventEmitter<MouseEvent>();
  @Output() onActionTwoClick = new EventEmitter<MouseEvent>();
  @Output() onActionCloseClick = new EventEmitter<MouseEvent>();

  getBgVariantClass(variant: AlertVariant | null) {
    let res = 'bg-info'
    switch(variant) {
      default:
      case 'primary':
        res = 'bg-primary text-white';
        break;
      case 'secondary':
        res = 'bg-secondary';
        break;
      case 'success':
        res = 'bg-success text-white';
        break;
      case 'danger':
        res = 'bg-danger text-white';
        break;
      case 'warning':
        res = 'bg-warning text-white';
        break;
      case 'info':
        res = 'bg-info text-white';
        break;
      case 'light':
        res = 'bg-light';
        break;
      case 'dark':
        res = 'bg-dark text-white';
        break;
      case 'gray':
        res = 'bg-gray-400 color-gray-900'
        break;
      case 'dark-gray':
        res = 'bg-gray-700 color-gray-100'
        break;
    }

    return res;
  }

  getBtnVariantClass(variant: AlertVariant) {
    let res = 'bg-info'
    switch(variant) {
      default:
      case 'primary':
        res = 'btn-outline-white';
        break;
      case 'secondary':
        res = 'btn-secondary';
        break;
      case 'success':
        res = 'btn-outline-white';
        break;
      case 'danger':
        res = 'btn-outline-white';
        break;
      case 'warning':
        res = 'btn-outline-white';
        break;
      case 'info':
        res = 'btn-outline-white';
        break;
      case 'light':
        res = 'btn-outline-dark border-dark';
        break;
      case 'dark':
        res = 'btn-outline-white';
        break;
      case 'gray':
        res = 'btn-transparent border-color-gray-700 color-black';
        break;
    }

    return res;
  }

  get hasFirstAction() {
    return this.actionOne || this.actionOneIcon;
  }

  get hasSecondAction() {
    return this.actionTwo || this.actionTwoIcon;
  }
}
