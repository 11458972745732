import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ErrorService, FileValidationErrorsType } from '../error/error.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastService } from '../../../ui-elements/toast/toast.service';
import { TranslateService } from '@ngx-translate/core';

export interface ChatStateInterface {
  isSending: boolean;
  validationErrors?: FileValidationErrorsType;
}

@Injectable()
export class ChatHelperService {
  chatState = new BehaviorSubject<ChatStateInterface>({
    isSending: false,
    validationErrors: null,
  });

  constructor(
    private errorService: ErrorService,
    private translator: TranslateService,
    private toastService: ToastService,
  ) {}

  parseValidationErrors(errorResponse: HttpErrorResponse) {
    const validationErrors = this.errorService.indexedFileErrorsFromResponse(errorResponse);

    this.setValidationErrors(validationErrors);
  }

  setValidationErrors(validationErrors: FileValidationErrorsType) {
    this.chatState.next({
      ...this.chatState.value,
      validationErrors,
    });

    const nonNullValidationErrors = Object.values(validationErrors).filter(Boolean);

    if (nonNullValidationErrors.length) {
      this.translator.get('ERRORS.BY_ERROR_CODE').subscribe((translation: string) => {
       this.toastService.danger(`${translation[nonNullValidationErrors[0]]}`);
      });
    }
  }

  clearValidationErrors() {
    this.chatState.next({
      ...this.chatState.value,
      validationErrors: null,
    });
  }

  setIsSending(isSending: boolean) {
    this.chatState.next({
      ...this.chatState.value,
      isSending,
    });
  }
}
