import { Component, Input } from '@angular/core';

import { DeliveryOptions } from '../../../core/enums/delivery-options.enum';
import { AddressInterface } from '../../../core/models/address.model';
import { isAddressFullyFilled } from '../address-filled-check';

@Component({
  selector: 'app-address-item',
  templateUrl: './address-item.component.html',
  styleUrls: ['./address-item.component.scss'],
  host: {
    class: 'd-flex justify-content-between w-100'
  }
})
export class AddressItemComponent {
  @Input() set address(value: AddressInterface | null) {
    this.extractParts(value);
  };
  @Input() hideMissingInfoError = false;

  isAddressFullyFilled = false;
  addressData: string[] = [];
  contactPersonData: string[] = [];
  deliveryAddressee = '';
  contactWorkingHours = '';
  deliveryOption: DeliveryOptions | undefined;

  extractParts(address: AddressInterface | null) {
    this.addressData = [];
    this.contactPersonData = [];

    this.isAddressFullyFilled = isAddressFullyFilled(address);

    if (!address) {
      return;
    }

    if (address.address) {
      this.addressData.push(address.address);
    }
    if (address.postCode) {
      this.addressData.push(`${address.postCode} ${address.city ? address.city : ''}`);
    }
    if (address.country?.title) {
      this.addressData.push(address.country.title);
    } else if (address.country?.code) {
      this.addressData.push(address.country.code);
    }

    if (address.contactPerson) {
      this.contactPersonData.push(address.contactPerson);
    }
    if (address.email) {
      this.contactPersonData.push(address.email);
    }
    if (address.phone) {
      this.contactPersonData.push(address.phone);
    }

    this.deliveryAddressee = address.deliveryAddressee;
    this.contactWorkingHours = address.contactWorkingHours;
    // this.deliveryOption = address.deliveryOption;
  }
}
