import { Injectable } from '@angular/core';
import { ApiService } from '../../../api.service';
import { CacheableObservable } from '../../../cacheable-observable/cacheable-observable.model';
import { NotificationsListResponse } from '../../../core/models/notification.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private apiService: ApiService) {}

  getAll(page?: number): CacheableObservable<NotificationsListResponse> {
    return this.apiService.get(`notification/`, { page }) as CacheableObservable<NotificationsListResponse>;
  }

  markAllAsSeen(): Observable<void> {
    return this.apiService.post(`notification/seen`, null) as Observable<void>;
  }

  markAsSeen(id: number): Observable<void> {
    return this.apiService.post(`notification/seen/${id}`, null);
  }
}
