import {
  Component,
  ElementRef,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {ModelViewer} from './model-viewer';
import {ModelViewerService} from './model-viewer.service';
import {ModelViewerConstants} from './model-viewer.constants';
import {ConfiguratorService} from '../../core/services/configurator/configurator.service';
import {ProductsService} from '../../catalogue/products/products.service';
import {LoaderService} from '../../ui-elements/loader/loader.service';
import {ConfiguratorModalService} from '../configurator-modal.service';
import {FileFormat} from '../../core/enums/file-format.enum';
import {catchError, delay, switchMap, tap} from 'rxjs/operators';
import {of, Subscription} from 'rxjs';
import ModelService from '../model/model-service';
import MeshNode from '../mesh/mesh-node';
import {BoundingBoxService} from '../bounding-box/bounding-box-service';
import {ConfiguratorModalComponent} from '../configurator-modal.component';
import MeshExtended from '../mesh/mesh-extended';
import {ModalComponent} from '../../shared/components/modal/modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { throwError } from 'rxjs';

export enum ModelViewType {
  TYPE_NONE = 'NONE',
  TYPE_3D = '3D',
}

export interface ArticleRef {
  article: any;
  mesh: any;
  node: MeshExtended;
}

export interface ViewerRef {
  viewer: any;
  scene: any;
}

@Component({
  selector: 'app-configurator-modal-model-viewer',
  templateUrl: './model-viewer.component.html',
  styleUrls: ['./model-viewer.component.scss'],
  providers: [LoaderService, BoundingBoxService],
  encapsulation: ViewEncapsulation.None,
})
export class ModelViewerComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('viewer', { static: true }) viewerElement: ElementRef;
  @ViewChild('loader', { static: true }) loader;

  @Input() session: string;
  @Input() item: string;
  @Input() server: string;
  @Input() updated?: number = null;
  @Input() viewType?: ModelViewType;
  public fileFormat = FileFormat;
  public showDropdown = false;
  public viewerNotAvailable = false;
  private viewer: ModelViewer;
  private subscriptions: Subscription = new Subscription();
  private ROTATION_AMOUNT = 10;
  public darkMode = 'dark';
  public lightMode = 'light';
  public activeBgMode = this.lightMode;

  constructor(
    private viewerService: ModelViewerService,
    private configuratorModalService: ConfiguratorModalService,
    private configuratorModalComponent: ConfiguratorModalComponent,
    private productsService: ProductsService,
    private loaderService: LoaderService,
    private ngZone: NgZone,
    private configuratorService: ConfiguratorService,
    private boundingBoxService: BoundingBoxService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.loader.show();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.viewType && changes.viewType.currentValue) {
      if (this.viewType === ModelViewType.TYPE_NONE) {
        this.viewerNotAvailable = true;
        this.loader.hide();
        return;
      }
    }

    if (!this.viewerNotAvailable && !changes.session && changes.updated) {
      this.updateGeometry().subscribe();
      return;
    }

    if (!this.viewerNotAvailable && changes.updated && this.server && this.item && this.session) {
      this.loaderService
        .load(this.render(), this.loader)
        .pipe(
          catchError(e => {
            this.viewerNotAvailable = true;
            return throwError(() => e);
          })
        )
        .subscribe();
    }

    if (changes.updated && this.session) {
      this.subscriptions.add(this.configuratorService
        .keepAlive(this.session, 'test', { interval: 10000 }) //
        .subscribe(
          res => {
            if (res && res.data && res.data.version > 0) {
              this.configuratorModalService.setCurrentVersionId(res.data.version);
            }
          },
          error => {
            const modalRef = this.modalService.open(ModalComponent, {
              windowClass: '',
              size: 'sm',
              keyboard: false,
              beforeDismiss: () => false,
            });
            modalRef.componentInstance.component = 'app-reload';
          }
        )
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();

    /**
     * @NOTE: This is needed to stop requestAnimationFrame iterations.
     * It triggers angular ngZone's onStable event emitter so ngbDropdown's show is being triggered on every iteration
     */
    if (this.viewer) {
      this.viewer.stopRendering();
    }

    this.boundingBoxService.destruct();
  }

  render() {
    return of(0).pipe(
      delay(0), // initialize viewer after configurator modal is rendered.
      switchMap(() => {
        this.viewerElement.nativeElement.innerHTML = '';
        /**
         * We have to run this outside the angular, because it runs requestAnimationFrame
         * which triggers angular to rerender whole DOM and do changeDetection every animation frame request
         */
        this.ngZone.runOutsideAngular(() => {
          this.viewer = this.viewerService.create(
            this.server,
            this.session,
            this.item,
            this.viewerElement.nativeElement,
            ModelViewerConstants.ASSETS_PATH
          );
        });

        this.viewer.connect();
        this.viewerService.viewerRef$.next(this.viewerRef);

        return this.createGeometry();
      })
    );
  }

  updateGeometry() {
    if (this.viewer) {
      return this.viewer.updateGeometry().pipe(
        tap(() => {
          this.extendOriginMesh();
          this.configuratorModalService.setArticleRef(this.articleRef);
        })
      );
    }
  }

  createGeometry() {
    return this.viewer.createGeometry().pipe(
      tap(() => {
        this.extendOriginMesh();
        this.configuratorModalService.setArticleRef(this.articleRef);
      })
    );
  }

  extendOriginMesh() {
    const originMesh = this.articleRef.mesh;
    const originScene = this.viewerRef.scene;

    new ModelService(originMesh)
      .extendMesh(undefined, originScene)
      .addType()
      .addName()
      .bindExecuteCodeAction(undefined, originScene, ViewerDependencies.ActionManager.OnPickTrigger, (mesh: MeshNode) => {
        const mainArticle = mesh.mExtended.getMain(),
          rootId = mesh.mExtended.getRoot().getArticleId(),
          mainId = mainArticle.getArticleId();

        window.console.debug('Article ids:', { rootId, mainId });

        const nextId = this.boundingBoxService.getCurrentArticleId() === mainId ? null : mainId;
        this.configuratorModalService.setSelectedArticleId(nextId);
        this.configuratorModalService.triggerModelPartSelectedEvent();
      });
  }

  selectArticle(id: string) {
    this.configuratorModalComponent.selectArticle(id);
  }

  onRotate(direction: 'left' | 'right') {
    let x = this.ROTATION_AMOUNT;
    if (direction === 'right') {
      x *= -1;
    }
    this.viewer.viewer.updatePosition({ x: 0, y: 0 }, { x, y: 0 });
  }

  /**
   * Switch configurator background mode
   *
   * @param mode string
   */
  onSetBackground(mode: string) {
    let r = 1;
    let g = 1;
    let b = 1;
    let a = 1;
    switch (mode) {
      case this.darkMode:
        this.activeBgMode = this.darkMode;
        r = 0.8;
        g = 0.8;
        b = 0.8;
        a = 1;
        break;
      case this.lightMode:
        this.activeBgMode = this.lightMode;
        r = 1;
        g = 1;
        b = 1;
        break;
    }

    this.viewer.viewer.updateBackground(r, g, b, a).then(() => {
      this.extendOriginMesh();
      this.boundingBoxService.redraw(this.articleRef.node);
    });
  }

  downloadFile(format: FileFormat, height?: number, width?: number) {
    const { x, y } = this.viewer.viewer.mView.mCamera.rotation;
    const options = width && height ? { height, width } : {};
    options['alpha'] = ((y * -180) / Math.PI).toFixed(2);
    options['beta'] = ((x * 180) / Math.PI).toFixed(2);
    options['background'] = this.activeBgMode === this.darkMode ? "0.8 0.8 0.8" : "1 1 1";

    this.loader.show();
      this.productsService
        .downloadConfiguratorFile(this.item, format, options)
        .add(() => this.loader.hide());
  }

  public get articleRef(): ArticleRef {
    return {
      article: this.viewer.viewer.mArticle,
      mesh: this.viewer.viewer.mArticle.mesh,
      node: this.viewer.viewer.mArticle.mesh.mExtended,
    };
  }

  public get viewerRef(): ViewerRef {
    return {
      scene: this.viewer.viewer.mView.mViewer.mScene,
      viewer: this.viewer.viewer.mView.viewer,
    };
  }

  viewerContainerSizeChanged(): void {
    this.viewer?.resizeViewer();
  }
}
