<div class="position-relative" appUploadDragDrop (fileDropped)="onDrop($event)">
  <div
    class="my-0 d-flex align-items-center"
    [ngClass]="previewClass"
    [class.position-absolute]="contentRef.children.length"
    [class.right-0]="contentRef.children.length"
    [class.me-2]="contentRef.children.length"
    [class.mt-2]="contentRef.children.length"
  >
    <i
      class="icon ni-close bg-gray-600 rounded-circle color-white p-1 hover-bg-gray-800"
      *ngIf="removable"
      (click)="onRemove()"
    ></i>
    <ng-container *ngIf="noContentPassed && !contentRef.children.length">
      <img [src]="imgSrc" [ngClass]="imgClass" />
    </ng-container>
    <ng-container *ngIf="showUploadLinkOnly">
      <button class="btn btn-link" (click)="onActivateFileInput($event)">{{ 'SETTINGS.PROFILE.PHOTO.UPLOAD' | translate }}</button>
      <input
        class="d-none"
        [disabled]="disabled"
        [id]="inputName"
        type="file"
        (change)="onSelect($event)"
        [accept]="acceptableTypes"
        #input
        [lang]="locale"
      />
    </ng-container>
  </div>
  <div #contentRef>
    <ng-content select="[placeholder]"></ng-content>
  </div>
  <ng-container *ngIf="!(fileUrl || file) && contentRef.children.length">
    <div class="d-flex">
      <button class="btn bg-gray-600 hover-bg-gray-700 color-white mx-auto mt-2" (click)="onActivateFileInput($event)">{{ label }}</button>
      <input
        class="d-none"
        [disabled]="disabled"
        [id]="inputName"
        type="file"
        (change)="onSelect($event)"
        [accept]="acceptableTypes"
        #input
        [lang]="locale"
      />
    </div>
  </ng-container>
  <div *ngIf="!noContentPassed" [ngClass]="inputClass">
    <label [for]="inputName" class="w-50 me-1">
      <ng-container *ngIf="noContentPassed">
        <span *ngIf="label">{{ label }}</span>
      </ng-container>
      <div #content>
        <ng-content select="[label]"></ng-content>
      </div>
    </label>
    <div class="w-50 custom-file">
      <input
        class="form-control"
        [disabled]="disabled"
        [id]="inputName"
        type="file"
        (change)="onSelect($event)"
        [accept]="acceptableTypes"
        #input
        [lang]="locale"
      />
    </div>
  </div>
</div>
