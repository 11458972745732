import { Component, ContentChild, Input, TemplateRef, ViewEncapsulation } from '@angular/core';
import { NgControl } from '@angular/forms';

import { InlineFieldErrorsTemplateDirective } from '../inline-field-errors-template.directive';
import { InlineFieldInfoTemplateDirective } from '../inline-field-info-template.directive';

/**
 * Component for automatically positioning label and some form element, and for displaying
 * validation errors of inline form controls. Adds *(asterisk) automatically when
 * Validators.required is in validators, although it can be disabled by adding
 * [showRequired]="false" element.
 *
 * @example
 * // simple usage example
 * <app-inline-field>
 *  <label appInlineFieldLabel for="someFieldId">Label text</label>
 *  <input id="someFieldId" formControlName="nameOfControl" type="text">
 * </app-inline-field>
 *
 * @example
 * // component can take template for customizing display of validation errors:
 * <app-inline-field>
 *  <label appInlineFieldLabel for="someFieldId">Label text</label>
 *  <app-custom-component [id]="'someFieldId'" formControlName="nameOfControl"></app-custom-component>
 *  <ng-template appInlineFieldErrorsTemplate let-errors>
 *    {{ errors | json }}
 *  </ng-template>
 * </app-inline-field>
 *
 * @example
 * // component can take template for adding content under field(eg. explanation)
 * <app-inline-field>
 *  <label appInlineFieldLabel for="someSelectId">Label text</label>
 *  <select id="someSelectId" formControlName="nameOfControl">
 *    <option [ngValue]="true">Yes</option>
 *    <option [ngValue]="false">No</option>
 *  </select>
 *  <ng-template appInlineFieldInfoTemplate>
 *    Some explanation text under the field
 *  </ng-template>
 * </app-inline-field>
 */
@Component({
  selector: 'app-inline-field',
  templateUrl: './inline-field.component.html',
  exportAs: 'inlineField',
  styleUrls: ['./inline-field.component.scss'],
  host: {
    class: 'd-block mb-3'
  },
  encapsulation: ViewEncapsulation.None,
})
export class InlineFieldComponent {
  @Input() defaultErrors = false;

  @ContentChild(NgControl) ngControl?: NgControl;
  @ContentChild(InlineFieldErrorsTemplateDirective, { read: TemplateRef }) errorsTemplate?: TemplateRef<InlineFieldErrorsTemplateDirective>;
  @ContentChild(InlineFieldInfoTemplateDirective, { read: TemplateRef }) additionalInfoTemplate?: TemplateRef<InlineFieldInfoTemplateDirective>;
}
