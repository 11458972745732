import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { TreeService } from '../../core/tree/tree.service';
import { ProfileModalComponent } from '../profile-modal/profile-modal.component';
import { UserService } from '../../core/services/user/user.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-modal-profile-opener',
  template: ''
})
export class ModalProfileOpenerComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();

  constructor(
    private modalService: NgbModal,
    private treeService: TreeService,
    private userService: UserService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.open();
  }

  open() {
    this.subscription.add(
      this.userService.getUser().subscribe(user => {
        if (user.isProfileComplete) {
          this.router.navigateByUrl('/');
          return;
        }

        this.treeService.closeAll();
        this.modalService.open(ProfileModalComponent, {
          size: 'lg',
          scrollable: true,
          keyboard: false,
          backdrop: 'static'
        });
      })
    );
    
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
