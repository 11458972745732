import { Directive } from '@angular/core';

@Directive({
  selector: '[appTextFieldActions]'
})
export class TextFieldActionsDirective {

  constructor() { }

}
