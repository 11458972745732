export enum ActionType {
  MODIFY = 'modify',
  GROUP = 'group',
  COPY = 'copy',
  CUT = 'cut',
  PASTE = 'paste',
  EDIT = 'edit',
  ADD_ITEMS = 'addItems',
  ADD_EXTRA_ITEMS = 'addExtraItems',
  IMPORT = 'import',
  IMPORT_TO_ORDER = 'import',
  DISCOUNT = 'discount',
  REMOVE = 'remove',
  UNGROUP = 'ungroup',
  ADD_STANDARD_ITEM = 'addStandardItem',
  ADD_OTHER_SUPPLIER_ITEM = 'addOtherSupplierItem',
  ADD_NON_STANDARD_ITEM = 'addNoneStandardItem',
  ADD_PAGE_BREAK = 'addPageBreak',
  ADD_TEXT = 'addText',
  EXPORT_TO_PDF = 'exportToPdf',
  CREATE_NEW_CLAIM = 'createNewClaim',
}
