<button class="btn btn-lg no-outline h-100 px-3 d-flex align-items-center text-truncate rounded-0 w-100 hover-color-black"
        (click)="onOpenMenu()"
>
  <span
    class="d-flex align-items-center justify-content-center h-100"
    [ngbTooltip]="(isDealer 
      ? 'CONFIGURATOR_MODAL.ORDER_SELECT.SELECT_OFFER' 
      : 'CONFIGURATOR_MODAL.ORDER_SELECT.SELECT_ORDER') 
      | translate"
    [disableTooltip]="!!selectedOrder"
  >
    <ng-container *ngIf="selectedOrder; else select">
      <i class="icon ni-folder mx-2 color-black"></i>
      <span class="text-truncate color-black fw-bold" [style.max-width.rem]="16">
        <ng-container [class.single-item]="!selectedGroup">
          {{ selectedOrder?.title }}
        </ng-container>
        <ng-container *ngIf="selectedGroup">
          / {{ getSelectedGroupTitle(selectedGroup) || ('ORDER_SELECT.GROUPS.PLACEHOLDER' | translate) }}
        </ng-container>
      </span>
    </ng-container>
  </span>
</button>

<ng-template #select>
  <i class="icon ni-warning-circle mx-2 text-warning"></i>
  <span class="text-truncate color-black fw-bold" [style.max-width.rem]="16">
    {{ (isDealer ? 'CONFIGURATOR_MODAL.ERRORS.NO_OFFER_SELECTED' : 'CONFIGURATOR_MODAL.ERRORS.NO_ORDER_SELECTED') | translate }}
  </span>
</ng-template>
