<app-generic-modal [closable]="false">
  <ng-template appModalHeader>
    <h4 class="modal-header__title">
      {{ headerContent }}
    </h4>
  </ng-template>
  <ng-template appModalBody>
    <p>
      {{ bodyContent }}
    </p>
  </ng-template>
  <ng-template appModalFooter>
    <div class="d-flex justify-content-between w-100">
      <div class="modal-footer__optional-control d-flex" *ngIf="optionalControl">
        <button (click)="onOptionalControlClick()" class="btn" [ngClass]="optionalControl.classes">
          {{ optionalControl.text }}
        </button>
      </div>
      <div class="modal-footer__main-controls d-flex justify-content-end w-100">
        <button *ngFor="let button of buttons; let index = index" (click)="button.action()" class="btn" [ngClass]="button.classes">
          {{ buttonTexts[index] }}
        </button>
      </div>
    </div>
  </ng-template>
</app-generic-modal>
