<app-notifications-header [enableMarkAll]="allNotificationsSeen(notifications)"
  (markAllAsRead)="onMarkAllAsRead()"
  (close)="onClose()"
></app-notifications-header>

<app-loader #loader [loading]="loading"></app-loader>

<div 
  class="h-full overflow-auto"
  infiniteScroll
  [infiniteScrollThrottle]="50"
  [infiniteScrollDistance]="0.5"
  [scrollWindow]="false"
  (scrolled)="onScrolled()"
>
  <ng-container *ngIf="notifications.length; else noNotifications">
    <app-abstract-notification *ngFor="let notification of notifications"
      (click)="onOpen(notification)"
      [showBadge]="notification.status === notificationStatus.NEW"
    >
      <ng-container notification-icon>
        <i class="icon ni-document color-gray"></i>
      </ng-container>
      <ng-container notification-title>{{ getNotificationTitle(notification) }}</ng-container>
      <ng-container notification-text>
        {{ getTranslation(notification) | async }}
        <ng-container *ngIf="notification?.detailsBeforeChanges?.length">
          <ng-container *ngFor="let change of notification.detailsBeforeChanges; index as changeIndex">
            <span
              *ngIf="notification.detailsBeforeChanges[changeIndex].value !== notification.detailsAfterChanges[changeIndex].value"
            >
              {{"INQUIRIES.QUOTATIONS.NEW." + notification.detailsBeforeChanges[changeIndex].name | translate }}:
              <s>{{ notification.detailsBeforeChanges[changeIndex].value}}</s>
              {{ notification.detailsAfterChanges[changeIndex].value }}
            </span>
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-container notification-subtext>
        {{notification.timestamp * 1000 | timeAgo | async }}
      </ng-container>
    </app-abstract-notification>
  </ng-container>
  <ng-template #noNotifications>
    <div class="small color-gray p-4 text-center border-bottom">
      {{ 'NOTIFICATIONS.NO_DATA' | translate }}
    </div>
  </ng-template>
</div>