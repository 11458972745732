import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';

import { ContainerComponent } from '../container/container.component';
import { RowTypes } from '../../../order-articles-list.interface';
import { OpenGroupsService } from '../group/open-groups/open-groups.service';
import { DraggingDetectorService } from '../../../draggable/services/dragging-detector/dragging-detector.service';
import { DraggableDirective } from '../../../draggable/directives/ngx-draggable.directive';
import { SelectedRowsService } from '../../../services/selected-rows/selected-rows.service';
import { Subscription } from 'rxjs';
import { OrderArticleListRow } from '../../../../../core/models/order-article.model';

/**
 * Component that allows nested ngxDroppable and ngxDraggables
 * Should only be use inside a ngx-dnd-container
 * Outside a ngx-dnd-container use ngxDroppable
 *
 * @export
 */
@Component({
  selector: 'draggable-item',
  templateUrl: './draggable-item.component.html',
  styleUrls: ['./draggable-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DraggableItemComponent implements OnInit, OnChanges, OnDestroy {
  private subscriptions: Subscription = new Subscription();

  @Input() model: OrderArticleListRow[] | OrderArticleListRow;
  @Input() dropZone = this.container.dropZone;
  @Input() dropZones = this.container.dropZones;
  @Input() droppableItemClass = this.container.droppableItemClass;
  @Input() removeOnSpill: boolean = this.container.removeOnSpill;
  @Input() copy: boolean = this.container.copy;
  @Input() visibleEmptyGroup: boolean;

  @Output() drop: EventEmitter<any> = new EventEmitter<any>();

  data: any;
  rowType = RowTypes;
  dragging = false;
  isGroupOpen: boolean;

  get hasHandle(): boolean {
    return this.draggableDirective.hasHandle;
  }

  get moveDisabled(): boolean {
    return !this.draggableDirective.canMove();
  }

  get modelChildren(): OrderArticleListRow[] | undefined {
    if (Array.isArray(this.model)) {
      return;
    }
    
    return this.model.children;
  }

  @HostBinding('class')
  get classString() {
    const itemClass = typeof this.droppableItemClass === 'function' ? this.droppableItemClass(this.model) : this.droppableItemClass;

    const classes = ['ngx-dnd-item', itemClass || ''];
    if (!Array.isArray(this.model)) {
      if (this.model.cut) {
        classes.push('cut');
      }
      if (this.selectedRowsService.isSelected(this.model)) {
        classes.push('selectedItem');
      }
      if (this.moveDisabled) {
        classes.push('move-disabled');
      }
      if (this.hasHandle) {
        classes.push('has-handle');
      }
    }

    return classes.join(' ');
  }

  get type() {
    if (Array.isArray(this.model)) {
      return 'array';
    }
    return typeof this.model;
  }

  constructor(
    public container: ContainerComponent,
    public draggableDirective: DraggableDirective,
    private openGroupsService: OpenGroupsService,
    private selectedRowsService: SelectedRowsService,
    private draggingDetectorService: DraggingDetectorService
  ) {}

  ngOnChanges() {
    this.data = {
      model: this.model,
      type: this.type,
      dropZone: this.dropZone,
      template: this.container.template,
    };
  }

  ngOnInit() {
    this.subscriptions.add(
      this.draggingDetectorService.getStatusAsObservable().subscribe(status => {
        this.dragging = status;
      })
    );
    
    this.subscriptions.add(
      this.openGroupsService.getOpenGroupsAsObservable().subscribe(() => {
        if (Array.isArray(this.model)) {
          return;
        }

        const isOpen = this.openGroupsService.isOpen(this.model);
        this.isGroupOpen = isOpen || (isOpen && !this.model.children.length && this.dragging);
      })
    );
  }

  onDrop(v) {
    this.drop.emit(v);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
