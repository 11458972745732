import { ChangeDetectorRef, Directive, ElementRef, Input, OnInit } from '@angular/core';

/**
 * This directive focuses on input when element is created if `appFocusOnInit` is true.
 */
@Directive({
  selector: '[appFocusOnInit]'
})
export class FocusOnInitDirective implements OnInit {
  @Input() appFocusOnInit = true;

  constructor(
    private el: ElementRef<HTMLInputElement>,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    if (this.appFocusOnInit) {
      this.el.nativeElement.focus();
      this.el.nativeElement.select();
      this.cd.detectChanges();
    }
  }
}
