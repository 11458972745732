import { Pipe, PipeTransform } from '@angular/core';
import { AddressInterface } from '../../../core/models/address.model';

@Pipe({
  name: 'shippingAddressPipe',
})
export class ShippingAddressPipe implements PipeTransform {
  transform(address: AddressInterface): any {
    if (!address) {
      return '';
    }

    return address.axAddress ? address.axAddress : address.address + ', ' + address.city;
  }
}
