import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

@Component({
  selector: 'app-password-requirement-indicator',
  templateUrl: './password-requirement-indicator.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PasswordRequirementIndicatorComponent {
  @Input() validationErrors: ValidationErrors | null = null;

  isValid(key: string): boolean {
    return !this.validationErrors || !this.validationErrors['required'] && !(key in this.validationErrors);
  }
}
