import { Injectable } from '@angular/core';
import { INVISIBLE_PROPERTY_CLASS_PART_IDENTIFIER } from './configurator-modal.constants';
import { ConfiguratorPropertyClassCombinations } from './configurator-property-classes-type.enum';
import { ArticlePropertyClassInterface, ArticlePropertyClassPropertyInterface, ArticlePropertyValueInterface } from '../core/models/configurator.model';

@Injectable({
  providedIn: 'root',
})
export class PropertyClassGeneratorService {
  constructor() { }

  static customizeAndFilterPropertyClasses(passedPropertyClasses: ArticlePropertyClassInterface[], configuratorId: string): ArticlePropertyClassInterface[] {
    return passedPropertyClasses
      .map((propertyClass: ArticlePropertyClassInterface) => {
        return {
          ...propertyClass,
          properties: propertyClass.properties.map((property: ArticlePropertyClassPropertyInterface) => {
            return { ...property, configuratorId, propClass: propertyClass.code };
          }),
        };
      })
      .filter((propertyClass: ArticlePropertyClassInterface) => {
        return (
          propertyClass.properties.filter((property: ArticlePropertyClassPropertyInterface) => {
            return property.originalName.toLowerCase().includes(INVISIBLE_PROPERTY_CLASS_PART_IDENTIFIER.toLowerCase());
          }).length === 0
        );
      });
  }

  static groupPropertyClasses(propertyClasses: ArticlePropertyClassInterface[]): ArticlePropertyClassInterface[] {
    propertyClasses = propertyClasses.filter((propertyClass: ArticlePropertyClassInterface) => {
      return propertyClass.properties && propertyClass.properties.length > 0;
    });

    return propertyClasses
      .reduce((carry, propertyClass: ArticlePropertyClassInterface) => {
        const { properties, ...restOfPropertyClass } = propertyClass;
        const foundPropertyClass: ArticlePropertyClassInterface[] = carry.find(
          addedPropertyClass => addedPropertyClass.code === restOfPropertyClass.code
        );

        if (!foundPropertyClass) {
          carry.push(restOfPropertyClass);
        }

        return carry;
      }, [])
      .map(groupedPropertyClass => {
        groupedPropertyClass.properties = propertyClasses
          .reduce((carry, propertyClass: ArticlePropertyClassInterface) => {
            const items = propertyClass.code === groupedPropertyClass.code ? propertyClass.properties : [];
            return [...carry, ...items];
          }, [])
          .map((property: ArticlePropertyClassPropertyInterface) => {
            return property;
          });

        return groupedPropertyClass;
      });
  }

  static removeEmptyValues(propertyClasses: ArticlePropertyClassInterface[]): ArticlePropertyClassInterface[] {
    return [
      ...propertyClasses.map((propertyClass: ArticlePropertyClassInterface) => {
        return {
          ...propertyClass,
          properties: propertyClass.properties.map((property: ArticlePropertyClassPropertyInterface) => {
            const { emptyValue, mandatory, textOnly } = property;

            if (!mandatory && !!emptyValue) {
              return {
                ...property,
                articlePropertyValues: property.articlePropertyValues.filter((filterablePropertyValue: ArticlePropertyValueInterface) => {
                  return emptyValue && filterablePropertyValue.valueFrom !== emptyValue;
                }),
              };
            }

            return {
              ...property,
            };
          }),
        };
      }),
    ];
  }

  /**
   * Removes all of the hidden properties.
   * @param propertyClasses
   */
  static hideHiddenPropertyClasses(propertyClasses: ArticlePropertyClassInterface[]): ArticlePropertyClassInterface[] {
    return [
      ...propertyClasses
        .map((propertyClass: ArticlePropertyClassInterface) => {
          return {
            ...propertyClass,
            properties: propertyClass.properties.filter((property: ArticlePropertyClassPropertyInterface) => {
              const { hidden } = property;

              return !hidden;
            }),
          };
        })
        .filter((propertyClass: ArticlePropertyClassInterface) => propertyClass.properties.length > 0),
    ];
  }

  /**
   * If not mandatory, but selected = added.
   * PropertyClassInterface::added has always higher priority than the statement above.
   *
   * @param propertyClasses
   */
  static flagAdded(propertyClasses: ArticlePropertyClassInterface[]): ArticlePropertyClassInterface[] {
    return [
      ...propertyClasses.map((propertyClass: ArticlePropertyClassInterface) => {
        return {
          ...propertyClass,
          properties: propertyClass.properties.map((property: ArticlePropertyClassPropertyInterface) => {
            if (property.added) {
              return {
                ...property,
              };
            }

            const propertyValue = PropertyClassGeneratorService.findNotEmptyValues(property).find(
              (filterablePropertyValue: ArticlePropertyValueInterface) => {
                return filterablePropertyValue.valueFrom === property.currentValue?.valueFrom;
              }
            );

            /**
             * by filtering out Without value we might end up in a situation
             * where no other "selected" value exists, so we have to check
             * if propertyValue is defined
             */
            if (
              (propertyValue && !property.mandatory && !!property.emptyValue)
            ) {
              return {
                ...property,
                added: !!propertyValue,
              };
            }

            return {
              ...property,
              added: false,
            };
          }),
        };
      }),
    ];
  }

  static determinePropertyClassTypes(propertyClasses: ArticlePropertyClassInterface[]): ArticlePropertyClassInterface[] {
    const innerMap = (property: ArticlePropertyClassPropertyInterface) => {
      const shouldDisplayImages = PropertyClassGeneratorService.shouldDisplayPropertyValueImages(property);
      const { emptyValue, mandatory, textOnly } = property;

      const compareTo = [textOnly, shouldDisplayImages];
      const foundType = Object.entries(ConfiguratorPropertyClassCombinations).find(([entryKey, entryValue]) => {
        return (
          entryValue.filter(filterableValue => {
            return filterableValue.every((value, index) => compareTo[index] === value);
          }).length > 0
        );
      });

      if (!foundType) {
        throw new Error(`PropertyClassGeneratorService: Unrecognized type ${[textOnly, shouldDisplayImages]}`);
      }

      const [foundKey, foundValue] = foundType;

      return {
        ...property,
        type: foundKey,
        removable: !mandatory,
      };
    };

    return [
      ...propertyClasses.map((propertyClass: ArticlePropertyClassInterface) => {
        return {
          ...propertyClass,
          properties: propertyClass.properties.map(innerMap),
        };
      }),
    ];
  }

  static findNotEmptyValues(property: ArticlePropertyClassPropertyInterface): ArticlePropertyValueInterface[] {
    return [...property.articlePropertyValues].filter((filterablePropertyValue: ArticlePropertyValueInterface) => {
      return filterablePropertyValue.valueFrom !== property.emptyValue;
    });
  }

  static shouldDisplayPropertyValueImages(property: ArticlePropertyClassPropertyInterface): boolean {
    return property.articlePropertyValues.filter(propertyValue => !!propertyValue.img).length >= property.articlePropertyValues.length / 2;
  }
}
