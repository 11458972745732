import { NgModule } from '@angular/core';
import { MaintenanceComponent } from './maintenance.component';
import { TranslateModule } from '@ngx-translate/core';
import { CoreModule } from '../core/core.module';
import { CommonModule } from '@angular/common';
import { SharedModule } from "../shared/shared.module";

@NgModule({
    imports: [
      TranslateModule,
      CoreModule,
      CommonModule,
      CoreModule,
      SharedModule,
    ],
    declarations: [MaintenanceComponent],
    exports: [],
})
export class MaintenanceModule {}
