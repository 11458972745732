import { Injectable } from '@angular/core';
import { UserService } from '../../core/services/user/user.service';
import { UserInterface } from '../../core/models/user.model';
import { RouteTypeEnums } from '../../core/enums/route-types.enum';
import { switchMap } from 'rxjs/operators';
import { TreeService } from '../../core/tree/tree.service';
import { NavbarElements } from '../../core/tree/navbar-elements';
import { BehaviorSubject, Observable } from 'rxjs';
import { OrderInterface } from '../../core/models/order.model';
import { ExtraListElementInterface } from '../order-articles-list/order-articles-list/components/extra-row/extra-items.model';

@Injectable({
  providedIn: 'root',
})
export class AddItemHelperService {
  private lastOrder$ = new BehaviorSubject<OrderInterface | null>(null);
  private lastGroup$ = new BehaviorSubject<ExtraListElementInterface | null>(null);

  constructor(private userService: UserService, private treeService: TreeService) {}

  lastOrderAsObservable(): Observable<OrderInterface | null> {
    return this.lastOrder$.asObservable();
  }

  lastGroupAsObservable(): Observable<ExtraListElementInterface | null> {
    return this.lastGroup$.asObservable();
  }

  setLastOrder(order?: OrderInterface) {
    this.lastOrder$.next(order);
  }

  setLastGroup(group: ExtraListElementInterface) {
    this.lastGroup$.next(group);
  }

  itemAddClicked(idOfOrder: number, idOfGroup?: number) {
    this.userService
      .fromStorage()
      .pipe(switchMap(user => this.userService.update(user.id, { lastUpdatedOrder: idOfOrder, lastUpdatedPageBreak: idOfGroup })))
      .subscribe((updatedUser: UserInterface) => {
        this.setLastOrder(updatedUser.lastUpdatedOrder);
        this.setLastGroup(updatedUser.lastUpdatedPageBreak);
        sessionStorage.setItem('selectedOrder', JSON.stringify(updatedUser.lastUpdatedOrder));
        sessionStorage.setItem('selectedGroup', JSON.stringify(updatedUser.lastUpdatedPageBreak));
        this.treeService.open(
          NavbarElements.find(({ id }) => id === RouteTypeEnums.CATALOGUE),
          null,
          true
        );
      });
  }

  resetLastOrderAndGroup() {
    this.setLastOrder(null);
    this.setLastGroup(null);
  }
}
