import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserInterface } from '../../../core/models/user.model';
import { ClientInterface } from '../../../core/models/client.model';
import { ClientService } from '../../../core/services/client/client.service';
import { LoaderComponent } from '../../../ui-elements/loader/loader.component';
import { ClientEditModalComponent } from './edit-modal/edit-modal.component';
import { ClientDeleteModalComponent } from './delete-modal/delete-modal.component';
import { CountryService } from '../../../core/services/country/country.service';
import { CountryInterface } from '../../../core/models/country.model';
import { GenericModalTypes } from '../../../ui-elements/generic-modal/generic-modal-types';

@Component({
  selector: 'app-user-settings-clients',
  templateUrl: './clients.component.html',
})
export class ClientsComponent implements OnInit {
  @ViewChild('loader', { static: true }) loader: LoaderComponent;
  user: UserInterface;

  clients: ClientInterface[];
  countries: CountryInterface[];

  constructor(private modalService: NgbModal, private clientService: ClientService, private countryService: CountryService) {}

  ngOnInit() {
    this.countryService.all().subscribe();
    this.onRefresh();
  }

  onRefresh() {
    this.loader.show();
    this.clientService
      .all()
      .noCache()
      .subscribe(
        result => {
          this.clients = result;
          this.loader.hide();
        },
        err => {
          console.log(err);
          this.loader.hide();
        }
      );
  }

  onEdit(client) {
    const modalRef = this.modalService.open(ClientEditModalComponent, {
      windowClass: `${GenericModalTypes.GREEN}  create-document`,
      size: 'lg',
      centered: true
    });
    modalRef.componentInstance.client = client;
    modalRef.componentInstance.updated.subscribe(() => {
      this.onRefresh();
    });
  }

  onDelete(client) {
    const modalRef = this.modalService.open(ClientDeleteModalComponent, {
      windowClass: GenericModalTypes.ORANGE,
      size: 'sm',
      centered: true
    });
    modalRef.componentInstance.client = client;
    modalRef.componentInstance.deleted.subscribe(() => {
      this.onRefresh();
    });
  }
}
