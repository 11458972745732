import { ProjectReservationLostReasonsUpdateInterface } from './project-reservation.service';
import { Injectable } from "@angular/core";
import { ProjectReservationInterface, ProjectReservationOutcomeEnum, ProjectReservationStatusEnum, ProjectReservationLostReasonsInterface } from "../core/models/project-reservation.model";
import { ProjectReservationLostUpdateInterface, ProjectReservationReasonsMap } from "./project-reservation.service";

@Injectable({
  providedIn: 'root',
})
export class ProjectReservationHelperService {

  static prepareLostFeedbackFormValues(projectReservation?: ProjectReservationInterface): ProjectReservationLostReasonsUpdateInterface {
    const feedbackData: ProjectReservationLostReasonsUpdateInterface = { otherLostReasons: projectReservation?.otherLostReasons };

    if (!projectReservation?.lostReasons?.length) {
      return feedbackData;
    }

    projectReservation?.lostReasons.forEach(reason => {
      feedbackData[ProjectReservationReasonsMap[reason as string]] = true;
    })

    return feedbackData;
  }

  static formatLostFeedback(projectReservation: ProjectReservationInterface, lostReasonsInput: ProjectReservationLostReasonsUpdateInterface) {
    // format values to match API side
    const lostReasons: ProjectReservationLostReasonsInterface[] = [];
    Object.keys(lostReasonsInput).forEach(item => {
      if (item !== 'otherLostReasons' && lostReasonsInput[item]) {
        lostReasons.push(ProjectReservationReasonsMap[item]);
      }
    });

    const otherLostReasons: string = lostReasonsInput.otherLostReasons;
    const feedbackData: ProjectReservationLostUpdateInterface = {
      lostReasons,
      otherLostReasons,
    };

    if (projectReservation.status !== ProjectReservationStatusEnum.LOST) {
      feedbackData.outcome = ProjectReservationOutcomeEnum.LOST;
    }

    return feedbackData;
  }
}
