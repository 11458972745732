import {Component, Input, OnChanges, ViewChild} from '@angular/core';
import {LoaderComponent} from '../../../../ui-elements/loader/loader.component';
import {TreeService} from '../../tree.service';
import {LoaderService} from '../../../../ui-elements/loader/loader.service';
import { ImageSizeEnum } from '../../../../shared/pipes/resize-image/resize-image.pipe';

export interface CatalogCategoryDataInterface {
  title: string;
  content: string;
  img: string;
}

@Component({
  selector: 'app-navbar-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnChanges {
  @Input() subCategory: number;
  @ViewChild('detailsLoader', {static: true}) detailsLoader: LoaderComponent;
  data: CatalogCategoryDataInterface;
  imageSizes = ImageSizeEnum;

  constructor(private treeService: TreeService, private loaderService: LoaderService) {
  }

  ngOnChanges() {
    this.data = null;
    this.loaderService.load(
      this.treeService.getCategoryOrSubCategoryData(this.subCategory),
      this.detailsLoader
    )
      .subscribe((data: CatalogCategoryDataInterface) => {
        this.data = data;
      });
  }

}
