import {ElementRef, TemplateRef} from '@angular/core';

export interface TooltipContentConfigInterface {
  content: string;
  position: string;
  arrowPosition: string;
  openerElement: ElementRef;
}

export interface TooltipChangedEventInterface {
  open: boolean;
  content?: string | TemplateRef<any>;
  position?: string;
  arrowPosition?: string;
  openerElement?: ElementRef;
  additionalClasses?: string;
  contentClasses?: string;
  arrowClasses?: string;
  hoverableContent?: boolean;
  tooltipBounds?: HTMLElement;
}

export const arrowSize = {
  HEIGHT: 10,
  WIDTH: 10,
};

export type TooltipPositionValues = 'top' | 'top-left' | 'top-right' | 'bottom' | 'bottom-right' | 'bottom-left'
