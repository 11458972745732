import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PreviewFileDirective } from './preview-file.directive';
import { PreviewImageModalComponent } from './preview-image-modal/preview-image-modal.component';
import { SharedModule } from '../../shared/shared.module';
import { GenericModalModule } from '../generic-modal/generic-modal.module';

@NgModule({
  declarations: [
    PreviewFileDirective,
    PreviewImageModalComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    GenericModalModule
  ],
  exports: [
    PreviewFileDirective
  ]
})
export class PreviewFileModule { }
