import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { first, forkJoin } from 'rxjs';
import { ActionType } from '../../configurator-modal/action-type.enum';

import { ConfiguratorModalComponent } from '../../configurator-modal/configurator-modal.component';
import { ConfiguratorModalActionType } from '../../core/enums/configurator-modal-action-type.enum';
import { UserRole } from '../../core/enums/user-role.enum';
import { OrderInterface } from '../../core/models/order.model';
import { PageBreakInterface } from '../../core/models/page-break.model';
import { UserInterface } from '../../core/models/user.model';
import { UserService } from '../../core/services/user/user.service';
import { AddItemHelperService } from '../../orders/controls/add-item-helper.service';
import { ToastService } from '../../ui-elements/toast/toast.service';
import { CatalogueSystemsInterface, ProductArticleVariant, ProductsArticleInterface } from './product.model';
import { OrderState } from "../../core/enums/order.state.enum";

interface SelectedArticleInterface {
  article?: ProductsArticleInterface;
  variant?: ProductArticleVariant;
}

@Injectable()
export class ConfiguratorOpenerService {
  private modifiableOrder?: OrderInterface;

  constructor(
    private modalService: NgbModal,
    private addItemHelperService: AddItemHelperService,
    private userService: UserService,
    private toastService: ToastService
  ) { }

  openConfiguratorModal(system: CatalogueSystemsInterface, article: ProductsArticleInterface, articleVariant?: ProductArticleVariant) {
    forkJoin([
      this.addItemHelperService.lastOrderAsObservable().pipe(first()),
      this.addItemHelperService.lastGroupAsObservable().pipe(first()),
      this.userService.fromStorage().pipe(first())
    ]).subscribe(([lastOrder, lastGroup, user]: [OrderInterface, PageBreakInterface, UserInterface]) => {
      const modalRef = this.modalService.open(ConfiguratorModalComponent, {
        windowClass: 'configurator-modal',
        keyboard: false,
      });
      let selectedArticle: SelectedArticleInterface = {};

      selectedArticle = {
        article: article,
        variant: articleVariant,
      };

      const componentInstance = modalRef.componentInstance as ConfiguratorModalComponent;

      componentInstance.article = selectedArticle.article;
      componentInstance.articleVariant = selectedArticle.variant;
      componentInstance.system = system.system;
      componentInstance.order = lastOrder;
      componentInstance.group = lastGroup;

      componentInstance.save.subscribe(({ order }) => {
        this.modifiableOrder = order;
      });

      if (
        (OrderState.WAITING === this.modifiableOrder?.state) ||
        (user && user.role.name === UserRole.ROLE_PM_NARBUTAS)
      ) {
        componentInstance.action = ConfiguratorModalActionType.ADD_TO_ORDER;
      }

      window.dataLayer.push({
        event: 'configuratorCatalogView',
        url: window.location.href,
        company: user.companyName || 'none',
        user_id: user.id,
        role: user.role.name,
        ax: user.axClientCode || 'none',
        item_id: article.id,
        item_name: (article as any).fullCode || article.code || 'none',
        item_variant: articleVariant.names[0] || 'none',
        item_category: system.system,
        quantity: 1,
      });

      componentInstance.error.subscribe((type: ActionType) => {
        modalRef.close();

        if (type === ActionType.UPDATE) {
          this.toastService.danger('Error! Please try again later.');
        }
      });
    })
  }
}
