import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {ClientInterface} from '../../../core/models/client.model';
import {CountryInterface} from '../../../core/models/country.model';
import {UserService} from '../../../core/services/user/user.service';
import {LoaderService} from '../../loader/loader.service';
import {ClientService} from '../../../core/services/client/client.service';
import {CountryService} from '../../../core/services/country/country.service';
import {ClientModelInstanceChangeEventInterface} from '../../../client/client-form/client-form.component';
import {CustomerMode} from '../client-modal.model';

@Component({
  selector: 'app-customers-list',
  templateUrl: './customers-list.component.html',
})
export class CustomersListComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('loader') loader;
  @Input() selected: ClientInterface;
  @Input() selectedMode: CustomerMode = CustomerMode.LIST;

  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() selectClient: EventEmitter<ClientInterface> = new EventEmitter<ClientInterface>();

  clients: ClientInterface[];
  customerMode = CustomerMode;
  countries: CountryInterface[];
  formValid: boolean;

  constructor(
    private userService: UserService,
    private loaderService: LoaderService,
    private countryService: CountryService,
    private clientService: ClientService
  ) {}

  ngOnInit() {
    this.countryService.all().subscribe((countries) => {
      this.countries = countries;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedMode && changes.selectedMode.currentValue !== changes.selectedMode.previousValue) {
      this.resetFormValidations();
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.loaderService.load(this.clientService.all().noCache(), this.loader).subscribe((clients: ClientInterface[]) => {
        this.clients = clients;
      });
    }, 0);
  }

  onObjectChanged(event: ClientModelInstanceChangeEventInterface) {
    this.formValid = event.valid;
    if (this.formValid) {
      this.selected = event.client;
    }
  }

  onCreateClient() {
    if (this.formValid) {
      const { country, ...client } = this.selected;
      this.clientService.create({ country: country.id, ...client }).subscribe((responseClient) => {
        this.clients.push(responseClient);
        this.onClientSelect(responseClient);
        this.onCancel();
      });
    }
  }

  onClientSelect(client) {
    this.selected = this.selected && this.selected.id === client.id ? null : client;
    this.selectClient.emit(this.selected);
  }

  onCancel() {
    this.selectedMode = CustomerMode.LIST;
    this.resetFormValidations();
    this.cancel.emit();
  }

  private resetFormValidations() {
    this.formValid = false;
  }
}
