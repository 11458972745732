import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RedirectorService} from './redirector.service';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [],
  providers: [
    RedirectorService
  ]
})
export class RoleRedirectorModule {
}
