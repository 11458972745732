import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, map, shareReplay, switchMap } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { FinishesService } from '../finishes/finishes.service';
import { BreadcrumbModel } from '../../shared/components/breadcrumbs/breadcrumb.model';
import { FinishFamily, FinishFamilyFile, FinishItem, FinishTexture } from '../finishes/finishes.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfiguratorModalComponent } from '../../configurator-modal/configurator-modal.component';
import { ImageSizeEnum } from '../../shared/pipes/resize-image/resize-image.pipe';

@Component({
  selector: 'app-finishes-family',
  templateUrl: './finishes-family.component.html',
  styleUrls: ['./finishes-family.component.scss']
})
export class FinishesFamilyComponent implements OnInit {
  private id$ = this.route.params.pipe(
    map(params => params['id'])
  );

  finish$: Observable<FinishItem> = this.id$.pipe(
    switchMap(id => this.finishesService.get(id)),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  finishFamily$: Observable<FinishFamily[]> = this.id$.pipe(
    switchMap(id => this.finishesService.getFinishFamily(id)),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  breadcrumbs: BreadcrumbModel[] = [];
  imageSizes = ImageSizeEnum;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private finishesService: FinishesService,
    private translateService: TranslateService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.translateService.get('FINISHES.TITLE').subscribe(finishPageTitle => {
      this.breadcrumbs = [{
        title: finishPageTitle,
        onClick: (elem: BreadcrumbModel, $event: MouseEvent) => {
          $event.preventDefault();
          this.router.navigate(['../'], {relativeTo: this.route})
        },
      }]
    });
  }

  filterByLanguage(finishFamilyFiles: FinishFamilyFile[]) {
    const currentLanguage = this.translateService.currentLang;

    return finishFamilyFiles.filter(file => file.locale === currentLanguage);
  }

  orderSwatchCard(system: string, code: string) {
    const modalRef = this.modalService.open(ConfiguratorModalComponent, {
      windowClass: 'configurator-modal',
      keyboard: false,
    });
    const componentInstance = modalRef.componentInstance as ConfiguratorModalComponent;
    componentInstance.system = system;
    componentInstance.article = {code};
    componentInstance.quantity = 1;
  }
}
