import {Component} from '@angular/core';
import {Observable} from 'rxjs';
import {ContentModel} from '../content.model';
import {ContentService} from '../content.service';

@Component({
  selector: 'app-content-entry',
  templateUrl: './content-entry.component.html',
  styleUrls: ['./content-entry.component.scss'],
})
export class ContentEntryComponent {
  get item$(): Observable<ContentModel.Data> {
    return this.contentService.getItemAsObservable();
  }

  constructor(private contentService: ContentService) {
  }
}
