import { Directive } from '@angular/core';

@Directive({
  selector: '[appTextFieldErrors]'
})
export class TextFieldErrorsDirective {

  constructor() { }

}
