import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';

import {ProductsComponent} from './products/products.component';
import {CatalogueRouteType, RouteTypeEnums} from '../core/enums/route-types.enum';
import {AutoDownloadComponent} from './products/auto-download/auto-download.component';
import {CatalogueParentComponent} from './catalogue-parent.component';

const catalogueRoutes: Routes = [
  {
    path: '',
    component: CatalogueParentComponent,
    children: [
      {
        path: 'category', data: {id: CatalogueRouteType.CATEGORY}, children: [
          {path: ':slug', component: ProductsComponent},
          {path: ':slug/:childSlug', component: ProductsComponent}
        ]
      },
      {
        path: 'systems', data: {id: CatalogueRouteType.SYSTEMS}, children: [
          {path: ':rootNa', component: ProductsComponent},
          {path: ':rootNa/:slug', component: ProductsComponent}
        ]
      },
      {
        path: 'components', data: {id: CatalogueRouteType.COMPONENTS}, children: [
          {path: ':slug', component: ProductsComponent},
          {path: ':slug/:childSlug', component: ProductsComponent}
        ]
      },
      {
        path: 'search', data: {id: RouteTypeEnums.SEARCH}, component: ProductsComponent
      }
    ]
  },
  {
    path: 'auto-download', component: AutoDownloadComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(catalogueRoutes)],
  exports: [RouterModule]
})

export class CatalogueRoutingModule {
}
