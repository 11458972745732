import { Injectable } from '@angular/core';
import { ApiService } from '../../../api.service';
import { CacheableObservable } from '../../../cacheable-observable/cacheable-observable.model';
import { FieldInterface, FieldUpdateInterface } from '../../models/field.model.';
import { GroupByService } from '../../../shared/services/group-by/group-by.service';
import { FieldClass } from '../../enums/field-class';
import { GroupableItemInterface } from '../../models/groupable-item.model';
import { map } from 'rxjs/internal/operators/map';
import { UserService } from '../user/user.service';
import { Observable, ReplaySubject, zip } from 'rxjs';

const generateEndpointPath = (fieldClass: FieldClass, order?: number): string => {
  let path = `field/user`;
  if (fieldClass && fieldClass === FieldClass.ORDER && order != null) {
    path += `/${order}`;
  }
  return path;
};

@Injectable({
  providedIn: 'root',
})
export class FieldService {
  private options: Map<FieldClass, ReplaySubject<GroupableItemInterface[]>> = new Map<
    FieldClass,
    ReplaySubject<GroupableItemInterface[]>
  >();

  private currentOptions = new Map<FieldClass, GroupableItemInterface[]>();

  constructor(private apiService: ApiService, private groupByService: GroupByService, private userService: UserService) {
    Object.values(FieldClass).forEach((value) => {
      this.options.set(value, new ReplaySubject<GroupableItemInterface[]>(1));
    });
  }

  getGroupedBy(fieldClass: FieldClass, order?: number): Observable<GroupableItemInterface[]> {
    zip(
      this.fetchAll().noCache(),
      this.getUserFieldsByClass(fieldClass, order).noCache(),
      this.userService.fromStorage()
    ).subscribe(
      ([options, list]) => {
        const convertedOptions: GroupableItemInterface[] = this.groupByService.groupBy(
          options.map((option) => {
            option.checked = !!list.find((field: FieldInterface) => field.name === option.name);
            return option;
          }),
          'fieldGroup'
        );
        this.options.get(fieldClass).next(convertedOptions);
        this.currentOptions.set(fieldClass, convertedOptions);
      }
    );
    return this.getOptionsAsObservable(fieldClass);
  }

  getOptionsAsObservable(fieldClass: FieldClass): Observable<GroupableItemInterface[]> {
    return this.options.get(fieldClass).asObservable();
  }

  fetchAll(type?: string): CacheableObservable<FieldInterface[]> {
    const params = type ? { type } : {};
    return this.apiService.get(`field/`, params).pipe(map(({ data }) => data)) as CacheableObservable<FieldInterface[]>;
  }

  getUserFieldsByClass(fieldClass: FieldClass, order?: number): CacheableObservable<FieldInterface[]> {
    return this.apiService.get(generateEndpointPath(fieldClass, order)).pipe(map(({ data }) => data)) as CacheableObservable<
      FieldInterface[]
    >;
  }

  update(id: number, field: FieldInterface): Observable<any> {
    return this.apiService.patch(`field/${id}`, field).pipe(map(({ data }) => data)) as Observable<any>;
  }

  addUser(id: number): Observable<any> {
    return this.apiService.post(`field/${id}/user`, null).pipe(map(({ data }) => data)) as Observable<any>;
  }

  removeUser(id: number): Observable<any> {
    return this.apiService.delete(`field/${id}/user`, null).pipe(map(({ data }) => data)) as Observable<any>;
  }

  updateMultiple(fieldClass: FieldClass, fields: FieldUpdateInterface[], order?: number): Observable<boolean> {
    return new Observable((observer) => {
      this.apiService.patch(generateEndpointPath(fieldClass, order), fields).subscribe(
        ({ success }) => {
          if (success) {
            fields.forEach((field) => {
              this.currentOptions.get(fieldClass).forEach((groups) => {
                groups.values
                  .filter((r) => r.id === field.id)
                  .map((r) => {
                    r.checked = field.status;
                  });
              });
            });
            this.options.get(fieldClass).next(this.currentOptions.get(fieldClass));
          }
          observer.next(success);
          observer.complete();
        },
        (error) => {
          observer.error();
          observer.complete();
        }
      );
    });
  }
}
