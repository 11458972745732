<ng-container [ngSwitch]="type">
  <ng-container *ngSwitchCase="'array'">
    <draggable-container
      [model]="model"
      [containerTemplate]="container.template"
      [dropZone]="dropZone"
      [dropZones]="dropZones"
      [removeOnSpill]="removeOnSpill"
      [droppableItemClass]="droppableItemClass"
      [visibleEmptyGroup]="visibleEmptyGroup"
      [copy]="copy"
    ></draggable-container>
  </ng-container>

  <ng-container *ngSwitchCase="'object'">
    <ng-template *ngIf="container.template" [ngTemplateOutlet]="container.template" [ngTemplateOutletContext]="data"></ng-template>
    <draggable-container
      *ngIf="modelChildren && isGroupOpen"
      [visibleEmptyGroup]="visibleEmptyGroup"
      [groupHead]="model"
      [model]="modelChildren"
      [containerTemplate]="container.template"
      [dropZone]="dropZone"
      [dropZones]="dropZones"
      [removeOnSpill]="removeOnSpill"
      [droppableItemClass]="droppableItemClass"
      (drop)="onDrop($event)"
      [copy]="copy"
    ></draggable-container>
  </ng-container>

  <ng-container *ngSwitchCase="'undefined'"> </ng-container>

  <ng-container *ngSwitchDefault>
    <ng-template *ngIf="container.template" [ngTemplateOutlet]="container.template" [ngTemplateOutletContext]="data"></ng-template>
    <div *ngIf="!container.template" class="ngx-dnd-content">
      {{ model }}
    </div>
  </ng-container>
</ng-container>
