/** Page types that can be returned by back-end */
export enum InfoBlockTabsEnum {
  GALLERY = 0,
  PAGE = 1,
  TWO_COLUMNS_PAGE = 2,
  DOWNLOADS = 3
}

/** Page types that are defined/added only via front-end */
export enum UIInfoBlockTabsEnum {
  FINISHES = 'finishes',
  DOWNLOADS = 'downloads'
}
