<div class="p-4">
  <h4>
    {{ 'FINISHES.TITLE' | translate }}
  </h4>

  <ng-container *ngFor="let finish of finishes">
    <ng-container *ngIf="filterByLanguage(finish.files) as files">
      <div *ngFor="let file of files">
        <a [href]="file.file.url" class="btn btn-link" download>
          <span class="d-flex justify-content-center align-items-center">
            {{ 'CATALOGUE.INFO_BLOCKS.SWATCH_CARD' | translate: { name: finish.title } }}
            <i class="icon ni-download ms-2"></i>
          </span>
        </a>
      </div>
    </ng-container>
  </ng-container>
</div>
