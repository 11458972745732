import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { extendedNumbers, noWhitespace, numbers, onlyText, textWithSeparators } from '../../shared/class/custom-validators';

@Injectable()
export class ProfileFormService {

  constructor() { }

  /** Returns user profile FormGroup. */
  getForm(): FormGroup {
    return new FormGroup({
      firstName: new FormControl(null, [Validators.required, noWhitespace, onlyText, Validators.maxLength(50)]),
      lastName: new FormControl(null, [Validators.required, noWhitespace, onlyText, Validators.maxLength(50)]),
      phone: new FormControl(null, [extendedNumbers, Validators.maxLength(50)]),
      companyName: new FormControl(null, [Validators.required, noWhitespace, Validators.maxLength(140)]),
      companyCode: new FormControl(null, [Validators.maxLength(50)]),
      vatCode: new FormControl(null, [Validators.maxLength(50)]),
      address: new FormControl(null, [Validators.maxLength(255)]),
      country: new FormControl(null, [Validators.maxLength(50)]),
      city: new FormControl(null, [textWithSeparators, Validators.maxLength(50)]),
      postCode: new FormControl(null, [Validators.maxLength(50)]),
      iban: new FormControl(null, [Validators.maxLength(50)]),
      bank: new FormControl(null, [onlyText, Validators.maxLength(50)]),
      bankCode: new FormControl(null, [numbers, Validators.maxLength(50)]),
      swift: new FormControl(null, [Validators.maxLength(50)]),
    });
  }
}
