<div
  class="migration-changes-log w-full h-full bg-white d-flex flex-column overflow-auto"
  [style.z-index]="10"
>
  <div class="flex-grow-1">
    <div *ngFor="let entry of migrationLog" class="border-bottom">
      <ng-container *ngIf="configurationArticlesWithMigration.includes(entry.ItemID)">
        <button
          class="bg-gray-100 border-bottom btn d-flex flex-column font-secondary text-black w-100 text-left"
          [ngClass]="['article_'+entry.ItemID]"
          type="button"
          (click)="onSelectArticle(entry.ItemID)"
        >
          {{ getConfigurationArticleLongText(entry) }}
          <div class="status-text text-muted text-loweracse">{{ getChangeStatusText(entry) | translate }}</div>
        </button>
        <div class="d-flex flex-column py-2 gap-2">
          <ng-container *ngFor="let propertyKey of getPropertyKeys(entry.Props)">
            <ng-container
              *ngTemplateOutlet="propertyChangeTemplate; context: {
                propertyKey,
                property: entry.Props[propertyKey],
                configuratorID: entry.ItemID || entry.ID.From
              }"
            ></ng-container>
          </ng-container>
        </div>
      </ng-container>
      <ng-container *ngIf="entry.Deleted">
        <button
          class="bg-gray-300 border-bottom border-color-gray-400 border-1 btn d-flex flex-column font-secondary text-black w-100 text-left"
          type="button"
        >
          {{ getConfigurationArticleLongText(entry) }}
          <div class="status-text text-muted text-loweracse">{{ getChangeStatusText(entry) | translate }}</div>
        </button>
        <div class="d-flex flex-column bg-gray-300 border-bottom border-color-gray-400 border-1">
          <ng-container *ngFor="let propertyKey of getPropertyKeys(entry.Props); let first = first">
            <ng-container
              *ngTemplateOutlet="removedItemPropertyTemplate; context: {
                propertyKey,
                property: entry.Props[propertyKey],
                configuratorID: entry.ItemID || entry.ID.From,
                first
              }"
            ></ng-container>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="p-3 border-top">
    <button
      class="btn btn-primary w-100"
      type="button"
      (click)="onAcceptChanges()"
    >
      {{ 'CONFIGURATOR_MODAL.CONTROLS.ACCEPT_CHANGES' | translate }}
    </button>
  </div>
</div>

<!-- property: MigrationLogEntryPropInterface -->
<ng-template #propertyChangeTemplate let-property="property" let-propertyKey="propertyKey" let-configuratorID="configuratorID">
  <div class="d-flex px-3 flex-column">
    <small class="m-0">{{ nameToOriginalNameMap.get(propertyKey) || propertyKey }}</small>
    <button
      class="btn bg-gray-200 w-100 d-flex px-2 font-secondary align-items-center text-start m-0"
      [ngClass]="[property.From && property.To ? 'py-1' : 'py-2', 'prop_'+configuratorID+'_'+propertyKey]"
      (click)="onSelectArticleProperty(configuratorID, propertyKey)"
    >
      <button
        class="btn btn-small d-flex p-1 align-items-center deep-hover-d-block s-deep-hover-d-none hover-color-warning"
        (click)="onMarkChangeAsAccepted($event, property, configuratorID, propertyKey)"
        [ngClass]="{
          'text-gray-200 bg-gray-200': acceptedMigrationsMap.has(configuratorID+'_'+propertyKey),
          'text-warning bg-white hover-bg-warning': !acceptedMigrationsMap.has(configuratorID+'_'+propertyKey)
        }"
        [ngbTooltip]="'CONFIGURATOR_MODAL.ARTICLE_MIGRATION.CLICK_TO_ACCEPT' | translate"
        triggers="mouseenter:mouseleave" container="body" placement="top" popoverClass="shadow"
      >
        <i
          class="deep-hover d-none icon"
          [class.ni-loop]="acceptedMigrationsMap.has(configuratorID+'_'+propertyKey)"
          [ngClass]="{'ni-check text-white': !acceptedMigrationsMap.has(configuratorID+'_'+propertyKey)}"
        ></i>

        <i class="s-deep-hover icon ni-new-outline" *ngIf="!property.From.length"></i>
        <i *ngIf="property.From.length && property.To.length" class="s-deep-hover icon ni-loop"></i>
        <i *ngIf="property.From.length && !property.To.length" class="s-deep-hover icon ni-cancel-block"></i>
      </button>
      <div class="px-2 d-flex flex-column flex-grow-1">
        <h5 *ngIf="property.From" class="m-0 text-gray">
          {{ currentPropertyValuesMap.get(propertyKey+'_'+property.From) ?? property.FromValue.Title || property.From }}
        </h5>
        <h5 *ngIf="property.To" class="text-black m-0">
          {{ currentPropertyValuesMap.get(propertyKey+'_'+property.OriginalTo) ?? property.To }}
        </h5>
      </div>
    </button>
  </div>
</ng-template>

<ng-template
  #removedItemPropertyTemplate
  let-property="property"
  let-propertyKey="propertyKey"
  let-configuratorID="configuratorID"
  let-first="first"
>
  <div class="mt-1 d-flex px-3 flex-column">
    <div
      class="bg-gray-300 w-100 d-flex px-2 align-items-center text-start m-0 border-1 border-color-gray-400"
      [ngClass]="{'pt-2 border-top': !first}"
    >
      <div class="btn btn-small d-flex p-1 align-items-center ng-gray-300 text-gray-200">
        <i class="icon ni-cancel-block"></i>{{ '' | translate }}
      </div>
      <div class="px-2 d-flex flex-column flex-grow-1">
        <h5 class="m-0 fw-normal color-gray-600">{{ property.FromProperty.Title }}</h5>
        <h5 class="m-0 color-gray-600">{{ property.FromValue.Title.length ? property.FromValue.Title : property.From }}</h5>
      </div>
    </div>
  </div>
</ng-template>
