import { ComponentFactoryResolver, ComponentRef } from '@angular/core';
import { TransformedResponseInterface } from '../../core/models/configurator.model';

export abstract class BasePropertiesConfiguratorComponentFactoryService {
  constructor(protected componentFactoryResolver: ComponentFactoryResolver) {}

  abstract create(element, data: TransformedResponseInterface): ComponentRef<any>;

  protected createInstance(element, component): ComponentRef<any> {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);

    element.clear();

    return element.createComponent(componentFactory);
  }
}
