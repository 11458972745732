import { Pipe, PipeTransform } from '@angular/core';

/**
 * Converts array into array of arrays of specified length,
 * the last array contains what's left
 *
 * @example
 * [1, 2, 3, 4, 5, 6] | splitIntoGroupsOf: 3 => [[1, 2, 3], [4, 5, 6]]
 * [1, 2, 3, 4, 5, 6, 7] | splitIntoGroupsOf: 3 => [[1, 2, 3], [4, 5, 6], [7]]
 */
@Pipe({
  name: 'splitIntoGroupsOf'
})
export class SplitIntoGroupsOfPipe implements PipeTransform {

  transform<T>(arr: T[], count: number): T[][] {
    let newArray: T[][] = [];

    arr.forEach((item, index) => {
      if (newArray.length < (index + 1) / count) {
        newArray.push([item]);
      } else {
        newArray[Math.floor(index / count)].push(item);
      }
    });

    return newArray;
  }

}
