export enum ClaimStatus {
  DRAFT = "draft",
  WAITING = "waiting",
  IN_PROGRESS = 'in_progress',
  COMPLETED = "completed",
}

export const ClaimStatusToTranslationMap = {
  [ClaimStatus.DRAFT]: 'INQUIRIES.CLAIMS.STATE.DRAFT',
  [ClaimStatus.WAITING]: 'INQUIRIES.CLAIMS.STATE.WAITING_FOR_RESPONSE',
  [ClaimStatus.IN_PROGRESS]: 'INQUIRIES.CLAIMS.STATE.IN_PROGRESS',
  [ClaimStatus.COMPLETED]: 'INQUIRIES.CLAIMS.STATE.COMPLETED',
};
