<div class="details">
  <app-loader #detailsLoader cssClasses="loader--white"></app-loader>
  <div class="details__thumbnail" [class.details__thumbnail--no-image]="!data?.img"
    [style.background-image]="data?.img ? 
      'url('+ ((data?.img | resizeImage: imageSizes.SIZE_320x240 | uncache | async ) ?? '') +')' : 
      'url(\'../../../assets/images/catalogue-list/no-article-image.svg\')'
  "></div>
  <div class="body-wrapper">
    <div class="details__body">
      <div class="details__body__title fw-bold text-center color-black text-break mb-3 mh-100">
        {{data?.title}}
      </div>
      <div class="w-100 details__body__content" *ngIf="data?.content" [innerHtml]="data?.content">
      </div>
    </div>
  </div>
</div>
