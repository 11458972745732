import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'encodeUri'
})
export class EncodeUriPipe implements PipeTransform {

  transform(value: string): string {
    // prevent string from double encoding - encode only if there are some whitespace
    return /\s/g.test(value) ? encodeURI(value) : value;
  }

}
