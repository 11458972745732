<form class="row justify-content-between align-items-center" novalidate>
  <label for="quantitySpinner-{{ inputId }}" class="col-auto">{{ 'QUANTITY_SPINNER.QUANTITY' | translate }}</label>
  <div class="input-group w-auto col-auto align-items-center" #tooltipBounds>
    <div class="input-group-prepend">
      <button class="btn btn-default p-0"
        type="button"
        [disabled]="disabled || canDecreaseQuantity()"
        [class.color-gray-400]="canDecreaseQuantity()"
        (click)="decreaseQuantity()">
        <i class="icon ni-remove-circle-outline d-block"></i>
      </button>
    </div>
    <input class="form-control text-center mx-2 px-0" id="quantitySpinner-{{ inputId }}"
           [formControl]="input"
           [style.width.px]="68"
           [class.is-invalid]="input.dirty && input.invalid"
           (blur)="onBlur()"

           tooltipOpener
           [tooltipContent]="errorMessage()"
           [tooltipPosition]="'top'"
           [tooltipArrowPosition]="'bottom'"
           [tooltipDisabled]="input.pristine || input.valid"
           [tooltipBounds]="tooltipBounds"
           [additionalClasses]="'tooltip--warning tooltip--configurator-modal-footer'"
    />
    <div class="input-group-append">
      <button class="btn btn-default p-0"
        type="button"
        [disabled]="disabled || canIncreaseQuantity()"
        [class.color-gray-400]="canIncreaseQuantity()"
        (click)="increaseQuantity()">
        <i class="icon ni-add-circle-outline-1 d-block"></i>
      </button>
    </div>
  </div>
</form>
