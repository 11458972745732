<app-generic-modal [closable]="false">
  <ng-template appModalHeader>
    <h4 class="modal-header__title">
      {{ 'LEAVE_MODAL.TITLE'  | translate}}
    </h4>
  </ng-template>
  <ng-template appModalBody>
    <p>
      {{ 'LEAVE_MODAL.DESCRIPTION'  | translate}}
    </p>
  </ng-template>
  <ng-template appModalFooter>
    <button *ngFor="let button of buttons" (click)="button.action()" class="btn" [ngClass]="button.classes">
      {{ button.text | translate }}
    </button>
  </ng-template>
</app-generic-modal>
