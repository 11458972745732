<div
  class="header-message"
  [class.open]="currentMessage && !closing"
  [ngClass]="{
    'header-message--success': currentMessage?.type === messageTypes.POSITIVE,
    'header-message--error': currentMessage?.type === messageTypes.NEGATIVE
  }"
>
  <div class="d-flex align-items-center">
    <div class="header-message__content" [innerHTML]="currentMessage?.text"></div>
    <div #buttonComponent>
      <div class="header-message__button" *ngIf="currentMessage?.options?.buttonComponent"></div>
    </div>
  </div>
</div>
