<ng-container *ngIf="{
  selected: selected$ | async,
  all: languages$ | async
} as data">
  <div ngbDropdown
       [ngClass]="cssClass"
       *ngIf="data.selected"
  >
    <button ngbDropdownToggle
            type="button"
            [ngClass]="toggleClass"
    >{{data.selected.short}}</button>

    <div ngbDropdownMenu
         [ngClass]="menuClass"
    >
      <button ngbDropdownItem
              *ngFor="let item of data.all"
              [ngClass]="itemClass"
              (click)="onSelect(item)"
      >{{item.language}}</button>
    </div>
  </div>
</ng-container>
