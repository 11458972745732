import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
  reset$: Subject<void> = new Subject();

  constructor() { }

  resetObservable(): Observable<void> {
    return this.reset$.asObservable();
  }

  reset() {
    this.reset$.next();
  }
}
