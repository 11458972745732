import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {ApiService} from '../../../api.service';
import {CacheableObservable} from '../../../cacheable-observable/cacheable-observable.model';

@Injectable({
  providedIn: 'root'
})
export class SystemVersionService {

  constructor(private api: ApiService) {
  }

  fetch(): CacheableObservable<string> {
    return this.api.get('system/version').pipe(map(({version}) => version)) as CacheableObservable<string>;
  }
}
