import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-no-results-found',
  templateUrl: './no-results-found.component.html',
  styleUrls: ['./no-results-found.component.scss']
})
export class NoResultsFoundComponent implements OnInit {
  @Input() cssClasses: string;
  constructor() {
  }

  ngOnInit() {
  }
}
