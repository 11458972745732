import { Injectable } from '@angular/core';
import { NotificationInterface } from '../../core/models/notification.model';
import { Router } from '@angular/router';
import { InquiriesRoutePath } from '../../core/enums/route-types.enum';
import { ProjectReservationPreviewTabs } from '../../inquiries/project-reservation/project-reservation-preview/project-reservation-preview.component';

@Injectable({
  providedIn: 'root',
})
export class ProjectReservationNotificationOpenerService {
  constructor(
    private router: Router
  ) {}

  open(notification: NotificationInterface) {
    this.router.navigate(['/', InquiriesRoutePath.ROOT, InquiriesRoutePath.PROJECT_RESERVATIONS, notification.objectId, ProjectReservationPreviewTabs.COMMUNICATION]);
  }
}
