import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';

import { TERMS_QUERY_PARAM } from './terms.const';

@Injectable()
export class TermsUrlService {

  constructor(
    private router: Router
  ) { }

  /**
   * Takes URI of terms item and constructs URL that can be used to navigate to specific terms item.
   * @example
   * // returns UrlTree object of "/terms?document=privacy" url
   * getTermsUrl('privacy')
   * @example
   * // returns UrlTree object of "/terms" url
   * getTermsUrl(null)
   *
   * @param uri terms item/document URI
   */
  createTermsUrl(uri?: string | null): UrlTree {
    return this.router.createUrlTree(['/terms'], { queryParams: { [TERMS_QUERY_PARAM]: uri }});
  }
}
