<app-loader #loader [cssClasses]="'loader--white'"></app-loader>
<div
  class="viewer-component justify-content-center align-items-center d-flex viewer-component--missing-view"
  *ngIf="viewerNotAvailable && !loader.loading"
>
  <svg>
    <use xlink:href="#icon-3d-box"/>
  </svg>
</div>

<div class="viewer-component" #viewer appBorderBoxResizeObserver (onSizeChange)="viewerContainerSizeChanged()"></div>

<div class="viewer-controls mt-4 mb-3">
  <div class="container" *ngIf="!loader.loading">
    <div class="row">
      <div class="col-6 text-end border-end pe-0">
        <button
          class="btn btn-default p-1 d-inline-flex no-outline me-2"
          (click)="onSetBackground(lightMode)"
          [ngClass]="{ 'bg-active': activeBgMode === lightMode }"
          title="{{ 'CONFIGURATOR_MODAL.SWITCH_BG_MODE' | translate }}"
        >
          <i class="icon dot bg-white d-block"></i>
        </button>
        <button
          class="btn btn-default p-1 d-inline-flex no-outline me-3"
          (click)="onSetBackground(darkMode)"
          [ngClass]="{ 'bg-active': activeBgMode === darkMode }"
          title="{{ 'CONFIGURATOR_MODAL.SWITCH_BG_MODE' | translate }}"
        >
          <i class="icon dot bg-gray-400 d-block"></i>
        </button>
      </div>
      <div class="col-6 ps-0">
        <div class="d-inline-block ms-3"
             ngbDropdown
             placement="top-start"
             #downloadDropdown="ngbDropdown"
        >
          <button class="btn btn-default p-1 d-inline-flex no-outline hover-color-black"
                  [disabled]="viewerNotAvailable"
                  ngbDropdownToggle
                  [class.bg-gray-200]="!downloadDropdown.isOpen()"
                  [class.bg-gray-400]="downloadDropdown.isOpen()"
                  [class.color-black]="downloadDropdown.isOpen()"
          >
            <i class="icon ni-download d-bock"></i>
          </button>
          <div ngbDropdownMenu class="shadow">
            <!-- Commented out as per issue NAR-1073. Uncomment when BE implmented.
                  <button>{{'CONFIGURATOR_MODAL.DOWNLOAD.REPORT_PDF' | translate}}</button>
            -->
            <div class="border-bottom">
              <button class="btn btn-default d-flex align-items-center px-3 py-2
                             bg-gray-200 color-gray-600 hover-color-black hover-bg-gray-100"
                      ngbDropdownItem
                      (click)="downloadFile(fileFormat.JPG, 600, 800)"
              >
                <i class="icon ni-download my-1"></i> {{ 'CONFIGURATOR_MODAL.DOWNLOAD.IMAGE' | translate: {dimension: '800x600'} }}
              </button>
            </div>
            <div class="border-bottom">
              <button class="btn btn-default d-flex align-items-center px-3 py-2
                             bg-gray-200 color-gray-600 hover-color-black hover-bg-gray-100"
                      ngbDropdownItem
                      (click)="downloadFile(fileFormat.JPG, 1080, 1920)"
              >
                <i class="icon ni-download my-1"></i> {{ 'CONFIGURATOR_MODAL.DOWNLOAD.IMAGE' | translate: {dimension: '1920x1080'} }}
              </button>
            </div>
            <button class="btn btn-default d-flex align-items-center px-3 py-2
                           bg-gray-200 color-gray-600 hover-color-black hover-bg-gray-100"
                    ngbDropdownItem
                    (click)="downloadFile(fileFormat.DWG)"
            >
              <i class="icon ni-download my-1"></i> {{ 'CONFIGURATOR_MODAL.DOWNLOAD.CAD_DATA' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
