<ng-container *ngTemplateOutlet="item; context: {item: item$ | async}"></ng-container>

<ng-template #item
             let-translation="item.translation"
             let-hasDescription="item.translation?.description?.length"
             let-hasShortDescription="item.translation?.shortDescription?.length"
>
  <h1 class="mb-3">{{translation?.title}}</h1>
  <div class="content-item__entry">
    <div *ngIf="!hasDescription && hasShortDescription" [innerHTML]="translation.shortDescription | safeHtml"></div>
    <div *ngIf="hasDescription" [innerHTML]="translation.description | safeHtml"></div>
  </div>
</ng-template>
