<form [formGroup]="form" class="form">
  <div class="datepicker__button" (click)="datepickerInput.click()">
    <input
      class="hidden-input"
      formControlName="dispatchDate"
      name="dispatchDate"
      #datepickerInput
      bsDatepicker
      placement="top"
      [minDate]="todayDate"
      (onShown)="onShown()"
      [bsConfig]="{ adaptivePosition: true, showWeekNumbers: false, dateInputFormat: 'shortDate' }"
    />
    <div class="datepicker__button__icon "></div>
    <div class="datepicker__button__text">
      {{ form.get('dispatchDate').value ? (form.get('dispatchDate').value | i18nDate: '-':'shortDate') : ('ORDERS.SELECT_DATE' | translate) }}
    </div>
  </div>
</form>
