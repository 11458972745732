<app-generic-modal [closable]="true" bodyClasses="p-4">
  <ng-template appModalHeader>
    <h2 class="mb-0 me-4">
      {{ 'ORDER_ARTICLES.REMOVE_MODAL.' + (removeActionType === removeActionTypeEnum.REMOVE ?
      'TITLE.' : 'UNGROUP_TITLE.') + (removableItems.length > 1 ? 'PLURAL' : 'SINGULAR') | translate }}
    </h2>
  </ng-template>
  <ng-template appModalBody>
    <p class="m-0">
      {{ 'ORDER_ARTICLES.REMOVE_MODAL.' + (removeActionType === removeActionTypeEnum.REMOVE ?
      'DESCRIPTION.' : 'UNGROUP_DESCRIPTION.') + (removableItems.length > 1 ? 'PLURAL' : 'SINGULAR') | translate }}
    </p>
  </ng-template>
  <ng-template appModalFooter>
    <button *ngFor="let button of buttons" (click)="button.action()" class="btn" [ngClass]="button.classes">
      {{ button.text | translate }}
    </button>
  </ng-template>
</app-generic-modal>
