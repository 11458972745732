import { AfterViewInit, Component, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Cookies from 'js-cookie';
import { TermsService } from '../../../terms/terms.service';
import { TranslateService } from '@ngx-translate/core';

const COOKIE_CONSENT_REJECT_FOR_DAYS = 90; // 3 months
const COOKIE_CONSENT_ACCEPT_FOR_DAYS = 730; // 2 years

const COOKIE_CONSENT_STATE_NONE = 'none'; // default case while no cookie concent status is stored
const COOKIE_CONSENT_STATE_REQUIRED = 'necessary';
const COOKIE_CONSENT_STATE_ALL = 'all';

@Component({
  selector: 'app-consent-modal',
  templateUrl: './consent-modal.component.html',
})

export class ConsentModalComponent implements AfterViewInit {
  public cookieConsentState = COOKIE_CONSENT_STATE_NONE;
  @ViewChild('cookieModal') cookieModal: TemplateRef<NgbModal>;
  public cookiesMust = true;
  public cookiesStatistics = false;
  private readonly cookieName = 'cookie_consent';

  constructor(
    private translate: TranslateService,
    private termsService: TermsService,
    private modalService: NgbModal
  ) {
    this.triggerConsentUpdate(true);
    const cookieValue = Cookies.get(this.cookieName);

    if (!cookieValue) {
      this.cookieConsentState = COOKIE_CONSENT_STATE_NONE;
    } else {
      this.cookieConsentState = cookieValue;
    }

    this.triggerConsentUpdate();
  }

  ngAfterViewInit() {
    if (this.cookieConsentState !== COOKIE_CONSENT_STATE_NONE) {

      return;
    }

    // open consent modal only if no consent_cookie was found
    this.modalService.open(this.cookieModal, {
      windowClass: 'cookie-consent-modal',
      size: 'lg',
      backdropClass: 'bg-success',
      centered: true,
    });
  }

  onDismiss() {
    this.saveCookies();
    this.modalService.dismissAll();
  }

  onAgree() {
    this.cookiesStatistics = true;
    this.saveCookies();
    this.modalService.dismissAll();
  }

  getCookieConsentLevel() {
    if (this.cookiesStatistics) {
      return COOKIE_CONSENT_STATE_ALL;
    }

    return COOKIE_CONSENT_STATE_REQUIRED;
  }

  saveCookies() {
    const expiration = this.getCookieConsentLevel() === COOKIE_CONSENT_STATE_ALL ? COOKIE_CONSENT_ACCEPT_FOR_DAYS : COOKIE_CONSENT_REJECT_FOR_DAYS
    Cookies.set(this.cookieName, this.getCookieConsentLevel(), { expires: expiration });
    this.cookieConsentState = this.getCookieConsentLevel();
    this.triggerConsentUpdate();
  }

  triggerConsentUpdate(initDefaults = false) {
    // @ts-ignore: global window.dataLayer
    window.dataLayer = window.dataLayer || [];

    function gtag() {
      // @ts-ignore: global window.dataLayer
      dataLayer.push(arguments);
    }

    // @ts-ignore: global window.dataLayer
    gtag('consent', initDefaults ? 'default' : 'update', {
      ad_storage: 'denied',
      analytics_storage: !initDefaults && this.cookieConsentState === COOKIE_CONSENT_STATE_ALL ? 'granted' : 'denied',
      functionality_storage: !initDefaults && this.cookieConsentState !== COOKIE_CONSENT_STATE_NONE ? 'granted' : 'denied',
      personalization_storage: 'denied',
      security_storage: 'denied'
    });
  }

  openTermsModal(event) {
    event.preventDefault();
    this.termsService.openTermsModal('privacy');
  }
}
