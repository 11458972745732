import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Pipe({
  name: 'isFormControlRequired',
  pure: false
})
export class IsFormControlRequiredPipe implements PipeTransform {
  transform(abstractControl: AbstractControl, args?: any): boolean {
    return this.hasRequiredField(abstractControl);
  }

  private hasRequiredField(abstractControl: AbstractControl): boolean {
    if (!abstractControl || abstractControl.disabled) {
      return false;
    }
    if (abstractControl.validator) {
      const validator = abstractControl.validator({} as AbstractControl);
      return validator && validator.required;
    }
    return false;
  }
}
