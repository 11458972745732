import { Component } from '@angular/core';
import { BehaviorSubject, Observable, Subject, combineLatest, map, merge, of, scan, shareReplay, startWith } from 'rxjs';

import { FinishesService } from './finishes.service';
import { FinishItem, FinishType } from './finishes.model';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-finishes',
  templateUrl: './finishes.component.html',
  styleUrls: ['./finishes.component.scss']
})
export class FinishesComponent {
  private finishes$ = this.finishesSerivce.getFinishes().pipe(
    shareReplay({ bufferSize: 1, refCount: true })
  );

  finishTypes$ = this.finishes$.pipe(
    map(this.getUniqueFinishTypes),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  /** Emits array of finish type IDs */
  private pickedFinishTypes$ = new BehaviorSubject<number[]>([]);

  filteredFinishes$ = combineLatest([this.finishes$, this.pickedFinishTypes$]).pipe(
    map(([finishes, finishTypes]) => {
      if (!finishTypes.length) {
        return finishes;
      }

      return finishes.filter(finish => finishTypes.some(finishType => finishType === finish.finishType.id))
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  links$ = this.finishesSerivce.getFinishPageLinks().pipe(
    shareReplay({ bufferSize: 1, refCount: true })
  );

  loading$ = merge(
    of(true), // loading by default
    this.finishes$.pipe(map(() => false)) // not loading when this emits
  );

  marketingMaterialsURL = environment.marketingMaterialsURL;

  constructor(
    private finishesSerivce: FinishesService,
  ) {}

  private getUniqueFinishTypes(finishes: FinishItem[]): FinishType[] {
    const finishTypes = new Map<number, FinishType>();
    finishes.forEach(finish => {
      if (!finishTypes.has(finish.finishType.id)) {
        finishTypes.set(finish.finishType.id, finish.finishType);
      }
    });

    return Array.from(finishTypes.values());
  }

  filterFinishTypes(finishTypes: number[]) {
    this.pickedFinishTypes$.next(finishTypes);
  }
}
