import { Injectable } from '@angular/core';
import { SESSION_CONSTANTS } from '../session/session.constants';

export interface ConfiguratorModalUserConfigurationInterface {
  shouldShowPriceChanges?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class UserConfigurationService {
  configuration: ConfiguratorModalUserConfigurationInterface;

  constructor(
  ) {
    this.configuration = this.load();
  }

  update(configuration: ConfiguratorModalUserConfigurationInterface) {
    this.save({
      ...this.configuration,
      ...configuration,
    });
  }

  load(): ConfiguratorModalUserConfigurationInterface {
    const loaded = localStorage.getItem(SESSION_CONSTANTS.CONFIGURATOR_CONFIGURATION_STORAGE_KEY);
    if (loaded) {
      const parsed = JSON.parse(loaded);
      return parsed;
    }

    return {
      shouldShowPriceChanges: true,
    };
  }

  private save(configuration: ConfiguratorModalUserConfigurationInterface) {
    localStorage.setItem(SESSION_CONSTANTS.CONFIGURATOR_CONFIGURATION_STORAGE_KEY, JSON.stringify({ ...configuration }));
  }
}
