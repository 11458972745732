<div class="d-block position-relative">
  <input
    class="form-control password-input"
    [type]="showPassword ? 'text' : 'password'"
    [autocomplete]="autocomplete"
    [class.is-invalid]="ngControl.touched && ngControl.invalid"
    [placeholder]="placeholder"
    [id]="id"
    [ngModel]="innerValue"
    (ngModelChange)="onModelChange($event)"
    (blur)="onBlur()"
    [disabled]="isDisabled"
  />
  <div [ngSwitch]="showPassword"
    (click)="togglePasswordVisibility()"
    class="password-visibility-toggle position-absolute d-flex align-items-center pe-2 color-gray-600"
  >
    <i *ngSwitchCase="false" class="icon ni-visibility"></i>
    <i *ngSwitchCase="true" class="icon ni-visibility-off"></i>
  </div>
</div>
