<div
  #triggerElement
  class="btn btn-link px-3 py-2 w-100 d-flex justify-content-between"
  (click)="hasMultipleArticles ? open() : null"
>
  <div class="d-flex flex-column">
    <span class="fw-bold text-start text-black">{{ currentSelectedArticle()?.longText }}</span>
  </div>
  <i *ngIf="hasMultipleArticles" class="icon ni-expand-more color-gray-500 ms-3"></i>
</div>

<div class="d-flex gap-3 flex-wrap px-3 pb-2 fw-bold text-start color-gray-500">
  <span>{{ currentSelectedArticle()?.fullCode }}</span>
  <span>{{ currentArticlePrice | priceFormat:{zeroReplacement: '-'} | pricelistCurrencySymbol }}</span>
</div>

<ng-template #articleListTemplate>
  <div class="overflow-auto bg-white shadow rounded-1 w-100" (click)="closeOverlay()">
    <ng-container *ngFor="let configurationArticle of articles; let last = last">
      <div
        class="d-flex flex-nowrap align-items-center px-3 py-2 fw-bold text-black cursor-pointer"
        [class.border-bottom]="!last"
        (click)="selectArticle.emit(configurationArticle.id)"
      >
        <i class="icon ni-warning-circle text-warning me-2" *ngIf="configurationArticlesWithMigration.includes(configurationArticle.id)"></i>
        <div class="w-100">{{ configurationArticle.longText }}</div>
        <i *ngIf="configurationArticle.id === currentArticleId" class="icon ni-check text-primary ms-auto"></i>
      </div>
    </ng-container>
  </div>
</ng-template>
