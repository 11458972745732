import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientFormComponent } from './client-form/client-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MissingTranslationHandler, TranslateModule } from '@ngx-translate/core';
import { CustomMissingTranslationHandler } from '../core/models/custom-missing-translation-handler.model';
import { TextFieldModule } from '../ui-elements/text-field/text-field.module';
import { PermissionsModule } from '../permissions/permissions.module';
import { SharedModule } from '../shared/shared.module';
import { ClientFormService } from './client-form/client-form.service';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule.forChild({
      missingTranslationHandler: {provide: MissingTranslationHandler, useClass: CustomMissingTranslationHandler},
    }),
    TextFieldModule,
    PermissionsModule,
    SharedModule
  ],
  declarations: [ClientFormComponent],
  exports: [
    ClientFormComponent
  ],
  providers: [ClientFormService]
})
export class ClientModule {
}
