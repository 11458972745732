import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class ValidatorService {
  constructor() {}

  setValidators(form: UntypedFormGroup, validators: ValidatorFn[], controlsNames?: string[]): AbstractControl[] {
    return Object.keys(form.controls).map(key => {
      const control = form.controls[key];
      if ((controlsNames && controlsNames.length && controlsNames.indexOf(key) > -1) || !controlsNames) {
        control.setValidators(validators);
        control.updateValueAndValidity();
      }
      return control;
    });
  }

  addValidators(form: UntypedFormGroup, validators: ValidatorFn[], controlsNames?: string[]): AbstractControl[] {
    return Object.keys(form.controls).map(key => {
      const control = form.get(key);
      if ((controlsNames && controlsNames.length && controlsNames.indexOf(key) > -1) || !controlsNames) {
        control.addValidators(validators);
        control.updateValueAndValidity();
      }
      return control;
    });
  }
}
