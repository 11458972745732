<ul class="nav nav-tabs">
  <li class="nav-item">
    <a class="nav-link" routerLink="../our-ui" routerLinkActive="active">Our UI</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" routerLink="../bootstrap" routerLinkActive="active">Default Bootstrap</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" routerLink="../html" routerLinkActive="active">Default HTML</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" routerLink="../icons" routerLinkActive="active">Our Icons</a>
  </li>
</ul>

<ng-container [ngSwitch]="section$ | async">
  <ng-container *ngSwitchCase="'our-ui'">
    <div class="border my-2" id="app-footer-date-picker">
      <div class="bg-gray-400 p-2">
        <ul class="list-unstyled mb-2">
          <li>
            <b id="app-footer-date-picker">Name: </b>
            <span class=text-underline>app-footer-date-picker</span>
            <a routerLink="./" fragment="app-footer-date-picker"><i class="icon ni-place"></i></a>
          </li>
          <li>Params:
            <ol>
              <li><b>dispatchDate:</b> {{ dispatchDate }}</li>
            </ol>
          </li>
        </ul>
      </div>
      <div class="p-2">
        <app-footer-date-picker
          [dispatchDate]="dispatchDate"
          (dateChange)="onDateChange($event)"
          class="m-0"
        ></app-footer-date-picker>
      </div>
    </div>
    <div class="border my-2">
      <div class="bg-gray-400 p-2">
        <ul class="list-unstyled mb-2">
          <li>
            <b id="app-drop-down">Name: </b>
            <span class=text-underline>app-drop-down</span>
            <a routerLink="./" fragment="app-drop-down"><i class="icon ni-place"></i></a>
          </li>
          <li>Params:
            <ol>
              <li><b>data:</b></li>
              <li>cssClass</li>
              <li>placement</li>
            </ol>
          </li>
        </ul>
      </div>
      <div class="p-2">
        <app-drop-down
          [data]="language"
          class="m-0"
        ></app-drop-down>
      </div>
    </div>
    <div class="border my-2" id="app-breadcrumbs">
      <div class="bg-gray-400 p-2">
        <ul class="list-unstyled mb-2">
          <li>
            <b id="app-breadcrumbs">Name: </b>
            <span class=text-underline>app-breadcrumbs</span>
            <a routerLink="./" fragment="app-breadcrumbs"><i class="icon ni-place"></i></a>
          </li>
          <li>Params:
            <ol>
              <li><b>breadcrumbs:</b></li>
            </ol>
          </li>
        </ul>
      </div>
      <div class="p-2">
        <app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>
      </div>
    </div>
    <div class="border my-2" id="class-app-breadcrumbs">
      <div class="bg-gray-400 p-2">
        <ul class="list-unstyled mb-2">
          <li>
            <b id="class-app-breadcrumbs">Name: </b>
            <span class=text-underline>.app-breadcrumbs</span>
            <a routerLink="./" fragment="class-app-breadcrumbs"><i class="icon ni-place"></i></a>
          </li>
        </ul>
      </div>
      <div class="p-2">
        <div class="container-fluid">
          <div class="row g-0">
            <div class="col-3">
              <div class="app-card">
                <div class="title"><h4>Title</h4></div>
                <div class="text">Some text</div>
                <div class="image"
                     [style.height.px]="224"
                     [style.background-image]="'url(https://d35jf3ycvu078l.cloudfront.net/files/629af526a8ab4_warranty.png?version=6.0.0)'"
                ></div>
              </div>
            </div>
            <div class="col-3">
              <div class="app-card">
                <div class="title"><h4>Title</h4></div>
                <div class="text">Some text</div>
                <div class="image"
                     [style.height.px]="224"
                     [style.background-image]="'url(https://d35jf3ycvu078l.cloudfront.net/files/629af526a8ab4_warranty.png?version=6.0.0)'"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="border my-2" id="app-alert">
        <div class="bg-gray-400 p-2">
          <ul class="list-unstyled mb-2">
            <li>
              <b id="class-app-alert">Name: </b>
              <span class=text-underline>.app-alert</span>
              <a routerLink="./" fragment="class-app-alert"><i class="icon ni-place"></i></a>
            </li>
            <li>
              props:
              <ul>
                <li>variant (string) default 'primary' - bootstrap based variant name</li>
                <li>text (string) default '' - visible as 'Hello there' in sample</li>
                <li>icon (string) default 'ni-info' - icon name</li>
                <li>actionOne (string) default '' - if set - visible as button one text, 'Save' in sample</li>
                <li>actionTwo (string) default '' - if set - visible as button one text, 'Ignore' in sample</li>
                <li>actionOneIcon (string) default '' - icon name, adds icon into first button</li>
                <li>actionTwoIcon (string) default '' - icon name, adds icon into second button</li>
                <li>closeable (boolean) default true - shows/hides close icon</li>
              </ul>
            </li>
            <li>
              events:
              <ul>
                <li>onActionIconClick - emits if icon is not '' and clicked</li>
                <li>onActionOneClick - emits if actionOne is visible and clicked</li>
                <li>onActionTwoClick - emits if actionOne is visible and clicked</li>
                <li>onActionCloseClick - emits if closeable = true and clicked</li>
              </ul>
            </li>
            <li>
              slots:
              <ul>
                <li>default - will be shown wright after text, check last example</li>
                <li>.actions-content - will be shown in buttons section, check last example</li>
              </ul>
            </li>
          </ul>
        </div>
        <div class="p-2">
          <div class="row">
            <div class="col mb-1">
              <strong>Variants: </strong>
            <ng-container *ngFor="let variant of variants">
              <button class="btn btn-sm me-2" [ngClass]="('btn-'+variant)" (click)="selectedVariant = variant">
                <i *ngIf="selectedVariant === variant" class="ni-icon ni-check"></i>
                {{ variant }}
              </button>
            </ng-container>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <app-alert [variant]="selectedVariant">Hello there</app-alert>
              <app-alert [closeable]="false" [variant]="selectedVariant">Hello there</app-alert>
              <app-alert [closeable]="false" icon="" [variant]="selectedVariant">Hello there</app-alert>
              <app-alert [closeable]="false" icon="" [variant]="selectedVariant" actionOne="Save" (onActionOneClick)="actionOneClickHandler($event)">Hello there</app-alert>
              <app-alert [closeable]="false" icon="" [variant]="selectedVariant" actionOne="Save" actionTwo="Ignore">Hello there</app-alert>
              <app-alert icon="" [variant]="selectedVariant" actionOne="Save" actionTwo="Ignore">Hello there</app-alert>
              <app-alert icon="ni-customer-service" [variant]="selectedVariant" actionOne="Save" actionTwo="Ignore">Hello there</app-alert>
              <app-alert icon="ni-customer-service" [variant]="selectedVariant" actionOne="Save" actionOneIcon="ni-settings" actionTwo="Ignore" actionTwoIcon="ni-file-download">Hello there</app-alert>
              <app-alert icon="ni-customer-service" [variant]="selectedVariant">
                <i>html content in default slot</i>
                <div class="actions-content">
                  <button class="btn btn-sm btn-outline-danger d-flex text-nowrap border py-01875r rounded-0">
                    Custom button html <i class="my-auto ms-2 d-inline-flex font-smaller ni-new-release"></i>
                  </button>
                </div>
              </app-alert>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="border my-2" id="class-app-searchable-select">
      <div class="bg-gray-400 p-2">
        <ul class="list-unstyled mb-2">
          <li>
            <b id="class-searchable-select">Name: </b>
            <span class=text-underline>.app-searchable-select</span>
            <a routerLink="./" fragment="class-app-searchable-select"><i class="icon ni-place"></i></a>
          </li>
        </ul>
      </div>
      <div>
        selectedDealer:
        {{ selectedDealer | json }}
        <hr>
        <app-searchable-select [options]="dealerList" [(ngModel)]="selectedDealer" textField="code" valueField="code"></app-searchable-select>
        <app-searchable-select disabled [options]="dealerList" [(ngModel)]="selectedDealer" textField="name" valueField="code"></app-searchable-select>
        <app-searchable-select [options]="dealerList" [(ngModel)]="selectedDealer" textField="name" valueField="code"></app-searchable-select>
        <app-searchable-select placeholder="some placeholder" [options]="dealerList" [(ngModel)]="selectedDealer" textField="code" valueField="code"></app-searchable-select>
        <hr>
        selectedDealer2:
        {{ selectedDealer2 | json }}
        <hr>
        <div class="container">
          <div class="row">
            <div class="col-12 col-sm-6 col-md-4 col-lg-3">
              <app-searchable-select class="w-100 d-inline-block" placeholder="by name" [options]="dealerList" [(ngModel)]="selectedDealer2" textField="code" valueField="code"></app-searchable-select>
            </div>
            <div class="col-12 col-sm-2">
              <app-searchable-select disabled class="w-100 d-inline-block" placeholder="by name" [options]="dealerList" [(ngModel)]="selectedDealer2" textField="code" valueField="code"></app-searchable-select>
            </div>
            <div class="col-12 col-sm-3">
              <app-searchable-select class="w-100 d-inline-block" placeholder="by name" [options]="dealerList" [(ngModel)]="selectedDealer2" textField="name" valueField="code"></app-searchable-select>
            </div>
            <div class="col-12 col-sm-4">
              <app-searchable-select class="w-100 d-inline-block" placeholder="by name" [options]="dealerList" [(ngModel)]="selectedDealer2" textField="code" valueField="code"></app-searchable-select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'bootstrap'">
    <div class="row">
      <div class="col pt-4">
        <div class="column main">
          <p>
            This is a test page filled with common HTML elements to be used to provide visual feedback whilst building CSS systems and
            frameworks. Change the comments to select a different Bootstrap CDN style.
          </p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <h2 class="display-4" id="bs-typography">
          Typography
          <a routerLink="./" fragment="bs-typography"><i class="icon ni-place"></i></a>
        </h2>

        <h1>H1: Occaecat occaecat laborum voluptate in sit ad id proident.</h1>
        <h2>H2: Mollit mollit aliqua quis reprehenderit.</h2>
        <h3>H3: Non est excepteur sint culpa laborum amet nostrud et eu in ea.</h3>
        <h4>H4: Aliquip ullamco esse velit ex officia non culpa sunt aliquip ea esse nisi.</h4>
        <h5>H5: Nulla aliquip ut laborum magna eiusmod laborum Lorem duis ea magna Lorem minim sint consequat.</h5>
        <h6>H6: Dolore minim esse laborum id cupidatat magna sint.</h6>
        <p>
          Ullamco excepteur excepteur eu sint eu irure voluptate et culpa ullamco. Labore culpa cillum culpa et tempor minim consequat
          occaecat occaecat non cillum. Enim ullamco cupidatat exercitation officia elit aliqua Lorem fugiat occaecat excepteur occaecat
          cupidatat ex velit. Cupidatat cillum in sit proident ea.
        </p>
        <p>
          Ad mollit et laborum exercitation cillum ad est ex. Magna aliqua esse occaecat pariatur. Excepteur ex eiusmod eu sunt voluptate
          laboris ullamco nisi consequat laborum tempor excepteur minim. Magna dolore magna consequat commodo consequat fugiat.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <h2 class="display-4 pt-4" id="bs-alerts">
          Alerts
          <a routerLink="./" fragment="bs-alerts"><i class="icon ni-place"></i></a>
        </h2>
        <div>
          <ngb-alert type="primary" [dismissible]="false">
            A simple primary alert — <a href="#" class="alert-link">check it out!</a>
          </ngb-alert>
          <ngb-alert type="secondary" [dismissible]="false">
            A simple secondary alert — <a href="#" class="alert-link">check it out!</a>
          </ngb-alert>
          <ngb-alert type="success" [dismissible]="false">
            A simple success alert — <a href="#" class="alert-link">check it out!</a>
          </ngb-alert>
          <ngb-alert type="danger" [dismissible]="false">
            A simple danger alert — <a href="#" class="alert-link">check it out!</a>
          </ngb-alert>
          <ngb-alert type="warning" [dismissible]="false">
            A simple warning alert — <a href="#" class="alert-link">check it out!</a>
          </ngb-alert>
          <ngb-alert type="info" [dismissible]="false">
            A simple info alert — <a href="#" class="alert-link">check it out!</a>
          </ngb-alert>
          <ngb-alert type="light" [dismissible]="false">
            A simple light alert — <a href="#" class="alert-link">check it out!</a>
          </ngb-alert>
          <ngb-alert type="dark" [dismissible]="false">
            A simple dark alert — <a href="#" class="alert-link">check it out!</a>
          </ngb-alert>

          <h4 class="py-3">Alert with additional content</h4>
          <ngb-alert type="success" [dismissible]="false">
            <h4 class="alert-heading">Well done!</h4>
            <p>Aww yeah, you successfully read this important alert message. This example text is going to run a bit longer so that you
              can see how spacing within an alert works with this kind of content.</p>
            <hr>
            <p class="mb-0">Whenever you need to, be sure to use margin utilities to keep things nice and tidy.</p>
          </ngb-alert>

          <h4 class="py-3">Dismissible alert</h4>
          <ngb-alert type="danger">
            <strong>Holy guacamole!</strong> You should check in on some of those fields below.
          </ngb-alert>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <h2 class="display-4 pt-4" id="bs-buttons">
          Buttons
          <a routerLink="./" fragment="bs-buttons"><i class="icon ni-place"></i></a>
        </h2>
        <table class="table table-borderless">
          <tbody>
          <tr>
            <td></td>
            <td>
              <button type="button" class="btn btn-primary">Primary</button>
            </td>
            <td>
              <button type="button" class="btn btn-secondary">Secondary</button>
            </td>
            <td>
              <button type="button" class="btn btn-success">Success</button>
            </td>
            <td>
              <button type="button" class="btn btn-danger">Danger</button>
            </td>
            <td>
              <button type="button" class="btn btn-warning">Warning</button>
            </td>
            <td>
              <button type="button" class="btn btn-info">Info</button>
            </td>
            <td>
              <button type="button" class="btn btn-light">Light</button>
            </td>
            <td>
              <button type="button" class="btn btn-dark">Dark</button>
            </td>
            <td>
              <button type="button" class="btn btn-link">Link</button>
            </td>
            <td class="bg-gray-900">
              <button type="button" class="btn btn-inverse">Inverse</button>
            </td>
          </tr>
          <tr>
            <td class="align-middle">Active</td>
            <td>
              <button type="button" class="btn btn-primary active">Primary</button>
            </td>
            <td>
              <button type="button" class="btn btn-secondary active">Secondary</button>
            </td>
            <td>
              <button type="button" class="btn btn-success active">Success</button>
            </td>
            <td>
              <button type="button" class="btn btn-danger active">Danger</button>
            </td>
            <td>
              <button type="button" class="btn btn-warning active">Warning</button>
            </td>
            <td>
              <button type="button" class="btn btn-info active">Info</button>
            </td>
            <td>
              <button type="button" class="btn btn-light active">Light</button>
            </td>
            <td>
              <button type="button" class="btn btn-dark active">Dark</button>
            </td>
            <td>
              <button type="button" class="btn btn-link active">Link</button>
            </td>
            <td class="bg-gray-900">
              <button type="button" class="btn btn-inverse active">Inverse</button>
            </td>
          </tr>
          <tr>
            <td class="align-middle">Focused</td>
            <td>
              <button type="button" class="btn btn-primary focus">Primary</button>
            </td>
            <td>
              <button type="button" class="btn btn-secondary focus">Secondary</button>
            </td>
            <td>
              <button type="button" class="btn btn-success focus">Success</button>
            </td>
            <td>
              <button type="button" class="btn btn-danger focus">Danger</button>
            </td>
            <td>
              <button type="button" class="btn btn-warning focus">Warning</button>
            </td>
            <td>
              <button type="button" class="btn btn-info focus">Info</button>
            </td>
            <td>
              <button type="button" class="btn btn-light focus">Light</button>
            </td>
            <td>
              <button type="button" class="btn btn-dark focus">Dark</button>
            </td>
            <td>
              <button type="button" class="btn btn-link focus">Link</button>
            </td>
            <td class="bg-gray-900">
              <button type="button" class="btn btn-inverse focus">Inverse</button>
            </td>
          </tr>
          <tr>
            <td class="align-middle">Pressed</td>
            <td>
              <button type="button" class="btn btn-primary focus active">Primary</button>
            </td>
            <td>
              <button type="button" class="btn btn-secondary focus active">Secondary</button>
            </td>
            <td>
              <button type="button" class="btn btn-success focus active">Success</button>
            </td>
            <td>
              <button type="button" class="btn btn-danger focus active">Danger</button>
            </td>
            <td>
              <button type="button" class="btn btn-warning focus active">Warning</button>
            </td>
            <td>
              <button type="button" class="btn btn-info focus active">Info</button>
            </td>
            <td>
              <button type="button" class="btn btn-light focus active">Light</button>
            </td>
            <td>
              <button type="button" class="btn btn-dark focus active">Dark</button>
            </td>
            <td>
              <button type="button" class="btn btn-link focus active">Link</button>
            </td>
            <td class="bg-gray-900">
              <button type="button" class="btn btn-inverse focus active">Inverse</button>
            </td>
          </tr>
          <tr>
            <td class="align-middle">Disabled</td>
            <td>
              <button type="button" class="btn btn-primary" disabled>Primary</button>
            </td>
            <td>
              <button type="button" class="btn btn-secondary" disabled>Secondary</button>
            </td>
            <td>
              <button type="button" class="btn btn-success" disabled>Success</button>
            </td>
            <td>
              <button type="button" class="btn btn-danger" disabled>Danger</button>
            </td>
            <td>
              <button type="button" class="btn btn-warning" disabled>Warning</button>
            </td>
            <td>
              <button type="button" class="btn btn-info" disabled>Info</button>
            </td>
            <td>
              <button type="button" class="btn btn-light" disabled>Light</button>
            </td>
            <td>
              <button type="button" class="btn btn-dark" disabled>Dark</button>
            </td>
            <td>
              <button type="button" class="btn btn-link" disabled>Link</button>
            </td>
            <td class="bg-gray-900">
              <button type="button" class="btn btn-inverse" disabled>Inverse</button>
            </td>
          </tr>
          <tr>
            <td class="align-middle">Small</td>
            <td>
              <button type="button" class="btn btn-primary btn-sm">Primary</button>
            </td>
            <td>
              <button type="button" class="btn btn-secondary btn-sm">Secondary</button>
            </td>
            <td>
              <button type="button" class="btn btn-success btn-sm">Success</button>
            </td>
            <td>
              <button type="button" class="btn btn-danger btn-sm">Danger</button>
            </td>
            <td>
              <button type="button" class="btn btn-warning btn-sm">Warning</button>
            </td>
            <td>
              <button type="button" class="btn btn-info btn-sm">Info</button>
            </td>
            <td>
              <button type="button" class="btn btn-light btn-sm">Light</button>
            </td>
            <td>
              <button type="button" class="btn btn-dark btn-sm">Dark</button>
            </td>
            <td>
              <button type="button" class="btn btn-link btn-sm">Link</button>
            </td>
            <td class="bg-gray-900">
              <button type="button" class="btn btn-inverse btn-sm">Inverse</button>
            </td>
          </tr>
          <tr>
            <td class="align-middle">Large</td>
            <td>
              <button type="button" class="btn btn-primary btn-lg">Primary</button>
            </td>
            <td>
              <button type="button" class="btn btn-secondary btn-lg">Secondary</button>
            </td>
            <td>
              <button type="button" class="btn btn-success btn-lg">Success</button>
            </td>
            <td>
              <button type="button" class="btn btn-danger btn-lg">Danger</button>
            </td>
            <td>
              <button type="button" class="btn btn-warning btn-lg">Warning</button>
            </td>
            <td>
              <button type="button" class="btn btn-info btn-lg">Info</button>
            </td>
            <td>
              <button type="button" class="btn btn-light btn-lg">Light</button>
            </td>
            <td>
              <button type="button" class="btn btn-dark btn-lg">Dark</button>
            </td>
            <td>
              <button type="button" class="btn btn-link btn-lg">Link</button>
            </td>
            <td class="bg-gray-900">
              <button type="button" class="btn btn-inverse btn-lg">Inverse</button>
            </td>
          </tr>
          <tr>
            <td class="align-middle">Outline</td>
            <td>
              <button type="button" class="btn btn-outline-primary">Primary</button>
            </td>
            <td>
              <button type="button" class="btn btn-outline-secondary">Secondary</button>
            </td>
            <td>
              <button type="button" class="btn btn-outline-success">Success</button>
            </td>
            <td>
              <button type="button" class="btn btn-outline-danger">Danger</button>
            </td>
            <td>
              <button type="button" class="btn btn-outline-warning">Warning</button>
            </td>
            <td>
              <button type="button" class="btn btn-outline-info">Info</button>
            </td>
            <td>
              <button type="button" class="btn btn-outline-light">Light</button>
            </td>
            <td>
              <button type="button" class="btn btn-outline-dark">Dark</button>
            </td>
            <td>
              <button type="button" class="btn btn-outline-link">Link</button>
            </td>
          </tr>
          <tr>
            <td class="align-middle text-nowrap">With icon</td>
            <td>
              <button type="button" class="btn btn-primary d-flex align-items-center"><i class="icon ni-info me-1"></i> Primary</button>
            </td>
            <td>
              <button type="button" class="btn btn-secondary d-flex align-items-center"><i class="icon ni-info me-1"></i> Secondary
              </button>
            </td>
            <td>
              <button type="button" class="btn btn-success d-flex align-items-center"><i class="icon ni-info me-1"></i> Success</button>
            </td>
            <td>
              <button type="button" class="btn btn-danger d-flex align-items-center"><i class="icon ni-info me-1"></i> Danger</button>
            </td>
            <td>
              <button type="button" class="btn btn-warning d-flex align-items-center"><i class="icon ni-info me-1"></i> Warning</button>
            </td>
            <td>
              <button type="button" class="btn btn-info d-flex align-items-center"><i class="icon ni-info me-1"></i> Info</button>
            </td>
            <td>
              <button type="button" class="btn btn-light d-flex align-items-center"><i class="icon ni-info me-1"></i> Light</button>
            </td>
            <td>
              <button type="button" class="btn btn-dark d-flex align-items-center"><i class="icon ni-info me-1"></i> Dark</button>
            </td>
            <td>
              <button type="button" class="btn btn-link d-flex align-items-center"><i class="icon ni-info me-1"></i> Link</button>
            </td>
            <td class="bg-gray-900">
              <button type="button" class="btn btn-inverse d-flex align-items-center"><i class="icon ni-info me-1"></i> Inverse</button>
            </td>
          </tr>
          <tr>
            <td class="align-middle text-nowrap">Icon only</td>
            <td>
              <button type="button" class="btn btn-primary d-flex align-items-center px-2"><i class="icon ni-info"></i></button>
            </td>
            <td>
              <button type="button" class="btn btn-secondary d-flex align-items-center px-2"><i class="icon ni-info"></i></button>
            </td>
            <td>
              <button type="button" class="btn btn-success d-flex align-items-center px-2"><i class="icon ni-info"></i></button>
            </td>
            <td>
              <button type="button" class="btn btn-danger d-flex align-items-center px-2"><i class="icon ni-info"></i></button>
            </td>
            <td>
              <button type="button" class="btn btn-warning d-flex align-items-center px-2"><i class="icon ni-info"></i></button>
            </td>
            <td>
              <button type="button" class="btn btn-info d-flex align-items-center px-2"><i class="icon ni-info"></i></button>
            </td>
            <td>
              <button type="button" class="btn btn-light d-flex align-items-center px-2"><i class="icon ni-info"></i></button>
            </td>
            <td>
              <button type="button" class="btn btn-dark d-flex align-items-center px-2"><i class="icon ni-info"></i></button>
            </td>
            <td>
              <button type="button" class="btn btn-link d-flex align-items-center px-2"><i class="icon ni-info"></i></button>
            </td>
            <td class="bg-gray-900">
              <button type="button" class="btn btn-inverse d-flex align-items-center px-2"><i class="icon ni-info"></i></button>
            </td>
          </tr>
          <tr>
            <td class="align-middle">Dropdowns</td>
            <td>
              <div ngbDropdown>
                <button type="button" class="btn btn-primary" ngbDropdownToggle>Primary</button>
                <div ngbDropdownMenu>
                  <button ngbDropdownItem>Action - 1</button>
                  <button ngbDropdownItem>Another Action</button>
                </div>
              </div>
            </td>
            <td>
              <div ngbDropdown>
                <button type="button" class="btn btn-secondary" ngbDropdownToggle>Secondary</button>
                <div ngbDropdownMenu>
                  <button ngbDropdownItem>Action - 1</button>
                  <button ngbDropdownItem>Another Action</button>
                </div>
              </div>
            </td>
            <td>
              <div ngbDropdown>
                <button type="button" class="btn btn-success" ngbDropdownToggle>Success</button>
                <div ngbDropdownMenu>
                  <button ngbDropdownItem>Action - 1</button>
                  <button ngbDropdownItem>Another Action</button>
                </div>
              </div>
            </td>
            <td>
              <div ngbDropdown>
                <button type="button" class="btn btn-danger" ngbDropdownToggle>Danger</button>
                <div ngbDropdownMenu>
                  <button ngbDropdownItem>Action - 1</button>
                  <button ngbDropdownItem>Another Action</button>
                </div>
              </div>
            </td>
            <td>
              <div ngbDropdown>
                <button type="button" class="btn btn-warning" ngbDropdownToggle>Warning</button>
                <div ngbDropdownMenu>
                  <button ngbDropdownItem>Action - 1</button>
                  <button ngbDropdownItem>Another Action</button>
                </div>
              </div>
            </td>
            <td>
              <div ngbDropdown>
                <button type="button" class="btn btn-info" ngbDropdownToggle>Info</button>
                <div ngbDropdownMenu>
                  <button ngbDropdownItem>Action - 1</button>
                  <button ngbDropdownItem>Another Action</button>
                </div>
              </div>
            </td>
            <td>
              <div ngbDropdown>
                <button type="button" class="btn btn-light" ngbDropdownToggle>Light</button>
                <div ngbDropdownMenu>
                  <button ngbDropdownItem>Action - 1</button>
                  <button ngbDropdownItem>Another Action</button>
                </div>
              </div>
            </td>
            <td>
              <div ngbDropdown>
                <button type="button" class="btn btn-dark" ngbDropdownToggle>Dark</button>
                <div ngbDropdownMenu>
                  <button ngbDropdownItem>Action - 1</button>
                  <button ngbDropdownItem>Another Action</button>
                </div>
              </div>
            </td>
            <td>
              <div ngbDropdown>
                <button type="button" class="btn btn-link" ngbDropdownToggle>Link</button>
                <div ngbDropdownMenu>
                  <button ngbDropdownItem>Action - 1</button>
                  <button ngbDropdownItem>Another Action</button>
                </div>
              </div>
            </td>
            <td class="bg-gray-900">
              <div ngbDropdown>
                <button type="button" class="btn btn-inverse" ngbDropdownToggle>Inverse</button>
                <div ngbDropdownMenu>
                  <button ngbDropdownItem>Action - 1</button>
                  <button ngbDropdownItem>Another Action</button>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td class="align-middle text-nowrap">Group</td>
            <td colspan="10">
              <div class="btn-group" role="group" aria-label="Basic example">
                <button type="button" class="btn btn-primary">Left</button>
                <button type="button" class="btn btn-secondary">Middle</button>
                <button type="button" class="btn btn-success">Right</button>
              </div>
            </td>
          </tr>
          <tr>
            <td class="align-middle">Block</td>
            <td colspan="10">
              <button type="button" class="btn btn-primary btn-block">Block</button>
            </td>
          </tr>
          <tr>
            <td class="align-middle">Links like buttons</td>
            <td><a href="#" class="btn btn-primary">Primary</a></td>
            <td><a href="#" class="btn btn-primary disabled">Primary disabled</a></td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <h2 class="display-4 pt-4" id="bs-cards">
          Cards
          <a routerLink="./" fragment="bs-cards"><i class="icon ni-place"></i></a>
        </h2>
        <div class="card" style="width: 18rem;">
          <img src="https://d35jf3ycvu078l.cloudfront.net/files/61d833f8a194f_POLYTONE%20Thumbnail_1920x780.jpg" class="card-img-top"
               alt="...">
          <div class="card-body">
            <h5 class="card-title">Card title</h5>
            <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
            <a href="#" class="btn btn-primary">Go somewhere</a>
          </div>
        </div>
        <div class="card" style="width: 18rem;">
          <div class="card-body">
            <h5 class="card-title">Card title</h5>
            <h6 class="card-subtitle mb-2 text-muted">Card subtitle</h6>
            <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
            <a href="#" class="card-link">Card link</a>
            <a href="#" class="card-link">Another link</a>
          </div>
        </div>
        <div class="card">
          <div class="card-header">
            Featured
          </div>
          <div class="card-body">
            <h5 class="card-title">Special title treatment</h5>
            <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
            <a href="#" class="btn btn-primary">Go somewhere</a>
          </div>
        </div>
        <div class="card text-center">
          <div class="card-header">
            <ul class="nav nav-tabs card-header-tabs">
              <li class="nav-item">
                <a class="nav-link active" href="#">Active</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#">Link</a>
              </li>
              <li class="nav-item">
                <a class="nav-link disabled">Disabled</a>
              </li>
            </ul>
          </div>
          <div class="card-body">
            <h5 class="card-title">Special title treatment</h5>
            <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
            <a href="#" class="btn btn-primary">Go somewhere</a>
          </div>
        </div>
        <h4 class="pt-3 pb-1">Card deck</h4>
        <div class="card-deck">
          <div class="card">
            <img src="https://d35jf3ycvu078l.cloudfront.net/files/61d833f8a194f_POLYTONE%20Thumbnail_1920x780.jpg" class="card-img-top"
                 alt="...">
            <div class="card-body">
              <h5 class="card-title">Card title</h5>
              <p class="card-text">This is a longer card with supporting text below as a natural lead-in to additional content. This
                content is a little bit longer.</p>
              <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
            </div>
          </div>
          <div class="card">
            <img src="https://d35jf3ycvu078l.cloudfront.net/files/61d833f8a194f_POLYTONE%20Thumbnail_1920x780.jpg" class="card-img-top"
                 alt="...">
            <div class="card-body">
              <h5 class="card-title">Card title</h5>
              <p class="card-text">This card has supporting text below as a natural lead-in to additional content.</p>
              <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
            </div>
          </div>
          <div class="card">
            <img src="https://d35jf3ycvu078l.cloudfront.net/files/61d833f8a194f_POLYTONE%20Thumbnail_1920x780.jpg" class="card-img-top"
                 alt="...">
            <div class="card-body">
              <h5 class="card-title">Card title</h5>
              <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This card
                has even longer content than the first to show that equal height action.</p>
              <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Special title treatment</h5>
            <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
            <a href="#" class="btn btn-primary">Go somewhere</a>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Special title treatment</h5>
            <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
            <a href="#" class="btn btn-primary">Go somewhere</a>
          </div>
        </div>
      </div>
    </div>
    <div class="row row-cols-1 row-cols-md-3">
      <div class="col mb-4">
        <div class="card h-100">
          <img src="https://d35jf3ycvu078l.cloudfront.net/files/61d833f8a194f_POLYTONE%20Thumbnail_1920x780.jpg" class="card-img-top"
               alt="...">
          <div class="card-body">
            <h5 class="card-title">Card title</h5>
            <p class="card-text">This is a longer card with supporting text below as a natural lead-in to additional content. This content
              is a little bit longer.</p>
          </div>
        </div>
      </div>
      <div class="col mb-4">
        <div class="card h-100">
          <img src="https://d35jf3ycvu078l.cloudfront.net/files/61d833f8a194f_POLYTONE%20Thumbnail_1920x780.jpg" class="card-img-top"
               alt="...">
          <div class="card-body">
            <h5 class="card-title">Card title</h5>
            <p class="card-text">This is a short card.</p>
          </div>
        </div>
      </div>
      <div class="col mb-4">
        <div class="card h-100">
          <img src="https://d35jf3ycvu078l.cloudfront.net/files/61d833f8a194f_POLYTONE%20Thumbnail_1920x780.jpg" class="card-img-top"
               alt="...">
          <div class="card-body">
            <h5 class="card-title">Card title</h5>
            <p class="card-text">This is a longer card with supporting text below as a natural lead-in to additional content.</p>
          </div>
        </div>
      </div>
      <div class="col mb-4">
        <div class="card h-100">
          <img src="https://d35jf3ycvu078l.cloudfront.net/files/61d833f8a194f_POLYTONE%20Thumbnail_1920x780.jpg" class="card-img-top"
               alt="...">
          <div class="card-body">
            <h5 class="card-title">Card title</h5>
            <p class="card-text">This is a longer card with supporting text below as a natural lead-in to additional content. This content
              is a little bit longer.</p>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <section>
          <h2 class="display-4 pt-4" id="bs-forms">
            Forms
            <a routerLink="./" fragment="bs-forms"><i class="icon ni-place"></i></a>
          </h2>
          <form>
            <div class="mb-3">
              <label for="exampleInputEmail1" class="form-label">Email address</label>
              <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">
              <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>
            </div>
            <div class="mb-3">
              <label for="exampleInputPassword1" class="form-label">Password</label>
              <input type="password" class="form-control" id="exampleInputPassword1">
            </div>
            <div class="mb-3 form-check">
              <input type="checkbox" class="form-check-input" id="exampleCheck1">
              <label class="form-check-label" for="exampleCheck1">Check me out</label>
            </div>
            <button type="submit" class="btn btn-primary">Submit</button>
          </form>
          <form>
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label">Email address</label>
              <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="name@example.com">
            </div>
            <div class="mb-3">
              <label for="exampleFormControlSelect1" class="form-label">Example select</label>
              <select class="form-control" id="exampleFormControlSelect1">
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </select>
            </div>
            <div class="mb-3">
              <label for="exampleFormControlSelect2" class="form-label">Example multiple select</label>
              <select multiple class="form-control" id="exampleFormControlSelect2">
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </select>
            </div>
            <div class="mb-3">
              <label for="exampleFormControlTextarea1" class="form-label">Example textarea</label>
              <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
            </div>
          </form>
        </section>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <section>
          <h2 class="display-4 pt-4" id="bs-pagination">
            Pagination
            <a routerLink="./" fragment="bs-pagination"><i class="icon ni-place"></i></a>
          </h2>
          <nav aria-label="Page navigation example">
            <ul class="pagination">
              <li class="page-item">
                <a class="page-link" href="#" aria-label="Previous">
                  <span aria-hidden="true">&laquo;</span>
                </a>
              </li>
              <li class="page-item"><a class="page-link" href="#">1</a></li>
              <li class="page-item"><a class="page-link" href="#">2</a></li>
              <li class="page-item"><a class="page-link" href="#">3</a></li>
              <li class="page-item">
                <a class="page-link" href="#" aria-label="Next">
                  <span aria-hidden="true">&raquo;</span>
                </a>
              </li>
            </ul>
          </nav>
        </section>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <section>
          <h2 class="display-4 pt-4" id="bs-progress">
            Progress
            <a routerLink="./" fragment="bs-progress"><i class="icon ni-place"></i></a>
          </h2>
          <div class="progress">
            <div class="progress-bar progress-bar-striped" role="progressbar" style="width: 10%" aria-valuenow="10" aria-valuemin="0"
                 aria-valuemax="100"></div>
          </div>
          <div class="progress">
            <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style="width: 10%" aria-valuenow="10"
                 aria-valuemin="0" aria-valuemax="100"></div>
          </div>
          <div class="progress">
            <div class="progress-bar progress-bar-striped bg-success" role="progressbar" style="width: 25%" aria-valuenow="25"
                 aria-valuemin="0" aria-valuemax="100"></div>
          </div>
          <div class="progress">
            <div class="progress-bar progress-bar-striped bg-primary-700" role="progressbar" style="width: 50%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
          <div class="progress">
            <div class="progress-bar progress-bar-striped bg-warning" role="progressbar" style="width: 75%" aria-valuenow="75"
                 aria-valuemin="0" aria-valuemax="100"></div>
          </div>
          <div class="progress">
            <div class="progress-bar progress-bar-striped bg-danger" role="progressbar" style="width: 100%" aria-valuenow="100"
                 aria-valuemin="0" aria-valuemax="100"></div>
          </div>
        </section>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <section>
          <h2 class="display-4 pt-4" id="bs-spinners">
            Spinners
            <a routerLink="./" fragment="bs-spinners"><i class="icon ni-place"></i></a>
          </h2>
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <div class="spinner-border text-secondary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <div class="spinner-border text-success" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <div class="spinner-border text-danger" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <div class="spinner-border text-warning" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <div class="spinner-border text-info" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <div class="spinner-border text-light" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <div class="spinner-border text-dark" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </section>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <section>
          <h2 class="display-4 pt-4" id="bs-tables">
            Tables
            <a routerLink="./" fragment="bs-tables"><i class="icon ni-place"></i></a>
          </h2>
          <table class="table table-hover">
            <thead class="thead-light small">
            <tr>
              <th class="col-3 text-uppercase">Header 1</th>
              <th class="col-3 text-uppercase">Header 2</th>
              <th class="col-2 text-uppercase">Header 3</th>
              <th class="col-2 text-uppercase">Header 4</th>
              <th class="col-2 text-uppercase">Header 5</th>
            </tr>
            </thead>
            <tbody class="border-bottom">
            <tr>
              <td>Data 1</td>
              <td>Data 2</td>
              <td>Data 3</td>
              <td>Data 4</td>
              <td>Data 5</td>
            </tr>
            <tr>
              <td>Data 1</td>
              <td>Data 2</td>
              <td>Data 3</td>
              <td>Data 4</td>
              <td>Data 5</td>
            </tr>
            <tr>
              <td>Data 1</td>
              <td>Data 2</td>
              <td>Data 3</td>
              <td>Data 4</td>
              <td>Data 5</td>
            </tr>
            <tr>
              <td>Data 1</td>
              <td>Data 2</td>
              <td>Data 3</td>
              <td>Data 4</td>
              <td>Data 5</td>
            </tr>
            </tbody>
          </table>
        </section>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h2 class="display-4 pt-4" id="bs-dropdown">
          Dropdown
          <a routerLink="./" fragment="bs-dropdown"><i class="icon ni-place"></i></a>
        </h2>
        <div class="pt-3 px-3 mb-4 bg-white" [style.padding-bottom.px]="200">
          <div ngbDropdown class="d-inline-block">
            <button type="button" class="btn btn-primary" ngbDropdownToggle>Toggle dropdown</button>
            <div ngbDropdownMenu class="shadow-sm">
              <button ngbDropdownItem>Action - 1</button>
              <button ngbDropdownItem>Another Action</button>
              <button ngbDropdownItem>Something else is here</button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </ng-container>
  <ng-container *ngSwitchCase="'html'">
    <div class="row">
      <div class="col">
        <section>
          <h3 class="pt-3 pb-1">HTML defaults basically</h3>
        </section>
      </div>
    </div>
    <div>
      <div>
        <div>
          <main role="main">
            <section id="text">
              <article id="text__headings">
                <header>
                  <h1>Headings</h1>
                </header>
                <div>
                  <h1>Heading 1</h1>
                  <h2>Heading 2</h2>
                  <h3>Heading 3</h3>
                  <h4>Heading 4</h4>
                  <h5>Heading 5</h5>
                  <h6>Heading 6</h6>
                </div>
                <footer>
                  <p>
                    <a href="#top">[Top]</a>
                  </p>
                </footer>
              </article>
              <article id="text__paragraphs">
                <header>
                  <h1>Paragraphs</h1>
                </header>
                <div>
                  <p>
                    A paragraph (from the Greek paragraphos, “to write beside” or “written beside”) is a self-contained unit of a discourse
                    in writing dealing with a particular point or idea. A paragraph consists of one or more sentences. Though not required
                    by the syntax of any language, paragraphs are usually an expected part of formal writing, used to organize longer prose.
                  </p>
                </div>
                <footer>
                  <p>
                    <a href="#top">[Top]</a>
                  </p>
                </footer>
              </article>
              <article id="text__blockquotes">
                <header>
                  <h1>Blockquotes</h1>
                </header>
                <div>
                  <blockquote>
                    <p>
                      A block quotation (also known as a long quotation or extract) is a quotation in a written document, that is set off
                      from the main text as a paragraph, or block of text.
                    </p>
                    <p>
                      It is typically distinguished visually using indentation and a different typeface or smaller size quotation. It may or
                      may not include a citation, usually placed at the bottom.
                    </p>
                    <cite>
                      <a href="#!">Said no one, ever.</a>
                    </cite>
                  </blockquote>
                </div>
                <footer>
                  <p>
                    <a href="#top">[Top]</a>
                  </p>
                </footer>
              </article>
              <article id="text__lists">
                <header>
                  <h1>Lists</h1>
                </header>
                <div>
                  <h3>Definition list</h3>
                  <dl>
                    <dt>Definition List Title</dt>
                    <dd>This is a definition list division.</dd>
                  </dl>
                  <h3>Ordered List</h3>
                  <ol>
                    <li>List Item 1</li>
                    <li>List Item 2</li>
                    <li>List Item 3</li>
                  </ol>
                  <h3>Unordered List</h3>
                  <ul>
                    <li>List Item 1</li>
                    <li>List Item 2</li>
                    <li>List Item 3</li>
                  </ul>
                </div>
                <footer>
                  <p>
                    <a href="#top">[Top]</a>
                  </p>
                </footer>
              </article>
              <article id="text__hr">
                <header>
                  <h1>Horizontal rules</h1>
                </header>
                <div>
                  <hr/>
                </div>
                <footer>
                  <p>
                    <a href="#top">[Top]</a>
                  </p>
                </footer>
              </article>
              <article id="text__tables">
                <header>
                  <h1>Tabular data</h1>
                </header>
                <table>
                  <caption>
                    Table Caption
                  </caption>
                  <thead>
                  <tr>
                    <th>Table Heading 1</th>
                    <th>Table Heading 2</th>
                    <th>Table Heading 3</th>
                    <th>Table Heading 4</th>
                    <th>Table Heading 5</th>
                  </tr>
                  </thead>
                  <tfoot>
                  <tr>
                    <th>Table Footer 1</th>
                    <th>Table Footer 2</th>
                    <th>Table Footer 3</th>
                    <th>Table Footer 4</th>
                    <th>Table Footer 5</th>
                  </tr>
                  </tfoot>
                  <tbody>
                  <tr>
                    <td>Table Cell 1</td>
                    <td>Table Cell 2</td>
                    <td>Table Cell 3</td>
                    <td>Table Cell 4</td>
                    <td>Table Cell 5</td>
                  </tr>
                  <tr>
                    <td>Table Cell 1</td>
                    <td>Table Cell 2</td>
                    <td>Table Cell 3</td>
                    <td>Table Cell 4</td>
                    <td>Table Cell 5</td>
                  </tr>
                  <tr>
                    <td>Table Cell 1</td>
                    <td>Table Cell 2</td>
                    <td>Table Cell 3</td>
                    <td>Table Cell 4</td>
                    <td>Table Cell 5</td>
                  </tr>
                  <tr>
                    <td>Table Cell 1</td>
                    <td>Table Cell 2</td>
                    <td>Table Cell 3</td>
                    <td>Table Cell 4</td>
                    <td>Table Cell 5</td>
                  </tr>
                  </tbody>
                </table>
                <footer>
                  <p>
                    <a href="#top">[Top]</a>
                  </p>
                </footer>
              </article>
              <article id="text__code">
                <header>
                  <h1>Code</h1>
                </header>
                <div>
                  <p>
                    <strong>Keyboard input:</strong>
                    <kbd>Cmd</kbd>
                  </p>
                  <p>
                    <strong>Inline code:</strong>
                    <code></code>
                  </p>
                  <div>
                    <code>code</code>
                  </div>
                  <p>&nbsp;</p>
                  <p>
                    <strong>Sample output:</strong>
                    <samp>This is sample output from a computer program.</samp>
                  </p>
                  <h2>Pre-formatted text</h2>
                  <pre>
    p r e f o r m a t t e d t e x t
        ! " # $ % &amp; ' ( ) * + , - . / \
        0 1 2 3 4 5 6 7 8 9 : ; &lt; = &gt; ?
        &#64; a b c d e f g h i j k l m n o
        p q r s t u v w x y z [ \ ] ^ _
        ` a b c d e f g h i j k l m n o
        p q r s t u v w x y z  ~ &amp;
      </pre>
                </div>
                <footer>
                  <p><a href="#top">[top]</a></p>
                </footer>
              </article>
              <article id="text__inline">
                <header>
                  <h1>inline elements</h1>
                </header>
                <div>
                  <p><a href="#!">this is a text link</a>.</p>
                  <p><strong>strong is used to indicate strong importance.</strong></p>
                  <p><em>this text has added emphasis.</em></p>
                  <p>the <strong>b element</strong> is stylistically different text from normal text, without any special importance.</p>
                  <p>the <em>i element</em> is text that is offset from the normal text.</p>
                  <p>the <u>u element</u> is text with an unarticulated, though explicitly rendered, non-textual annotation.</p>
                  <p>
                    <del>this text is deleted</del>
                    and
                    <ins>this text is inserted</ins>
                    .
                  </p>
                  <p><s>this text has a strikethrough</s>.</p>
                  <p>superscript<sup>®</sup>.</p>
                  <p>subscript for things like h<sub>2</sub>o.</p>
                  <p><small>this small text is small for for fine print, etc.</small></p>
                  <p>abbreviation: <abbr title="hypertext markup language">html</abbr></p>
                  <p><q cite="https://developer.mozilla.org/en-us/docs/html/element/q">this text is a short inline quotation.</q></p>
                  <p><cite>this is a citation.</cite></p>
                  <p>the <dfn>dfn element</dfn> indicates a definition.</p>
                  <p>the
                    <mark>mark element</mark>
                    indicates a highlight.
                  </p>
                  <p>the <var>variable element</var>, such as <var>x</var> = <var>y</var>.</p>
                  <p>the time element:
                    <time datetime="2013-04-06t12:32+00:00">2 weeks ago</time>
                  </p>
                </div>
                <footer>
                  <p><a href="#top">[top]</a></p>
                </footer>
              </article>
            </section>
            <section id="embedded">
              <header>
                <h1>embedded content</h1>
              </header>
              <article id="embedded__images">
                <header>
                  <h2>images</h2>
                </header>
                <div>
                  <h3>no <code></code></h3>
                  <figure><code></code> element</figure>
                  <p><img src="http://placekitten.com/480/480" alt="image alt text"/></p>
                  <h3>wrapped in a <code></code></h3>
                  <figure>
                    <code></code> element, no <code></code>
                    <figcaption><code></code></figcaption>
                  </figure>
                  <figure><img src="http://placekitten.com/420/420" alt="image alt text"/></figure>
                  <h3>wrapped in a <code></code></h3>
                  <figure>
                    <code></code> element, with a <code></code>
                    <figcaption><code></code></figcaption>
                  </figure>
                  <figure>
                    <img src="http://placekitten.com/420/420" alt="image alt text"/>
                    <figcaption>here is a caption for this image.</figcaption>
                  </figure>
                </div>
                <footer>
                  <p><a href="#top">[top]</a></p>
                </footer>
              </article>
              <article id="embedded__audio">
                <header>
                  <h2>audio</h2>
                </header>
                <div>
                  <audio controls="controls">audio</audio>
                </div>
                <footer>
                  <p><a href="#top">[top]</a></p>
                </footer>
              </article>
              <article id="embedded__video">
                <header>
                  <h2>video</h2>
                </header>
                <div>
                  <video controls="controls" width="300" height="150">video</video>
                </div>
                <footer>
                  <p><a href="#top">[top]</a></p>
                </footer>
              </article>
              <article id="embedded__canvas">
                <header>
                  <h2>canvas</h2>
                </header>
                <div>
                  <canvas>canvas</canvas>
                </div>
                <footer>
                  <p><a href="#top">[top]</a></p>
                </footer>
              </article>
              <article id="embedded__meter">
                <header>
                  <h2>meter</h2>
                </header>
                <div>
                  <meter value="2" min="0" max="10">2 out of 10</meter>
                </div>
                <footer>
                  <p><a href="#top">[top]</a></p>
                </footer>
              </article>
              <article id="embedded__progress">
                <header>
                  <h2>progress</h2>
                </header>
                <div>
                  <progress>progress</progress>
                </div>
                <footer>
                  <p><a href="#top">[top]</a></p>
                </footer>
              </article>
              <article id="embedded__svg">
                <header>
                  <h2>inline svg</h2>
                </header>
                <div>&nbsp;</div>
                <footer>
                  <p><a href="#top">[top]</a></p>
                </footer>
              </article>
              <article id="embedded__iframe">
                <header>
                  <h2>iframe</h2>
                </header>
                <div>
                  <iframe src="html5 test page.html" height="300"></iframe>
                </div>
                <footer>
                  <p><a href="#top">[top]</a></p>
                </footer>
              </article>
            </section>
            <section id="forms">
              <header>
                <h1>form elements</h1>
              </header>
              <form>
                <fieldset id="forms__input">
                  <legend>input fields</legend>
                  <p><label for="input__text">text input</label> <input id="input__text" type="text" placeholder="text input"/></p>
                  <p>
                    <label for="input__password">password</label>
                    <input id="input__password" type="password" placeholder="type your password"/>
                  </p>

                  <p>
                    <label for="input__webaddress">web address</label>
                    <input id="input__webaddress" type="url" placeholder="http://yoursite.com"/>
                  </p>

                  <p>
                    <label for="input__emailaddress">email address</label>
                    <input id="input__emailaddress" type="email" placeholder="name@email.com"/>
                  </p>

                  <p><label for="input__phone">phone number</label> <input id="input__phone" type="tel" placeholder="(999) 999-9999"/></p>
                  <p><label for="input__search">search</label> <input id="input__search" type="search" placeholder="enter search term"/></p>
                  <p><label for="input__text2">number input</label> <input id="input__text2" type="number" placeholder="enter a number"/>
                  </p>
                  <p>
                    <label class="error" for="input__text3">error</label>
                    <input id="input__text3" class="is-error" type="text" placeholder="text input"/>
                  </p>

                  <p>
                    <label class="valid" for="input__text4">valid</label>
                    <input id="input__text4" class="is-valid" type="text" placeholder="text input"/>
                  </p>
                </fieldset>
                <p><a href="#top">[top]</a></p>
                <fieldset id="forms__select">
                  <legend>select menus</legend>
                  <p>
                    <label for="select">select</label><select id="select">
                    <optgroup label="option group">
                      <option>option one</option>
                      <option>option two</option>
                      <option>option three</option>
                    </optgroup>
                  </select>
                  </p>
                </fieldset>
                <p><a href="#top">[top]</a></p>
                <fieldset id="forms__checkbox">
                  <legend>checkboxes</legend>
                  <ul class="list list--bare">
                    <li>
                      <label for="checkbox1"><input id="checkbox1" checked="checked" name="checkbox" type="checkbox"/> choice a</label>
                    </li>
                    <li>
                      <label for="checkbox2"><input id="checkbox2" name="checkbox" type="checkbox"/> choice b</label>
                    </li>
                    <li>
                      <label for="checkbox3"><input id="checkbox3" name="checkbox" type="checkbox"/> choice c</label>
                    </li>
                  </ul>
                </fieldset>
                <p><a href="#top">[top]</a></p>
                <fieldset id="forms__radio">
                  <legend>radio buttons</legend>
                  <ul class="list list--bare">
                    <li>
                      <label for="radio1"><input id="radio1" class="radio" checked="checked" name="radio" type="radio"/> option 1</label>
                    </li>
                    <li>
                      <label for="radio2"><input id="radio2" class="radio" name="radio" type="radio"/> option 2</label>
                    </li>
                    <li>
                      <label for="radio3"><input id="radio3" class="radio" name="radio" type="radio"/> option 3</label>
                    </li>
                  </ul>
                </fieldset>
                <p><a href="#top">[top]</a></p>
                <fieldset id="forms__textareas">
                  <legend>textareas</legend>
                  <p>
                    <label for="textarea">textarea</label>
                    <textarea id="textarea" cols="48" rows="8" placeholder="enter your message here"></textarea>
                  </p>
                </fieldset>
                <p><a href="#top">[top]</a></p>
                <fieldset id="forms__html5">
                  <legend>html5 inputs</legend>
                  <p><label for="ic">color input</label> <input id="ic" type="color" value="#000000"/></p>
                  <p><label for="in">number input</label> <input id="in" max="10" min="0" type="number" value="5"/></p>
                  <p><label for="ir">range input</label> <input id="ir" type="range" value="10"/></p>
                  <p><label for="idd">date input</label> <input id="idd" type="date" value="1970-01-01"/></p>
                  <p><label for="idm">month input</label> <input id="idm" type="month" value="1970-01"/></p>
                  <p><label for="idw">week input</label> <input id="idw" type="week" value="1970-w01"/></p>
                  <p><label for="idt">datetime input</label> <input id="idt" type="datetime" value="1970-01-01t00:00:00z"/></p>
                  <p><label for="idtl">datetime-local input</label> <input id="idtl" type="datetime-local" value="1970-01-01t00:00"/></p>
                </fieldset>
                <p><a href="#top">[top]</a></p>
                <fieldset id="forms__action">
                  <legend>action buttons</legend>
                  <p>
                    <input type="submit" value="<input type=submit>"/> <input type="button" value="<input type=button>"/>
                    <input type="reset" value="<input type=reset>"/> <input disabled="disabled" type="submit" value="<input disabled>"/>
                  </p>

                  <p>
                    <button type="submit">button type=submit</button>
                    <button type="button">button type=button</button>
                    <button type="reset">button type=reset</button>
                    <button disabled="disabled" type="button">button disabled</button>
                  </p>
                </fieldset>
                <p><a href="#top">[top]</a></p>
              </form>
            </section>
          </main>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'icons'">
    <div class="container header bg-white">
      <div class="row no-gutter">
        <div class="col d-flex justify-content-between align-items-center">
          <h1>nspace-icons <small>font demo</small></h1>

          <div class="m-0 filter-group">
            <input id="filter" class="form-control" type="text" name="filter" [(ngModel)]="filter">
          </div>

        </div>
      </div>
    </div>
    <div class="container" id="icons">
      <div class="row">
        <div
          class="col-2 py-2 px-3 hover-bg-white deep-hover-color-primary d-flex justify-content-between align-items-center text-break--word"
          *ngFor="let item of iconListFiltered(filter)">
          <i class="icon deep-hover" [ngClass]="item.name"></i>
          <span class="ps-3 ms-auto deep-hover text-end" [title]="item.unicode">{{ item.name }}</span>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
