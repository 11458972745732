import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AddressService } from '../../../../core/services/address/address.service';
import { LoaderComponent } from '../../../../ui-elements/loader/loader.component';
import { TranslateService } from '@ngx-translate/core';
import { AddressInterface } from '../../../../core/models/address.model';
import { ButtonInterface } from '../../../../shared/interfaces/modal/button.model';
import { ToastService } from '../../../../ui-elements/toast/toast.service';

@Component({
  selector: 'app-settings-shipping-addresses-delete-modal',
  templateUrl: './delete-modal.component.html',
})
export class DeleteModalComponent implements OnInit {
  @ViewChild('loader') loader: LoaderComponent;
  address: AddressInterface;
  @Output() deleted: EventEmitter<boolean | null | undefined> = new EventEmitter<boolean | null | undefined>();

  buttons: ButtonInterface[] = [
    {
      text: 'SETTINGS.CANCEL',
      action: () => this.activeModal.close(),
      classes: 'btn-secondary',
    },
    {
      text: 'SETTINGS.DELETE',
      action: () => this.onDelete(),
      classes: 'btn-danger',
    },
  ];

  constructor(
    private addressService: AddressService,
    private activeModal: NgbActiveModal,
    private toastService: ToastService,
    private translator: TranslateService
  ) {}

  ngOnInit() {}

  onDelete() {
    this.loader.show();
    this.addressService.delete(this.address.id).subscribe(
      result => {
        this.translator.get('SETTINGS.DELETED_SUCCESS').subscribe(translation => {
          this.toastService.success(translation);
          this.loader.hide();
          this.deleted.emit();
          this.activeModal.dismiss('success');
        });
      },
      err => {
        this.loader.hide();
      }
    );
  }
}
