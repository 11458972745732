import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { filter, map, NEVER, Observable, of, shareReplay, skipWhile, Subscription, switchMap, take, tap, timer } from 'rxjs';

import { ApiService } from '../api.service';
import { AuthService } from '../auth/auth.service';
import { ContentTreeModel } from '../content/content-tree.model';
import { ImpersonateService } from '../core/impersonate/impersonate.service';
import { UserInterface } from '../core/models/user.model';
import { UserService } from '../core/services/user/user.service';
import { TermsModalComponent } from './terms-modal/terms-modal.component';

@Injectable({
  providedIn: 'root'
})
export class TermsService implements OnDestroy {
  private waitingForResponse$: Observable<boolean> | null = null;

  private subscription = new Subscription();

  constructor(
    private userService: UserService,
    private impersonationService: ImpersonateService,
    private authService: AuthService,
    private ngbModal: NgbModal,
    private api: ApiService,
    private router: Router
  ) {

    // When user is logged in this observable regularly checks whether user has terms to agree to
    this.subscription.add(
      this.authService.authenticatedObservable().pipe(
        switchMap(loggedIn => loggedIn ? this.userService.poll() : NEVER),
        filter(user => user && !this.waitingForResponse$ && !this.impersonationService.impersonated()),
        this.getAgreement()
      ).subscribe(
        response => {
          if (!response && this.authService.isAuthenticated()) {
            this.router.navigate(['/logout']);
          }
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /**
   * Check whether user has agreed to terms and ask to agree if hasn't.
   * Returned observable emits true if agreed, emits false if didn't.
   * */
  getAgreement(): (source: Observable<UserInterface>) => Observable<boolean> {
    return (source: Observable<UserInterface>) => source.pipe(
      switchMap(user =>
        this.userAgreedToTerms(user)
        ? of(true) // no need to ask to agree to terms, emit true immediately
        : this.askToAgree()
      )
    );
  }

  userAgreedToTerms(user: UserInterface): boolean {
    return Boolean(user.currentTermsAndConditionsApproved);
  }

  /**
   * Returns observable that waits for any modals to close, opens terms modal,
   * then emits boolean of whether user agreed with terms or not
   */
  private askToAgree(): Observable<boolean> {
    if (!this.waitingForResponse$) {
      this.waitingForResponse$ = this.whenModalsAreClosed().pipe(
        switchMap(() => this.openAgreementModal()),
        switchMap((agreed: boolean) => {
          if (!agreed) {
            return of(false);
          } else {
            return this.agreeWithTerms().pipe(
              map(() => true)
            );
          }
        }),
        tap(() => { this.waitingForResponse$ = null }),
        shareReplay({ refCount: false })
      );
    }

    return this.waitingForResponse$;
  }

  private agreeWithTerms() {
    return this.api.patch('users/terms-and-conditions/approve', {}).pipe(
      switchMap(() => this.userService.reload())
    );
  }

  /**
   * Opens terms and conditions modal and returns observable of whether user agreed with terms
   */
  private openAgreementModal(): Observable<boolean> {
    const modalRef = this.ngbModal.open(TermsModalComponent, {
      size: 'xl',
      modalDialogClass: 'modal-dialog-scrollable',
      beforeDismiss: () => false
    })
    const component = modalRef.componentInstance as TermsModalComponent;
    component.showCloseButton = false;
    component.showAgreementButtons = true;
    component.replaceURL = false;

    return component.agreed.pipe(
      tap((val) => { modalRef.close(); }),
      take(1)
    );
  }

  /**
   * Observable that emits one time when no modals are opened
   */
  private whenModalsAreClosed() {
    return timer(0, 500).pipe(
      skipWhile(() => this.ngbModal.hasOpenModals()),
      take(1)
    );
  }

  openTermsModal(item: ContentTreeModel.Data['uri']) {
    const modalRef = this.ngbModal.open(TermsModalComponent, {
      size: 'xl',
      modalDialogClass: 'modal-dialog-scrollable'
    });
    const component = modalRef.componentInstance as TermsModalComponent;
    component.changeSelectedItem(item);

    return modalRef;
  }
}
