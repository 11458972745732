import {Directive, ElementRef, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {OrderRouteType} from '../../../core/enums/route-types.enum';
import {Subscription} from 'rxjs';
import {filter, map, mergeMap} from 'rxjs/operators';

@Directive({
  selector: '[appActivatePricelist]'
})
export class ActivatePricelistDirective implements OnInit, OnDestroy {
  private subscription: Subscription;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private elRef: ElementRef, private renderer: Renderer2) {
  }

  ngOnInit(): void {
    this.subscription = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data))
      .subscribe((event) => {
        if (event.id in OrderRouteType) {
          this.renderer.addClass(this.elRef.nativeElement, 'dropdown--disabled');
        } else {
          this.renderer.removeClass(this.elRef.nativeElement, 'dropdown--disabled');
        }
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
