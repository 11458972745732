import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ImpersonateService } from '../impersonate.service';
import { AuthService } from '../../../auth/auth.service';
import { UserService } from '../../services/user/user.service';
import { UserInterface } from '../../models/user.model';

@Injectable({ providedIn: 'root' })
export class ImpersonateResolver  {
  constructor(private impersonateService: ImpersonateService, private authService: AuthService, private userService: UserService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | any {
    return new Observable(observer => {
      const encodedEmail = encodeURIComponent(route.params.email);
      const email = decodeURIComponent(encodedEmail);
      this.authService.authenticated(false);

      this.impersonateService.updateUser({ email });
      this.impersonateService.impersonate();
      this.userService.fromStorage().subscribe((user: UserInterface) => {
        this.authService.updateUser(user);
        this.authService.authenticated(true);
        observer.next(email);
        observer.complete();
      });
    });
  }
}
