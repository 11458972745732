import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RefreshButtonComponent } from './refresh-button.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    RefreshButtonComponent
  ],
  exports: [
    RefreshButtonComponent
  ]
})
export class RefreshButtonModule { }
