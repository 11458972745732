import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BrowserSupportService {
  public browserIsSupported$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  constructor() {
    if (/msie\s|trident\/|edge\//i.test(window.navigator.userAgent)) {
      this.browserIsSupported$.next(false);
    }
  }
}
