import {Injectable} from '@angular/core';
import {LoaderComponent} from './loader.component';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  constructor() {
  }

  /**
   * @param {Observable<any>} observable
   * @param loader?: ViewContainerRef
   * @returns {Observable<any>}
   */
  load<T>(observable: Observable<T>, loader?: LoaderComponent): Observable<T> {
    loader?.show();
    return new Observable(observer => {
      observable.subscribe({
        next: res => {
        observer.next(res);
          observer.complete();
          loader?.hide();
        },
        error: (error) => {
          loader?.hide();
          observer.error(error);
        }
      });
    });
  }
}
