import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-abstract-notification',
  templateUrl: './abstract-notification.component.html',
  styleUrls: ['./abstract-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AbstractNotificationComponent {
  @Input() showBadge = false;
}
