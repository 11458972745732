import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {TooltipChangedEventInterface} from './model';
import Timer = NodeJS.Timer;

@Injectable({
  providedIn: 'root'
})
export class TooltipOpenerService {
  private changed$: BehaviorSubject<TooltipChangedEventInterface> = new BehaviorSubject<TooltipChangedEventInterface>({
    open: false
  });
  private timer: Timer;

  constructor() {
  }

  changed(event: TooltipChangedEventInterface|void) {
    if (event) {
      this.changed$.next(event);
    }

    return this.changed$.getValue();
  }

  changedAsObservable(): Observable<TooltipChangedEventInterface> {
    return this.changed$.asObservable();
  }

  setTimer(timer: Timer) {
    this.timer = timer;
  }

  clearTimer() {
    clearTimeout(this.timer);
  }
}
