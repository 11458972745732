import {forwardRef, Injectable} from '@angular/core';
import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import {LanguageStoreHandlerService} from './language-store-handler.service';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';

export const ACCEPT_LANGUAGE_HEADER_INTERCEPTOR_PROVIDER = {
  provide: HTTP_INTERCEPTORS,
  useClass: forwardRef(() => AcceptLanguageInterceptor),
  multi: true
};

@Injectable()
export class AcceptLanguageInterceptor implements HttpInterceptor {

  constructor(private languageService: LanguageStoreHandlerService,
              private translateService: TranslateService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const language = this.translateService.currentLang || this.translateService.defaultLang;
    /** Do not override accept-language header if already exists. Used for translations/ endpoint
     * HttpClient changes all headers to lowercase on request
     */
    if (language && !request.headers.has('accept-language')) {
      request = request.clone({
        setHeaders: {
          'Accept-Language': `${language}`
        }
      });
    }
    return next.handle(request);
  }
}
