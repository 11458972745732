<div class="card-body pb-0">
  <app-loader #loader [cssClasses]="'loader--white'"></app-loader>
  <div class="p-2 mb-3">
    <h2>{{'SETTINGS.CUSTOMERS.TITLE' | translate}}</h2>
    <p class="m-0 color-gray">{{ 'SETTINGS.CUSTOMERS.TITLE_TEXT' | translate}}</p>
  </div>
  <div class="mx-n3">
    <ul class="list-unstyled mb-0">
      <li class="d-flex justify-content-between align-items-start
                 hover-bg-gray-100 deep-hover-d-flex py-3 px-4 border-top"
          *ngFor="let client of clients"
      >
        <span class="my-1">{{client | clientPipe}}</span>
        <div class="d-flex w-25 justify-content-end">
          <div class="deep-hover d-none">
            <button class="btn btn-danger color-white no-outline p-1 me-2 d-flex" (click)="onDelete(client)">
              <i class="icon ni-delete-1"></i>
            </button>
            <button class="btn btn-primary color-white no-outline p-1 d-flex" (click)="onEdit(client)">
              <i class="icon ni-create"></i>
            </button>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>
