import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PropertyValuesSelectorComponent} from './property-values-selector.component';
import {RadioButtonModule} from '../../ui-elements/radio-button/radio-button.module';
import {AccordionTogglerModule} from '../../ui-elements/accordion-toggler/accordion-toggler.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {MissingTranslationHandler, TranslateModule} from '@ngx-translate/core';
import {CustomMissingTranslationHandler} from '../../core/models/custom-missing-translation-handler.model';
import {PipesModule} from '../pipes/pipes.module';
import {TooltipModule} from '../../ui-elements/tooltip/tooltip.module';
import {UncacheableResourceModule} from '../../uncacheable-resource/uncacheable-resource.module';
import { MigrationIssuesTooltipComponent } from './migration-issues-tooltip/migration-issues-tooltip.component';

@NgModule({
  imports: [
    CommonModule,
    RadioButtonModule,
    AccordionTogglerModule,
    NgbModule,
    TranslateModule.forChild({
      missingTranslationHandler: {provide: MissingTranslationHandler, useClass: CustomMissingTranslationHandler},
    }),
    PipesModule,
    TooltipModule,
    UncacheableResourceModule
  ],
  declarations: [
    PropertyValuesSelectorComponent,
    MigrationIssuesTooltipComponent,
  ],
  exports: [
    PropertyValuesSelectorComponent,
  ]
})
export class PropertyValuesSelectorModule {
}
