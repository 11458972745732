import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AbstractNotificationComponent } from './abstract-notification/abstract-notification.component';
import { NotificationsHeaderComponent } from './notifications-header/notifications-header.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    AbstractNotificationComponent,
    NotificationsHeaderComponent
  ],
  imports: [
    CommonModule,
    TranslateModule
  ],
  exports: [
    AbstractNotificationComponent,
    NotificationsHeaderComponent
  ]
})
export class NotificationComponentsModule { }
