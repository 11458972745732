import {Observable, Subscription} from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import {ApiService} from '../api.service';
import {TranslateService} from '@ngx-translate/core';
import {AbstractSearchService, SearchResponseInterface} from '../core/tree/search/search-service.model';
import { switchMap, map, distinctUntilChanged, tap, take } from 'rxjs/operators';
import { ListModeSwitchService } from '../shared/components/list-mode-switch/list-mode-switch.service';
import { Search } from '../core/tree/search/search.enum';
import { QueryParamsService } from '../core/services/query-params/query-params.service';
import { ActivatedRoute, Router } from '@angular/router';
import { QueryParams } from '../core/enums/query-params.enum';
import { NavbarState, TreeService } from '../core/tree/tree.service';

@Injectable()
export class CatalogueSearchService extends AbstractSearchService implements OnDestroy {
  placeholder = 'CATALOGUE.SEARCH.PLACEHOLDER';
  protected route = ['/catalogue', 'search'];

  term$ = this.activatedRoute.queryParams.pipe(
    map(params => params[QueryParams.TERM] ?? null),
    distinctUntilChanged()
  );

  searchTerm: string;
  searchActive = false;
  searchActive$ = this.term$.pipe(
    map(term => {
      this.searchTerm = term;
      return term && term.length > Search.MIN_TERM_LENGTH;
    }),
    distinctUntilChanged(),
    tap(isActive => this.searchActive = isActive)
  );

  private preRedirectUrl: string | null = null;
  private navigationState: NavbarState;
  private allowedQueryParams: QueryParams[] = [QueryParams.TERM, QueryParams.SALE_MODE];
  private subscriptions = new Subscription();

  constructor(
    protected api: ApiService,
    protected translator: TranslateService,
    protected listModeSwitchService: ListModeSwitchService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private treeService: TreeService
  ) {
    super(api, translator);
    this.subscriptions.add(this.searchActive$.subscribe())
  }

  protected endpointUrl = (term) => `articles/search/${encodeURI(term)}`;

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  changeTerm(term: string | null): void {
    if (term && term !== this.searchTerm && term.length > Search.MIN_TERM_LENGTH) {
      if (!this.searchTerm && term) {
        this.savePreSearchNavigationState();
      }
      const currentParams = QueryParamsService.getAllowedQueryParams(this.activatedRoute, this.allowedQueryParams);
      this.router.navigate(this.route, {
        queryParams: { ...currentParams, term },
        replaceUrl: this.isSearchActive(),
      });
    } else if (!term && this.isSearchActive()) {
      this.restorePreSearchNavigationState();
    }
  }

  get(term): Observable<SearchResponseInterface> {
    const saleMode = this.listModeSwitchService.getSaleMode();
    return this.api.get(this.endpointUrl(term), {'sale-mode': saleMode}).noCache().pipe(
      map(({data}) => data),
      switchMap((data) => {
        return this.combineTranslationWithSearchResponse(term, data, this.translator);
      })
    );
  }

  isSearchActive(): boolean {
    return this.searchActive;
  }

  savePreSearchNavigationState() {
    this.preRedirectUrl = this.router.url;

    // try to remember tree-navigation state
    this.treeService.getState().pipe(take(1)).subscribe(data => {
      this.navigationState = data;
    });
    this.treeService.onSearch();

    if (this.preRedirectUrl) {
      this.preRedirectUrl = this.router.url;
    }
  }

  restorePreSearchNavigationState() {
    this.router.navigateByUrl(this.preRedirectUrl);
    this.treeService.modifyState(this.navigationState);
    this.preRedirectUrl = null;
    this.navigationState = null;
  }
}

