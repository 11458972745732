<form id="form" [formGroup]="form" (ngSubmit)="onSubmit()" class="m-0">
  <input class="d-none" >
  <app-loader #loader cssClasses="loader--white"></app-loader>
  <div class="card-body mb-3">
    <h2 class="mb-3">{{ 'SETTINGS.PROFILE.MY_PROFILE' | translate }}</h2>
    <div class="form-h-spacer"></div>
    <div class="profile__image pb-2" [class.profile__image--with-errors]="errors.profilePicture && errors.profilePicture.length > 0">
      <app-file-upload
        [showUploadLinkOnly]="true"
        previewClass="col-8 mt-3 mb-2"
        imgClass="rounded-circle sp-60 me-2"
        [fallback]="{
          default: 'assets/images/user/placeholder.svg',
          half: 'assets/images/user/placeholder.svg',
          double: 'assets/images/user/placeholder.svg',
          triple: 'assets/images/user/placeholder.svg'
        }"
        [labels]="{ empty: 'SETTINGS.PROFILE.PHOTO.UPLOAD' | translate, selected: 'SETTINGS.PROFILE.PHOTO.CHANGE' | translate }"
        (selected)="onProfilePictureChange($event)"
        [fileUrl]="user?.profilePicture?.url"
      >
      </app-file-upload>
      <div class="w-50 offset-4 text-danger small profile__image__error ps-1"
           *ngIf="errors.profilePicture && errors.profilePicture.length > 0">
        <div class="pb-2" *ngFor="let error of errors.profilePicture">
          {{ error }}
        </div>
      </div>
    </div>
    <app-profile-form [profileForm]="form" [countries]="countries" [user]="user"></app-profile-form>
  </div>
  <div class="card-footer w-100 text-end p-0">
    <button type="button" class="btn btn-secondary btn-lg rounded-0 mw-11r" (click)="onReset()">
      {{ 'SETTINGS.PROFILE.CANCEL' | translate }}
    </button>
    <button type="submit" class="btn btn-primary btn-lg rounded-0 mw-11r">
      {{ 'SETTINGS.PROFILE.SAVE' | translate }}
    </button>
  </div>
</form>
