import { Observable } from 'rxjs';
import { filter, repeatWhen, takeUntil } from 'rxjs/operators';

export const pause = (observable: Observable<any>) => (source: Observable<any>) =>
  new Observable(observer => {
    const { pause$, resume$ } = {
      pause$: observable.pipe(filter(v => v)),
      resume$: observable.pipe(filter(v => !v)),
    };
    return source
      .pipe(
        takeUntil(pause$),
        repeatWhen(() => resume$)
      )
      .subscribe({
        next() {
          observer.next();
        },
        error(err) {
          observer.error(err);
        },
        complete() {
          observer.complete();
        },
      });
  });
