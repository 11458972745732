<div class="accordion">
  <div class="card border-0 shadow-sm mb-3"
    [class.card--active]="expanded">
    <div class="card-header border-0 p-3"
      (click)="toggleExpansion()"
    >
      <div class="w-100 d-flex align-items-center justify-content-between">
        <a class="checkbox-filter-title fw-bold text-truncate color-black overflow-hidden">
          {{ title }}
        </a>

        <a class="filter-sidebar-clear color-gray" *ngIf="hasCheckedFilter(); else notChecked"
            (click)="onClear()" appClickStopPropagation>{{ 'CATALOGUE.FILTERS.CLEAR' | translate }}</a>
        <ng-template #notChecked>
          <i *ngIf="expanded; else notExpended" class="icon ni-minus color-gray"></i>
          <ng-template #notExpended>
            <i class="icon ni-plus color-gray"></i>
          </ng-template>
        </ng-template>
      </div>
    </div>
    <div class="collapse" [class.show]="expanded" data-parent="#accordion">
      <div class="card-body px-3 pb-3 pt-0">
        <div class="pb-2 color-black">
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>

