import {Component, OnDestroy, OnInit} from '@angular/core';
import {QueryParamsService} from '../../services/query-params/query-params.service';
import {QueryParams} from '../../enums/query-params.enum';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {AuthService} from '../../../auth/auth.service';
import {combineLatest, filter, map, Subscription} from 'rxjs';
import {TreeService} from '../tree.service';

export function getCurrentParams(activatedRoute: ActivatedRoute) {
  return QueryParamsService.getAllowedQueryParams(activatedRoute,
    [QueryParams.VIEW, QueryParams.SALE_MODE]);
}

const DISABLE_SCROLL = 'disable-scroll';

@Component({
  selector: 'app-navbar-tree',
  templateUrl: './navbar-tree.component.html',
  styleUrls: ['./navbar-tree.component.scss']
})
export class NavbarTreeComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  authenticated: boolean;
  isThirdNavbarOpen: boolean;

  showSimpleNav$ = combineLatest([
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.router.isActive('/terms', {
        paths: 'exact', queryParams: 'ignored', fragment: 'ignored', matrixParams: 'ignored'
      }))
    ),
    this.authService.authenticatedObservable()
  ]).pipe(
    map(([matchesTermsUrl, loggedIn]) => matchesTermsUrl && !loggedIn)
  );

  constructor(
    private router: Router,
    private authService: AuthService,
    private treeService: TreeService
  ) {
  }

  ngOnInit(): void {
    this.subscription.add(
      this.authService.authenticatedObservable().subscribe((auth) => {
        this.authenticated = auth;
      })
    );
    this.subscription.add(
      this.treeService.getState()
        .subscribe(({second, third}) => {
          this.isThirdNavbarOpen = !!second && third;
          if (this.isThirdNavbarOpen) {
            document.querySelector('body').classList.add(DISABLE_SCROLL);
          } else {
            document.querySelector('body').classList.remove(DISABLE_SCROLL);
          }
        })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
