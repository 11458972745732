import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FilterPathGeneratorService {

  constructor() {
  }

  generate(category: string, subCategory: string): string|null {
    let path = null;
    if (category && subCategory) {
      path = `${category}/${subCategory}`;
    } else if (!category && subCategory) {
      path = `${subCategory}`;
    } else if (category && !subCategory) {
      path = `${category}`;
    }
    if (path) {
      path = path.replace(/ /g, '_');
    }
    return path;
  }
}
