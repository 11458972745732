import { forwardRef, Injectable, Injector } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpParams, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { ImpersonateService } from './impersonate.service';
import { Observable } from 'rxjs';
import { CustomURLEncoder } from '../encoders/customHttpParamsEncoder';

export const IMPERSONATED_USER_INTERCEPTOR = {
  provide: HTTP_INTERCEPTORS,
  useClass: forwardRef(() => ImpersonateInterceptor),
  multi: true,
};

@Injectable()
export class ImpersonateInterceptor implements HttpInterceptor {
  constructor(public injector: Injector, private router: Router, private impersonateService: ImpersonateService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.impersonateService.impersonated()) {
      const impersonatedUser = this.impersonateService.getUser();

      const params = new HttpParams({ fromString: request.params.toString(), encoder: new CustomURLEncoder() }).append(
        '_impersonate',
        impersonatedUser.email
      );

      request = request.clone({
        params,
      });
    }

    return next.handle(request);
  }
}
