import { ArticlePropertyClassInterface } from '../core/models/configurator.model';
import { PropertyClassGeneratorService } from './property-class-generator.service';

export class PropertyClassesBuilder {
  private queue: Function[];

  constructor(private propertyClasses: ArticlePropertyClassInterface[]) {
    this.queue = [];
  }

  group() {
    this.queue.push(PropertyClassGeneratorService.groupPropertyClasses);
    return this;
  }

  determineTypes() {
    this.queue.push(PropertyClassGeneratorService.determinePropertyClassTypes);
    return this;
  }

  removeEmptyValues() {
    this.queue.push(PropertyClassGeneratorService.removeEmptyValues);
    return this;
  }

  hideHidden() {
    this.queue.push(PropertyClassGeneratorService.hideHiddenPropertyClasses);
    return this;
  }

  flagAdded() {
    this.queue.push(PropertyClassGeneratorService.flagAdded);
    return this;
  }

  build() {
    this.queue.forEach((fn: Function) => {
      this.propertyClasses = fn.apply(this, [this.propertyClasses]);
    });

    return this.propertyClasses;
  }
}
