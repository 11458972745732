import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { OrderItemsClipboardService } from '../../orders/order-items-clipboard/order-items-clipboard.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit {
  constructor(private authService: AuthService, private orderItemsClipboardService: OrderItemsClipboardService) {}

  ngOnInit() {
    this.orderItemsClipboardService.clearClipboard();
    this.authService.logout();
  }
}
