import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { noWhitespace } from '../../class/custom-validators';
import { I18nDatePipe } from '../../pipes/i18n-date/i18n-date.pipe';

const convertDateToTimestamp = (date: string | Date) => {
  return new Date(date).getTime();
};

@Component({
  selector: 'app-footer-date-picker',
  templateUrl: './footer-date-picker.component.html',
  styleUrls: ['./footer-date-picker.component.scss'],
})
export class FooterDatePickerComponent implements OnInit, OnChanges {
  @ViewChild('datepickerInput', { static: true }) datepickerInput;
  @Input() orderId;
  @Input() dispatchDate: string;

  @Output() dateChange: EventEmitter<any> = new EventEmitter<any>();
  todayDate = new Date();
  form: UntypedFormGroup = new UntypedFormGroup({
    dispatchDate: new UntypedFormControl(null, [noWhitespace]),
  });

  constructor(private i18nDatePipe: I18nDatePipe) {}

  ngOnInit() {
    this.form.valueChanges.subscribe(({ dispatchDate }) => {
      if (convertDateToTimestamp(this.dispatchDate) !== convertDateToTimestamp(dispatchDate)) {
        this.dateChange.emit(this.i18nDatePipe.transform(dispatchDate, '-', 'yyyy-MM-dd'));
      }
    });
  }

  ngOnChanges() {
    if (this.dispatchDate) {
      this.form.patchValue({ dispatchDate: new Date(this.dispatchDate) }, { emitEvent: false });
    }
  }

  onShown() {
    // bsDatepicker's adaptivePosition doesn't take into account left/right position correctly, so we have to move it manually.
    setTimeout(() => {
      const datePicker = document.querySelector('.bs-datepicker') as HTMLElement;
      const bounding = datePicker.getBoundingClientRect();
      if (bounding.left < 0) {
        // Left side is out of viewoprt, move it.
        datePicker.style.left = bounding.left * -1 + 'px';
      }
    });
  }
}
