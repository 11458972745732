import { ElementRef, Injectable } from '@angular/core';
import { arrowSize } from './model';

export enum TooltipArrowPositionClasses {
  TOP = 'top',
  TOP_LEFT = 'top-left',
  TOP_RIGHT = 'top-right',
  BOTTOM = 'bottom',
  BOTTOM_RIGHT = 'bottom-right',
  BOTTOM_LEFT = 'bottom-left',
}

@Injectable({
  providedIn: 'root',
})
export class TooltipArrowClassGuesserService {
  constructor() {}

  private guess(position: string) {
    switch (position) {
      case TooltipArrowPositionClasses.TOP:
      case TooltipArrowPositionClasses.TOP_LEFT:
      case TooltipArrowPositionClasses.TOP_RIGHT:
        return 'tooltip__arrow--top-center';
      case TooltipArrowPositionClasses.BOTTOM:
      case TooltipArrowPositionClasses.BOTTOM_LEFT:
      case TooltipArrowPositionClasses.BOTTOM_RIGHT:
        return 'tooltip__arrow--bottom-center';
      default:
        throw new Error(`Tooltip arrow position ${position} is not available.`);
    }
  }

  generate(position: TooltipArrowPositionClasses, element: ElementRef, parentElement: ElementRef) {
    const parentRect = parentElement.nativeElement.getBoundingClientRect();
    const elementRect = element.nativeElement.getBoundingClientRect();

    if (Object.values(TooltipArrowPositionClasses).includes(position)) {
      const styles = { ...this.verticalAlign(position, elementRect), ...this.horizontalAlign(position, parentRect) };
      const className = this.guess(position);
      return {
        styles,
        className,
      };
    } else {
      throw new Error(`Tooltip arrow position ${position} is not available.`);
    }
  }

  private verticalAlign(position, elementRect) {
    if (
      position === TooltipArrowPositionClasses.TOP ||
      position === TooltipArrowPositionClasses.TOP_LEFT ||
      position === TooltipArrowPositionClasses.TOP_RIGHT
    ) {
      return {
        top: `${elementRect.top}px`,
      };
    }
    // In other way it will be BOTTOM positioned arrow
    return {
      top: `${elementRect.top + elementRect.height - arrowSize.HEIGHT / 2 - 4}px`,
    };
  }

  private horizontalAlign(position, parentRect) {
    if (position === TooltipArrowPositionClasses.BOTTOM_LEFT || position === TooltipArrowPositionClasses.TOP_LEFT) {
      return {
        left: `${parentRect.left + parentRect.width / 4}px`,
      };
    } else if (position === TooltipArrowPositionClasses.TOP_RIGHT || position === TooltipArrowPositionClasses.BOTTOM_RIGHT) {
      return {
        left: `${parentRect.left + parentRect.width * 0.75}px`,
      };
    }
    // In other way it will be horizontally centered arrow
    return {
      left: `${parentRect.left + parentRect.width / 2}px`,
    };
  }
}
