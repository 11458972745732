import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopoverComponent } from './popover/popover.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { UncacheableResourceModule } from '../../uncacheable-resource/uncacheable-resource.module';

@NgModule({
  declarations: [PopoverComponent],
  imports: [
    CommonModule,
    OverlayModule,
    UncacheableResourceModule,
  ]
})
export class PopoverModule {
}
