import { Component, ContentChild, Input, OnInit, TemplateRef } from '@angular/core';
import { ModalHeaderDirective } from './directives/modal-header/modal-header.directive';
import { ModalBodyDirective } from './directives/modal-body/modal-body.directive';
import { ModalFooterDirective } from './directives/modal-footer/modal-footer.directive';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CloseButtonTheme } from '../close-button/close-button-theme.enum';

@Component({
  selector: 'app-generic-modal',
  templateUrl: './generic-modal.component.html',
  styleUrls: ['./generic-modal.component.scss'],
})
export class GenericModalComponent implements OnInit {
  @ContentChild(ModalHeaderDirective, { read: TemplateRef }) headerTemplate;
  @ContentChild(ModalBodyDirective, { read: TemplateRef }) bodyTemplate;
  @ContentChild(ModalFooterDirective, { read: TemplateRef }) footerTemplate;
  @Input() cssClasses?: string;
  @Input() headerClasses?: string;
  @Input() bodyClasses?: string;
  @Input() footerClasses?: string;
  @Input() heading?: string;
  @Input() subHeading?: string;
  @Input() closable? = true;
  @Input() hasHeader? = true;

  closeButtonTheme = CloseButtonTheme;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {}
}
