<form id="form" [formGroup]="form" (ngSubmit)="onSubmit()" class="m-0">
  <div class="card-body p-4">
    <div>
      <h2 class="mb-3">{{ "SETTINGS.BASICS.CHANGE" | translate }}</h2>
    </div>
    <div class="container-fluid px-0">
      <div class="d-none">
        <!-- Hidden email field allows password managers to save both email and password -->
        <input id="email" type="text" name="username" [value]="user.email" autocomplete="username" disabled>
      </div>
      <div class="row text-nowrap d-flex align-items-center mb-3">
        <label class="col-6 m-0 color-gray-500 text-end">
          {{ 'SETTINGS.BASICS.OLD_PASSWORD' | translate }}
        </label>
        <app-password-input class="col-6"
          [id]="'current-password'"
          formControlName="oldPassword"
        ></app-password-input>
        <div class="col-6 offset-6">
          <div *ngIf="form.controls.oldPassword.touched && form.controls.oldPassword.invalid"
            class="text-danger mt-2 small"
          >
            {{ form.controls.oldPassword.errors | translateValidationErrors }}
          </div>
        </div>
      </div>
      <div class="row text-nowrap d-flex align-items-center mb-3">
        <label class="col-6 m-0 color-gray-500 text-end">
          {{ 'SETTINGS.BASICS.NEW_PASSWORD' | translate }}
        </label>
        <app-password-input
          [id]="'new-password'"
          class="col-6"
          formControlName="newPassword"
        ></app-password-input>
        <div class="col-6 offset-6 small color-gray-600">
          <div *ngIf="form.errors && form.errors['match']"
            class="color-danger mt-2"
          >
            {{ 'SETTINGS.BASICS.SAME_PASSWORDS' | translate }}
          </div>
          <div *ngIf="form.get('newPassword').errors && form.get('newPassword').errors['isEqualTo']"
            class="color-danger mt-2"
          >
            {{ 'PASSWORD.PASSWORD_REQUIREMENTS.DONT_USE_EXAMPLE' | translate }}
          </div>
          <app-password-strength-indicator
            *ngIf="form.controls.newPassword.errors && !form.controls.newPassword.errors['required'] || !form.controls.newPassword.errors"
            class="d-block mt-2"
            [control]="form.controls.newPassword"
          ></app-password-strength-indicator>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-6 small color-gray-600">
          <p>{{ 'PASSWORD.UNIQUE_AND_UNGUESSABLE' | translate }}</p>
          <p>{{ 'PASSWORD.EXAMPLE' | translate }}</p>
        </div>
        <div class="col-6 small color-gray-900">
          <app-password-requirement-indicator [validationErrors]="form.controls.newPassword.errors"></app-password-requirement-indicator>
        </div>
      </div>

      <hr class="form-h-spacer mt-0">

      <div class="row">
        <div class="offset-6 col-6 text-end">
          <button class="btn btn-primary w-50" type="submit" [disabled]="form.invalid">
            {{"SETTINGS.BASICS.SAVE" | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
