import {Injectable} from '@angular/core';
import {UserInterface} from '../models/user.model';
import {Observable, Subject} from 'rxjs';
import {ListModeSwitchService} from "../../shared/components/list-mode-switch/list-mode-switch.service";

export const ImpersonateConstants = {
  LOCAL_STORAGE_KEY: 'impersonatedUser'
};

@Injectable()
export class ImpersonateService {
  private impersonated$: Subject<boolean | null> = new Subject<boolean | null>();

  constructor() {
    this.impersonated$.next(false);
  }

  impersonated(): boolean {
    return !!this.getUser();
  }

  getUser(): UserInterface {
    return JSON.parse(localStorage.getItem(ImpersonateConstants.LOCAL_STORAGE_KEY)) || null;
  }

  updateUser(user: UserInterface) {
    const currentUser: UserInterface = this.getUser();
    const updatedUser: UserInterface = {...currentUser, ...user};
    localStorage.setItem(ImpersonateConstants.LOCAL_STORAGE_KEY, JSON.stringify(updatedUser));
  }

  impersonate() {
    ListModeSwitchService.clearLocalStorage();
    this.impersonated$.next(true);
  }

  unImpersonate() {
    ListModeSwitchService.clearLocalStorage();
    localStorage.removeItem(ImpersonateConstants.LOCAL_STORAGE_KEY);
    this.impersonated$.next(false);
  }

  impersonatedObservable(): Observable<boolean> {
    return this.impersonated$.asObservable();
  }
}
