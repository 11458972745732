import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VisibilityByVariablesComponent } from './visibility-by-variables.component';
import { VisibilityByVariablesReplacementDirective } from './visibility-by-variables-replacement.directive';
import { VisibilityByVariablesPipe } from './pipe/visibility-by-variables.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [VisibilityByVariablesComponent, VisibilityByVariablesReplacementDirective, VisibilityByVariablesPipe],
  exports: [VisibilityByVariablesComponent, VisibilityByVariablesReplacementDirective, VisibilityByVariablesPipe],
})
export class VisibilityByVariablesModule {}
