<div class="shadow-sm border-end bg-gray-200 position-absolute w-100 h-100" (clickOutside)="clickOutside.emit()">
  <div class="navigation d-flex bg-white align-items-stretch position-relative border-bottom">
    <!-- Orders tab: Offers button -->
    <ng-container *ngTemplateOutlet="tabButton; context: { $implicit: {
      type: tabTypes.OFFERS,
      permission: permissionActions.CAN_EDIT_OFFERS,
      title: 'ORDER_SELECT.OFFERS.TITLE' | translate
    } }"></ng-container>
    <!-- Orders tab: Orders button -->
    <ng-container *ngTemplateOutlet="tabButton; context: { $implicit: {
      type: tabTypes.ORDERS,
      permission: permissionActions.CAN_ADD_STANDARD_ITEM_TO_ORDER,
      title: 'ORDER_SELECT.ORDERS.TITLE' | translate
    } }"></ng-container>

    <!-- Search -->
    <div class="navigation__search d-flex align-items-center position-relative"
         [class.ms-0]="isTabActive(tabTypes.SEARCH)"
    >
      <div class="navigation__search__divider"></div>
      <div class="navigation__search__input-group mx-3 d-flex align-items-center">
        <input
          type="text"
          class="form-control w-100"
          [(ngModel)]="search.term"
          #searchInput
          (keyup)="onSearch($event)"
          [class.invisible]="!isTabActive(tabTypes.SEARCH)"
        />
        <button
          class="btn btn-lg btn-default px-0 ms-1 no-outline rounded-0 hover-color-black"
          [class.color-black]="isTabActive(tabTypes.SEARCH)"
          (click)="onTabChange(isTabActive(tabTypes.SEARCH) ? tabTypes.OFFERS : tabTypes.SEARCH)"
        >
          <i
            class="icon d-block"
            [class.ni-search]="!isTabActive(tabTypes.SEARCH)"
            [class.ni-close]="isTabActive(tabTypes.SEARCH)"
          ></i>
        </button>
      </div>
    </div>
  </div>

  <!-- items list -->
  <app-loader #loader></app-loader>
  <ul
    class="list list--orders list-unstyled small color-black"
    [class.list--orders--loading]="loader.loading"
    infiniteScroll
    [infiniteScrollDistance]="0.5"
    [infiniteScrollThrottle]="50"
    [scrollWindow]="false"
    (scrolled)="onScroll()"
    [infiniteScrollDisabled]="loader.loading || (pagination.totalPages && pagination.page >= pagination.totalPages)"
    *ngIf="!groupsListVisible; else selectedTemplate"
  >
    <li class="item-row d-flex align-items-center position-relative cursor-pointer py-2 px-3 border-bottom border-color-gray-400">
      <button class="btn btn-primary btn-sm d-flex mx-1 my-2" (click)="onFormToggle()">
        <i class="icon d-block me-2 ni-folder-add"></i>
        <div>{{ (userService.isDealer() ? "ORDER_SELECT.OFFERS.NEW_OFFER" : "ORDER_SELECT.ORDERS.NEW_ORDER") | translate }}</div>
      </button>
    </li>
    <li *ngIf="isFormActive" class="item-row d-flex align-items-center position-relative cursor-pointer
        px-3 border-bottom border-color-gray-400 bg-gray-100"
    >
      <app-order-select-menu-new-item
        class="w-100"
        (create)="onCreateNewOrder($event)"
        [type]="newItemTypes.ORDER"
      ></app-order-select-menu-new-item>
    </li>

    <ng-container *ngIf="orders?.length; else noResults">
      <li
        class="item-row d-flex align-items-center position-relative cursor-pointer
          py-2 px-3 border-bottom border-color-gray-400 deep-hover-color-gray-100"
        [ngClass]="{
          'color-gray-400 cursor-not-allowed': order.isSendingToAX,
          'bg-gray-100': selectedOrder?.id === order.id
        }"
        (click)="onRowClick(order)"
        *ngFor="let order of orders"
      >
        <i class="icon me-2 my-1 d-block"
           [ngClass]="{
              'color-warning': order.state === orderState.WAITING,
              'ni-document': !order.isSendingToAX,
              'ni-clock': order.isSendingToAX
            }"
        ></i>
        <div class="me-2 d-flex flex-grow-1 mw-0r">
          <span class="text-truncate">{{ order.title }}</span>
        </div>
        <i *ngIf="selectedOrder?.id === order?.id" class="icon ni-check text-primary hide-on-hover"></i>
        <button class="btn btn-default btn-sm p-0 my-1 color-gray-500 btn-select">
          <i class="icon ni-chevron-right d-block"></i>
        </button>
      </li>
    </ng-container>
  </ul>
</div>

<ng-template #noResults>
  <div class="p-4 color-gray-700">{{ 'ORDER_SELECT.NO_MATCHES_FOUND' | translate }}</div>
</ng-template>

<ng-template #selectedTemplate>
  <app-order-select-menu-group-select-list (switchTabBack)="onTabBack(tab || tabTypes.OFFERS)"></app-order-select-menu-group-select-list>
</ng-template>

<ng-template #tabButton let-button>
  <button
    class="navigation__item btn btn-lg btn-default mx-4 px-0 no-outline rounded-0 hover-color-black"
    [ngClass]="{ 'border-color-primary color-black': isTabActive(button.type) }"
    (click)="onTabChange(button.type)"
    *permissionsElementVisibilityDecisionMaker="[button.permission]"
  >
    <span>{{ button.title }}</span>
  </button>
</ng-template>
