<div class="messages-detail mb-4">
  <app-loader [loading]="loading"></app-loader>
  <div class="messages-detail__message messages-detail__message--no-messages d-flex" *ngIf="!messages.length && !loading">
    <div class="messages-detail__text">
      {{ 'INQUIRIES.QUOTATIONS.NO_MESSAGES' | translate }}
    </div>
  </div>
  <div
    class="messages-detail__message d-flex"
    *ngFor="let message of messages; trackBy: trackByMessageId"
    [ngClass]="user?.id === message.user?.id ? 'messages-detail__message--user' : 'messages-detail__message--other'"
  >
    <div class="messages-detail__image">
      <img 
        [src]="message?.user?.profilePicture?.url || '/assets/images/user/user@1,5x.svg'" 
        alt="avatar" 
        [ngbTooltip]="message?.user?.firstName + ' ' + message?.user?.lastName"
        placement="top-start"
      />
    </div>

    <div class="messages-detail__text">
      <div class="messages-detail__title" *ngIf="message.title">
        {{ message.title }}
      </div>
      <div
        class="messages-detail__message-text text-pre-wrap"
        [ngClass]="{ 'messages-detail__message-text--system': message.messageType === conversationMessageType.SYSTEM }"
        *ngIf="message.text"
      >
        <div [innerHTML]="message.text"></div>
      </div>
      <div class="messages-detail__details d-flex flex-column" *ngIf="message.changedDetails?.length">
        <div class="d-flex mb-2" *ngFor="let changedItem of message.changedDetails">
          <i class="messages-detail__details-menu"></i>
          <div class="d-flex flex-column">
            <div class="messages-detail__details-text">
              {{ 'INQUIRIES.QUOTATIONS.NEW.ITEM_ID' | translate }}:
              {{ changedItem.itemCode }}
            </div>

            <div class="messages-detail__details-text" *ngFor="let itemChange of changedItem.changes">
              {{ 'INQUIRIES.QUOTATIONS.NEW.' + itemChange.name | translate }}:
              {{ itemChange.value | messageDetailsValueFormat: itemChange.name }}
            </div>
          </div>
        </div>
      </div>
      <div class="messages-detail__files" *ngIf="message.files.length">
        <app-files-list [files]="getMessageFiles(message.files)"></app-files-list>
      </div>
      <div class="messages-detail__time">
        {{ message.timestamp * 1000 | date:'YYYY-MM-dd H:mm' }}
      </div>
    </div>
  </div>
</div>
