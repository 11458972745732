import { Directive } from '@angular/core';

@Directive({
  selector: '[appModalFooter]'
})
export class ModalFooterDirective {

  constructor() { }

}
