import { OrderItemType, OrderPasteActionType } from './order-item-type.enum';
import { OrderArticlesListRow, OrderArticlesListRowInterface, OrderArticlesListRowSizeInterface, RowTypes } from '../order-articles-list/order-articles-list.interface';
import { PageBreakInterface } from '../../core/models/page-break.model';
import { OrderArticleInterface, OrderArticleListRow, OrderArticleTranslationInterface, OrderArticleType } from '../../core/models/order-article.model';
import { OrderArticleDiscountType, OrderArticleSaleDiscountType } from '../../core/enums/order-article-discount-type.enum';

export interface PasteEvent {
  movedRows: OrderArticleListRow[];
  pasteItems: PasteItemInterface[];
}

export interface PasteItemInterface {
  type: OrderItemType;
  id: number;
  position: number;
  rowType?: RowTypes;
  pageBreak?: any;
  item?: any;
  order?: number;
  parent?: PageBreakInterface;
  groupLevel?: number;
}

export interface ClipboardOrderArticleListRowInterface extends Partial<OrderArticlesListRowInterface> {
  actionType?: OrderPasteActionType;
  order?: number;
}

export interface ClipboardExtraListRowInterface extends Partial<OrderArticlesListRowInterface> {
  actionType?: OrderPasteActionType;
  order?: number;
}

export type ClipboardOrderArticleListRow = ClipboardOrderArticleListRowInterface | ClipboardExtraListRowInterface;

export interface ClipboardPasteItemModel {
  orderArticles: any[];
  pageBreaks: any[];
}

export interface OrderItemsPasteEvent {
  copied?: ClipboardPasteItemModel;
  cut?: ClipboardPasteItemModel;
  lastCutItems?: ClipboardRowInterface[];
  modifiedRows?: OrderArticlesListRow[];
  order?: number;
}

export enum OrderItemsPasteEventType {
  COPY,
  CUT,
  CANCEL,
}

export interface ClipboardCopyMapInterface {
  [key: string]: ClipboardRowInterface[];
}

export type RowMap = Map<any, OrderArticleListRow[]>

export interface ClipboardRowInterface {
  id: number;
  item: ClipboardOrderArticlePartialItemInterface;
  originals?: ClipboardPartialOriginalsInterface;
  salePrice?: number;
  size?: OrderArticlesListRowSizeInterface;
  rowType: RowTypes;
  position: number;
  pageBreak?: { id: number | null };
  parent?: { id: number | null };
  discount?: number;
  discountType?: OrderArticleDiscountType;
  saleDiscount?: number;
  saleDiscountType?: OrderArticleSaleDiscountType;
  saleCoefficient?: number;
  saleCoefficientType?: number;
  children?: ClipboardRowInterface[];
  groupLevel?: number;
  code?: string;
  customPrice?: number;
  pricelistPrice?: number;
  additionalParts?: ClipboardPartialOrderArticleInterface[];
  order?: number;
  type?: OrderItemType | null;
  orderArticleType?: OrderArticleType | null;
  title?: string;
  fullCode?: string;
  shortText?: string;
  longText?: string;
  translations?: OrderArticleTranslationInterface[];
  selected?: boolean;
  cut?: boolean;
  versionOutdated?: boolean;
}

export interface ClipboardPartialOriginalsInterface {
  orderArticle: ClipboardPartialOriginalsOrderArticleInterface;
}

export interface ClipboardPartialOriginalsOrderArticleInterface {
  code: string;
  children?: OrderArticleInterface[];
}

export interface ClipboardPartialOrderArticleInterface {
  id: number;
  title?: string;
  translations?: OrderArticleTranslationInterface[];
  discountType?: OrderArticleDiscountType;
  discount?: number;
  customPrice?: number;
  pricelistPrice?: number;
  saleCoefficientType?: number;
  saleDiscount?: number;
  order?: number;
  children?: (ClipboardRowInterface)[];
  fullCode?: string;
  orderArticleType?: OrderArticleType | null;
}

export interface ClipboardOrderArticlePartialItemInterface {
  category?: string;
  code?: string;
  system?: string;
  image?: string;
  title?: string;
  translations: OrderArticleTranslationInterface[];
}
