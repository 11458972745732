export enum APISortOrder {
  DESC = 'desc',
  ASC = 'asc',
}

export enum APISortOrderConverted {
  DESC = '-',
  ASC = '+',
  NONE = ''
}

export const DEFAULT_SORT_VALUE = {prop: `updatedAt`, dir: APISortOrderConverted.DESC};
