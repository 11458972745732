import {Component, OnInit} from '@angular/core';
import {UserService} from '../../../core/services/user/user.service';
import {UserInterface, UserUpdateInterface} from '../../../core/models/user.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {TextFieldThemeTypes} from '../../../ui-elements/text-field/text-field/theme-types.enum';
import { ToastService } from '../../../ui-elements/toast/toast.service';
import { PASSWORD_VALIDATION } from '../../../password/password-validation';
import { match } from '../../../shared/class/custom-validators';


@Component({
  selector: 'app-user-settings-basics',
  templateUrl: './basics.component.html',
})
export class BasicsComponent implements OnInit {
  form: ReturnType<typeof this.constructForm>;
  user: UserInterface;
  textFieldThemeTypes = TextFieldThemeTypes;

  constructor(private userService: UserService, private translator: TranslateService, private toastService: ToastService) {
  }

  ngOnInit() {
    this.form = this.constructForm();
    this.userService.fromStorage().subscribe((user: UserInterface) => {
      this.user = user;
    });
  }

  constructForm() {
    return new FormGroup({
      oldPassword: new FormControl(null, [Validators.required]),
      newPassword: new FormControl(null, PASSWORD_VALIDATION)
    }, { validators: [ match('oldPassword', 'newPassword') ] });
  }

  onSubmit() {
    if (this.form.valid) {
      this.userService.update(
        this.user.id,
        <UserUpdateInterface>{oldPassword: this.form.value.oldPassword, plainPassword: this.form.value.newPassword})
        .subscribe({
          next: result => {
            this.translator.get('SETTINGS.BASICS.SUCCESS').subscribe(translation => {
              this.toastService.success(translation);
            });
            this.onReset();
          },
          error: error => {
            this.translator.get('SETTINGS.BASICS.FAILURE').subscribe(translation => {
              this.toastService.danger(translation);
              console.log(error);
            });
          }
        });
    } else {
      this.form.controls['oldPassword'].markAsDirty();
      this.form.controls['newPassword'].markAsDirty();
    }
  }

  onReset() {
    this.form.reset();
  }
}
