export enum OrderUIStatus {
  DEFAULT = 0,
  PENDING = 1,
  DONE = 2,
  REMOVED = 3,
  ARCHIVED = 4,
  WAITING = 5,
  APPROVED = 6,
  SHIPPED = 7,
  IMPORTING = 8,
  MIGRATING = 9,
}
