import {Injectable} from '@angular/core';
import {ApiService} from '../../../api.service';
import {UserNotificationUpdateInterface, UserNotificationViewInterface} from '../../../core/models/user-notification.model';
import {CacheableObservable} from '../../../cacheable-observable/cacheable-observable.model';
import {map} from 'rxjs/operators';

@Injectable()
export class UserNotificationService {

  constructor(private apiService: ApiService) {
  }


  getAll(): CacheableObservable<UserNotificationViewInterface[]> {
    return this.apiService.get(`user-notification/`).pipe(
      map(({data}) => data)
    ) as CacheableObservable<UserNotificationViewInterface[]>;
  }

  getByUser(): CacheableObservable<UserNotificationViewInterface[]> {
    return this.apiService.get(`user-notification/user`).pipe(
      map(({data}) => data)
    ) as CacheableObservable<UserNotificationViewInterface[]>;
  }

  addUser(id) {
    return this.apiService.post(`user-notification/${id}/user`, null).pipe(map(({data}) => data));
  }

  removeUser(id) {
    return this.apiService.delete(`user-notification/${id}/user`, null).pipe(map(({data}) => data));
  }


  updateMultiple(userNotificationUpdate: Array<UserNotificationUpdateInterface>) {
    return this.apiService.patch(`user-notification/user`, userNotificationUpdate).pipe(map(({data}) => data));
  }
}
