import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { BehaviorSubject, Observable, map } from 'rxjs';
import * as moment from 'moment';
import { DateHelper } from '../core/util/date.helper';

export interface MaintenancePlanInterface {
  dateFrom?: string;
  dateTo?: string;
}

export const MAINTENANCE_DISMISSED_UNTIL_KEY = 'maintenanceBannerDismissedUntil';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceService {
  public maintenancePlan$ = new BehaviorSubject<MaintenancePlanInterface>(null);
  public maintenanceBannerDismissed$ = new BehaviorSubject<boolean>(false);

  constructor(private api: ApiService) { }

  dismissMaintenanceBanner(): void {
    const plan = this.maintenancePlan$.getValue();
    
    let dismissedUntil = moment(plan?.dateFrom).subtract(2, 'hours')

    if (moment(plan?.dateFrom).diff(moment(), 'hours') < 2) {
      dismissedUntil = moment(plan?.dateFrom);
    }

    localStorage.setItem(MAINTENANCE_DISMISSED_UNTIL_KEY, dismissedUntil.format());
    this.maintenanceBannerDismissed$.next(true);
  }

  fetchPlan(): void {
    this.getPlan().subscribe(plan => {
      this.maintenancePlan$.next(plan);

      const dismissedUntil = localStorage.getItem(MAINTENANCE_DISMISSED_UNTIL_KEY);
      const isDismissed = dismissedUntil && moment().isBefore(moment(dismissedUntil));
      
      const now = moment();
      const moreThanTwoDaysLeft = moment(plan?.dateFrom).diff(now, 'days') >= 2;
      const hasPassed = now.isAfter(plan?.dateTo);
  
      this.maintenanceBannerDismissed$.next(moreThanTwoDaysLeft || hasPassed || isDismissed);
    });
  }

  getPlan(): Observable<MaintenancePlanInterface> {
    return this.api.get('maintenance-mode/plan').pipe(
       map(({ data }) => {
        if (data?.dateFrom) {
          data.dateFrom = DateHelper.convertUtcToIso(data.dateFrom);
        }

        if (data?.dateTo) {
          data.dateTo = DateHelper.convertUtcToIso(data.dateTo);
        }

        return data;
      }),
    ) as Observable<MaintenancePlanInterface>;
  }
}
