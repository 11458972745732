import {Component, OnInit} from "@angular/core";
import {switchMap} from "rxjs/operators";
import {ActivatedRoute, Params} from "@angular/router";
import {ConfiguratorModalService} from "./configurator-modal.service";
import {ConfiguratorModalComponent} from "./configurator-modal.component";
import {ConfiguratorModalActionType} from "../core/enums/configurator-modal-action-type.enum";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  template: ``,
})
export class OpenConfiguratorModalComponent implements OnInit {
  private productResponse$;

  constructor(private route: ActivatedRoute, private configuratorModalService: ConfiguratorModalService, private modalService: NgbModal) {
  }

  ngOnInit(): void {

    this.route.paramMap
      .subscribe(params => {
        this.openModal(params.get('system'), params.get('code'), params.get('variant'));
      });
  }

  private openModal(system, code, variant) {
    const modalRef = this.modalService.open(ConfiguratorModalComponent, {
      windowClass: 'configurator-modal',
      keyboard: false,
    });
    modalRef.componentInstance.system = system;
    modalRef.componentInstance.article = {code};
    modalRef.componentInstance.articleVariant = {name: variant};
    modalRef.componentInstance.action = ConfiguratorModalActionType.EDIT;
    modalRef.componentInstance.quantity = 1;
  }
}
