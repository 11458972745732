import { Component, Input } from '@angular/core';
import { Validators } from '@angular/forms';

import { InlineFieldComponent } from '../inline-field/inline-field.component';

/**
 * Only adds * asterisk to label if NgControl within <app-inline-field>
 * has Validator.required.
 */
@Component({
  selector: 'label[appInlineFieldLabel]',
  templateUrl: './inline-field-label.component.html'
})
export class InlineFieldLabelComponent {
  /** Set to `false` to hide `*` in app-inline-field's label */
  @Input() hideRequired = false;
  requiredValidator = Validators.required;

  constructor(
    public inlineField: InlineFieldComponent
  ) {}
}
