<div class="card-body">
  <h2 class="mb-3">{{"SETTINGS.DEFAULTS.TITLE" | translate}}</h2>
  <p class="color-gray">{{"SETTINGS.DEFAULTS.TITLE_TEXT" | translate}}</p>
  <div class="pt-3" *ngFor="let optionGroup of options">
    <div class="form-h-spacer mb-3"></div>
    <div class="pb-3"></div>
    <ng-container *ngFor="let option of optionGroup.values">
      <div class="px-3">
        <app-check-mark *ngIf="option.title"
                        [value]="option.checked"
                        (changed)="onChange($event, optionGroup, option)"
                        [name]="option.name"
                        [text]="(option.title.toUpperCase() | translate) || option.title"
                        [cssClasses]="'mb-3'"></app-check-mark>
      </div>
    </ng-container>
  </div>
  <app-loader #loader [cssClasses]="'loader--white'"></app-loader>
</div>
<div class="card-footer p-0 text-end">
  <button type="reset" class="btn btn-secondary btn-lg rounded-0 mw-11r" (click)="onReset()">
    {{"SETTINGS.CANCEL" | translate}}
  </button>
  <button type="submit" class="btn btn-primary btn-lg rounded-0 mw-11r" (click)="onSave()">
    {{"SETTINGS.SAVE" | translate}}
  </button>
</div>
