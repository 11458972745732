<div class="container position-relative mt-4 terms-page-container">
  <ng-container *ngIf="{
    tabs: tabs$ | async,
    selectedItem: selectedItem$ | async
  } as data">
    <ng-container *ngIf="data.tabs && data.selectedItem; else loading">
      <div class="row">
        <h1 class="mb-3">{{ 'TERMS.PRIVACY_AND_TERMS' | translate }}</h1>
      </div>
      <div class="row">
        <app-terms-and-conditions class="d-flex flex-column w-100"
          [tabs]="data.tabs"
          [selected]="data.selectedItem"
          (selectedChange)="onSelectedChange($event)"
        ></app-terms-and-conditions>
      </div>
    </ng-container>
  </ng-container>
  <ng-template #loading>
    <app-loader [loading]="true"></app-loader>
  </ng-template>
</div>
