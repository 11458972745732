import {Component, OnInit, TemplateRef} from '@angular/core';
import {PopoverRef} from '../popover-ref';

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss']
})
export class PopoverComponent implements OnInit {
  content: TemplateRef<any>;
  context: any;

  constructor(private popoverRef: PopoverRef) {
  }

  ngOnInit() {
    this.content = this.popoverRef.content;
    this.context = this.popoverRef.data;
  }
}
