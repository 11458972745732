<div class="view-wrapper" #wrapperBounds>
  <div class="list-view-mode">
    <div class="system" *ngFor="let system of systems; trackBy: trackById, let i = index" appSystem>
      <div class="system__heading shadow-sm">
        <h2 class="m-0 fw-bold color-gray" [innerHTML]="system.system | uppercase"></h2>
        <div class="system__heading__more">
          <button
            *ngIf="system.infoBlock?.hasInfoBlock || system.infoBlock?.hasFinishFamily"
            (click)="openInfoBlock(system)"
            class="btn btn-light d-flex"
          >
            <span class="px-2">{{ 'CATALOGUE.INFO_BLOCKS.LINK_TEXT' | translate }}</span>
            <i class="icon ni-info"></i>
          </button>
        </div>
      </div>
      <app-products-block  *ngFor="let articlePropertyClass of system.articlePropertyClasses; let i = index"
        [searchQuery]="searchQuery"
        [blockIndex]="i"
        [articlePropertyClass]="articlePropertyClass"
        [system]="system"
        [descriptionColumnVisible]="descriptionColumnVisible"
        [codeColumnVisible]="codeColumnVisible"
      ></app-products-block>
    </div>
  </div>

  <div #loadThreshold class="py-3 mt-n3 w-100"></div>
</div>

