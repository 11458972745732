import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, EMPTY, map, Observable, of, shareReplay, switchMap } from 'rxjs';
import { ContentTreeModel } from '../../content/content-tree.model';
import { ContentService } from '../../content/content.service';
import { storeSwitchMap } from '../../core/operators/storeSwitchMap.operator';
import { catchError } from "rxjs/operators";

import { TERMS_QUERY_PARAM } from '../terms.const';

@Component({
  selector: 'app-terms-page',
  templateUrl: './terms-page.component.html',
  styleUrls: ['./terms-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TermsPageComponent {

  tabs$: Observable<ContentTreeModel.Data[]> = this.contentService.getContentTree('legal').pipe(
    map(res => res.data),
    shareReplay(1)
  );

  private selectedUri$ = this.route.queryParamMap.pipe(
    map(params => params.get(TERMS_QUERY_PARAM))
  );
  selectedItem$ = this.tabs$.pipe(
    switchMap(tabs => combineLatest([of(tabs), this.selectedUri$])),
    storeSwitchMap(
      ([tabs, uri]) => uri ?? tabs[0].uri,
      (data, key) => this.contentService.getContentItem(key)
    ),
    map(res => res.data),
    catchError((err, caught) => {
      this.router.navigate(['/']);
      return EMPTY;
    })
  );

  constructor(private contentService: ContentService, private router: Router, private route: ActivatedRoute) {}

  onSelectedChange(item: ContentTreeModel.Data) {
    this.router.navigate(['/terms'], {
      queryParams: { [TERMS_QUERY_PARAM]: item.uri }
    });
  }
}
