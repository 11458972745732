<ng-container *ngIf="authenticated">
  <div class="sticky-navbar" appFullScreenMode [appBorderBoxResizeObserver]="'navbarBorderBox'">
    <app-navbar></app-navbar>
    <app-sub-navbar class="app-sub-navbar"></app-sub-navbar>
  </div>
  <app-third-navbar *ngIf="isThirdNavbarOpen"></app-third-navbar>
</ng-container>

<ng-container *ngIf="showSimpleNav$ | async">
  <app-simple-navbar></app-simple-navbar>
</ng-container>
