import { Injectable } from '@angular/core';
import { NotificationInterface } from '../../core/models/notification.model';
import { OrdersService } from '../../orders/orders.service';
import { OrderInterface } from '../../core/models/order.model';
import { Router } from '@angular/router';
import { UserService } from '../../core/services/user/user.service';
import { UserInterface } from '../../core/models/user.model';

@Injectable({
  providedIn: 'root',
})
export class OrderNotificationOpenerService {
  constructor(private ordersService: OrdersService, private userService: UserService, private router: Router) {}

  open(notification: NotificationInterface) {
    this.ordersService
      .getOne(notification.objectId)
      .noCache()
      .subscribe((order: OrderInterface) => {
        this.userService.fromStorage().subscribe((user: UserInterface) => {
          this.redirectByOrderStatusAndUserRole(order, user);
        });
      });
  }

  private redirectByOrderStatusAndUserRole(order: OrderInterface, user: UserInterface) {
    this.router.navigate(this.ordersService.getOrderPathByUserAndState(user, order));
  }
}
