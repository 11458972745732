import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FileData, imageFileExtension } from '../../core/models/file-data.model';
import { ImageSizeEnum } from '../../shared/pipes/resize-image/resize-image.pipe';

@Component({
  selector: 'app-file-preview',
  templateUrl: './file-preview.component.html',
  styleUrls: ['./file-preview.component.scss']
})
export class FilePreviewComponent implements OnInit {
  @Input() file: FileData;
  @Input() removable = false;
  @Input() inProgress = false;
  @Output() remove = new EventEmitter<FileData>();

  isImage = false;
  filenameWithoutExtension: string;
  imageSizes = ImageSizeEnum;

  constructor() { }
  
  ngOnInit(): void {
    this.isImage = imageFileExtension.includes(this.file.extension);
    this.filenameWithoutExtension = this.file.name.split('.').slice(0, -1).join('.');
  }

  onRemoveFile(event: MouseEvent) {
    event.stopPropagation();
    this.remove.emit(this.file);
  }
}
