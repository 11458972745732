import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription, Subject, startWith, filter } from 'rxjs';
import type * as zxcvbnType from 'zxcvbn';

import { UserService } from '../../core/services/user/user.service';

type ZxcvbnWorkerResult = { data: zxcvbnType.ZXCVBNResult };

const StrengthTextsMap = {
  '0': 'WEAK',
  '1': 'WEAK',
  '2': 'FAIR',
  '3': 'GOOD',
  '4': 'STRONG'
}

@Component({
  selector: 'app-password-strength-indicator',
  templateUrl: './password-strength-indicator.component.html',
  styleUrls: ['./password-strength-indicator.component.scss']
})
export class PasswordStrengthIndicatorComponent implements OnInit, OnDestroy {
  @Input() control: FormControl;

  passwordStrengthScore$ = new Subject<number>();
  strengthTexts = StrengthTextsMap;
  private subscription = new Subscription();
  private worker: Worker | undefined;

  constructor(
    private userService: UserService
  ) {}

  ngOnInit() {
    let additionalDictionary: string[] = [];

    this.userService.fromStorage().subscribe(user => {
      additionalDictionary = [
        ...user.email.split(/[^\w]/),
        user.email,
        user.firstName,
        user.lastName
      ];
    });

    // Checking long password strength with zxcvbn on slower hardware while typing fast can
    // cause main thread blocking. Web Worker offloads heavy work away main thread.
    const worker = new Worker(new URL('./zxcvbn.worker', import.meta.url));
    worker.onmessage = ({ data }: ZxcvbnWorkerResult) => {
      this.passwordStrengthScore$.next(data.score);
    };

    this.subscription.add(
      this.control.valueChanges.pipe(startWith(this.control.value)).pipe(
        filter(value => value !== null && value !== undefined)
      ).subscribe(value => {
        worker.postMessage({ value, additionalDictionary });
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    if (this.worker) {
      this.worker.terminate();
    }
  }
}
