import {MissingTranslationHandler, MissingTranslationHandlerParams} from '@ngx-translate/core';

export class CustomMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    if (params.interpolateParams && params.interpolateParams['fallback']) {
      return params.interpolateParams['fallback'];
    }

    return '';
  }
}
