import { Component, Input } from '@angular/core';

import { FinishFamily, FinishFamilyFile } from '../../../content/finishes/finishes.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-finishes-downloads',
  templateUrl: './finishes-downloads.component.html',
})
export class FinishesDownloadsComponent {
  @Input() finishes: FinishFamily[];

  constructor(
    private translateService: TranslateService
  ) {}

  filterByLanguage(finishFamilyFiles: FinishFamilyFile[]) {
    const currentLanguage = this.translateService.currentLang;

    return finishFamilyFiles.filter(file => file.locale === currentLanguage);
  }
}
