import { ChangeDetectionStrategy, Component, TemplateRef } from '@angular/core';
import { Observable } from 'rxjs';

import { ToastService, Toast } from '../toast.service';

@Component({
  selector: 'app-toast-container',
  templateUrl: './toast-container.component.html',
  styleUrls: ['./toast-container.component.scss'],
  host: {
    class: 'toast-container position-fixed top-0 end-0 pe-3 pt-3'
  },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToastContainerComponent {
  toasts$: Observable<Toast[]> = this.toastService.getToasts();

  constructor(private toastService: ToastService) { }

  isTemplate(toast: Toast) {
    return toast.body instanceof TemplateRef;
  }

  removeToast(toast: Toast) {
    this.toastService.remove(toast);
  }
}
