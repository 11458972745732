import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { OrderInterface } from '../../core/models/order.model';
import { OrderSelectMenuService } from '../order-select-menu.service';
import { UserRole } from '../../core/enums/user-role.enum';
import { ExtraListElementInterface } from '../../orders/order-articles-list/order-articles-list/components/extra-row/extra-items.model';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { OrderSelectMenuComponent } from '../order-select-menu.component';
import { UserService } from '../../core/services/user/user.service';

@Component({
  selector: 'app-order-select-menu-toggler',
  templateUrl: './menu-toggler.component.html',
})
export class MenuTogglerComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();
  selectedOrder: OrderInterface;
  selectedGroup: ExtraListElementInterface;

  userRole = UserRole;
  modalRef: NgbModalRef | null;
  isDealer: boolean;
  private isModalClosed = true;

  constructor(
    private orderSelectMenuService: OrderSelectMenuService,
    private modalService: NgbModal,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      this.orderSelectMenuService.openObservable().subscribe(open => {
        if (open) {
          this.openOrderSelect()
          this.isModalClosed = false;

          return;
        }

        if (!this.isModalClosed) {
          this.modalRef?.close();
          this.isModalClosed = true;
        }
      })
    );

    this.subscriptions.add(
      this.orderSelectMenuService.selectedOrderAsObservable().subscribe(selectedOrder => {
        this.selectedOrder = selectedOrder;
      })
    );

    this.subscriptions.add(
      this.orderSelectMenuService.selectedGroupAsObservable().subscribe(selectedGroup => {
        this.selectedGroup = selectedGroup;
      })
    );

    this.isDealer = this.userService.isDealer();
  }
  
  onOpenMenu() {
    this.orderSelectMenuService.open();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  openOrderSelect() {
    if(this.modalRef) {
      this.modalRef.close();
    }

    if(this.selectedOrder) {
      this.orderSelectMenuService.openOrder(this.selectedOrder);
    }

    this.modalRef = this.modalService.open(OrderSelectMenuComponent, {
      windowClass: 'sidebar-modal',
      modalDialogClass: 'position-fixed h-100 right-0',
    });
  }

  getSelectedGroupTitle(selectedGroup: ExtraListElementInterface) {
    let title = [];
    if (selectedGroup) {
      title.push(selectedGroup.title);
      if (selectedGroup.parent) {
        title.push(...this.getSelectedGroupTitle(selectedGroup.parent).split(' / ').reverse());
      }
    }

    return title.reverse().join(' / ');
  }
}
