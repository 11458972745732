import { Component, ElementRef, HostListener, NgZone, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { TreeService } from '../tree.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderService } from '../../../ui-elements/loader/loader.service';
import { LoaderComponent } from '../../../ui-elements/loader/loader.component';
import { NavbarElementInterface, NavbarElements } from '../navbar-elements';
import { CatalogueRouteType, RouteTypeEnums } from '../../enums/route-types.enum';
import { getCurrentParams } from '../navbar-tree/navbar-tree.component';
import { ResizeNotifierService } from '../../../resize-observer/resize-notifier.service';
import { Subscription } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';
import { ThirdNavbarService } from './third-navbar.service';
import Timer = NodeJS.Timer;

interface Element {
  id: number;
  type?: number;
  name: string;
  children: Element[];
  path?: string;
  saleCoefficient?: any;
  originalName?: string;
  slug: string;
  isNameModified?: boolean;

  parent?: Element | null;
  pathId?: string;
}

const ROW_HEIGHT = 12;

@Component({
  selector: 'app-third-navbar',
  templateUrl: './third-navbar.component.html',
  styleUrls: ['./third-navbar.component.scss'],
})
export class ThirdNavbarComponent implements OnInit, OnDestroy {
  @ViewChild('menuItemsLoader', { static: true }) menuItemsLoader: LoaderComponent;
  @ViewChildren('htmlElements') htmlElements: QueryList<ElementRef>;
  @ViewChild('htmlElementsBounds', { static: true }) htmlElementsBounds: any;

  private timer: Timer;
  private subscription: Subscription = new Subscription();
  parentElement: NavbarElementInterface;
  elements: Element[] = [];
  rowHeight = ROW_HEIGHT;
  hoveredSubCategory: Element = null;
  isDetailsBlockHidden: boolean;
  flattenElements: Element[] = [];
  CatalogueRouteType = CatalogueRouteType;

  public iconToCategoryMap = {
    category__desking: 'ni-cat-desks',
    category__meeting: 'ni-cat-meeting',
    category__storage: 'ni-cat-storage',
    category__executive_furniture: 'ni-cat-executive-furniture',
    category__reception_furniture: 'ni-cat-reception-furniture',
    category__screens__and__acoustic: 'ni-cat-acoustic-solutions',
    category__chairs: 'ni-cat-chairs',
    category__lounge__and__soft_furniture: 'ni-cat-lounge-and-soft-furniture',
    category__cable_management: 'ni-cat-cable-management',
    category__accessories: 'ni-cat-accessories',
    category__marketing_materials: 'ni-cat-marketing-materials',
  }

  @HostListener('window:resize', [])
  onResize() {
    this.findWrappedElements();
  }

  constructor(
    private treeService: TreeService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private loaderService: LoaderService,
    private thirdNavbarService: ThirdNavbarService,
    private ngZone: NgZone,
    public resizeObservser: ResizeNotifierService
  ) { }

  ngOnInit() {
    this.subscription.add(
      this.treeService
        .getState()
        .pipe(
          filter(({ second, third }) => !!second && third),
          tap(() => (this.elements = [])),
          switchMap(({ second }) => {
            this.parentElement = second;
            this.isDetailsBlockHidden = this.parentElement.id === CatalogueRouteType.COMPONENTS;

            return this.loaderService.load(this.treeService.getCatalogue(this.parentElement.id), this.menuItemsLoader);
          })
        )
        .subscribe(data => {
          const { firstGroup, firstGroupChild } = this.modifyItems(data);

          // Wait until page loads
          setTimeout(() => {
            this.findWrappedElements();
          }, 0);

          this.setHoveredSubCategory(firstGroupChild);
        })
    );
  }

  private modifyItems(data) {
    const items = this.parentElement.id === CatalogueRouteType.SYSTEMS ? this.thirdNavbarService.reorderSystems(data) : data;
    const mainBlockOriginalName = ((id) => {
      switch (id) {
        case CatalogueRouteType.CATEGORY:
          return 'Category';
        case CatalogueRouteType.SYSTEMS:
          return 'Systems';
        case CatalogueRouteType.COMPONENTS:
          return 'Components';
      }
    })(this.parentElement.id);

    const getPathId = (parent: string, child?: string): string => {
      let attributeId = `${mainBlockOriginalName}`;
      if (parent) {
        attributeId += `__${parent}`;
      }

      if (child) {
        attributeId += `--${child}`;
      }

      return attributeId.replace(' ', '_').toLowerCase();
    };

    if (items && items.length) {
      this.elements = items;
      this.flattenElements = this.elements.reduce((acc: Element[], current: Element) => {
        acc.push({ ...current, parent: null, pathId: getPathId(current.originalName) });
        if (current.children && current.children.length) {
          acc.push(
            ...current.children.map(child => {
              return { ...child, parent: current, pathId: getPathId(current.originalName, child.originalName) };
            })
          );
        }
        return acc;
      }, []);

      const firstGroup = this.elements.find(parent => !!parent.children.length);
      const [firstGroupChild] = firstGroup.children;

      return { firstGroup, firstGroupChild };
    }
    return { firstGroup: null, firstGroupChild: null };
  }

  onMouseEnter(subCategory) {
    this.timer = setTimeout(() => {
      this.setHoveredSubCategory(subCategory);
    }, 50);
  }

  onMouseLeave() {
    clearTimeout(this.timer);
  }

  private setHoveredSubCategory(subCategory: Element) {
    this.hoveredSubCategory = subCategory;
  }

  onNavbarClose() {
    this.treeService.toggleThirdNavbarState();
  }

  onClick(element: Element) {
    if (element.parent) {
      this.navigateTo(element.parent.slug, element.slug);
    } else {
      this.navigateTo(element.slug);
    }
    this.onNavbarClose();
  }

  private navigateTo(categoryId: any, childCategoryId?: any) {
    const cataloguePath = NavbarElements.find(element => element.id === RouteTypeEnums.CATALOGUE).path;
    const commands = [cataloguePath, this.parentElement.path.toLowerCase()];

    if (childCategoryId) {
      commands.push(categoryId, childCategoryId);
    } else {
      commands.push(categoryId);
    }

    this.router.navigate(commands, {
      queryParams: { ...getCurrentParams(this.activatedRoute) },
    });
  }

  private findWrappedElements() {
    const removeBreaks = () => {
      // Polyfill for IE11 https://caniuse.com/#feat=mdn-api_nodelist_foreach
      Array.prototype.forEach.call(document.querySelectorAll('.flex-break'), node => {
        node.remove();
      });
    };

    this.ngZone.runOutsideAngular(() => {
      removeBreaks();
      const bounds = this.htmlElementsBounds.nativeElement.getBoundingClientRect();
      let prevElementRef = null;
      this.htmlElements.forEach((elementRef: ElementRef) => {
        elementRef.nativeElement.classList.remove('next-wrapped');
        if (prevElementRef) {
          if (elementRef.nativeElement.getBoundingClientRect().top - bounds.top === 0) {
            if (elementRef.nativeElement.classList.contains('category-group__link--child')) {
              if (prevElementRef.nativeElement.classList.contains('category-group__link--child')) {
                prevElementRef.nativeElement.classList.add('next-wrapped');
              } else {
                const breakElement = document.createElement('div');
                breakElement.className = 'flex-break';
                this.htmlElementsBounds.nativeElement.insertBefore(breakElement, prevElementRef.nativeElement);
              }
            }
          }
        }
        prevElementRef = elementRef;
      });
    });
  }

  public generateIdByCategoryPathId(categoryPathId: string) {
    return categoryPathId.replace(/ /g, '_').replace(/&/g, '_and_')
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
