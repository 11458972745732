import { Injectable } from '@angular/core';
import { ApiService, FileSizeEnum } from '../api.service';
import { CommunicationMessageInterface, MessagesInterface } from '../core/models/messages.model';
import { CacheableObservable } from '../cacheable-observable/cacheable-observable.model';

interface MessagesListResponseInterface {
  data: CommunicationMessageInterface[];
}

interface MessagesResponseInterface {
  data: CommunicationMessageInterface;
}

@Injectable({
  providedIn: 'root'
})
export class ProjectReservationMessagesService {

  constructor(
    private api: ApiService
  ) { }

  getMessages(projectReservationId: number): CacheableObservable<MessagesListResponseInterface> {
    return this.api.get(`project-reservations/${projectReservationId}/messages/`);
  }

  sendMessage(projectReservationId: number, { text, files }: Partial<MessagesInterface>): CacheableObservable<MessagesResponseInterface> {
    return this.api.upload(`project-reservations/${projectReservationId}/messages/`, { text }, files, FileSizeEnum.SIZE_1GB) as CacheableObservable<MessagesResponseInterface>;
  }
}
