import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, ActivatedRoute} from '@angular/router';

export const REGULAR_USER_GUARD_CONSTANTS = {
  ALLOW_ADMIN_QUERY_PARAM_KEY: '_allow_admin'
};

@Injectable()
export class RegularUserGuardService {
  private allowAdmin?: boolean = null;

  constructor() {}

  shouldAllowAdmin(snapshot: ActivatedRouteSnapshot): boolean {
    this.allowAdmin = this.allowAdmin || snapshot.queryParamMap.has(REGULAR_USER_GUARD_CONSTANTS.ALLOW_ADMIN_QUERY_PARAM_KEY);
    return this.allowAdmin;
  }
}
