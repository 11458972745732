import {Inject, Injectable} from '@angular/core';
import {VISIBILITY_BY_VARIABLES_PROPERTIES} from './injection.tokens';
import {PropertyInterface} from './model';

@Injectable({
  providedIn: 'root'
})
export class VisibilityDecisionMakerService {

  constructor(@Inject(VISIBILITY_BY_VARIABLES_PROPERTIES) private properties: PropertyInterface[]) {
  }

  shouldHide(propertyKeys: string[]): boolean {
    return propertyKeys.every((propertyKey: string) => {
      return this.properties[propertyKey];
    });
  }
}
