<ng-container *ngIf="{
  legal: legal$ | async,
  tabs: tabs$ | async,
  selectedItem: selectedItem$ | async
} as data">
  <ng-container *ngIf="data.legal && data.tabs && data.selectedItem; else loading">
    <div class="modal-header d-flex justify-content-between py-3">
      <h2 class="mb-0">{{ data.legal.translation?.title || data.legal.title }}</h2>
      <app-ui-elements-close-button *ngIf="showCloseButton" class="m-1" (click)="onClose()"
      ></app-ui-elements-close-button>
    </div>
    <div class="modal-body p-0 align-items-stretch d-flex">
      <app-terms-and-conditions class="d-flex flex-column w-100"
        [tabs]="data.tabs"
        [selected]="data.selectedItem"
        (selectedChange)="changeSelectedItem($event.uri)"
      ></app-terms-and-conditions>
    </div>
    <div *ngIf="showAgreementButtons" class="modal-footer">
      <button class="btn btn-secondary btn-lg rounded-0 px-5 w-25 border-start" (click)="respond(false)">{{ 'TERMS.DISAGREE_AND_LOGOUT' | translate }}</button>
      <button class="btn btn-lg btn-primary rounded-0 px-5 w-25" (click)="respond(true)">{{ 'TERMS.AGREE_WITH_ALL' | translate }}</button>
    </div>
  </ng-container>
</ng-container>
<ng-template #loading>
  <div class="bg-white p-5 position-relative">
    <app-loader [loading]="true"></app-loader>
  </div>
</ng-template>
