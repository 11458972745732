<app-generic-modal
  cssClasses="transparent-body component-host-scrollable"
  bodyClasses="p-0"
  class="component-host-scrollable"
  headerClasses="px-0 pb-0"
>
  <ng-template appModalHeader>
    <div class="container-fluid p-0">
      <div class="row g-0 px-4">
        <div class="col">
          <h2 class="fw-bold mb-0">
            {{ 'ORDER_PRODUCTS_LIST.SELECT_CLIENT.CLIENT' | translate }}
          </h2>
          <p class="m-0">
            {{ order.title }}
          </p>
        </div>
      </div>
      <div class="row g-0">
        <div class="col">
          <ul class="nav nav-pills d-flex ps-2 pe-3 m-0 justify-content-between border-top mt-3">
            <li
              class="nav-item cursor-pointer"
              (click)="onSelectMode(customerMode.LIST)"
              [ngClass]="{ 'hover-border-color-primary': selectedMode === customerMode.LIST }"
            >
              <span class="nav-link" [ngClass]="{ active: selectedMode === customerMode.LIST }">{{
                'ORDER_PRODUCTS_LIST.SELECT_CLIENT.CLIENTS' | translate
              }}</span>
            </li>
            <li class="nav-item cursor-pointer">
              <span
                (click)="onSelectMode(customerMode.NEW)"
                class="nav-link d-flex h-100 align-items-center"
                [class.active]="selectedMode === customerMode.NEW"
                [class.color-black]="selectedMode === customerMode.NEW"
              >
                <i class="icon ni-add-circle-outline me-2"></i>
                <span>
                  {{ 'ORDER_PRODUCTS_LIST.SELECT_CLIENT.ADD_NEW_CLIENT' | translate }}
                </span>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template appModalBody>
    <app-customers-list
      [selected]="selectedClient"
      (cancel)="onSelectMode(customerMode.LIST)"
      (selectClient)="onClientSelect($event)"
      [selectedMode]="selectedMode"
    ></app-customers-list>
  </ng-template>
</app-generic-modal>
