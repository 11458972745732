import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from "@angular/core";
import { ImageSizeEnum, ResizeImagePipe } from '../../../shared/pipes/resize-image/resize-image.pipe';

@Component({
  selector: 'app-custom-article-image-control',
  templateUrl: './image-control.component.html',
  styleUrls: ['./image-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageControlComponent {
  @Input() image?: File | string;
  @Input() disabled = false;
  @Input() fileTooBig = false;
  @Output() fileChange: EventEmitter<any> = new EventEmitter();
  private fileUrl?: string = null;

  constructor(private resizeImagePipe: ResizeImagePipe) 
  {}

  onFileSelect(event) {
    if (event.target.files.length === 0) {
      return;
    }
    this.updateImage(event.target.files[0]);
  }

  onFileDrop(event: File) {
    this.updateImage(event);
  }

  onActivateFileInput(event: MouseEvent, inputName: string) {
    event.preventDefault();
    document.getElementById(inputName).click();
  }

  updateImage(file: File) {

    const reader = new FileReader();
    reader.onload = (e: any) => {
      const fileUrl = e.target.result;

      this.fileUrl = fileUrl;
      this.fileChange.emit({file, fileUrl})
    };

    reader.readAsDataURL(file);
  }

  resetImage() {
    this.fileUrl = null;
    this.fileChange.emit({});
  }

  hasImage() {
    return this.fileUrl || this.image;
  }

  imageBackground(): string | null {
    if (!this.hasImage()) {
      return null;
    }

    let imageUrl = this.image;
    
    if(!(this.image instanceof File)) {
      imageUrl = this.resizeImagePipe.transform(this.image, ImageSizeEnum.SIZE_402x258);
    }

    return `url("${this.fileUrl ?? imageUrl}")`;
  }
}
