<app-generic-modal [closable]="false" headerClasses="py-3 px-4" bodyClasses="p-0">
  <ng-template appModalHeader>
    <h2 class="mb-0">
      {{ 'APP_UPDATED.HEADER' | translate }}
    </h2>
  </ng-template>

  <ng-template appModalBody>
    <p class="p-4 mb-0">{{ 'APP_UPDATED.INFO' | translate }}</p>
  </ng-template>

  <ng-template appModalFooter>
    <button class="btn btn-primary btn-lg rounded-0 mw-11r" (click)="doFullReload()">{{ 'APP_UPDATED.ACTION' | translate }}</button>
  </ng-template>
</app-generic-modal>
