import { Injectable } from '@angular/core';
import { ApiService } from '../../../api.service';
import { ClientCreateInterface, ClientInterface, ClientUpdateInterface } from '../../models/client.model';
import { CacheableObservable } from '../../../cacheable-observable/cacheable-observable.model';
import { SelectableServiceInterface } from '../../../orders/additional-order-details/modal/service.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ClientService implements SelectableServiceInterface {
  constructor(private apiService: ApiService) {}

  all(): CacheableObservable<ClientInterface[]> {
    return this.apiService.get(`clients/`).pipe(map(({ data }) => data)) as CacheableObservable<ClientInterface[]>;
  }

  search(term: string): CacheableObservable<ClientInterface[]> {
    return this.apiService.get(`clients/search/${term}`).pipe(map(({ data }) => data)) as CacheableObservable<ClientInterface[]>;
  }

  update(id: number, client: ClientInterface): Observable<ClientInterface> {
    const body: ClientUpdateInterface = {
      email: client.email,
      firstName: client.firstName,
      lastName: client.lastName,
      axClientCode: client.axClientCode,
      companyName: client.companyName,
      companyCode: client.companyCode,
      vatCode: client.vatCode,
      country: client.country.id,
      phone: client.phone,
      address: client.address,
      city: client.city,
      postCode: client.postCode,
      contactPerson: client.contactPerson,
    };
    return this.apiService.patch(`clients/${id}`, body).pipe(map(({ data }) => data)) as Observable<ClientInterface>;
  }

  create(client: ClientCreateInterface): Observable<ClientInterface> {
    return this.apiService.post(`clients/`, client).pipe(map(({ data }) => data)) as Observable<ClientInterface>;
  }

  delete(id: number): Observable<any> {
    return this.apiService.delete(`clients/${id}`, null).pipe(map(({ data }) => data)) as Observable<any>;
  }

  /**
   * Gets all clients by user id
   * @param id
   */
}
