import { Component, Input, OnChanges, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import {
  OrderArticlesListColumns,
  OrderArticlesListRow,
  OrderArticlesListRowInterface,
  OrderArticlesListRowItemInterface,
  OrderArticlesListRowSizeInterface,
} from '../../../order-articles-list.interface';
import { FieldType } from '../../../../../shared/directives/hide-by-field-type/field-types.enum';
import { SaleMode } from '../../../../../shared/components/list-mode-switch/sale-mode.types';
import { SelectedRowsService } from '../../../services/selected-rows/selected-rows.service';
import { SelectableRowInterface } from '../../models/selectable-row.interface';
import { PermissionActions } from '../../../../../permissions.config';
import { OrderArticleSaleDiscountType } from '../../../../../core/enums/order-article-discount-type.enum';
import { OrderArticlesRowsService } from '../../../services/order-articles-rows/order-articles-rows.service';
import { Subscription, zip, Observable } from 'rxjs';
import { UserService } from '../../../../../core/services/user/user.service';
import { UserRole } from '../../../../../core/enums/user-role.enum';
import { UserInterface } from '../../../../../core/models/user.model';
import { OrderArticleMigrationStatusType, OrderArticleType } from '../../../../../core/models/order-article.model';
import { COLUMNS } from '../../order-articles-list-columns.constants';
import { OrderArticleMaterialInputService } from '../../../../../ui-elements/order-article-material-input/order-article-material-input.service';
import { OrderArticleMaterialInterface } from '../../../../../ui-elements/order-article-material-input/order-article-material-interface';
import { PopoverRef } from '../../../../../ui-elements/popover/popover-ref';
import { PopoverService } from '../../../../../ui-elements/popover/popover.service';
import { PropertyImageSizes } from '../../../../../core/enums/property-image-sizes.enum';
import { UncachePipe } from '../../../../../uncacheable-resource/uncache.pipe';
import { FieldClass } from '../../../../../core/enums/field-class';
import { FieldService } from '../../../../../core/services/field/field.service';
import { LanguageStoreHandlerService } from '../../../../../core/services/language/language-store-handler.service';
import { ListModeSwitchService } from '../../../../../shared/components/list-mode-switch/list-mode-switch.service';
import { OrderState } from "../../../../../core/enums/order.state.enum";
import { ImageSizeEnum } from '../../../../../shared/pipes/resize-image/resize-image.pipe';
import { OrderArticleRowsFilteringService } from '../../../services/order-article-rows-filtering/order-article-rows-filtering.service';

@Component({
  selector: 'app-order-articles-list-row',
  templateUrl: './row.component.html',
  styleUrls: ['./row.component.scss'],
})
export class RowComponent implements OnInit, OnChanges, SelectableRowInterface, OnDestroy {
  @Input() row: OrderArticlesListRowInterface;
  @Input() columns: OrderArticlesListColumns = COLUMNS;
  @Input() selectedRows: OrderArticlesListRow[];
  @Input() limitedView?: boolean;
  @Input() orderState?: OrderState;

  private subscription: Subscription = new Subscription();

  fieldTypes = FieldType;
  saleMode = SaleMode;
  orderArticleTypes = OrderArticleType;
  invalidRow = false;

  selectedMode: SaleMode;
  openRowDetails: number;

  permissionActions = PermissionActions;

  saleDiscountTypes = OrderArticleSaleDiscountType;
  migrationTypes = OrderArticleMigrationStatusType;
  selected: boolean;
  user: UserInterface;
  userRole = UserRole;
  availableMaterials: OrderArticleMaterialInterface[] = [];
  rowMaterials: OrderArticleMaterialInterface[] = [];
  popover: PopoverRef;
  fieldsToShow = [];

  price: string;
  uiLanguage = 'en';
  public orderStates = OrderState;
  imageSizes = ImageSizeEnum;
  isFilterEnabled = false;

  constructor(
    private listModeSwitchService: ListModeSwitchService,
    private selectedRowsService: SelectedRowsService,
    private orderArticlesRowsService: OrderArticlesRowsService,
    private userService: UserService,
    private orderArticleMaterialInputService: OrderArticleMaterialInputService,
    private popoverService: PopoverService,
    private uncachePipe: UncachePipe,
    private fieldService: FieldService,
    private languageStore: LanguageStoreHandlerService,
    private orderArticleRowsFilteringService: OrderArticleRowsFilteringService
  ) {}

  ngOnInit() {
    this.initializeMaterials();

    this.subscription.add(
      this.languageStore.getCurrentAsObservable().subscribe(language => {
        this.uiLanguage = language.short;
      })
    );

    this.selected = this.selectedRowsService.isSelectedByRowAndType(this.row, this.row.rowType);
    this.userService.getUser().subscribe(user => (this.user = user));
    this.subscription.add(
      this.listModeSwitchService.saleModeAsObservable().subscribe((saleMode: SaleMode) => {
        this.selectedMode = +saleMode;
      })
    );

    if (
      this.row.type === OrderArticleType.CUSTOM_ITEM_TYPE &&
      this.orderState !== OrderState.DRAFT &&
      (0
        || !this.row.item.code?.length
        || this.row.item.code.indexOf('*') !== -1
        || this.row.formattedTotalConfigurationPurchasePrice === '0.00'
      )
    ) {
      this.invalidRow = true;
    }

    this.subscription.add(
      this.fieldService.getOptionsAsObservable(FieldClass.ORDER).subscribe(groups => {
        const foundFields = groups
          .reduce((accumulator, current) => {
            accumulator.push(
              ...current.values.filter(value => {
                return value.checked;
              })
            );
            return accumulator;
          }, [])
          .map(item => {
            return item.name;
          });

        this.fieldsToShow = foundFields;
      })
    );

    this.subscription.add(
      this.orderArticleRowsFilteringService.getIsFilterEnabledObservable().subscribe((isEnabled) => {
        this.isFilterEnabled = isEnabled;
      })
    );
  }

  isColumnsVisible(targetColumns = []): boolean {
    return targetColumns.some(value => this.fieldsToShow.includes(value) );
  }

  formatDimensions(size: OrderArticlesListRowSizeInterface): string {
    if (!size) {
      return '';
    }

    const { width, depth, height } = size;

    const dimensionsSegments = [
      width ? `W=${width}` : '',
      depth ? `D=${depth}` : '',
      height ? `H=${height}` : ''
    ].filter(segment => segment);

    return dimensionsSegments.join(' ');
  }

  setTotals() {
    this.row.totalVolume = Number(
      (
        this.row.additionalParts?.reduce((acc = 0, item) => {
          return !+item.volume ? acc : acc + +item.volume * item.quantity;
        }, 0) / this.row.quantity
      ).toFixed(3)
    );

    this.row.totalWeight = Number(
      (
        this.row.additionalParts?.reduce((acc = 0, item) => {
          return !+item.weight ? acc : acc + +item.weight * item.quantity;
        }, 0) / this.row.quantity
      ).toFixed(3)
    );
  }

  ngOnChanges(c): void {
    this.setTotals();
    this.selected = this.selectedRowsService.isSelectedByRowAndType(this.row, this.row.rowType);
  }

  setSelected() {
    this.selected = this.selectedRowsService.isSelectedByRowAndType(this.row, this.row.rowType);
  }

  onToggleRowDetails(row: number) {
    this.openRowDetails = this.openRowDetails !== row ? row : null;
  }

  onToggleSelect() {
    this.onSelect(!this.selected);
  }

  onSelect(selected: boolean) {
    this.selectedRowsService.onSelect(this.row, selected);
  }

  onApplyCustomDiscount(event: OrderArticlesListRowInterface) {
    this.orderArticlesRowsService.applyCustomDiscount.next(event);
  }

  private getUncachedImageBackground({ img_thumbnails, img }, size?: PropertyImageSizes): Observable<string> {
    const value =
      (img_thumbnails &&
        ((size && img_thumbnails[size]) ||
          img_thumbnails[PropertyImageSizes.EXTRA_LARGE] ||
          img_thumbnails[PropertyImageSizes.LARGE] ||
          img_thumbnails[PropertyImageSizes.MEDIUM] ||
          img_thumbnails[PropertyImageSizes.SMALL])) ||
      img;

    // trying to update image URL to get larger file by replacing _32x32. to _240x240.
    // (full file name https://....cloudfront.net/files/SOME_CODE_32x32.jpg?version=***)
    if (value.indexOf(`_${PropertyImageSizes.SMALL}.`) !== -1) {
      return this.uncachePipe.transform(value.replace(`_${PropertyImageSizes.SMALL}.`, `_${PropertyImageSizes.EXTRA_LARGE}.`));
    }

    return this.uncachePipe.transform(value);
  }

  onClosePopover() {
    this.popover.close();
  }

  onOpenPopover(value, templateRef: TemplateRef<any>, origin: HTMLElement) {
    this.popover = this.popoverService.open<{ img: string; value: string }>({
      content: templateRef,
      origin,
      width: '110px',
      height: '110px',
      data: { img: value.img, value: this.getMaterialDetailsText([value.code, value.translation]) },
    });

    this.getUncachedImageBackground(value).subscribe(largeThumb => {
      const img = new Image();
      img.onload = () => {
        this.popover.data.img = largeThumb;
      };
      img.src = largeThumb;
    });
  }

  ngOnDestroy(): void {
    if (this.popover) {
      this.popover.close();
    }

    this.subscription.unsubscribe();
  }

  getMaterialDetailsText(materials: string[]): string {
    return Array.from(new Set(materials)).join(' - ');
  }

  getTranslatedDescription(item: OrderArticlesListRowItemInterface, locale: string) {
    if (item.translations.length) {
      const current = item.translations.filter(i => i.locale === locale);
      return current[0]?.content ?? item.translations[0].content;
    }

    return item.shortText || item.longText;
  }

  protected initializeMaterials() {
    if (this.row.type !== OrderArticleType.CUSTOM_ITEM_TYPE || !this.row.materialsAndType?.type) {
      return;
    }

    this.orderArticleMaterialInputService.parseMaterialsString(this.row.materialsAndType.type).subscribe(result => {
      this.rowMaterials = result;
    })
  }
}
