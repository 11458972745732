<div>
  <form class="d-flex py-1 w-100 flex-nowrap align-items-center" [formGroup]="form">
    <i
      class="icon d-block me-2 color-black"
      [class.color-warning]="!userService.isDealer()"
      [class.ni-file-add]="type === newItemType.ORDER"
      [class.ni-folder-add]="type !== newItemType.ORDER"
    ></i>
    <input class="form-control flex-grow-1 p-1" #addNewOrderOrGroup [formControl]="form.controls.title"
      [style.height]="'auto'" name="title" [class.is-invalid]="form.controls.title.invalid && form.controls.title.dirty"
      [placeholder]="getStringByType().placeholder | translate" (keydown.enter)="submitForm()"
      (keydown.escape)="onFormToggle()" />
    <button type="button" class="btn btn-primary btn-sm p-1 m-1" (click)="submitForm()">
      <i class="icon ni-check d-block"></i>
    </button>
  </form>
</div>
