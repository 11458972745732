import MeshExtended from '../mesh/mesh-extended';
import { MeshType } from '../mesh/mesh-type.enum';
import MeshNode from '../mesh/mesh-node';

export default class ModelService {
  constructor(private originMesh: MeshNode) {}

  static listVisibleMeshes(mesh: MeshNode) {
    let list = [];

    if (mesh.isVisible) {
      list.push(mesh);
    }

    if (undefined !== mesh.mChildren) {
      for (const child of mesh.mChildren) {
        // Skip additional parts, extensions, etc.
        if (MeshType.ROOT === child.mType || MeshType.MAIN === child.mType) {
          continue;
        }

        const newItems = ModelService.listVisibleMeshes(child);
        list = [...list, ...newItems];
      }
    }

    return list;
  }

  extendMesh(mesh: MeshNode | undefined, scene: typeof ViewerDependencies.Scene): ModelService {
    if (undefined === mesh) {
      mesh = this.originMesh;
    }

    this.extendMeshChildren(mesh, this.extendMesh.bind(this), scene);

    if (undefined !== mesh.mExtended) {
      return this;
    }

    mesh.mExtended = new MeshExtended(mesh, scene);

    return this;
  }

  extendMeshChildren(mesh: MeshNode, callable, ...params) {
    if (undefined !== mesh.mChildren) {
      for (const child of mesh.mChildren) {
        callable(child, ...params);
      }
    }
  }

  addType(mesh?: MeshNode): ModelService {
    if (undefined === mesh) {
      mesh = this.originMesh;
    }

    if (undefined === mesh.geometry) {
      switch (true) {
        case undefined === mesh.parent:
          mesh.mType = MeshType.ROOT;
          break;
        case undefined !== mesh.mOrderNumber:
          mesh.mType = MeshType.MAIN;
          break;
        default:
          mesh.mType = MeshType.GROUP;
      }
    } else {
      mesh.mType = MeshType.MESH;
    }

    this.extendMeshChildren(mesh, this.addType.bind(this));

    return this;
  }

  addName(mesh?): ModelService {
    if (undefined === mesh) {
      mesh = this.originMesh;
    }

    let meshName = 'gfj_';

    switch (mesh.mType) {
      case MeshType.ROOT:
        meshName += `article_${mesh.mOrderNumber}`;
        break;
      case MeshType.MAIN:
        meshName += `subarticle_${mesh.mOrderNumber}`;
        break;
      case MeshType.GROUP:
        meshName += 'group';
        break;
      default:
        meshName += 'mesh';
    }

    this.extendMeshChildren(mesh, this.addName.bind(this));

    mesh.name = meshName;

    return this;
  }

  bindExecuteCodeAction(mesh: MeshNode, scene: typeof ViewerDependencies.Scene, trigger: number, callable: Function): ModelService {
    if (undefined === mesh) {
      mesh = this.originMesh;
    }

    if (undefined === mesh.actionManager) {
      mesh.actionManager = new ViewerDependencies.ActionManager(scene);
    }

    if (ViewerDependencies.ActionManager.OnPickTrigger === trigger && mesh.isPickable) {
      mesh.actionManager.registerAction(new ViewerDependencies.ExecuteCodeAction(trigger, () => callable(mesh)));
    }

    this.extendMeshChildren(mesh, this.bindExecuteCodeAction.bind(this), scene, trigger, callable);

    return this;
  }
}
