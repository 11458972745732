import {Directive, DoCheck, ElementRef, Input, Renderer2} from '@angular/core';

@Directive({
  selector: '[appParentBoundary]'
})
export class ParentBoundaryDirective implements DoCheck {
  @Input('appParentBoundary') bounds;
  firstCheck = true;

  constructor(private elRef: ElementRef, private renderer: Renderer2) {
  }

  ngDoCheck() {
    if (this.elRef.nativeElement.offsetParent && this.firstCheck) {
      this.horizontallyBoundBox();
      this.firstCheck = false;
    } else {
      this.firstCheck = true;
    }
  }

  private horizontallyBoundBox() {
    const boundsRect = this.bounds.getBoundingClientRect();
    const popoverRect = this.elRef.nativeElement.getBoundingClientRect();
    const right = (boundsRect.right - popoverRect.right);
    if (right < 0) {
      this.renderer.setStyle(this.elRef.nativeElement, 'transform', `translate(${right}px`);
    }
  }

}
