import { Component, ElementRef, EventEmitter, Inject, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { OrderInterface } from '../core/models/order.model';
import { OrdersService } from '../orders/orders.service';
import { OrderSelectMenuService } from './order-select-menu.service';
import { PermissionsDecisionMakerServiceInterface } from '../permissions/decision-maker.service';
import { CAN_EDIT_OFFERS, PermissionActions } from '../permissions.config';
import { ExtraListElementInterface } from '../orders/order-articles-list/order-articles-list/components/extra-row/extra-items.model';
import { APISortOrderConverted } from '../core/enums/sort-order.enum';
import { CreateNewItemEventInterface, NewItemType } from './new-item/new-item.component';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { PERMISSIONS_DECISION_MAKER_SERVICE } from '../permissions/injection-tokens';
import { LoaderService } from '../ui-elements/loader/loader.service';
import { LoaderComponent } from '../ui-elements/loader/loader.component';
import { UserService } from '../core/services/user/user.service';
import { combineLatest, Observable, of, Subject, Subscription } from 'rxjs';
import { UserRole } from '../core/enums/user-role.enum';
import { OrderState } from "../core/enums/order.state.enum";
import { UserInterface } from "../core/models/user.model";

export interface OrderSelectOnChangeEventInterface {
  order: OrderInterface;
  group?: ExtraListElementInterface;
}

enum TabTypes {
  OFFERS = 1,
  ORDERS = 2,
  SEARCH = 3,
}

export interface OrderSelectMenuPaginationInterface {
  page: number;
  totalPages: number;
}

export interface SearchInterface {
  term$: Subject<string>;
  term: string;
}

@Component({
  selector: 'app-order-select-menu',
  templateUrl: './order-select-menu.component.html',
  styleUrls: ['./order-select-menu.component.scss'],
  providers: [OrdersService],
})
export class OrderSelectMenuComponent implements OnInit, OnDestroy {
  @ViewChild('loader', { static: true }) loader: LoaderComponent;
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef<HTMLInputElement>;

  @Output('click-outside') clickOutside: EventEmitter<any> = new EventEmitter<any>();

  selectedOrder: OrderInterface;
  selectedGroup;

  filteredOrders: OrderInterface[] = [];
  orderState = OrderState;
  permissionActions = PermissionActions;
  orders: OrderInterface[] = [];
  groupsListVisible = false;
  tab: TabTypes;
  userRole = UserRole;
  pagination: OrderSelectMenuPaginationInterface = {
    page: 1,
    totalPages: null,
  };

  search: SearchInterface = {
    term$: new Subject(),
    term: '',
  };

  tabTypes = TabTypes;
  isFormActive = false;
  newItemTypes = NewItemType;

  private subscriptions: Subscription = new Subscription();

  constructor(
    private loaderService: LoaderService,
    private ordersService: OrdersService,
    public orderSelectMenuService: OrderSelectMenuService,
    public userService: UserService,
    @Inject(PERMISSIONS_DECISION_MAKER_SERVICE)
    private permissionsDecisionMakerService: PermissionsDecisionMakerServiceInterface
  ) { }

  ngOnInit() {
    this.subscriptions.add(
      this.fetchOrders().subscribe()
    );

    this.subscriptions.add(
      this.orderSelectMenuService.selectedOrderAsObservable().subscribe(selectedOrder => {
        this.selectedOrder = selectedOrder
        if (selectedOrder) {
          this.groupsListVisible = true;
        }
      })
    );

    this.subscriptions.add(
      this.orderSelectMenuService.selectedGroupAsObservable().subscribe(selectedGroup => this.selectedGroup = selectedGroup)
    );

    this.subscriptions.add(
      this.search.term$
        .asObservable()
        .pipe(
          distinctUntilChanged(),
          debounceTime(500),
          switchMap((value: string) => {
            this.search.term = value;
            this.pagination.page = 1;
            this.orders = [];
            return this.fetchOrders(this.tab);
          })
        )
        .subscribe()
    );
  }

  fetchOrders(tab?: TabTypes, append = false) {
    return this.getStatesByTab(tab).pipe(
      switchMap(states => {
        return this.loaderService
          .load(
            this.ordersService
              .filterAll(
                {
                  states,
                  title: this.search.term,
                  page: this.pagination.page,
                },
                { prop: 'updatedAt', dir: APISortOrderConverted.DESC }
              )
              .noCache(),
            this.loader
          )
          .pipe(
            tap(({ data, meta }) => {
              if (append) {
                this.orders.push(...data);
              } else {
                this.orders = data;
              }

              this.pagination.totalPages = meta['total-pages'];
            })
          );
      })
    );
  }

  onRowClick(order: OrderInterface) {
    if (order.isSendingToAX) {
      return;
    }

    this.orderSelectMenuService.openOrder(order);
    this.groupsListVisible = true;
  }

  onCreateNewOrder(order: CreateNewItemEventInterface) {
    this.isFormActive = false;
    this.loaderService.load(
      this.ordersService.create(order), this.loader).subscribe(newOrder => {
        this.orders = [newOrder, ...this.orders];
      });
  }

  onSearch(event) {
    const { value } = event.target;
    this.search.term$.next(value);
  }

  onTabBack(tab: TabTypes) {
    this.selectedGroup = null;
    this.onTabChange(tab);
  }

  onTabChange(tab: TabTypes) {
    this.tab = tab;
    if (tab !== TabTypes.SEARCH) {
      this.search.term = '';

      this.pagination.page = 1;
      this.orders = [];

      this.groupsListVisible = false;
      this.fetchOrders(tab).subscribe(({ data }) => {
        if (this.selectedOrder && this.selectedGroup) {
          this.onRowClick(this.selectedOrder);
        }
      });
    } else {
      this.searchInput.nativeElement.focus();
    }
  }

  onFormToggle() {
    this.isFormActive = !this.isFormActive;
  }

  isTabActive(tab) {
    return tab === this.tab;
  }

  private getStatesByTab(tab: TabTypes): Observable<OrderState[]> {
    const decideStates = (innerTab: TabTypes, user: UserInterface) => {
      let states = [];
      this.tab = innerTab;
      switch (innerTab) {
        case TabTypes.OFFERS:
          states = [OrderState.DRAFT];
          break;
        case TabTypes.ORDERS:
          if (user && user.role.name === UserRole.ROLE_PM_NARBUTAS) {
            states = [OrderState.DRAFT, OrderState.WAITING];
          } else {
            states = [OrderState.WAITING];
          }
          break;
        case TabTypes.SEARCH:
          switch (user.role.name) {
            case UserRole.ROLE_DEALER:
              states = [OrderState.DRAFT];
              break;
            case UserRole.ROLE_PM_NARBUTAS:
              states = [OrderState.DRAFT, OrderState.WAITING];
              break;
            case UserRole.ROLE_PM:
            case UserRole.ROLE_PM_RU:
              states = [OrderState.WAITING];
              break;
            default:
              states = [
                OrderState.DRAFT,
                OrderState.WAITING,
              ];
          }
          break;
      }
      return states;
    };

    return combineLatest(this.userService.fromStorage(), this.permissionsDecisionMakerService.shouldAllow([CAN_EDIT_OFFERS])).pipe(
      switchMap(([user, shouldAllow]) => {
        let statuses = [];

        if (!tab) {
          if (shouldAllow) {
            statuses = decideStates(TabTypes.OFFERS, user);
          } else {
            statuses = decideStates(TabTypes.ORDERS, user);
          }
        } else {
          statuses = decideStates(tab, user);
        }
        return of(statuses);
      })
    );
  }

  onScroll() {
    this.pagination.page++;
    this.fetchOrders(this.tab, true).subscribe();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
