import { Directive, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { SearchService } from '../../../core/tree/search/search.service';

@Directive({
  selector: '[appHideOnSearch]'
})
export class HideOnSearchDirective implements OnInit {

  constructor(
    private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private searchService: SearchService) {
  }

  ngOnInit() {
    if (this.searchService.isSearchActive()) {
      this.viewContainer.clear();
    } else {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
}
