<ng-container>
  <ng-content *ngIf="!hidden">
  </ng-content>
  <ng-container *ngIf="hidden && !replacement">
    <ng-container *ngTemplateOutlet="replacementTemplate"></ng-container>
  </ng-container>
  <ng-container *ngIf="hidden && replacement">
    {{ replacement }}
  </ng-container>
</ng-container>
