import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'fileExtension'
})
export class FileExtensionPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return value.substr(value.lastIndexOf('.') + 1);
  }
}
