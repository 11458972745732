import {Directive, ElementRef, OnInit, Renderer2} from '@angular/core';
import {FullScreenModeService} from '../../../core/services/full-screen-mode/full-screen-mode.service';

@Directive({
  selector: '[appFullScreenMode]'
})
export class FullScreenModeDirective implements OnInit {

  constructor(private fullScreenModeService: FullScreenModeService, private elRef: ElementRef, private renderer: Renderer2) {
  }

  ngOnInit() {
    this.fullScreenModeService.enabledAsObservable().subscribe((enabled: boolean) => {
      enabled ? this.renderer.setStyle(this.elRef.nativeElement, 'display', 'none') :
        this.renderer.removeStyle(this.elRef.nativeElement, 'display');
    });
  }

}
