import {Injectable} from '@angular/core';
import {ApiService} from '../../../api.service';
import {AddressCreateInterface, AddressInterface, AddressUpdateInterface} from '../../models/address.model';
import {CacheableObservable} from '../../../cacheable-observable/cacheable-observable.model';
import {map} from 'rxjs/operators';
import {SelectableServiceInterface} from '../../../orders/additional-order-details/modal/service.model';
import {Observable} from 'rxjs';

@Injectable()
export class AddressService implements SelectableServiceInterface {

  constructor(private apiService: ApiService) {
  }

  all(belongsToUser = false): CacheableObservable<AddressInterface[]> {
    return this.apiService.get(`address/`, { belongsToUser }).pipe(
      map(({data}) => data)
    ) as CacheableObservable<AddressInterface[]>;
  }

  search(term: string): CacheableObservable<AddressInterface[]> {
    return this.apiService.get(`address/search/${term}`).pipe(
      map(({data}) => data)
    ) as CacheableObservable<AddressInterface[]>;
  }

  update(id: number, address: AddressUpdateInterface): Observable<AddressInterface> {
    return this.apiService.patch(`address/${id}`, address).pipe(map(({data}) => data));
  }

  create(address: AddressCreateInterface): Observable<AddressInterface> {
    return this.apiService.post(`address/`, address).pipe(map(({data}) => data));
  }

  delete(id: number): Observable<AddressInterface> {
    return this.apiService.delete(`address/${id}`, null).pipe(map(({data}) => data));
  }
}
