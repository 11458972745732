<app-generic-modal class="edit-modal" bodyClasses="px-4 pt-4 pb-2">
  <ng-template appModalHeader>
    <h2 class="mb-0">
      {{ 'SETTINGS.CUSTOMERS.EDIT.TITLE' | translate }}
    </h2>
  </ng-template>
  <ng-template appModalBody>
    <app-loader [loading]="!countries?.length" #loader [cssClasses]="'loader--white'"></app-loader>
    <app-client-form *ngIf="countries?.length"
      [countries]="countries"
      (changed)="onObjectChanged($event)"
      [model]="client"
    ></app-client-form>
  </ng-template>
  <ng-template appModalFooter>
    <button class="btn btn-secondary btn-lg rounded-0 w-50" (click)="onCancel()">
      <span> {{ 'SETTINGS.CANCEL' | translate }} </span>
    </button>
    <button class="btn btn-primary btn-lg rounded-0 w-50" (click)="onSubmit()" [disabled]="!isFormValid">
      <span>{{ 'SETTINGS.SAVE' | translate }}</span>
    </button>
  </ng-template>
</app-generic-modal>
