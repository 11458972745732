import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map, Observable, shareReplay, take, tap } from 'rxjs';
import { ApiService } from '../../api.service';
import { OrderArticleMaterialInterface } from './order-article-material-interface';

interface MaterialsByCode {
  [key: string]: OrderArticleMaterialInterface
};

const defaultMaterialImg = '/assets/icons/icon-material-placeholder-small.svg';

@Injectable({
  providedIn: 'root',
})
export class OrderArticleMaterialInputService {
  protected route = 'material/';
  private materials$: Observable<OrderArticleMaterialInterface[]> = this.api.get(this.route).pipe(
    map(data => data['data'] as OrderArticleMaterialInterface[]),
    shareReplay({ refCount: true, bufferSize: 1 })
  );

  private materialsMappedByCode$: Observable<MaterialsByCode> = this.materials$.pipe(
    map(materials => {
      const byCode = {};
      materials.forEach(material => {
        byCode[material.code] = material;
      });

      return byCode;
    }),
    shareReplay({ refCount: true, bufferSize: 1 })
  );

  constructor(protected api: ApiService, protected translator: TranslateService) {}

  getMaterials(): Observable<OrderArticleMaterialInterface[]> {
    return this.materials$;
  }

  /**
   * Parses string of material codes into materials objects.
   */
  parseMaterialsString(str: string | number): Observable<OrderArticleMaterialInterface[]> {
    const codes = str.toString().split(', ');

    return this.materialsMappedByCode$.pipe(
      map(materials => {
        const materialObjects = codes.map(code => {
          if (code in materials) {
            return materials[code];
          }

          return { code: code, img: defaultMaterialImg, translation: code };
        });

        return materialObjects;
      }),
      take(1)
    )
  }
}
