import { Injectable } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {QueryParams} from '../../enums/query-params.enum';

@Injectable()
export class QueryParamsService {

  constructor() {}

  static getAllowedQueryParams(activatedRoute: ActivatedRoute, queryParamsToAllow: QueryParams[]) {
    const currentParams = Object.keys(activatedRoute.snapshot.queryParams)
      .filter((key: QueryParams) => queryParamsToAllow.includes(key))
      .reduce((obj, key) => {
        obj[key] = activatedRoute.snapshot.queryParams[key];
        return obj;
      }, {});
    return currentParams;
  }
}
