import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, map } from 'rxjs';
import { LanguageStoreHandlerService } from '../services/language/language-store-handler.service';

export const ValidLanguageGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): boolean | Observable<boolean | UrlTree> => {
  const router = inject(Router);
  const languageService = inject(LanguageStoreHandlerService);

  const lang = route.params['language'];

  if (!lang) {
    return true;
  }

  return languageService.getAll().pipe(
    map((languages) => {
      const validLanguage = languages.some((language) => language.short === lang);

      if (validLanguage) {
        return true;
      }

      // Removes language path param while assuming that it is first path param
      const pathWithoutLang = state.url.split('/').slice(2);
      const navigationUrl = '/' + pathWithoutLang.join('/');

      return router.parseUrl(navigationUrl);
    })
  );
};
