import { Component, Input, OnInit } from '@angular/core';
import { FileData, imageFileExtension } from '../../../core/models/file-data.model';

@Component({
  selector: 'app-files-list',
  templateUrl: './files-list.component.html',
  styleUrls: ['./files-list.component.scss']
})
export class FilesListComponent implements OnInit {
  @Input() files: FileData[] = [];

  imageFiles: FileData[] = [];
  otherFiles: FileData[] = [];

  constructor() { }

  ngOnInit() {
    this.sortMessageFilesByType(this.files);
  }

  sortMessageFilesByType(files: FileData[]) {
    // @todo: refactor this when mime type information will be available on the API side
    files.forEach((file) => {
      if (imageFileExtension.includes(file.extension)) {
        this.imageFiles.push(file);
      } else {
        this.otherFiles.push(file);
      }
    });
  }
}
