import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-info-block-static-info',
  templateUrl: './static-info.component.html',
  styleUrls: ['./static-info.component.scss']
})
export class StaticInfoComponent implements OnInit {
  @Input() blockContent;

  constructor() { }

  ngOnInit() {
  }
}
