import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderMessageComponent } from './header-message.component';
import { HeaderMessageService } from './header-message.service';
import { CloseButtonModule } from '../ui-elements/close-button/close-button.module';

@NgModule({
  imports: [CommonModule, CloseButtonModule],
  declarations: [HeaderMessageComponent],
  exports: [HeaderMessageComponent],
  providers: [HeaderMessageService]
})
export class HeaderMessageModule { }
