export enum RouteTypeEnums {
  SEARCH,
  CATALOGUE,
  ORDERS,
  PAYMENTS,
  SHIPPING,
  USER,
  NEWS,
  ESTIMATES,
  INFO,
  FLANCO,
  INQUIRIES,
}

export enum CatalogueRouteType {
  CATEGORY = 1,
  SYSTEMS = 2,
  COMPONENTS = 3,
}

export enum OrderListRouteType {
  OFFERS = 1,
  ORDERS = 2,
  ARCHIVE = 3,
  TRASH = 4,
  WAITING = 5,
  SEARCH = 6,
  PENDING = 7,
  CONFIRMED = 8,
  LOADED = 9,
}

// Children of OrderListRouteType
export enum OrderRouteType {
  OFFERS = 101,
  ORDERS = 102,
  ARCHIVE = 103,
  TRASH = 104,
  WAITING = 105,
  PENDING = 106,
  CONFIRMED = 107,
  LOADED = 108,
}

export enum OrderRoutePath {
  ROOT = 'orders',
  OFFERS = 'offers',
  ORDERS = 'orders',
  ARCHIVE = 'archive',
  TRASH = 'trash',
  WAITING = 'waiting',
  SEARCH = 'search',
  PENDING = 'pending',
  CONFIRMED = 'confirmed',
  LOADED = 'loaded',
}

export enum UserRouterType {
  MY_DETAILS = 1,
  SALE_COEFFICIENTS = 2,
  SETTINGS = 3,
}

export enum InquiriesRouteType {
  CLAIMS = 1,
  PROJECT_RESERVATIONS = 2,
}

export enum InquiriesRoutePath {
  ROOT = 'inquiries',
  CLAIMS = 'claims',
  PROJECT_RESERVATIONS = 'project-reservations',
}

export enum GeneralRoutePaths {
  SIGN_IN = 'sign-in',
  LOGOUT = 'logout',
  TERMS = 'terms',
  IMPERSONATE = 'impersonate',
  CATALOGUE = 'catalogue',
  CONFIGURATION = 'configuration',
  USER = 'user',
  NEWS = 'news',
  INFO = 'info',
  ESTIMATES = 'estimates',
  DOWNLOAD_PRICELIST = 'download-pricelist',
  UI_INVENTORY = 'ui-inventory',
  PAGE_NOT_FOUND = 'page-not-found',
  SOMETHING_WENT_WRONG = 'something-went-wrong',
  UNSUPPORTED_BROWSER = 'unsupported-browser',
  MAINTENANCE = 'maintenance'
}

export enum RoutePathParams {
  EMAIL = ':email',
  LANGUAGE = ':language'
}
