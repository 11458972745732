import {Directive, ElementRef, HostListener, Input, OnDestroy, TemplateRef} from '@angular/core';
import { TooltipPositionValues } from './model';
import {TooltipOpenerService} from './tooltip-opener.service';

@Directive({
  exportAs: 'tooltipOpener',
  selector: 'tooltipOpener, [tooltipOpener]',
})
export class TooltipGlobalOpenerDirective implements OnDestroy {
  @Input() tooltipContent: string | TemplateRef<any>;
  @Input() tooltipPosition: TooltipPositionValues = 'top';
  @Input() tooltipArrowPosition: TooltipPositionValues = 'bottom-left';
  @Input() additionalClasses: string;
  @Input() contentClasses: string;
  @Input() arrowClasses: string;
  @Input() tooltipHoverableContent: boolean;
  @Input() tooltipDisabled?: boolean;
  @Input() tooltipBounds?: HTMLElement;

  constructor(private element: ElementRef,
              private tooltipOpener: TooltipOpenerService
  ) {
  }

  @HostListener('pointerenter') onMouseEnter() {
    this.tooltipOpener.clearTimer();
    if (!this.tooltipDisabled) {
      this.tooltipOpener.changed({
        open: true,
        content: this.tooltipContent,
        position: this.tooltipPosition,
        arrowPosition: this.tooltipArrowPosition,
        openerElement: this.element,
        additionalClasses: this.additionalClasses,
        contentClasses: this.contentClasses,
        arrowClasses: this.arrowClasses,
        hoverableContent: this.tooltipHoverableContent,
        tooltipBounds: this.tooltipBounds,
      });
    }
  }

  @HostListener('pointerleave') onMouseLeave() {
    if (this.tooltipHoverableContent) {
      this.tooltipOpener.setTimer(setTimeout(() => {
        this.close();
      }, 100));
    } else {
      this.close();
    }
  }

  @HostListener('click')
  onMouseClick() {
    this.close();
  }

  public open() {
    this.tooltipOpener.changed({
      open: true,
      content: this.tooltipContent,
      position: this.tooltipPosition,
      arrowPosition: this.tooltipArrowPosition,
      openerElement: this.element,
      additionalClasses: this.additionalClasses,
      contentClasses: this.contentClasses,
      arrowClasses: this.arrowClasses,
      hoverableContent: this.tooltipHoverableContent,
      tooltipBounds: this.tooltipBounds,
    });
  }

  public close() {
    this.tooltipOpener.changed({
      open: false
    });
  }

  ngOnDestroy() {
    this.onMouseLeave();
  }
}
