<div class="d-flex p-3 border-bottom bg-gray-200 hover-bg-white cursor-pointer">
  <div>
    <div class="d-flex position-relative me-3">
      <ng-content select="[notification-icon]"></ng-content>
      <span *ngIf="showBadge"
        class="position-absolute rounded-circle bg-primary abstract-notification-badge"
      ></span>
    </div>
  </div>
  <div class="d-flex flex-column flex-nowrap">
    <span class="color-black"><ng-content select="[notification-title]"></ng-content></span>
    <span class="small color-gray-600"><ng-content select="[notification-text]"></ng-content></span>
    <span class="small color-gray"><ng-content select="[notification-subtext]"></ng-content></span>
    <ng-content></ng-content>
  </div>
</div>
