import {Component, OnInit, Input, ViewChild, OnDestroy} from '@angular/core';
import {PriceDetailsInterface} from '../footer/price-details/price-details.component';
import {ProductsService} from '../../catalogue/products/products.service';
import {LoaderService} from '../../ui-elements/loader/loader.service';
import {first} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {OrderArticleInterface} from '../../core/models/order-article.model';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {OrderInterface} from '../../core/models/order.model';
import {SaleMode} from '../../shared/components/list-mode-switch/sale-mode.types';
import {UserService} from '../../core/services/user/user.service';
import {UserInterface} from '../../core/models/user.model';
import {UserRole} from '../../core/enums/user-role.enum';
import {ListModeSwitchService} from '../../shared/components/list-mode-switch/list-mode-switch.service';

@Component({
  selector: 'app-price-details-modal',
  templateUrl: './price-details-modal.component.html',
  styleUrls: ['./price-details-modal.component.scss'],
})
export class PriceDetailsModalComponent implements OnInit, OnDestroy {
  @Input() quantity: number;
  @Input() item: string;
  @Input() selectedOrder?: OrderInterface;
  @Input() orderArticle?: OrderArticleInterface;

  loading = false;
  details: PriceDetailsInterface;
  items: OrderArticleInterface[];
  saleMode: SaleMode;
  user: UserInterface;

  saleModes = SaleMode;

  subscriptions = new Subscription();

  constructor(
    private saleModeService: ListModeSwitchService,
    private productsService: ProductsService,
    private loaderService: LoaderService,
    private activeModal: NgbActiveModal,
    private userService: UserService
  ) {
  }

  ngOnInit() {
    this.refetch();

    this.subscriptions.add(this.saleModeService.saleModeAsObservable().subscribe(saleMode => (this.saleMode = +saleMode)));

    this.userService.fromStorage().subscribe(user => (this.user = user));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onClose() {
    this.activeModal.dismiss();
  }

  determinePrice(item: OrderArticleInterface) {
    if (this.user) {
      if (this.user.role.name === UserRole.ROLE_PM_NARBUTAS) {
        return item.discount;
      }

      return this.saleMode === SaleMode.ORDER ? item.discount : item.saleDiscount;
    }
  }

  private refetch(): void {
    this.loading = true;

    this.productsService
      .fetchProductPrice({
        quantity: this.quantity,
        saleMode: this.saleMode ? this.saleMode : this.saleModeService.getSaleMode(),
        order: this.selectedOrder ? this.selectedOrder.id : null,
        orderArticle: this.orderArticle ? this.orderArticle.id : null,
      })
      .noCache()
      .pipe(first())
      .subscribe((data: PriceDetailsInterface) => {
        this.details = data;
        this.items = data.orderArticles;
        this.loading = false;
      });
  }
}
