import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ButtonInterface } from '../../../../shared/interfaces/modal/button.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {RemoveActionType} from "../../../can-modify-product-view.component";

@Component({
  selector: 'app-remove-order-article-modal',
  templateUrl: './remove-modal.component.html'
})
export class RemoveModalComponent implements OnInit {
  // @Input() removableOrderArticles: number[];
  @Input() removableItems: number[];
  @Input() removeActionType: RemoveActionType;
  // @Input() itemsToRemoveType: 'ITEMS' | 'PRODUCTS';
  @Output() remove: EventEmitter<null> = new EventEmitter<null>();
  @Output() cancel: EventEmitter<null> = new EventEmitter<null>();

  removeActionTypeEnum = RemoveActionType;

  buttons: ButtonInterface[] = [];

  constructor(private activeModal: NgbActiveModal) {}

  ngOnInit() {
    this.buttons = [
      {
        text: 'ORDER_ARTICLES.REMOVE_MODAL.CANCEL',
        action: () => this.onCancel(),
        classes: 'btn-lg btn-secondary w-25',
      },
      {
        text: `ORDER_ARTICLES.REMOVE_MODAL.${this.removeActionType === RemoveActionType.REMOVE ? 'REMOVE' : 'UNGROUP'}`,
        action: () => this.onRemove(),
        classes: 'btn btn-danger btn-lg rounded-0 w-25',
      },
    ];
  }

  onRemove() {
    this.remove.emit();
    this.activeModal.close();
  }

  onCancel() {
    this.cancel.emit();
    this.activeModal.close();
  }
}
