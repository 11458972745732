<div class="modal-content">
  <div class="modal-header flex-column p-0">
    <div class="d-flex justify-content-between w-100 py-3 px-4">
      <h2 class="mb-0">{{ system.system }}</h2>
      <button type="button" class="btn btn-default p-0 m-1 d-flex" (click)="modal.dismiss()">
        <i class="icon ni-close"></i>
      </button>
    </div>
    <div *ngIf="tabs.length" class="w-100 border-top">
      <ul class="nav nav-pills tabs flex-nowrap text-nowrap px-2">
        <li *ngFor="let tab of tabs" class="nav-item cursor-pointer">
          <a class="nav-link" (click)="switchTab(tab)" [class.active]="activeTab === tab">
            {{tab.pageTypeName}}
          </a>
        </li>
      </ul>
    </div>
  </div>

  <div class="modal-body min-body-height position-relative p-0">
    <app-loader #loader [cssClasses]="'loader--relative'"></app-loader>
    <div class="tab-content" *ngIf="tabs.length && !loader.loading">
      <ng-container [ngSwitch]="activeTab?.pageTypeType">
        <div class="tab-pane" *ngSwitchCase="pageTypes.GALLERY">
          <app-gallery [images]="activeTabImages"></app-gallery>
        </div>
        <div class="tab-pane" *ngSwitchCase="pageTypes.PAGE">
          <app-info-block-static-info [blockContent]="activeTab.blockContents"></app-info-block-static-info>
        </div>
        <div class="tab-pane" *ngSwitchCase="pageTypes.TWO_COLUMNS_PAGE">
          <app-info-block-static-info [blockContent]="activeTab.blockContents"></app-info-block-static-info>
        </div>
        <div class="tab-pane" *ngSwitchCase="pageTypes.DOWNLOADS">
          <div class="container-fluid">
            <div class="row">
              <div class="col">
                <app-catalogue-downloads [filesBlocks]="activeTab.blockPageFiles"></app-catalogue-downloads>
              </div>
              <div class="col" *ngIf="finishFamilies && activeTab === finishDownloadsTab">
                <app-finishes-downloads [finishes]="finishFamilies"></app-finishes-downloads>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane" *ngSwitchCase="uiPageTypes.DOWNLOADS">
          <app-finishes-downloads [finishes]="finishFamilies"></app-finishes-downloads>
        </div>
        <div class="tab-pane" *ngSwitchCase="uiPageTypes.FINISHES">
          <app-finishes-info [finishes]="finishes"></app-finishes-info>
        </div>
      </ng-container>
    </div>
  </div>

  <app-no-results-found *ngIf="!tabs.length && !loader.loading" [cssClasses]="'no-data--top'"></app-no-results-found>
</div>

