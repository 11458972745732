import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { CountryInterface } from '../../core/models/country.model';
import { UserInterface } from '../../core/models/user.model';

@Component({
  selector: 'app-profile-form',
  templateUrl: './profile-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileFormComponent {
  @Input() profileForm: UntypedFormGroup;
  @Input() inModal = false;
  @Input() countries: CountryInterface[];
  @Input() user: UserInterface;
}
