

export interface UserNotificationInterface {
  id?: number,
  name?: string,
  notificationGroup?: number,
}

export interface UserNotificationViewInterface {
  id?: number,
  name?: string,
  notificationGroup?: number,
  checked?: boolean
}

export interface UserNotificationUpdateInterface {
  id?: number,
  status?: boolean
}

export enum UserNotificationGroupEnum {
  ORDERS = 2,
  INQUIRIES = 5,
}

export const UserNotificationGroupTranslationMap = {
  [UserNotificationGroupEnum.ORDERS]: 'NAVBAR.ORDERS.TITLE',
  [UserNotificationGroupEnum.INQUIRIES]: 'NAVBAR.INQUIRIES.TITLE',
};
