<app-generic-modal bodyClasses="p-4">
  <ng-template appModalHeader>
    <h2 class="mb-0">{{ 'SETTINGS.CUSTOMERS.DELETE.TITLE' | translate}}</h2>
  </ng-template>
  <ng-template appModalBody>
    <app-loader #loader [cssClasses]="'loader--white'"></app-loader>
    <p class="mb-0">{{ 'SETTINGS.CUSTOMERS.DELETE.TITLE_TEXT' | translate }}</p>
  </ng-template>
  <ng-template appModalFooter>
    <button *ngFor="let button of buttons" (click)="button.action()" [ngClass]="button.classes" class="btn btn-lg rounded-0 w-25">
      {{ button.text | translate }}
    </button>
  </ng-template>
</app-generic-modal>
