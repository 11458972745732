import { Injectable, Injector } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ERROR_STATUSES } from '../header-message/server-errors.interceptor';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(public injector: Injector, private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authService: AuthService = this.injector.get(AuthService);
    if (authService.isAuthenticated()) {
      const token = authService.getToken();
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
    return next.handle(request).pipe(
      tap(
        _ => {},
        (error: any) => {
          if (error instanceof HttpErrorResponse) {
            if (error.status === ERROR_STATUSES.UNAUTHORIZED) {
              authService.logout(true);
              this.router.navigate(['/sign-in']);
            }
          }
        }
      )
    );

  }
}
