import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {LeaveModalComponent} from '../../shared/components/leave-modal/leave-modal.component';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class CloseWithConfirmationGuard  {

  modalRef: NgbModalRef;

  constructor(private translator: TranslateService,
              private modalService: NgbModal
  ) {
  }

  canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
    if (component.canDeactivate()) {
      // if there are no pending changes, just allow deactivation; else confirm first
      return true;
    } else {
      // NOTE: this warning message will only be shown when navigating elsewhere within your angular app;
      // when navigating away from your angular app, the browser will show a generic warning message
      // see http://stackoverflow.com/a/42207299/7307355
      return this.openConfirmDialog();
    }
  }

  openConfirmDialog() {
    this.modalRef = this.modalService.open(LeaveModalComponent);
    return this.modalRef.componentInstance.onClose;
  }
}

