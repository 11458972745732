import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LoaderComponent } from '../../../ui-elements/loader/loader.component';
import { FieldService } from '../../../core/services/field/field.service';
import { Subscription } from 'rxjs';
import { FieldClass } from '../../../core/enums/field-class';
import { GroupableItemInterface } from '../../../core/models/groupable-item.model';
import { ToastService } from '../../../ui-elements/toast/toast.service';

@Component({
  selector: 'app-user-settings-defaults',
  templateUrl: './defaults.component.html',
})
export class DefaultsComponent implements OnInit, OnDestroy {
  @ViewChild('loader', { static: true }) loader: LoaderComponent;
  private subscription: Subscription;
  options: GroupableItemInterface[];

  constructor(
    private fieldService: FieldService,
    private toastService: ToastService,
    private translator: TranslateService
  ) {}

  ngOnInit() {
    this.refreshData();
  }

  refreshData() {
    this.loader.show();

    this.options = [];
    this.subscription = this.fieldService.getGroupedBy(FieldClass.DEFAULT).subscribe(
      (options: GroupableItemInterface[]) => {
        this.options = options;
        this.loader.hide();
      },
      error => {
        console.log(error);
        this.loader.hide();
      }
    );
  }

  onSave() {
    this.loader.show();
    const update = [];
    this.options.forEach(optionGroup => {
      optionGroup.values.forEach(option => {
        update.push({ id: option.id, status: option.checked });
      });
    });
    this.fieldService.updateMultiple(FieldClass.DEFAULT, update).subscribe(
      () => {
        this.translator.get('SETTINGS.SUCCESS').subscribe(translation => {
          this.toastService.success(translation);
          this.loader.hide();
        });
      },
      err => {
        console.log(err);
        this.loader.hide();
      }
    );
  }

  onChange(checked: boolean, optionGroup, option) {
    this.options.forEach(group => {
      if (group.key === optionGroup.key) {
        group.values.forEach(value => {
          if (value.id === option.id) {
            value.checked = checked;
          }
        });
      }
    });
  }

  onReset() {
    this.ngOnDestroy();
    this.refreshData();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
