import { Component, Input } from '@angular/core';
import { CatalogueSystemsInterface, ProductArticleVariant, ProductsArticleInterface } from '../product.model';
import { ConfiguratorOpenerService } from '../products-configurator-opener.service';
import { ImageSizeEnum } from '../../../shared/pipes/resize-image/resize-image.pipe';

@Component({
  selector: 'app-products-thumbail-view',
  templateUrl: './products-thumbail-view.component.html',
  styleUrls: ['./products-thumbail-view.component.scss']
})
export class ProductsThumbailViewComponent {
  @Input() groupedSystems = [];
  @Input() hasFilters = false;

  imageSizes = ImageSizeEnum;

  constructor(
    private configuratorOpener: ConfiguratorOpenerService
  ) {}

  openConfiguratorModal(system: CatalogueSystemsInterface, article: ProductsArticleInterface, articleVariant?: ProductArticleVariant) {
    this.configuratorOpener.openConfiguratorModal(system, article, articleVariant);
  }
}
