import {NEVER, Observable} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {Injectable} from '@angular/core';
import {ApiService} from '../../../api.service';

export interface SearchResponseInterface {
  data: any[];
  message: string;
  query: string;
}

@Injectable()
export abstract class AbstractSearchService {
  placeholder = 'SEARCH.PLACEHOLDER';
  protected endpointUrl: (term) => string;

  term$: Observable<string | null> = NEVER;
  searchActive = false;
  searchActive$: Observable<boolean> = NEVER;

  protected constructor(
    protected api: ApiService,
    protected translator: TranslateService
  ) {
  }

  changeTerm(term: string | null): void {}

  combineTranslationWithSearchResponse(value: string, data: any, translateService: TranslateService): Observable<SearchResponseInterface> {
    return new Observable(observer => {
      translateService.get(
        ['SEARCH.RESULTS.FOUND', 'SEARCH.RESULTS.NOT_FOUND'],
        { value }
      ).subscribe(translation => {
        const message = data.length > 0 ? translation['SEARCH.RESULTS.FOUND'] :
          translation['SEARCH.RESULTS.NOT_FOUND'];
        observer.next({ data, message, query: value });
        observer.complete();
      });
    });
  }

  isSearchActive(): boolean {
    return this.searchActive;
  }
}
