import { CommaPipe } from './../../shared/pipes/comma/comma.pipe';
import {fromEvent, Subscription} from "rxjs";
import {AfterViewInit, ElementRef, EventEmitter, Injectable, Input, OnDestroy, OnInit, Output, ViewChild} from "@angular/core";
import {debounceTime, distinctUntilChanged} from "rxjs/operators";
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {CustomOrderArticleItemChangesInterface} from "./custom-article-modal.model";
import {innerHeight} from "../../core/util/dom.helper";
import {OtherSupplierOrderArticleInterface} from "../../core/models/order-article.model";
import {UserInterface} from "../../core/models/user.model";
import {PriceFormatPipe} from "../../shared/pipes/price-format/price-format.pipe";
import {decimalNumbers} from "../../shared/class/custom-validators";

@Injectable()
export abstract class FormComponentModel implements OnInit, OnDestroy, AfterViewInit {
  abstract set item(item: OtherSupplierOrderArticleInterface);
  abstract get item(): OtherSupplierOrderArticleInterface;
  @Input() user?: UserInterface;
  @Output() itemChanges: EventEmitter<CustomOrderArticleItemChangesInterface> = new EventEmitter();
  @ViewChild('formEl') formEl: ElementRef;

  protected subscriptions: Subscription = new Subscription();
  protected loaded = false;

  form = new UntypedFormGroup({
    category: new UntypedFormControl(null, [Validators.maxLength(100)]),
    // shortText means "custom category" ¯\_(ツ)_/¯
    shortText: new UntypedFormControl(null, [Validators.maxLength(130)]),
    system: new UntypedFormControl(null, [Validators.maxLength(100)]),
    code: new UntypedFormControl(null, [Validators.maxLength(100)]),
    typeInformation: new UntypedFormControl(null, [Validators.maxLength(100)]),
    volume: new UntypedFormControl(null, [Validators.maxLength(255), decimalNumbers]),
    weight: new UntypedFormControl(null, [Validators.maxLength(255), decimalNumbers]),
    height: new UntypedFormControl(null, [Validators.maxLength(255)]),
    depth: new UntypedFormControl(null, [Validators.maxLength(255)]),
    width: new UntypedFormControl(null, [Validators.maxLength(255)]),
    customPrice: new UntypedFormControl(null, [decimalNumbers]),
    pricelistPrice: new UntypedFormControl(null, [decimalNumbers]),
  });

  protected formValues(item): OtherSupplierOrderArticleInterface {
    return {
      category: item.category ?? null,
      shortText: item.shortText ?? null,
      system: item.system ?? null,
      code: item.code ?? null,
      typeInformation: item.typeInformation ?? null,
      volume: item.volume ?? null,
      weight: item.weight ?? null,
      height: item.height ?? null,
      depth: item.depth ?? null,
      width: item.width ?? null,
      customPrice: item.customPrice ?? null,
      pricelistPrice: item.pricelistPrice ?? null,
    };
  }

  protected constructor(
    protected fb: UntypedFormBuilder,
    protected priceFormatPipe: PriceFormatPipe,
    protected commaPipe: CommaPipe
  ) {}

  ngOnInit() {
    this.itemChanges.emit({item: this.form.value, valid: this.form.valid});

    this.subscriptions.add(
      this.form.valueChanges
        .pipe(debounceTime(500), distinctUntilChanged())
        .subscribe(values => {
          this.itemChanges.emit({item: values, valid: this.form.valid});
        })
    );

    this.subscriptions.add(
      fromEvent(window, 'resize')
        .pipe(debounceTime(200), distinctUntilChanged())
        .subscribe(() => this.calculateFormHeight())
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  ngAfterViewInit(): void {
    window.dispatchEvent(new Event('resize'));
  }

  formatPrice(controlName: string) {
    const control = this.form.get(controlName);

    if (control.dirty && control.valid) {
      const formattedPrice = this.priceFormatPipe.transform(control.value);
      control.setValue(formattedPrice);
    }
  }

  formatFloatNumber(controlName: string) {
    const control = this.form.get(controlName);
    const formattedValue = this.commaPipe.transform(control.value, '.');

    control.setValue(formattedValue);
  }

  calculateFormHeight() {
    const modalBodyEl = document.querySelector('.modal-body') as HTMLElement;
    const modalBodyHeight = innerHeight(modalBodyEl);
    const modalBodyInnerPadding = 16; // hence .p-2

    this.formEl.nativeElement.style.maxHeight = `${modalBodyHeight - modalBodyInnerPadding}px`;
  }
}
