import { Directive } from '@angular/core';

@Directive({
  selector: '[appModalHeader]'
})
export class ModalHeaderDirective {

  constructor() { }

}
