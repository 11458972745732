<form [formGroup]="form" (submit)="onFeedbackSubmit()">
  <app-generic-modal bodyClasses="p-0 bg-white d-flex flex-column" cssClasses="d-flex flex-column">
    <ng-template appModalHeader>
      <h3 class="m-0">{{ 'INQUIRIES.PROJECT_RESERVATIONS.PREVIEW.TITLE_MARK_AS_LOST' | translate }}</h3>
    </ng-template>

    <ng-template appModalBody>
      <div class="container-fluid">
        <div class="row">
          <div class="col px-4 pb-2">
            <div class="mt-4">
              <p class="color-gray-900 mb-4">{{ "INQUIRIES.PROJECT_RESERVATIONS.PREVIEW.ALERT.LOST.SUBHEADING" |
                translate }}</p>
              <app-project-reservation-lost-feedback formControlName="feedback" />
            </div>
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template appModalFooter>
      <div class="d-inline-flex" [ngbTooltip]="'INQUIRIES.PROJECT_RESERVATIONS.NEW.TOOLTIP_FILL_IN_FORM' | translate"
        [disableTooltip]="!form.invalid">
        <button type="button"
          class="btn btn-secondary btn-lg d-flex align-items-center justify-content-center h-100 no-outline"
          (click)="onDiscard()">
          {{ 'MODAL.BUTTON.CANCEL' | translate }}
        </button>
        <button type="submit"
          class="btn btn-primary btn-lg d-flex align-items-center justify-content-center h-100 no-outline"
          [disabled]="form.invalid || !isFeedbackProvided">
          {{ 'INQUIRIES.PROJECT_RESERVATIONS.ACTIONS.CONFIRM' | translate }}
        </button>
      </div>
    </ng-template>
  </app-generic-modal>
</form>
