<div class="accordion">
  <ng-container *ngIf="filters.length">
    <ng-container *ngFor="let filter of filters">
      <div class="card border-0 shadow-sm mb-3" *ngFor="let filterAssign of filter.filterAssigns"
           [class.card--active]="openFilters.includes(filterAssign.id)">
        <div class="card-header border-0 p-3" id="headingOne-{{ filterAssign.id }}"
             (click)="onOpenFilterBody(filterAssign.id)">
          <div class="w-100 d-flex align-items-center justify-content-between">
            <a class="filter-sidebar-title text-truncate color-black overflow-hidden">
              {{ 'CATALOGUE.FILTERS.COMPUTED_' + filterAssign.property | uppercase | translate }}
            </a>

            <a class="filter-sidebar-clear color-gray" *ngIf="hasCheckedFilter(filterAssign); else notChecked"
                (click)="onClear(filterAssign)" appClickStopPropagation>{{ 'CATALOGUE.FILTERS.CLEAR' | translate }}</a>
            <ng-template #notChecked>
              <i *ngIf="openFilters.includes(filterAssign.id); else notExpended" class="icon ni-minus color-gray"></i>
              <ng-template #notExpended>
                <i class="icon ni-plus color-gray"></i>
              </ng-template>
            </ng-template>
          </div>
        </div>
        <div id="toggle-{{ filterAssign.id }}" class="collapse" [class.show]="openFilters.includes(filterAssign.id)"
             attr.aria-labelledby="headingOne-{{ filterAssign.id }}" data-parent="#accordion">
          <div class="card-body px-3 pb-3 pt-0">
            <div class="pb-2 color-black" *ngFor="let filterAssignValue of filterAssign.filterAssignValues">
              <app-check-mark [name]="[filter.id, filterAssign.id, filterAssignValue.id].toString()"
                              [value]="filterAssignValue.status"
                              [text]="filterAssignValue.value"
                              cssClasses="m-0"
                              (changed)="onCheckMarkChange($event, filterAssignValue, filterAssign)"></app-check-mark>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="(!filters.length || !hasChild)">
    <div class="card border-0 shadow-sm filter-sidebar-empty p-3">
      {{ 'CATALOGUE.FILTERS.NO_FILTERS_AVAILABLE' | translate }}
    </div>
  </ng-container>
</div>

