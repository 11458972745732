import { Component, OnDestroy, OnInit } from '@angular/core';
import { CatalogueSearchService } from './catalogue-search.service';
import { SearchService } from '../core/tree/search/search.service';
import {ActivatedRoute, NavigationEnd, Router, UrlTree} from "@angular/router";
import {TreeService} from "../core/tree/tree.service";
import {filter, first, map, startWith} from "rxjs/operators";
import {CatalogueRouteType, RouteTypeEnums} from "../core/enums/route-types.enum";
import {CategoryPathInterface} from "../core/models/category-path.model";
import {Subscription} from "rxjs";
import { NavbarElements } from '../core/tree/navbar-elements';

/** Common functionality shared between component's child routes. */
@Component({
  selector: 'app-catalogue-parent',
  template: `<router-outlet></router-outlet>`,
  providers: [CatalogueSearchService]
})
export class CatalogueParentComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();

  private readonly catalogueTypes = new Map<UrlTree, CatalogueRouteType>([
    [this.router.createUrlTree(['/catalogue/category/']), CatalogueRouteType.CATEGORY],
    [this.router.createUrlTree(['/catalogue/systems/']), CatalogueRouteType.SYSTEMS],
    [this.router.createUrlTree(['/catalogue/components/']), CatalogueRouteType.COMPONENTS]
  ])

  constructor(
    private route: ActivatedRoute,
    private catalogueSearch: CatalogueSearchService,
    private searchService: SearchService,
    private treeService: TreeService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.searchService.switchSearchService(this.catalogueSearch);

    this.subscriptions.add(
      this.router.events.pipe(
        filter<NavigationEnd>(event => event instanceof NavigationEnd),
        startWith(0),
        map(() => this.findURLWithoutChild()),
        filter(Boolean)
      ).subscribe((urlTree) => {
        this.navigateToFirstSubCategory(this.catalogueTypes.get(urlTree));
      })
    );
  }

  ngOnDestroy(): void {
    this.searchService.switchSearchService(null);
    this.subscriptions.unsubscribe();
  }

  private navigateToFirstSubCategory(categoryType: CatalogueRouteType): void {
    this.treeService
      .getCatalogue(categoryType)
      .pipe(first())
      .subscribe((res: CategoryPathInterface[]) => {
        const commands = [
          '/catalogue',
          NavbarElements.find(e => e.id === RouteTypeEnums.CATALOGUE)
            .children.find(e => e.id === categoryType).path
        ];
        const category = res[0];
        const subcategory = category.children[0] ?? null;

        if (categoryType === CatalogueRouteType.SYSTEMS) {
          commands.push('' + category.slug.charAt(0).toLocaleUpperCase());
        }

        commands.push('' + category.slug);

        if (subcategory) {
          commands.push('' + subcategory.slug);
        }

        this.router.navigate(commands, {
          replaceUrl: true
        });
        this.treeService.modifyState({ third: true });
      });
  }

  private findURLWithoutChild(): UrlTree {
    return Array.from(this.catalogueTypes.keys()).find(urlTree => this.router.isActive(urlTree, {
      fragment: 'ignored',
      paths: 'exact',
      matrixParams: 'exact',
      queryParams: 'ignored'
    }));
  }
}
