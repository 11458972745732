<div class="card-body p-4">
  <h2 class="mb-3">{{"SETTINGS.NOTIFICATIONS.TITLE" | translate}}</h2>
  <p class="color-gray" [innerHTML]="'SETTINGS.NOTIFICATIONS.TITLE_TEXT' | translate"></p>
  <div class="form-h-spacer mb-4"></div>
  <div class="px-3" *ngFor="let optionGroup of options; let first = first">
    <div class="mb-4 color-gray">{{userNotificationGroupTranslationMap[optionGroup.key] | translate}}</div>
    <app-check-mark
      *ngFor="let option of optionGroup.values"
      class="d-block mb-4"
      [value]="option.checked"
      (changed)="option.checked = $event"
      [name]="option.id"
      [text]="'SETTINGS.NOTIFICATIONS.COMPUTED_' + option.name | uppercase | translate"
    ></app-check-mark>
  </div>
  <app-loader #loader [cssClasses]="'loader--white'"></app-loader>
</div>
<div class="card-footer p-0 text-end">
  <button type="reset" class="btn btn-secondary btn-lg rounded-0 mw-11r" (click)="onReset()">
    {{"SETTINGS.CANCEL" | translate}}
  </button>
  <button type="submit" class="btn btn-primary btn-lg rounded-0 mw-11r" (click)="onSave()">
    {{"SETTINGS.SAVE" | translate}}
  </button>
</div>
