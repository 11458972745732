import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenericModalComponent } from './generic-modal.component';
import { CloseButtonModule } from '../close-button/close-button.module';
import { OkCancelModalComponent } from './ok-cancel-modal/ok-cancel-modal.component';
import { ModalHeaderDirective } from './directives/modal-header/modal-header.directive';
import { ModalBodyDirective } from './directives/modal-body/modal-body.directive';
import { ModalFooterDirective } from './directives/modal-footer/modal-footer.directive';
import { OkCancelOptionalControlModalComponent } from './ok-cancel-optional-control-modal/ok-cancel-optional-control-modal.component';

@NgModule({
  imports: [CommonModule, CloseButtonModule],
  declarations: [
    GenericModalComponent,
    OkCancelModalComponent,
    ModalHeaderDirective,
    ModalBodyDirective,
    ModalFooterDirective,
    OkCancelOptionalControlModalComponent,
  ],
  exports: [
    GenericModalComponent,
    OkCancelModalComponent,
    ModalHeaderDirective,
    ModalFooterDirective,
    ModalBodyDirective,
    OkCancelOptionalControlModalComponent,
  ]
})
export class GenericModalModule { }
