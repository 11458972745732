import { CommaPipe } from './../../../../shared/pipes/comma/comma.pipe';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {UserInterface} from '../../../../core/models/user.model';
import {UserRole} from '../../../../core/enums/user-role.enum';
import {CustomOrderArticleItemChangesInterface} from '../../custom-article-modal.model';
import {PriceFormatPipe} from '../../../../shared/pipes/price-format/price-format.pipe';
import {innerHeight, outerHeight} from '../../../../core/util/dom.helper';
import {FormComponentModel} from "../../form-component.model";
import {OtherSupplierOrderArticleInterface} from "../../../../core/models/order-article.model";

@Component({
  selector: 'app-other-supplier-form',
  templateUrl: './form.component.html',
})
export class FormComponent extends FormComponentModel implements OnInit {
  @Input() set item(item: OtherSupplierOrderArticleInterface) {
    this.form.setValue(this.formValues(item));
  };
  get item(): OtherSupplierOrderArticleInterface {
    return this.form.value;
  }

  @Input() user?: UserInterface;
  @Output() itemChanges: EventEmitter<CustomOrderArticleItemChangesInterface> = new EventEmitter();
  @ViewChild('formEl') formEl: ElementRef;

  requiredFields: string[] = ['title', 'pricelistPrice'];

  constructor(fb: UntypedFormBuilder, priceFormatPipe: PriceFormatPipe, commaPipe: CommaPipe) {
    super(fb, priceFormatPipe, commaPipe);
  }

  ngOnInit() {
    if (this.user.role.name === UserRole.ROLE_PM_NARBUTAS) {
      this.form.get('code').addValidators([Validators.required]);
      this.form.get('code').updateValueAndValidity();
      this.requiredFields.push('code');
    }

    this.form.get('pricelistPrice').addValidators([Validators.required]);
    this.form.get('pricelistPrice').updateValueAndValidity();

    super.ngOnInit();
  }

  calculateFormHeight() {
    const modalBodyEl = document.querySelector('.modal-body') as HTMLElement;
    const firstRowEl = modalBodyEl.querySelector('.p-2 > .container-fluid > .row:first-child') as HTMLElement;
    const modalBodyHeight = innerHeight(modalBodyEl);
    const modalBodyInnerPadding = 16; // hence .p-2
    const firstRowHeight = outerHeight(firstRowEl);

    this.formEl.nativeElement.style.maxHeight = `${modalBodyHeight - modalBodyInnerPadding - firstRowHeight}px`;
  }
}
