import { Directive, ElementRef, Input, OnDestroy, Renderer2 } from '@angular/core';
import { FieldService } from '../../../core/services/field/field.service';
import { FieldType } from './field-types.enum';
import { FieldClass } from '../../../core/enums/field-class';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[appHideByFieldType]',
})
export class HideByFieldTypeDirective implements OnDestroy {
  private subscription = new Subscription();

  constructor(private elRef: ElementRef, private renderer: Renderer2, private fieldService: FieldService) {
    /**
     * By default do not display field
     */
    this.hideOrShowField(false);
  }

  @Input('appHideByFieldType') set field(fieldType: FieldType | FieldType[]) {
    this.subscription.add(
      this.fieldService.getOptionsAsObservable(FieldClass.ORDER).subscribe(groups => {
        if (!Array.isArray(fieldType)) {
          fieldType = [fieldType];
        }
        const foundFields = groups.reduce((accumulator, current) => {
          accumulator.push(
            ...current.values.filter(value => {
              return value.checked && (<FieldType[]>fieldType).includes(value.name);
            })
          );
          return accumulator;
        }, []);
        this.hideOrShowField(!!foundFields.length);
      })
    );
  }

  private hideOrShowField(show: boolean): void {
    if (show) {
      this.renderer.removeStyle(this.elRef.nativeElement, 'display');
      return;
    }
    this.renderer.setStyle(this.elRef.nativeElement, 'display', 'none');
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
