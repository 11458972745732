import { Injectable } from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UserService} from '../../core/services/user/user.service';
import {UserInterface} from '../../core/models/user.model';
import {filter} from 'rxjs/operators';
import {DiscountModalComponent} from './discount-modal.component';
import {UserRole} from '../../core/enums/user-role.enum';

@Injectable()
export class DiscountModalOpenerService {

  constructor(
    private modalService: NgbModal,
    private userService: UserService
  ) { }

  open(orderId: number, ids: number[], savedCallback: Function | CallableFunction | any) {
    this.userService.fromStorage().pipe(filter(user => !!user)).subscribe((user: UserInterface) => {
      let modal = DiscountModalComponent;

      switch (user.role.name) {
        case UserRole.ROLE_PM_NARBUTAS:
          modal = DiscountModalComponent;
          break;
        case UserRole.ROLE_PM:
        case UserRole.ROLE_PM_RU:
          modal = DiscountModalComponent;
          break;
      }

      const modalRef = this.modalService.open(modal, {size: 'xl'});
      modalRef.componentInstance.orderId = orderId;
      modalRef.componentInstance.ids = ids;
      modalRef.componentInstance.saved.subscribe(() => {
        savedCallback();
      });
    });
  }
}
