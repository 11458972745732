import { Observable, of, switchMap, tap } from "rxjs"

/**
 * Observable operator that reuses previously emitted values when key is matched.
 * Previously emitted results are stored only as long as observable is subscribed
 * and only within scope of operator(not globally).
 * Works similar switchMap - when source emits, then projection function is called and
 * previous observable is replaced.
 *
 * @example
 * const sourceId = new Subject(number);
 * sourceId.pipe(
 *  storeSwitchMap(
 *    id => id,
 *    (id, key) => fetchSomeData(id)
 *  )
 * )
 * @param keyFunction function to return an identifier value/key
 * @param actionFunction function to return observable. If key is found, it won't be called.
 */
export const storeSwitchMap = <T, K, R>(
  keyFunction: (data: T) => K,
  actionFunction: (data: T, key: K) => Observable<R>
) => {
  return (source: Observable<T>): Observable<R> => {
    const store = new Map<K, R>();

    return source.pipe(
      switchMap(data => {
        const key = keyFunction(data);
        if (store.has(key)) {
          return of(store.get(key));
        }

        return actionFunction(data, key).pipe(
          tap(data => store.set(key, data))
        );
      })
    );
  }
}
