import {Component, Inject, OnDestroy, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {Subscription} from "rxjs";
import {ContentService} from './content.service';
import {filter, first} from "rxjs/operators";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {TreeService} from "../core/tree/tree.service";
import {RouteTypeEnums} from "../core/enums/route-types.enum";
import {DOCUMENT} from "@angular/common";
import {ContentEntryComponent} from "./content-entry/content-entry.component";
import {ContentListComponent} from "./content-list/content-list.component";
import {BreadcrumbModel} from "../shared/components/breadcrumbs/breadcrumb.model";

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
})
export class ContentComponent implements OnInit, OnDestroy {
  @ViewChild('content', {read: ViewContainerRef}) contentRef: ViewContainerRef;

  private subscriptions: Subscription = new Subscription();
  private baseUri = '';
  private currentUri = '';
  private currentPage = 1;
  private pathSegments: string[] = [];
  private uriToTitleMap: {} = {};

  loading = true;
  pageTitle?: string = null;
  breadcrumbs: BreadcrumbModel[] = [];

  constructor(
    private contentService: ContentService,
    private treeService: TreeService,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document
  ) {
  }

  ngOnInit() {
    this.subscribeForUrlChange();
    this.subscribeForItemChange();
    this.subscribeForListChange();

    this.parsePageInfo();
    this
      .fetchPathTranslations()
      .add(() => this.addBreadcrumbs());
    this.fetchContent();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.contentService.resetSubjects();
  }

  private subscribeForUrlChange() {
    this.subscriptions.add(
      this.router.events
        .pipe(filter(e => e instanceof NavigationEnd))
        .subscribe((e: NavigationEnd) => {

          this.parsePageInfo();
          this.markSubmenuCurrentItem();
          this
            .fetchPathTranslations()
            .add(() => this.addBreadcrumbs());
          this.fetchContent();
        })
    );
  }

  private subscribeForItemChange() {
    this.subscriptions.add(this.contentService.getItemAsObservable().subscribe(item => {
      this.pageTitle = item.translation.title;

      if (!item.hasChildren) {
        this.showEntryPage();
      }
    }));
  }

  private subscribeForListChange() {
    this.subscriptions.add(this.contentService.getListAsObservable().subscribe(() => {
      this.showListPage();
    }))
  }

  private markSubmenuCurrentItem() {
    const categoryUri = this.document.location.pathname.split('/')[2] ?? null;
    let current = null;

    if (null !== categoryUri) {
      current = this.treeService
        .getRootNavElement(RouteTypeEnums.INFO)
        .children
        .find(category => category.path === categoryUri);
    }

    this.treeService.modifyState({second: current});
  }

  private addBreadcrumbs() {
    this.breadcrumbs = [];

    // Add breadcrumbs from level 3 only
    if (3 > this.pathSegments.length) {
      return;
    }

    this.pathSegments.slice(1).forEach((path) => {
      this.breadcrumbs.push({
        title: this.uriToTitleMap[path] ?? path,
        uri: path,
        onClick: this.onBreadcrumbClick.bind(this),
      });
    });

    const lastBreadcrumb = this.breadcrumbs.slice(-1).shift() as unknown as BreadcrumbModel | undefined;

    if (lastBreadcrumb) {
      lastBreadcrumb.uri = lastBreadcrumb.onClick = null;
      lastBreadcrumb.isPageTitle = true;
    }
  }

  onBreadcrumbClick(elem: BreadcrumbModel, $event: MouseEvent) {
    $event.preventDefault();
    const uri = [this.baseUri];

    for (const breadcrumb of this.breadcrumbs) {
      uri.push(breadcrumb.uri)

      if (elem.uri === breadcrumb.uri) {
        break;
      }
    }

    this.router.navigate(uri);
  }

  private parsePageInfo() {
    const location = this.document.location;

    this.pathSegments = location.pathname.split('/').filter(s => !!s);
    this.baseUri = this.pathSegments[0] ?? null;
    this.currentUri = this.pathSegments.slice(-1).shift() ?? '';
    this.currentPage = Number(this.route.snapshot.queryParams['page'] ?? 1);
  }

  private fetchContent() {
    this.loading = true;

    return this.contentService
      .getContentItem(this.currentUri)
      .pipe(first())
      .subscribe(response => {
        this.contentService.setItemValue(response.data);

        if (response.data.hasChildren) {
          this.contentService
            .getContentList(this.currentUri, this.currentPage)
            .pipe(first())
            .subscribe(listResponse => {
              this.contentService.setListValue(listResponse.data);
              this.contentService.setPaginationValue(listResponse.meta);
              this.loading = false;
            });
          return;
        }

        this.loading = false;
      });
  }

  private fetchPathTranslations(): Subscription {
    const missing = this.pathSegments.filter(v => !this.uriToTitleMap[v]);

    if (!missing.length) {
      return Subscription.EMPTY;
    }

    return this.contentService
      .getContentTranslations(missing)
      .pipe(first())
      .subscribe(response => {
        response.data.forEach((item) => {
          this.uriToTitleMap[item.uri] = item.title;

          if (1
            && item.basicTranslation.uri
            && item.basicTranslation.title
          ) {
            this.uriToTitleMap[item.basicTranslation.uri] = item.basicTranslation.title;
          }
        });
      });
  }

  private showEntryPage() {
    this.contentRef?.clear();
    this.contentRef?.createComponent(ContentEntryComponent);
  }

  private showListPage() {
    this.contentRef?.clear();
    this.contentRef?.createComponent(ContentListComponent);
  }
}
