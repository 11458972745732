import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TooltipComponent} from './tooltip.component';
import {TooltipArrowClassGuesserService} from './tooltip-arrow-class-guesser.service';
import {TooltipStylesGeneratorService} from './tooltip-styles-generator.service';
import {TooltipGlobalOpenerDirective} from './tooltip-global-opener.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    TooltipComponent,
    TooltipGlobalOpenerDirective
  ],
  exports: [
    TooltipComponent,
    TooltipGlobalOpenerDirective
  ],
  providers: [
    TooltipArrowClassGuesserService,
    TooltipStylesGeneratorService,
  ]
})
export class TooltipModule {
}
